import React, { Component } from "react";
import { connect } from "react-redux";

import { sdg1662Actions } from "../../../../../_actions";

class Introduction extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }
  state = {};

  handleChange(e) {
    const { dispatch } = this.props;

    dispatch(sdg1662Actions.updateMetadataField(e.target.name, e.target.value));
  }

  render() {
    return (
      <React.Fragment>
        {/* <div className="row" style={{ display: "block" }}> */}
        <div className="box-header with-border">
          <div className="col-sm-12" style={{display:"flex", justifyContent:"space-between"}}>
            <h3>Introduction</h3>
            <img
              src="/resources/img/undp-logo-30.svg"
              style={{ height: "55px", marginLeft: "60px" }}
              alt="undp logo"
            />
          </div>
        </div>
        <div className="box-body">
          <div className="row" style={{ marginBottom: "20px" }}>
            <div className="col-sm-12">
              <h5>
                SDG 16.6.2: Proportion of population satisfied with their last
                experience of public services, specifically (a) healthcare
                services, (b) education services and (c) government services
                (government-issued IDs and civil registration of life events)
              </h5>
            </div>
          </div>
          <div className="row" style={{ marginBottom: "10px" }}>
            <div className="col-lg-6">
              <img
                src="/resources/img/sdg16_6_2-1.jpg"
                className="intro-image"
                alt="sdg1662"
              />
              <div style={{ fontSize: "11px" }}>
                Image credit UNDP Pakistan / Mian Khurshee
              </div>
            </div>
            <div className="col-lg-6">
              <div style={{ marginBottom: "10px" }}>
                SDG indicator 16.6.2 measures levels of public satisfaction with
                people’s last experience with public services, in the three
                service areas of healthcare, education and government services
                (i.e. services to obtain government-issued identification
                documents and services for the civil registration of life events
                such as births, marriages and deaths). Questions on education
                may refer to either primary or secondary education – and
                separate computation of results is recommended for the two
                levels, resulting in de facto four service areas.
              </div>
              <div style={{ marginBottom: "10px" }}>
                Respondents are asked to reflect on their last experience with
                each service and to provide their opinions on five attributes of
                these services, such as accessibility, affordability and equal
                treatment for everyone. Data producers should then compute the
                average share of positive responses across the five attribute
                questions.
              </div>
              <div>
                In addition, a question asks respondents for their overall
                satisfaction level with each service. Please read carefully the
                full&nbsp;
                <a
                  href="https://unstats.un.org/sdgs/metadata/files/Metadata-16-06-02.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  metadata&nbsp;
                </a>
                for this indicator before submitting your data.
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              A PDF version of the data reporting form for this indicator can be
              downloaded <a target="_blank" href="/resources/files/16.6.2.pdf">here</a>. This PDF form is only for NSOs’ internal
              documentation and not a mandatory requirement of the reporting
              process.
            </div>
          </div>        
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { data, error } = state.sdg1662State;

  return {
    data,
    error,
  };
}

const connectedIntroduction = connect(mapStateToProps)(Introduction);
export { connectedIntroduction as Introduction };
