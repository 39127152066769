import React, { Component } from "react";
import { TextInput } from "carbon-components-react";
import { connect } from "react-redux";

import { sdg1671bActions } from "../../../../../../../_actions";
import YesNoRadio from "../../../../../../common/elements/YesNoRadio";

class SourceUnitData extends Component {
  constructor(props) {
    super(props);

    this.handleUnitDataRadioChange = this.handleUnitDataRadioChange.bind(this);
    this.handleUnitChange = this.handleUnitChange.bind(this);
  }

  handleChange(e) {
    const { dispatch } = this.props;

    dispatch(
      sdg1671bActions.updateSourceField(e.target.name, e.target.value)
    );
  }

  handleUnitChange(e) {
    let { dispatch } = this.props;

    let target = e.target.name.split(":");

    dispatch(
      sdg1671bActions.updateUnitDataField(parseInt(target[0]), target[1], e.target.value)
    );
  }  

  handleUnitDataRadioChange(name, value) {
    const { dispatch } = this.props;

    let target = name.split(":");

    dispatch(sdg1671bActions.updateUnitDataField(parseInt(target[0]), target[1], value));
  }

  render() {

    return (
      <React.Fragment>
        <br />

        <div className="col-sm-12">
          <table
            className="table table-bordered dataTable"
            name="question-table"
            style={{ tableLayout: "fixed" }}
          >
            <thead>
              <tr>
                <th></th>
                <th>Excluded?</th>
                <th>If not excluded please explain:</th>
              </tr>
            </thead>
            <tbody>
              <tr role="row">
                <td>Local government units (smallest geographical areas distinguished for administrative and political purposes: to be distinguished from ‘state government units’ as defined by the 20008 SNA – such as provinces or states – for which reporting is required)</td>
                <td>
                  <YesNoRadio
                    name="0:excluded"
                    defaultValue={
                      this.props.data &&
                      this.props.data.source &&
                      this.props.data.source.unit_data[0]["excluded"] !==
                        null
                        ?  this.props.data.source.unit_data[0]["excluded"]
                        : null
                    }
                    _handleSelectedValue={this.handleUnitDataRadioChange}
                  />
                </td>
                <td>
                  <TextInput
                        id="0:excluded_description"
                        name="0:excluded_description"
                        // className="input-1671b"
                        labelText=""
                        onChange={this.handleUnitChange}
                        value={
                          this.props.data && 
                          this.props.data.source &&
                          this.props.data.source.unit_data[0]["excluded_description"]
                            ? this.props.data.source.unit_data[0]["excluded_description"]
                            : ""
                        }
                  />
                </td>
              </tr>
              <tr>
                <td>Military</td>
                <td>
                  <YesNoRadio
                    name="1:excluded"
                    defaultValue={
                      this.props.data &&
                      this.props.data.source &&
                      this.props.data.source.unit_data[1]["excluded"] !==
                        undefined
                        ?  this.props.data.source.unit_data[1]["excluded"]
                        : null
                    }
                    _handleSelectedValue={this.handleUnitDataRadioChange}
                  />                </td>
                <td>
                  <TextInput
                        id="1:excluded_description"
                        name="1:excluded_description"
                        // className="input-1671b"
                        labelText=""
                        onChange={this.handleUnitChange}
                        value={
                          this.props.data && 
                          this.props.data.source &&
                          this.props.data.source.unit_data[1]["excluded_description"]
                            ? this.props.data.source.unit_data[1]["excluded_description"]
                            : ""
                        }
                  />
                </td>
              </tr>

              <tr>
                <td>Public corporations and quasi-corporations owned and controlled by government units</td>

                <td>
                  <YesNoRadio
                    name="2:excluded"
                    defaultValue={
                      this.props.data &&
                      this.props.data.source &&
                      this.props.data.source.unit_data[2]["excluded"] !==
                        undefined
                        ?  this.props.data.source.unit_data[2]["excluded"]
                        : null
                    }
                    _handleSelectedValue={this.handleUnitDataRadioChange}
                  />                </td>
                <td>
                  <TextInput
                        id="2:excluded_description"
                        name="2:excluded_description"
                        // className="input-1671b"
                        labelText=""
                        onChange={this.handleUnitChange}
                        value={
                          this.props.data && 
                          this.props.data.source &&
                          this.props.data.source.unit_data[2]["excluded_description"]
                            ? this.props.data.source.unit_data[2]["excluded_description"]
                            : ""
                        }
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { data, error } = state.sdg1671bState;

  return {
    data,
    error,
  };
}

const connectedSource = connect(mapStateToProps)(SourceUnitData);

export { connectedSource as SourceUnitData };
