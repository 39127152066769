import { sdg1672Service, commonService } from "../_services";

import { sdg1672Constants } from "../_constants";
import { notifyError } from "../components/common/notification/NotificationHelper";
import { userActions } from ".";

export const sdg1672Actions = {
  getIndicatorData,
  updateDataField,
  updateMetadataField,
  clearIndicatorData,
  saveIndicatorData,
  validateIndicatorData,
  finalizeIndicatorData,
  updateQuestionaireFile,
  updateImplManualFile,
  updateIntManualFile,

};

function getIndicatorData(id) {
  return dispatch => {
    sdg1672Service.getIndicatorData(id).then(
      data => {
        dispatch(get_success(data));
      },
      error => {
        if (error.response.status === 401) {
          notifyError("Session expired. Please login again!")
          dispatch(userActions.logout());
        }
        if (error.response.status === 500) {
          notifyError(
            "Server not available at the moment. Please try again later."
          );
        }
      }
    );
  };
}

function saveIndicatorData(data) {
  return dispatch => {
    sdg1672Service.saveIndicatorData(data).then(
      data => {
        dispatch(get_success(data));
      },
      error => {
        console.log("error", error);
        if (error.response.status === 500) {
          notifyError(
            "Server not available at the moment. Please try again later."
          );
        } else if (error.response.status === 401) {
          notifyError("Session expired. Please login again!")
          const { dispatch } = this.props;
          dispatch(userActions.logout());
        } else {
          notifyError(
            "There are arrors on the form, please review data before submitting!"
          );
          dispatch(save_data_failure(error.response.data));
        }
      }
    );
  };
}

function finalizeIndicatorData(finalizeStr, data) {
  return dispatch => {
    sdg1672Service.saveIndicatorData(data).then(
      data => {
        dispatch(get_success(data));
        commonService.finalizeIndicatorData(finalizeStr).then(data => {
          dispatch(get_success(data));
        });
      },
      error => {
        console.log("error", error);
        if (error.response.status === 500) {
          notifyError(
            "Server not available at the moment. Please try again later."
          );
        } else if (error.response.status === 401) {
          notifyError("Session expired. Please login again!")
          const { dispatch } = this.props;
          dispatch(userActions.logout());
        } else {
          notifyError(
            "There are arrors on the form, please review data before submitting!"
          );
          dispatch(save_data_failure(error.response.data));
        }
      }
    );
  };
}

function validateIndicatorData(validateStr, data) {
  return dispatch => {
    dispatch(get_success(data));
};}

function updateDataField(index, name, val) {
  return dispatch => {
    let value = val === "" ? null : val;
    dispatch(update_data_field({ index, name, value }));
  };
}

function updateMetadataField(name, val) {
  return dispatch => {
    let value = val === "" ? null : val;
    dispatch(update_metadata_field({ name, value }));
  };
}

function clearIndicatorData() {
  return dispatch => {
    dispatch(clear_indicator_data());
  };
}

function updateQuestionaireFile(data) {
  return dispatch => {
    dispatch(update_questionaire_file({ data }));
  };
}

function updateImplManualFile(data) {
  return dispatch => {
    dispatch(update_impl_manual_file({ data }));
  };
}

function updateIntManualFile(data) {
  return dispatch => {
    dispatch(update_int_manual_file({ data }));
  };
}


function update_data_field(data) {
  return {
    type: sdg1672Constants.UPDATE_1672_DATA_FIELD,
    data
  };
}

function update_metadata_field(data) {
  return {
    type: sdg1672Constants.UPDATE_1672_METADATA_FIELD,
    data
  };
}

function get_success(data) {
  return {
    type: sdg1672Constants.GET_1672_DATA_SUCCESS,
    data
  };
}

function clear_indicator_data() {
  return {
    type: sdg1672Constants.CLEAR_1672_DATA
  };
}

function save_data_failure(error) {
  return {
    type: sdg1672Constants.SAVE_1672_DATA_FAILURE,
    error: error
  };
}

function update_questionaire_file(data) {
  return {
    type: sdg1672Constants.UPDATE_1672_QUESTIONAIRE_FILE,
    data
  };
}

function update_impl_manual_file(data) {
  return {
    type: sdg1672Constants.UPDATE_1672_IMPL_MANUAL_FILE,
    data
  };
}

function update_int_manual_file(data) {
  return {
    type: sdg1672Constants.UPDATE_1672_INT_MANUAL_FILE,
    data
  };
}