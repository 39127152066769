import { combineReducers } from "redux";

import { authentication } from "./authentication.reducer";
import { sdg1662State } from "./sdg1662.reducer";
import { sdg1633State } from "./sdg1633.reducer";
import { sdg1671bState } from "./sdg1671b.reducer";
import { sdg1671cState } from "./sdg1671c.reducer";
import { sdg1672State } from "./sdg1672.reducer";

const rootReducer = combineReducers({
  authentication,
  sdg1662State,
  sdg1633State,
  sdg1671bState,
  sdg1671cState,
  sdg1672State
});

export default rootReducer;
