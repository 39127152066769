import React, { Component } from "react";
import { connect } from "react-redux";
import { Source } from "./metadata/Source";
import { Questionnaire } from "./metadata/Questionnaire";
import { Other } from "./metadata/Other";
import { Sample } from "./metadata/Sample";

class Metadata extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      error: props.error
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    // console.log("Mounted", this.props);
  }

  handleChange(e) {
    this.props.onHandleChange(e);
  }

  render() {
    return (
      <React.Fragment>
        <div className="box-header with-border">
          <div className="col-sm-8">
            <h3>Metadata fields</h3>
          </div>
        </div>
        <div className="box-body"></div>
        {/* {header} */}
        <Source />
        <Sample />
        <Questionnaire />
        <Other />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  const { data, error } = state.sdg1671cState;

  return {
    user,
    data,
    error
  };
}

const connectedMetadata = connect(mapStateToProps)(Metadata);

export { connectedMetadata as Metadata };
