import React, { Component } from "react";

import {
  TableRow,
  TableCell
} from "carbon-components-react/lib/components/DataTable/";

import { Button } from "carbon-components-react";

import axios from 'axios'

class NSOUserTableRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      url: props.url,
      country: props.country
    };

    this.onUserUpdate = this.onUserUpdate.bind(this);
  }

  state = {};

  onUserUpdate(updatedUser) {
    let newUser = {
      country_name: updatedUser.country_name,
      user_type: updatedUser.user_type,
      last_name: updatedUser.last_name,
      position: updatedUser.position,
      institution: updatedUser.institution,
      email: updatedUser.email,
      email_2: updatedUser.email_2
    };

    this.setState({ user: newUser });
  }

  deleteUser(id) {
    this.props.handleDelete(id)
  } 

  render() {
    return (
      <React.Fragment key={this.props.row.id}>
        <TableRow {...this.props.row_props}>
          {Object.keys(this.state.user)
            .filter(key => key !== "id")
            .map(key => {
              return (
                <TableCell key={this.props.row.id + ":" + key}>
                  {this.state.user[key]}
                </TableCell>
              );
            })}
            <TableCell>
              <Button
                onClick={() => this.deleteUser(this.props.row.id)}
                
              >Delete</Button>
          </TableCell>
          {/* {this.props.row.cells.map(cell => (
            <TableCell key={cell.id}>{cell.value}</TableCell>
          ))} */}
        </TableRow>
      </React.Fragment>
    );
  }
}

export default NSOUserTableRow;
