import React, { Component } from "react";

import { Close16 } from "@carbon/icons-react";

class FileUploader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      file_name: ""
    };
  
  }

  componentDidMount() {
    this.setState({file_name: this.props.value})
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value || prevProps.upload_status !== this.props.upload_status) {
      this.setState({file_name: this.props.value})
    }
  }

  handleOnChange = event => {
    this.setState({ file_name: event.target.files[0].name });
    this.props.onChange(event);
  };

  close = () => {
    this.setState({ file_name: "" });
    this.props.onDelete();
  };

  download = () => {
    //this.setState({ file_name: "" });
    this.props.onDownload();
  };

  getUploadStatus = () => {
    //console.log("UPLOAD STATUS", this.props.enable_delete);
    switch (this.props.upload_status) {
      case "edit":
        return (
          this.props.enable_delete && <span
            title="Click to delete file"
            className="bx--file__state-container"
            onClick={this.close}
          >
            <Close16 />
          </span>
        );
      case "uploading":
        return (
          <span className="bx--file__state-container">
            <div
              aria-atomic="true"
              aria-labelledby="loading-id-2"
              aria-live="assertive"
              className="bx--loading bx--loading--small"
            >
              <label id="loading-id-2" className="bx--visually-hidden">
                Provide icon description
              </label>
              <svg className="bx--loading__svg" viewBox="-75 -75 150 150">
                <title>Provide icon description</title>
                <circle
                  className="bx--loading__background"
                  cx="0"
                  cy="0"
                  r="26.8125"
                ></circle>
                <circle
                  className="bx--loading__stroke"
                  cx="0"
                  cy="0"
                  r="26.8125"
                ></circle>
              </svg>
            </div>
          </span>
        );
      default:
        return null;
    }
  };

  render() {
    let upload = this.getUploadStatus();

    return (
      <div className="bx--form-item">
        <p className="bx--file--label">{this.props.labelTitle}</p>
        <label
          className="bx--btn bx--btn--sm bx--btn--primary"
          htmlFor={this.props.id}
          style={{width:"100%"}}
        >
          {this.props.buttonLabel}
        </label>
        <input
          className="bx--visually-hidden"
          id={this.props.id}
          type="file"
          name="file"
          onChange={this.handleOnChange}
        />
        {this.state.file_name ? (
          <div className="bx--file-container">
            <span className="bx--file__selected-file" >
              <a title="Click to download file" onClick={this.download} style={{cursor:"pointer"}}>
                {this.state.file_name}
              </a>
              {upload}
            </span>
          </div>
        ) : null}
      </div>
    );
  }
}

export default FileUploader;
