import React, { Component } from "react";

class SideNavLinkContact extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  state = {};

  handleClick(e) {
    this.props.onClick(e);
  }
  render() {
    let elem = this.props.isActive ? (
      <li className="bx--side-nav__item">
        <a
          className="bx--side-nav__link  bx--side-nav__link--current"
          href="mailto:sdg16indicators@undp.org"
        >
          {/* ::before */}
          <div className="bx--side-nav__icon bx--side-nav__icon--small">
            <svg
              //   xmlns=
              //   width="32"
              //   height="32"
              viewBox="0 0 32 32"
              aria-hidden="true"
              style={{
                focusable: "false",
                perspectiveOrigin: "xMidYMid meet",
                willChange: "transform",
                xmlns: "http://www.w3.org/2000/svg",
                width: "26",
                height: "26"
                // viewBox: "0 0 32 32"
              }}
            >
              <path d="M28 6H4a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h24a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2zm-2.2 2L16 14.78 6.2 8zM4 24V8.91l11.43 7.91a1 1 0 0 0 1.14 0L28 8.91V24z"></path>{" "}
            </svg>
          </div>
          <div className="bx--side-nav__link-text">
            <div className="bx--side-nav__link-text">{this.props.name}</div>
            <div
              className="bx--side-nav__link-text"
              style={{ fontSize: "9px" }}
            >
              {this.props.description}
            </div>
          </div>
        </a>
      </li>
    ) : (
      <li className="bx--side-nav__item">
        <a
          className="bx--side-nav__link"
          href="mailto:sdg16indicators@undp.org"
        >
          <div className="bx--side-nav__icon bx--side-nav__icon--small">
            <svg
              //   xmlns=
              //   width="32"
              //   height="32"
              viewBox="0 0 32 32"
              aria-hidden="true"
              style={{
                focusable: "false",
                perspectiveOrigin: "xMidYMid meet",
                willChange: "transform",
                xmlns: "http://www.w3.org/2000/svg",
                width: "26",
                height: "26"
                // viewBox: "0 0 32 32"
              }}
            >
              <path d="M28 6H4a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h24a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2zm-2.2 2L16 14.78 6.2 8zM4 24V8.91l11.43 7.91a1 1 0 0 0 1.14 0L28 8.91V24z"></path>{" "}
            </svg>
          </div>
          <div className="bx--side-nav__link-text">
            <div className="bx--side-nav__link-text">{this.props.name}</div>
            <div
              className="bx--side-nav__link-text"
              style={{ fontSize: "9px" }}
            >
              {this.props.description}
            </div>
          </div>
        </a>
      </li>
    );

    return <React.Fragment>{elem}</React.Fragment>;
  }
}

export default SideNavLinkContact;
