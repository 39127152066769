import { RadioButton } from "carbon-components-react";
import React, {Component} from "react";


class DisputeResolutionTableSelection extends Component {
    constructor(props) {
        super(props);
        this.handleRadioClick = this.handleRadioClick.bind(this);
    }

    handleRadioClick(e) {
        //console.log(e.target.name, e.target.value);
        if (this.props._handleSelectedValue)
          this.props._handleSelectedValue(e.target.name, e.target.value === 'true');
      }

    render () {
        return (
            <React.Fragment>
                <td>
                    <RadioButton type="radio" name={this.props.name} value="true" checked={this.props.defaultValue === true} onClick={this.handleRadioClick} />
                </td>
                <td>
                    <RadioButton type="radio"  name={this.props.name} value="false" checked={this.props.defaultValue === false} onClick={this.handleRadioClick} />
                </td>
            </React.Fragment>
        )
    }
}


class DisputeResolutionTable extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick(name, value) {
        this.props.onChange(name,value);
    }

    render() {
        return (
        <div className="row">
            <div className="col-sm-8 adaptive-table">
                <table
                    className="table table-bordered dataTable"
                    name="question-table"
                    style={{ tableLayout: "fixed" }}
                >
                    <thead>
                    <tr>
                        <th style={{width:"5%"}}></th>
                        <th style={{width:"61.7%"}}>Dispute resolution mechanism included in your survey?</th>
                        <th style={{textAlign:"center"}}>Yes</th>
                        <th style={{textAlign:"center"}}>No</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>A</td>
                            <td>Court or tribunal </td>
                            <DisputeResolutionTableSelection name="court_tribunal"  defaultValue = {this.props.data ? this.props.data["court_tribunal"] : null} _handleSelectedValue = {this.handleClick} />
                        </tr>
                        <tr>
                            <td>B</td>
                            <td>Police (or other law enforcement)</td>
                            <DisputeResolutionTableSelection name="police"  defaultValue = {this.props.data ? this.props.data["police"] : null} _handleSelectedValue = {this.handleClick} />
                        </tr>                        
                        <tr>
                            <td>C</td>
                            <td>A government or municipal office or other formal designated authority or agency</td>
                            <DisputeResolutionTableSelection name="other_formal_authority"  defaultValue = {this.props.data ? this.props.data["other_formal_authority"] : null} _handleSelectedValue = {this.handleClick}/>
                        </tr>                        
                        <tr>
                            <td>D</td>
                            <td>Religious leader or authority</td>
                            <DisputeResolutionTableSelection name="religious_leader"  defaultValue = {this.props.data ? this.props.data["religious_leader"] : null} _handleSelectedValue = {this.handleClick}/>
                        </tr>                        
                        <tr>
                            <td>E</td>
                            <td>Community leader or authority (such as village elder, or local leader) </td>
                            <DisputeResolutionTableSelection name="communitu_leader" defaultValue = {this.props.data ? this.props.data["communitu_leader"] : null} _handleSelectedValue = {this.handleClick} />
                        </tr>                        
                        <tr>
                            <td>F</td>
                            <td>Lawyer, solicitor, paralegal</td>
                            <DisputeResolutionTableSelection name="lawyer_solicitor_paralegal" defaultValue = {this.props.data ? this.props.data["lawyer_solicitor_paralegal"] : null} _handleSelectedValue = {this.handleClick} />
                        </tr>                        
                        <tr>
                            <td>G</td>
                            <td>Other formal complaints or appeal process</td>
                            <DisputeResolutionTableSelection name="other_formal" defaultValue = {this.props.data ? this.props.data["other_formal"] : null} _handleSelectedValue = {this.handleClick} />
                        </tr>                        
                        <tr>
                            <td>H</td>
                            <td>Other external help, such as mediation, conciliation, arbitration</td>
                            <DisputeResolutionTableSelection name="other_external" defaultValue = {this.props.data ? this.props.data["other_external"] : null} _handleSelectedValue = {this.handleClick} />
                        </tr>                        
                        <tr>
                            <td>X</td>
                            <td>Other person or organisation</td>
                            <DisputeResolutionTableSelection name="other" defaultValue = {this.props.data ? this.props.data["other"] : null} _handleSelectedValue = {this.handleClick} />
                        </tr>                        

                    </tbody>
                </table>
            </div>
        </div>
        );
    }
}


export default DisputeResolutionTable;