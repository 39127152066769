import React, { Component } from "react";

import { Welcome } from "../common/welcome";
import { Users } from "../users/users";
import { Login } from "../login/Login";
import { UNDPAuth } from "../login/UNDPAuth";
import { NSOLogin } from "../login/NSOLogin";
import { SDG16_7_1b } from "../sdgs/16/7_1_b/SDG16_7_1b";
import TableSDG16_7_1b from "../sdgs/16/7_1_b/TableSDG16_7_1_b";
import { SDG16_7_1c } from "../sdgs/16/7_1_c/SDG16_7_1c";
import TableSDG16_7_1c from "../sdgs/16/7_1_c/TableSDG16_7_1_c";
import { SDG16_7_2 } from "../sdgs/16/7_2/SDG16_7_2";
import { SDG16_6_2 } from "../sdgs/16/6_2/SDG16_6_2";
import TableSDG1662 from "../sdgs/16/6_2/TableSDG16_6_2";
import TableSDG1672 from "../sdgs/16/7_2/TableSDG16_7_2";
import { UsersTable } from "../users/UsersTable";
import { OtherUsersTable } from "../users/OtherUsersTable";
import { NSOUsersTable } from "../users/NSOUsersTable";

import { AuthenticatedRouter } from "./AuthenticatedRouter";

import { Switch, Route } from "react-router-dom";
import NotifyUsers from "../users/NotifyUsers";
import { SDG16_3_3 } from "../sdgs/16/3_3/SDG16_3_3";
import TableSDG16_3_3 from "../sdgs/16/3_3/TableSDG16_3_3";

class MainRouter extends Component {
  state = {};
  render() {
    return (
      <Switch>
        <Route exact path="/undpAuth" component={UNDPAuth}></Route>
        <Route exact path="/login" component={Login}></Route>
        <Route exact path="/login/nso" component={NSOLogin}></Route>
        <AuthenticatedRouter
          exact
          path="/"
          component={Welcome}
        ></AuthenticatedRouter>
        <AuthenticatedRouter
          exact
          path="/users"
          component={Users}
        ></AuthenticatedRouter>
        <AuthenticatedRouter
          exact
          path="/users-view"
          component={UsersTable}
        ></AuthenticatedRouter>
        <AuthenticatedRouter
          exact
          path="/notify-users"
          component={NotifyUsers}
        ></AuthenticatedRouter>
        <AuthenticatedRouter exact path="/sdg1671b" component={SDG16_7_1b} />
        <AuthenticatedRouter
          exact
          path="/sdg1671b/:id"
          component={SDG16_7_1b}
        ></AuthenticatedRouter>
        <AuthenticatedRouter
          exact
          path="/sdg1671b-view"
          component={TableSDG16_7_1b}
        ></AuthenticatedRouter>
        <AuthenticatedRouter
          exact
          path="/sdg1671c"
          component={SDG16_7_1c}
        ></AuthenticatedRouter>
        <AuthenticatedRouter
          exact
          path="/sdg1671c/:id"
          component={SDG16_7_1c}
        ></AuthenticatedRouter>
        <AuthenticatedRouter
          exact
          path="/sdg1671c-view"
          component={TableSDG16_7_1c}
        ></AuthenticatedRouter>
        <AuthenticatedRouter
          exact
          path="/sdg1672"
          component={SDG16_7_2}
        ></AuthenticatedRouter>
        <AuthenticatedRouter
          exact
          path="/sdg1672/:id"
          component={SDG16_7_2}
        ></AuthenticatedRouter>
        <AuthenticatedRouter
          exact
          path="/sdg1672-view"
          component={TableSDG1672}
        ></AuthenticatedRouter>
        <AuthenticatedRouter
          exact
          path="/sdg1662"
          component={SDG16_6_2}
        ></AuthenticatedRouter>
        <AuthenticatedRouter
          exact
          path="/sdg1662/:id"
          component={SDG16_6_2}
        ></AuthenticatedRouter>
        <AuthenticatedRouter
          exact
          path="/sdg1662-view"
          component={TableSDG1662}
        ></AuthenticatedRouter>
        <AuthenticatedRouter
          exact
          path="/sdg1633"
          component={SDG16_3_3}
        ></AuthenticatedRouter>
        <AuthenticatedRouter
          exact
          path="/sdg1633/:id"
          component={SDG16_3_3}
        ></AuthenticatedRouter>
        <AuthenticatedRouter
          exact
          path="/sdg1633-view"
          component={TableSDG16_3_3}
        ></AuthenticatedRouter>
        <AuthenticatedRouter
          exact
          path="/ogc_users"
          component={UsersTable}
        ></AuthenticatedRouter>
        <AuthenticatedRouter
          exact
          path="/nso_users"
          component={NSOUsersTable}
        ></AuthenticatedRouter>        
        <AuthenticatedRouter
          exact
          path="/oecd_unodc_users"
          component={OtherUsersTable}
        ></AuthenticatedRouter>      
        </Switch>
    );
  }
}

export default MainRouter;
