import React, { Component } from "react";
import { TextArea, TextInput } from "carbon-components-react";
import { connect } from "react-redux";

import { sdg1671cActions } from "../../../../../../_actions";
import YesNoRadio from "../../../../../common/elements/YesNoRadio";

class Source extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anotherSource: this.checkAnotherSource()
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleAnothersourceRadioChange = this.handleAnothersourceRadioChange.bind(
      this
    );
  }

  handleChange(e) {
    const { dispatch } = this.props;

    dispatch(
      sdg1671cActions.updateMetadataField(e.target.name, e.target.value)
    );
  }

  handleAnothersourceRadioChange(name, value) {
    this.setState({ anotherSource: value });
  }

  checkAnotherSource() {
    return (
      this.props.data &&
      (this.props.data["nss_1_name"] != null ||
        this.props.data["nss_2_name"] != null ||
        this.props.data["nss_3_name"] != null)
    );
  }

  render() {
    let anotherTable = this.state.anotherSource ? (
      <React.Fragment>
        <div className="col-sm-12">
          Please provide here the contact information for your focal point(s) in
          this/these institution(s):
        </div>
        <br />

        <div className="col-sm-12">
          <table
            className="table table-bordered dataTable"
            name="question-table"
            style={{ tableLayout: "fixed" }}
          >
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Institution</th>
                <th>Position</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              <tr role="row">
                <td>Focal point 1:</td>
                <td>
                  <TextInput
                    id="nss_1_name"
                    name="nss_1_name"
                    // className="input-1671b"
                    labelText=""
                    onChange={this.handleChange}
                    value={this.props.data ? this.props.data["nss_1_name"] : ""}
                    invalid={
                      this.props.error &&
                      this.props.error["nss_1_name"] !== undefined
                    }
                    invalidText={
                      this.props.error && this.props.error["nss_1_name"]
                        ? this.props.error["nss_1_name"][0]
                        : ""
                    }
                  />
                </td>
                <td>
                  <TextInput
                    id="nss_1_institution"
                    name="nss_1_institution"
                    // className="input-1671b"
                    labelText=""
                    onChange={this.handleChange}
                    value={
                      this.props.data
                        ? this.props.data["nss_1_institution"]
                        : ""
                    }
                    invalid={
                      this.props.error &&
                      this.props.error["nss_1_institution"] !== undefined
                    }
                    invalidText={
                      this.props.error && this.props.error["nss_1_institution"]
                        ? this.props.error["nss_1_institution"][0]
                        : ""
                    }
                  />
                </td>
                <td>
                  <TextInput
                    id="nss_1_position"
                    name="nss_1_position"
                    // className="input-1671b"
                    labelText=""
                    onChange={this.handleChange}
                    value={
                      this.props.data ? this.props.data["nss_1_position"] : ""
                    }
                    invalid={
                      this.props.error &&
                      this.props.error["nss_1_position"] !== undefined
                    }
                    invalidText={
                      this.props.error && this.props.error["nss_1_position"]
                        ? this.props.error["nss_1_position"][0]
                        : ""
                    }
                  />
                </td>
                <td>
                  <TextInput
                    id="nss_1_email"
                    name="nss_1_email"
                    // className="input-1671b"
                    labelText=""
                    onChange={this.handleChange}
                    value={
                      this.props.data ? this.props.data["nss_1_email"] : ""
                    }
                    invalid={
                      this.props.error &&
                      this.props.error["nss_1_email"] !== undefined
                    }
                    invalidText={
                      this.props.error && this.props.error["nss_1_email"]
                        ? this.props.error["nss_1_email"][0]
                        : ""
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Focal point 2:</td>
                <td>
                  <TextInput
                    id="nss_2_name"
                    name="nss_2_name"
                    // className="input-1671b"
                    labelText=""
                    onChange={this.handleChange}
                    value={this.props.data ? this.props.data["nss_2_name"] : ""}
                    invalid={
                      this.props.error &&
                      this.props.error["nss_2_name"] !== undefined
                    }
                    invalidText={
                      this.props.error && this.props.error["nss_2_name"]
                        ? this.props.error["nss_2_name"][0]
                        : ""
                    }
                  />
                </td>
                <td>
                  <TextInput
                    id="nss_2_institution"
                    name="nss_2_institution"
                    // className="input-1671b"
                    labelText=""
                    onChange={this.handleChange}
                    value={
                      this.props.data
                        ? this.props.data["nss_2_institution"]
                        : ""
                    }
                    invalid={
                      this.props.error &&
                      this.props.error["nss_2_institution"] !== undefined
                    }
                    invalidText={
                      this.props.error && this.props.error["nss_2_institution"]
                        ? this.props.error["nss_2_institution"][0]
                        : ""
                    }
                  />
                </td>
                <td>
                  <TextInput
                    id="nss_2_position"
                    name="nss_2_position"
                    // className="input-1671b"
                    labelText=""
                    onChange={this.handleChange}
                    value={
                      this.props.data ? this.props.data["nss_2_position"] : ""
                    }
                    invalid={
                      this.props.error &&
                      this.props.error["nss_2_position"] !== undefined
                    }
                    invalidText={
                      this.props.error && this.props.error["nss_2_position"]
                        ? this.props.error["nss_2_position"][0]
                        : ""
                    }
                  />
                </td>
                <td>
                  <TextInput
                    id="nss_2_email"
                    name="nss_2_email"
                    // className="input-1671b"
                    labelText=""
                    onChange={this.handleChange}
                    value={
                      this.props.data ? this.props.data["nss_2_email"] : ""
                    }
                    invalid={
                      this.props.error &&
                      this.props.error["nss_2_email"] !== undefined
                    }
                    invalidText={
                      this.props.error && this.props.error["nss_2_email"]
                        ? this.props.error["nss_2_email"][0]
                        : ""
                    }
                  />
                </td>
              </tr>

              <tr>
                <td>Focal point 3:</td>

                <td>
                  <TextInput
                    id="nss_3_name"
                    name="nss_3_name"
                    // className="input-1671b"
                    labelText=""
                    onChange={this.handleChange}
                    value={this.props.data ? this.props.data["nss_3_name"] : ""}
                    invalid={
                      this.props.error &&
                      this.props.error["nss_3_name"] !== undefined
                    }
                    invalidText={
                      this.props.error && this.props.error["nss_3_name"]
                        ? this.props.error["nss_3_name"][0]
                        : ""
                    }
                  />
                </td>
                <td>
                  <TextInput
                    id="nss_3_institution"
                    name="nss_3_institution"
                    // className="input-1671b"
                    labelText=""
                    onChange={this.handleChange}
                    value={
                      this.props.data
                        ? this.props.data["nss_3_institution"]
                        : ""
                    }
                    invalid={
                      this.props.error &&
                      this.props.error["nss_3_institution"] !== undefined
                    }
                    invalidText={
                      this.props.error && this.props.error["nss_3_institution"]
                        ? this.props.error["nss_3_institution"][0]
                        : ""
                    }
                  />
                </td>
                <td>
                  <TextInput
                    id="nss_3_position"
                    name="nss_3_position"
                    // className="input-1671b"
                    labelText=""
                    onChange={this.handleChange}
                    value={
                      this.props.data ? this.props.data["nss_3_position"] : ""
                    }
                    invalid={
                      this.props.error &&
                      this.props.error["nss_3_position"] !== undefined
                    }
                    invalidText={
                      this.props.error && this.props.error["nss_3_position"]
                        ? this.props.error["nss_3_position"][0]
                        : ""
                    }
                  />
                </td>
                <td>
                  <TextInput
                    id="nss_3_email"
                    name="nss_3_email"
                    // className="input-1671b"
                    labelText=""
                    onChange={this.handleChange}
                    value={
                      this.props.data ? this.props.data["nss_3_email"] : ""
                    }
                    invalid={
                      this.props.error &&
                      this.props.error["nss_3_email"] !== undefined
                    }
                    invalidText={
                      this.props.error && this.props.error["nss_3_email"]
                        ? this.props.error["nss_3_email"][0]
                        : ""
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </React.Fragment>
    ) : (
      <React.Fragment />
    );

    return (
      <React.Fragment>
        <div className="box-header with-border">
          <h3 className="box-title metadata-title">Source</h3>
        </div>
        <div className="box-body with-border">
          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div className="col-sm-3">
              <TextInput
                id="collection_year"
                name="collection_year"
                // className="input-1671c"
                style={{ width: "80px" }}
                labelText="1. Reference period (YYYY):"
                onChange={this.handleChange}
                value={
                  this.props.data && this.props.data["collection_year"]
                    ? this.props.data["collection_year"]
                    : ""
                }
                invalid={
                  this.props.error && 
                  this.props.error["collection_year"] !== undefined
                }
                invalidText={
                  this.props.error &&
                  this.props.error["collection_year"]
                    ? this.props.error["collection_year"][0]
                    : ""
                }
                autoFocus={true}
                required
              />
            </div>
          </div>

          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div className="col-sm-8 adaptive-form">
              <TextArea
                id="judiciary_sources"
                name="judiciary_sources"
                value={
                  this.props.data && this.props.data["judiciary_sources"]
                    ? this.props.data["judiciary_sources"]
                    : ""
                }
                onChange={this.handleChange}
                labelText="2. Data source(s) used for judiciary personnel:"
              />
            </div>
          </div>
          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div
              className="col-sm-12"
              style={{ display: "flex", marginBottom: "15px" }}
            >
              <div
                className="bx--form-item bx--text-input-wrapper"
                style={{ display: "flex", marginBottom: "15px" }}
              >
                <YesNoRadio
                  label="3. Was this data on judiciary personnel collected by an entity other than the NSO, such as (an)other agency(ies)/ministry(ies) in the National Statistical System?"
                  name="collecting_entity"
                  defaultValue={this.state.anotherSource}
                  _handleSelectedValue={this.handleAnothersourceRadioChange}
                />
              </div>
            </div>
            {anotherTable}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { data, error } = state.sdg1671cState;

  return {
    data,
    error
  };
}

const connectedSource = connect(mapStateToProps)(Source);

export { connectedSource as Source };
