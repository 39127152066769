import { RadioButton, RadioButtonGroup } from "carbon-components-react";
import React, { Component } from "react";


class SourceSubmission extends Component {
    constructor(props) {
      super(props);
      this.handleRadioClick = this.handleRadioClick.bind(this);
    }
  
    handleRadioClick(e) {
      console.log(e.target, this.props);
      if (this.props.handleRadioChange)
        this.props.handleRadioChange(e.target.name, e.target.value);
    }
  
    render() {
      return (
        <React.Fragment>
          <RadioButtonGroup name={this.props.name} valueSelected={this.props.defaultValue ? this.props.defaultValue : null}>
            <RadioButton style={{marginRight: "35px"}} labelText="Yes" type="radio" name={this.props.name} value="yes" selected={this.props.defaultValue === "yes"} onClick={this.handleRadioClick} />
            <RadioButton labelText="No" type="radio" name={this.props.name} value="no" selected={this.props.defaultValue === "no"} onClick={this.handleRadioClick} />
            <RadioButton labelText="Submitting for the first time" type="radio" name={this.props.name} value="first" selected={this.props.defaultValue === "first"} onClick={this.handleRadioClick} />
          </RadioButtonGroup>
        </React.Fragment>
      )
    }
  }


export default SourceSubmission;  
  