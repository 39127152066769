import { setAuthToken } from "../_helpers";

import { history } from "../_helpers";

import axios from "axios";
import { notifyError } from "../components/common/notification/NotificationHelper";

export const userService = {
  login,
  undpLogin,
  undpAuthenticate,
  logout,
  fetchUserProfile,
  exportContactsToExcel
};

function login(username, password) {
  let data = { username: username, password: password };

  return axios.post("/api/nso/login/", data).then(response => {
    localStorage.setItem("token", response.data.token);

    setAuthToken();

    return response.data.user;
  });
}

function undpLogin() {
  axios.get("/api/undp/login/").then(response => {
    if (response.data) {
      let auth_link = response.data.auth_url;
      window.location.replace(auth_link);
    }
  });
}

function undpAuthenticate(code) {
  return axios.post("/api/undp/login/", code).then(response => {
    localStorage.setItem("token", response.data.token);
    setAuthToken();
    return response.data.user;
  });
}

function logout() {
  localStorage.removeItem("unauthorizedUndp");
  localStorage.removeItem("token");
  axios.get("/api/users/logout/").then(response => {
    console.log("loggedout");
  });
  axios.defaults.headers.common["Authorization"] = "";
  delete axios.defaults.headers.common["Authorization"];
  history.push("/");
}

function fetchUserProfile(token) {
  return axios.get("/api/user/" + token + "/token/").then(response => {
    if (response.data) {
      setAuthToken();
      return response.data.user;
    }
  }).catch(error => {
    if (error.response.status === 401) {
      notifyError("Session expired. Please login again!")
      logout();
    }
  });
}

function exportContactsToExcel() {
  return axios
    .get("/api/users/export/", { responseType: "blob" })
    .then(response => {
      let blob = new Blob([response.data], {
          type: "application/xlsx; charset=utf-8"
        }),
        downloadUrl = window.URL.createObjectURL(blob),
        filename = "contact_details.xlsx";

      let a = document.createElement("a");
      if (typeof a.download === "undefined") {
        window.location.href = downloadUrl;
      } else {
        a.href = downloadUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
      }
      return response.data;
    });
}
