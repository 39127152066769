import React, { Component } from "react";

import { connect } from "react-redux";

import { sdg1671bActions } from "../../../../../../_actions";
import { TextInput } from "carbon-components-react";

class ProportionsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      error: props.error
    };

    this.handleTableChange = this.handleTableChange.bind(this);
    this.goToMetadata = this.goToMetadata.bind(this);
    this.getProportionsColumns = this.getProportionsColumns.bind(this);
  }

  handleTableChange(e) {
    let { dispatch } = this.props;

    let target = e.target.name.split(":");

    // console.log("Target", target);

    dispatch(
      sdg1671bActions.updateDataField(parseInt(target[0]), target[1], e.target.value)
    );
  }

  goToMetadata() {
    this.props.onPrevious();
  }

  getTotalRowNumber(row) {
    let val = 0;

    if (this.props.data && this.props.data.data[0][row]) {
      val += parseInt(this.props.data.data[0][row])
    }

    if (this.props.data && this.props.data.data[1][row]) {
      val += parseInt(this.props.data.data[1][row])
    }

    return val;
  }

  getTotalNumber(index, prefix) {
    let val = 0;

    if (this.props.data && this.props.data.data[index][prefix+"_officials"]) {
      val += parseInt(this.props.data.data[index][prefix+"_officials"])
    }

    if (this.props.data && this.props.data.data[index][prefix+"_executives"]) {
      val += parseInt(this.props.data.data[index][prefix+"_executives"])
    }

    if (this.props.data && this.props.data.data[index][prefix+"_professionals"]) {
      val += parseInt(this.props.data.data[index][prefix+"_professionals"])
    }

    if (this.props.data && this.props.data.data[index][prefix+"_associate"]) {
      val += parseInt(this.props.data.data[index][prefix+"_associate"])
    }

    if (this.props.data && this.props.data.data[index][prefix+"_general"]) {
      val += parseInt(this.props.data.data[index][prefix+"_general"])
    }

    return val;
  }

  getProportionsColumns(index, column1, column2, name) {

    if (this.props.data && this.props.data.data[index][column1] !== null && this.props.data.data[2][column2] && (this.props.data.data[2][column2]  !== 0)) {

      //console.log('Proportions', this.props.data.proportions[0][name], ((parseInt(this.props.data.data[index][column1])/parseInt(this.props.data.data[2][column2]))*100.00).toFixed(2))

      if (this.props.data && this.props.data.proportions && this.props.data.proportions[0] && parseFloat(this.props.data.proportions[0][name]).toFixed(2) !== ((parseInt(this.props.data.data[index][column1])/parseInt(this.props.data.data[2][column2]))*100.00).toFixed(2)) {
        let { dispatch } = this.props;

        dispatch(
          sdg1671bActions.updateProportions(name, ((parseInt(this.props.data.data[index][column1])/parseInt(this.props.data.data[2][column2]))*100.00).toFixed(2))
        );     
      }
      return ((parseInt(this.props.data.data[index][column1])/parseInt(this.props.data.data[2][column2]))*100.00).toFixed(2);
    }
    else return 0;
  }

  getProportionsTotalsRow(column1, column2, name) {

    if (this.props.data && this.props.data.data[2][column1] && parseInt(this.props.data.data[2][column1]) !== 0) {

      if (this.props.data && this.props.data.proportions && this.props.data.proportions[0] && parseFloat(this.props.data.proportions[0][name]).toFixed(2) !== ((parseInt(this.props.data.data[2][column1])/parseInt(this.props.data.data[2][column2]))*100.00).toFixed(2)) {
        let { dispatch } = this.props;
        dispatch(
          sdg1671bActions.updateProportions(name, ((parseInt(this.props.data.data[2][column1])/parseInt(this.props.data.data[2][column2]))*100.00).toFixed(2))
        );        
      }

      return ((parseInt(this.props.data.data[2][column1])/parseInt(this.props.data.data[2][column2]))*100.00).toFixed(2);
    }
    else return 0;
  }
  
  getProportionsRowTotals(column1, name) {

    if (this.props.data && this.props.data.data[2][column1] && parseInt(this.props.data.data[2][column1]) !== 0 && this.props.data.data[2]["total_national"]) {
      if (name && 
        this.props.data && 
        this.props.data.proportions && 
        this.props.data.proportions[0] && 
        this.props.data.data[2]["total_national"] &&  
        parseFloat(this.props.data.proportions[0][name]).toFixed(2) !== ((parseInt(this.props.data.data[2][column1])/(parseInt(this.props.data.data[2]["total_national"])))*100.00).toFixed(2)) 
      {
        let { dispatch } = this.props;
        dispatch(
          sdg1671bActions.updateProportions(name, ((parseInt(this.props.data.data[2][column1])/(parseInt(this.props.data.data[2]["total_national"])))*100.00).toFixed(2))
        );          
      }
      return ((parseInt(this.props.data.data[2][column1])/(parseInt(this.props.data.data[2]["total_national"])))*100.00).toFixed(2);
    }
    else return 0;
  }

  getProportionsColumnTotals(index, column1) {

    if (this.props.data && parseInt(this.getTotalNumber(index, column1)) !== 0) {
      return ((parseInt(this.getTotalNumber(index, column1))/(parseInt(this.props.data.data[2]["total_national"])))*100.00).toFixed(2);
    }
    else return 0;
  }

  getYoungTotals(name) {
    let sum = 0.0;
    sum = (parseFloat(this.getProportionsRowTotals("below_25")) + parseFloat(this.getProportionsRowTotals("age_25_34"))).toFixed(2)

    if (this.props.data && this.props.data.proportions && this.props.data.proportions[0] && sum > 0 && sum !== parseFloat(this.props.data.proportions[0][name]).toFixed(2)) {
      let { dispatch } = this.props;
      dispatch(
        sdg1671bActions.updateProportions(name, sum)
      );   
    }
    // console.log("Young", sum)
    return sum;
  }
  
  
  render() {

    return (
      <React.Fragment>
        <div className="box-body">
          <div className="col-sm-8">
            <h3>TABLE 2: Proportions</h3>
          </div>
          <div className="row">
            <div className="col-sm-12 adaptive-table">
              <fieldset
                disabled={this.props.data && (!this.props.data.id || this.props.data.id === null)}
              >
                <table className="table table-bordered table-1671b">
                  <thead>
                    <tr>
                      <th width="35%"></th>
                      <th>
                        Proportion of female public servants
                      </th>
                      <th>
                        Proportion of ‘young’ public servants below 25
                      </th>
                      <th>
                        Proportion of ‘young’ public servants aged 34 and below
                      </th>
                      <th>
                        Proportion of public servants with a disability
                      </th>
                      <th>
                        Proportion of public servants in population group A
                      </th>
                      <th>
                        Proportion of public servants in population group B
                      </th>
                      <th>
                        Proportion of public servants in population group C
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{background:"#A9D08E"}} colSpan="8">Total public service personnel
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Senior Managers: Senior Government Officials
                      </td>
                      <td>
                        <TextInput
                          id="female_senior_managers"
                          name="female_senior_managers"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleChange}
                          value={this.getProportionsColumns(1, "total_officials", "total_officials", "female_senior_managers")}
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr> 
                    <tr>
                      <td>
                        Other Managers: Managing Directors and Chief Executives and Business Services and Administration Managers
                      </td>
                      <td>
                        <TextInput
                          id="female_other_managers"
                          name="female_other_managers"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleChange}
                          value={this.getProportionsColumns(1, "total_executives", "total_executives", "female_other_managers")}
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Administration Professionals
                      </td>
                      <td>
                        <TextInput
                          id="female_administration_professionals"
                          name="female_administration_professionals"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleChange}
                          value={this.getProportionsColumns(1, "total_professionals", "total_professionals", "female_administration_professionals")}
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Business and Administration Associate Professionals
                      </td>
                      <td>
                        <TextInput
                          id="female_ba_associate_professionals"
                          name="female_ba_associate_professionals"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleChange}
                          value={this.getProportionsColumns(1, "total_associate", "total_associate", "female_ba_associate_professionals")}
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        General and Keyboard Clerks
                      </td>
                      <td>
                        <TextInput
                          id="female_gk_clerks"
                          name="female_gk_clerks"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleChange}
                          value={this.getProportionsColumns(1, "total_general", "total_general", "female_gk_clerks")}
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Total national-level public service personnel (including police, education, health, front-desk administrative and all other public service personnel)
                      </td>
                      <td>
                        <TextInput
                          id="female_total_national"
                          name="female_total_national"
                          // className="input-1671b"
                          labelText=""
                          value={this.getProportionsColumns(1, "total_national", "total_national", "female_total_national")}
                        />
                      </td>
                      <td>
                        <TextInput
                          id="bellow25_total_national"
                          name="bellow25_total_national"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleChange}
                          value={this.getProportionsRowTotals("below_25", "bellow25_total_national")}
                        />
                      </td>
                      <td>
                        <TextInput
                          id="bellow34_total_national"
                          name="bellow34_total_national"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleChange}
                          value={this.getYoungTotals("bellow34_total_national")}
                        />
                      </td>
                      <td>
                        <TextInput
                          id="disabled_total_national"
                          name="disabled_total_national"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleChange}
                          value={this.getProportionsRowTotals("disability", "disabled_total_national")}
                        />
                      </td>
                      <td>
                        <TextInput
                          id="population_a_total_national"
                          name="population_a_total_national"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleChange}
                          value={this.getProportionsRowTotals("group_a_value", "population_a_total_national")}
                        />
                      </td>
                      <td>
                        <TextInput
                          id="population_b_total_national"
                          name="population_b_total_national"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleChange}
                          value={this.getProportionsRowTotals("group_b_value", "population_b_total_national")}
                        />
                      </td>
                      <td>
                        <TextInput
                          id="population_c_total_national"
                          name="population_c_total_national"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleChange}
                          value={this.getProportionsRowTotals("group_c_value", "population_c_national")}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </fieldset>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { data } = state.sdg1671bState;

  return {
    data
  };
}

const connectedIndicatorData = connect(mapStateToProps)(ProportionsTable);

export { connectedIndicatorData as ProportionsTable };
