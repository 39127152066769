import { RadioButton } from "carbon-components-react";
import React, {Component} from "react";


class DisputeResolutionActionTableSelection extends Component {
    constructor(props) {
        super(props);
        this.handleRadioClick = this.handleRadioClick.bind(this);
    }

    handleRadioClick(e) {
        console.log(e.target.name, e.target.value);
        if (this.props._handleSelectedValue)
          this.props._handleSelectedValue(e.target.name, e.target.value === 'true');
      }

    render () {
        return (
            <React.Fragment>
                <td>
                    <RadioButton type="radio" name={this.props.name} value="true" checked={this.props.defaultValue === true} onClick={this.handleRadioClick} />
                </td>
                <td>
                    <RadioButton type="radio"  name={this.props.name} value="false" checked={this.props.defaultValue === false} onClick={this.handleRadioClick} />
                </td>
            </React.Fragment>
        )
    }
}




class DisputeResolutionActionTable extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick(name, value) {
        this.props.onChange(name,value);
    }

    render() {
        return (
        <div className="row">
            <div className="col-sm-8 adaptive-table">
                <table
                    className="table table-bordered dataTable"
                    name="question-table"
                    style={{ tableLayout: "fixed" }}
                >
                    <thead>
                    <tr>
                        <th style={{width:"5%"}}></th>
                        <th style={{width:"61.7%"}}>Reasons for not taking action to resolve the dispute included in your survey?</th>
                        <th style={{textAlign:"center"}}>Yes</th>
                        <th style={{textAlign:"center"}}>No</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan="4" style={{fontStyle:"italic", fontWeight:"bold"}}>
                                Voluntary self-excluded
                            </td>
                        </tr>
                        <tr>
                            <td>11</td>
                            <td>I think/thought the problem is/was not important enough</td>
                            <DisputeResolutionActionTableSelection name="not_important"  defaultValue = {this.props.data ? this.props.data["not_important"] : null} _handleSelectedValue = {this.handleClick} />
                        </tr>
                        <tr>
                            <td>12</td>
                            <td>I was/am confident that I could/can easily resolve it by myself</td>
                            <DisputeResolutionActionTableSelection name="easly_resolve"  defaultValue = {this.props.data ? this.props.data["easly_resolve"] : null} _handleSelectedValue = {this.handleClick} />
                        </tr>                        
                        <tr>
                            <td>13</td>
                            <td>I caused the problem / Up to the other party</td>
                            <DisputeResolutionActionTableSelection name="other_party"  defaultValue = {this.props.data ? this.props.data["other_party"] : null} _handleSelectedValue = {this.handleClick} />
                        </tr>
                        <tr>
                            <td colSpan="4" style={{fontStyle:"italic", fontWeight:"bold"}}>Involuntary excluded</td>
                        </tr>
                        <tr>
                            <td>21</td>
                            <td>I did not know where to go to</td>
                            <DisputeResolutionActionTableSelection name="not_know"  defaultValue = {this.props.data ? this.props.data["not_know"] : null} _handleSelectedValue = {this.handleClick} />
                        </tr>                        
                        <tr>
                            <td>22</td>
                            <td>I could not obtain legal assistance</td>
                            <DisputeResolutionActionTableSelection name="obtain_legal"  defaultValue = {this.props.data ? this.props.data["obtain_legal"] : null} _handleSelectedValue = {this.handleClick} />
                        </tr>                        
                        <tr>
                            <td>23</td>
                            <td>It was too far away or hard to get to</td>
                            <DisputeResolutionActionTableSelection name="far_away_hard"  defaultValue = {this.props.data ? this.props.data["far_away_hard"] : null} _handleSelectedValue = {this.handleClick} />
                        </tr>                        
                        <tr>
                            <td>24</td>
                            <td>It was too expensive or inconvenient</td>
                            <DisputeResolutionActionTableSelection name="expensive_inconvenient"  defaultValue = {this.props.data ? this.props.data["expensive_inconvenient"] : null} _handleSelectedValue = {this.handleClick} />
                        </tr>                        
                        <tr>
                            <td>25</td>
                            <td>I did not trust the authorities</td>
                            <DisputeResolutionActionTableSelection name="trust_authorities"  defaultValue = {this.props.data ? this.props.data["trust_authorities"] : null} _handleSelectedValue = {this.handleClick} />
                        </tr>                        
                        <tr>
                            <td>26</td>
                            <td>I did not think they could help</td>
                            <DisputeResolutionActionTableSelection name="think_they_could_help"  defaultValue = {this.props.data ? this.props.data["think_they_could_help"] : null} _handleSelectedValue = {this.handleClick} />
                        </tr>                        
                        <tr>
                            <td>27</td>
                            <td>I was afraid of the consequences for me or my family</td>
                            <DisputeResolutionActionTableSelection name="afraid_of_consequences"  defaultValue = {this.props.data ? this.props.data["afraid_of_consequences"] : null} _handleSelectedValue = {this.handleClick} />
                        </tr>    
                    </tbody>
                </table>
            </div>
        </div>
        );
    }
}


export default DisputeResolutionActionTable;