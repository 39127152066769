import React, { Component } from "react";

import DataTable, {
  TableToolbar,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableContainer,
  TableToolbarContent,
  TableToolbarSearch
} from "carbon-components-react/lib/components/DataTable/";

import { notifyError, notifySuccess } from "../../components/common/notification/NotificationHelper";


import axios from "axios";
import { Button, Modal, TextInput } from "carbon-components-react";

import { userService } from "../../_services";

import { history } from "../../_helpers";
import { connect } from "react-redux";
import OtherUserTableRow from "./UsersTable/OtherUserTableRow";

class OtherUsersTable extends Component {
  constructor(props) {
    super(props);

    this.exportToExcel = this.exportToExcel.bind(this);
    this.handleAddNewClick = this.handleAddNewClick.bind(this);
    this.handleNotifyClick = this.handleNotifyClick.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.sendMailCredentials = this.sendMailCredentials.bind(this);
  }
  state = {
    initialRows: [],
    add : false,
    newUser: "",
    firstName: ""
  };

  componentDidMount() {
    axios.get("/api/users/").then(response => {
      let data = [];
      let row_metadata = [];
      response.data
        .filter(usr => usr.user_type === "OECD_UNODC")
        .forEach(user => {
          data.push({
            id: user.id.toString(),
            user_type: user.user_type,
            email: user.email,
          });
          row_metadata.push({
            url: user.url,
            id: user.id,
            country: user.country
          });
        });

      this.setState({ initialRows: data, metadata: row_metadata });
    });
  }

  exportToExcel() {
    userService.exportContactsToExcel();
  }

  handleAddNewClick() {
    history.push("/users");
  }

  handleNotifyClick() {
    history.push("/notify-users");
  }

  handleModal() {
    this.setState({add: true});
  }

  submit() {
    //console.log("Submit");

    let newUser = this.state.newUser;

    let data = {};
    data.email = this.state.newUser;
    data.user_type = "OECD_UNODC"
    data.country = 249

    axios.post("/api/users/", data).then(response => {
      
      axios.get("/api/users/").then(response => {
        let data = [];
        let row_metadata = [];
        response.data
          .filter(usr => usr.user_type === "OECD_UNODC")
          .forEach(user => {
            data.push({
              id: user.id.toString(),
              user_type: user.user_type,
              email: user.email,
            });
            row_metadata.push({
              url: user.url,
              id: user.id,
              country: user.country
            });
          });
  
        this.setState({ initialRows: data, metadata: row_metadata, add: false });
      });
    });

  }

  sendMailCredentials(email) {
    let data = { email_list: [email] };

    axios.post("/api/mailcredentials/", data).then(response => {
      //console.log("PWD generated");
      if(response.status === 200)
        notifySuccess("Email notification sent!");
      else
        notifyError("Error sending email. Please try again.")  
    });
  }
  
  handleDelete(id) {
    //console.log("Submit");


    axios.delete("/api/users/" + id + "/").then(response => {
      
      axios.get("/api/users/").then(response => {
        let data = [];
        let row_metadata = [];
        response.data
          .filter(usr => usr.user_type === "OECD_UNODC")
          .forEach(user => {
            data.push({
              id: user.id.toString(),
              user_type: user.user_type,
              email: user.email,
            });
            row_metadata.push({
              url: user.url,
              id: user.id,
              country: user.country
            });
          });
  
        this.setState({ initialRows: data, metadata: row_metadata });
      });
    });

  }

  onNewuser(e) { 
    this.setState({newUser: e.target.value})
  }

  close() {
    this.setState({add: false})
  }

  render() {
    const headers = [
      {
        key: "email",
        header: "E-mail"
      },
      {
        key: "val2",
        header: "Send New Credentials"
      },
      {
        key: "val",
        header: "Delete User"
      },

    ];

    const initialRows = this.state.initialRows;

    return (
      <div id="content-wrapper" className="content-wrapper">
        {/* <PageHeader title="Focal Points" url="/users" /> */}

        {this.state.add?     <Modal
      open
      modalHeading="Add new OECD/UNODC user"
      modalLabel=""
      primaryButtonText="Add"
      secondaryButtonText="Cancel"
      onRequestSubmit={this.submit.bind(this)}
      onRequestClose={this.close.bind(this)}
      >
      
      <TextInput
        data-modal-primary-focus
        id="text-input-1"
        labelText="UNDP email"
        placeholder="someone@undp.org"
        style={{ marginBottom: '1rem' }}
        onChange={this.onNewuser.bind(this)}
        value = {this.state.newUser}
      />
    </Modal> : null}

        <div className="page-header">
          <div className="page-header-left">
            <h1 className="page-header-title">OECD/UNODC Users</h1>
          </div>
        </div>
        <div className="box">
          <div className="box-body">
            <div className="row">
              <div className="col-sm-12">
                Please edit below the information about OECD/UNODC users.
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <DataTable
            headers={headers}
            rows={initialRows}
            render={({ rows, headers, getRowProps, onInputChange }) => (
              <TableContainer>
                <TableToolbar>
                  <TableToolbarContent>
                    <TableToolbarSearch onChange={onInputChange} />
                    <Button onClick={this.handleModal}>Add new user</Button>
                  </TableToolbarContent>
                </TableToolbar>
                <Table>
                  <TableHead>
                    <TableRow>
                      {headers.map(header => (
                        <TableHeader key={header.key}>
                          {header.header}
                        </TableHeader>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => {
                      return (
                        <OtherUserTableRow
                          id={this.state.metadata[index].id}
                          key={this.state.metadata[index].id}
                          row={this.state.metadata[index]}
                          row_props={{ ...getRowProps({ row }) }}
                          user={initialRows[index]}
                          country={this.state.metadata[index].country}
                          url={this.state.metadata[index].url}
                          handleDelete={this.handleDelete}
                          sendMailCredentials={this.sendMailCredentials}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          />
        </section>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;

  return {
    user
  };
}

const connectedUsersTable = connect(mapStateToProps)(OtherUsersTable);
export { connectedUsersTable as OtherUsersTable };
