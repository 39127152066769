import React, { Component } from "react";

import { TableRow } from "./TableRow";
import { connect } from "react-redux";

class TableBody extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate() {
    // this.setState({ rows: this.props.rows });
  }

  handleChange(name, value) {
    this.props.onTableChange(name, value);
  }

  renderRow = (row, rowIndex) => {
    if (row.isHead) {
      return (
        <tr key={rowIndex}>
          <th colSpan={row.span} style={{fontWeight:"bold"}}>
            {row.title}
          </th>
        </tr>
      );
    } else {
      return (
        <TableRow
          key={rowIndex}
          index={rowIndex}
          name={row.name}
          title={row.title}
          handleRowChange={this.handleChange}
        />
      );
    }
  };

  render() {
    return (
      <tbody key={"16.7.2-body"}>
        {this.props.rows.map((row, index) => this.renderRow(row, index))}
      </tbody>
    );
  }
}

function mapStateToProps(state) {
  const { data, error } = state.sdg1672State;

  return {
    data,
    error
  };
}

const connectedTable = connect(mapStateToProps)(TableBody);
export { connectedTable as TableBody };
