import React, { Component } from "react";
import { RadioButtonGroup, RadioButton } from "carbon-components-react";

class YesNoRadio extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected:
        this.props.defaultValue === null
          ? "none"
          : this.props.defaultValue
          ? "yes"
          : "no"
    };

    this.handleRadioClick = this.handleRadioClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.defaultValue !== this.props.defaultValue) {
      this.setState({
        selected:
          this.props.defaultValue === null
            ? "none"
            : this.props.defaultValue
            ? "yes"
            : "no"
      }
      )
    }
  }

  handleRadioClick(e) {
    let selected = e.target.value === "yes";

    if (this.props._handleSelectedValue)
      this.props._handleSelectedValue(this.props.name, selected);

    this.setState({ selected: e.target.value });
  }

  render() {
    return (
      <div
        className="bx--form-item bx--text-input-wrapper"
        style={{ marginBottom: "12px" }}
      >
        <label htmlFor={this.props.name} className="bx--label">
          {this.props.label}
        </label>
        <RadioButtonGroup
          name={this.props.name}
          valueSelected={this.state.selected}
        >
          <RadioButton
            name={this.props.name + "_yes"}
            value="yes"
            labelText="Yes"
            onClick={this.handleRadioClick}
            selected={this.state.selected === "yes"}
          />
          <RadioButton
            name={this.props.name + "_no"}
            labelText="No"
            value="no"
            onClick={this.handleRadioClick}
            selected={this.state.selected === "no"}
          />
        </RadioButtonGroup>
      </div>
    );
  }
}

export default YesNoRadio;
