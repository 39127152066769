import { sdg1671bService } from "../_services";

import { sdg1671bConstants } from "../_constants";
import { notifyError } from "../components/common/notification/NotificationHelper";
import { userActions } from ".";

export const sdg1671bActions = {
  getIndicatorData,
  updateDataField,
  updateMetadataField,
  updateSourceField,
  clearIndicatorData,
  saveIndicatorData,
  validateIndicatorData,
  finalizeIndicatorData,
  updateSourceDataField,
  updateUnitDataField,
  updateOccupationalField,
  updateDisaggregationField,
  updateDisaggregationPopulationField,
  updateDenominator,
  updateProportions,
  updateRatios,
  updateCareerDataField,
  updateOccupationalFile,
  saveSubmitErrors
};

function getIndicatorData(id) {
  return dispatch => {
    sdg1671bService.getIndicatorData(id).then(
      data => {
        dispatch(get_success(data));
      },
      error => {
        if (error.response.status === 401) {
          notifyError("Session expired. Please login again!")
          dispatch(userActions.logout());
        }
        if (error.response.status === 500) {
          notifyError(
            "Server not available at the moment. Please try again later."
          );
        }
      }
    );
  };
}

function saveIndicatorData(data) {
  return dispatch => {
        dispatch(get_success(data));
  };
}

function saveSubmitErrors(data) {
  return dispatch => {
    dispatch(set_error(data))
  }
}

function finalizeIndicatorData(finalizeStr, data) {
  return dispatch => {
        dispatch(get_success(data));
  };
}

function validateIndicatorData(validateStr, data) {
  return dispatch => {
        dispatch(get_success(data));
  };
}

function updateDataField(index, name, val) {
  return dispatch => {
    let value = val === "" ? null : val;
    dispatch(update_data_field({ index, name, value }));
  };
}

function updateSourceDataField(index, name, val) {
  return dispatch => {
    let value = val === "" ? null : val;
    dispatch(update_source_data_field({ index, name, value }));
  };
}

function updateUnitDataField(index, name, val) {
  return dispatch => {
    let value = val === "" ? null : val;
    dispatch(update_unit_data_field({ index, name, value }));
  };
}

function updateMetadataField(name, val) {
  return dispatch => {
    let value = val === "" ? null : val;
    dispatch(update_metadata_field({ name, value }));
  };
}

function updateSourceField(name, val) {
  return dispatch => {
    let value = val === "" ? null : val;
    dispatch(update_source_field({ name, value }));
  };
}

function updateOccupationalField(name, val) {
  return dispatch => {
    let value = val === "" ? null : val;
    dispatch(update_occupational_field({ name, value }));
  };
}

function updateCareerDataField(index, name, val) {
  return dispatch => {
    let value = val === "" ? null : val;
    dispatch(update_career_data_field({ index, name, value }));
  };
}

function updateOccupationalFile(data) {
  return dispatch => {
    dispatch(update_occupational_file({ data }));
  };
}

function updateDisaggregationField(name, val) {
  return dispatch => {
    let value = val === "" ? null : val;
    dispatch(update_disaggregation_field({ name, value }));
  };
}

function updateDisaggregationPopulationField(index, name, value) {
  return dispatch => {
    dispatch(update_disaggregation_population_field({index, name, value}) )
  }
}

function updateDenominator(name, val) {
  return dispatch => {
    let value = val === ""  || isNaN(val) ? null : val;
    dispatch(update_denominator_field({ name, value }));
  };
}

function updateProportions(name, val) {
  return dispatch => {
    let value = val === ""  || isNaN(val) ? null : val;
    dispatch(update_proportions_field({ name, value }));
  };
}

function updateRatios(name, val) {
  return dispatch => {
    let value = val === ""  || isNaN(val) ? null : val;
    dispatch(update_ratios_field({ name, value }));
  };
}

function clearIndicatorData() {
  return dispatch => {
    dispatch(clear_indicator_data());
  };
}

function update_data_field(data) {
  return {
    type: sdg1671bConstants.UPDATE_1671b_DATA_FIELD,
    data
  };
}

function update_source_data_field(data) {
  return {
    type: sdg1671bConstants.UPDATE_1671b_SOURCE_DATA_FIELD,
    data
  };
}

function update_unit_data_field(data) {
  return {
    type: sdg1671bConstants.UPDATE_1671b_UNIT_DATA_FIELD,
    data
  };
}

function update_metadata_field(data) {
  return {
    type: sdg1671bConstants.UPDATE_1671b_METADATA_FIELD,
    data
  };
}

function update_source_field(data) {
  return {
    type: sdg1671bConstants.UPDATE_1671b_SOURCE_FIELD,
    data
  };
}

function update_occupational_field(data) {
  return {
    type: sdg1671bConstants.UPDATE_1671b_OCCUPATIONAL_FIELD,
    data
  };
}

function update_occupational_file(data) {
  return {
    type: sdg1671bConstants.UPDATE_1671b_OCCUPATIONAL_FILE,
    data
  };
}

function update_career_data_field(data) {
  return {
    type: sdg1671bConstants.UPDATE_1671b_CAREER_DATA_FIELD,
    data
  };
}

function update_disaggregation_field(data) {
  return {
    type: sdg1671bConstants.UPDATE_1671b_DISAGGREGATION_FIELD,
    data
  };
}

function update_disaggregation_population_field(data) {
  return {
    type: sdg1671bConstants.UPDATE_1671b_DISAGGREGATION_POPULATION_FIELD,
    data
  };
}

function update_denominator_field(data) {
  return {
    type: sdg1671bConstants.UPDATE_1671b_DENOMINATOR_FIELD,
    data
  };
}

function update_proportions_field(data) {
  return {
    type: sdg1671bConstants.UPDATE_1671b_PROPORTIONS_FIELD,
    data
  };
}

function update_ratios_field(data) {
  return {
    type: sdg1671bConstants.UPDATE_1671b_RATIOS_FIELD,
    data
  };
}

function get_success(data) {
  return {
    type: sdg1671bConstants.GET_1671b_DATA_SUCCESS,
    data
  };
}

function set_error(data) {
  return {
    type: sdg1671bConstants.GET_1671b_DATA_FAILURE,
    data
  };
}

function clear_indicator_data() {
  return {
    type: sdg1671bConstants.CLEAR_1671b_DATA
  };
}
