import React, {Component} from "react";
import { connect } from "react-redux";
import { sdg1633Actions } from "../../../../../../_actions";
import InputTable from "../../../../../common/table/InputTable";


class DisputeResolutionActionTable extends Component {
    constructor(props) {
        super(props);

        this.handleTableChange = this.handleTableChange.bind(this);
        this.goToMetadata = this.goToMetadata.bind(this);
      }
    
      handleTableChange(name, value) {
        let { dispatch } = this.props;
    
        dispatch(
          sdg1633Actions.updateMetadataField(name, parseInt(value))
        );
      }
    
      goToMetadata() {
        this.props.onPrevious();
      }
    
    render() {

        let headers = [
            {title: "", width: "8%"},
            {title: "Reasons for not taking action to resolve the dispute included in your survey?", width: "75%"},
            {title: "Number of respondents who selected this reason "}
          ]

          let tableData = [
              {
                isTitle : true,
                colSpan : "3",
                title: "Voluntary self-excluded"
              },
            {
              texts: [
                {
                  title: "11", 
                },  
                {
                  title:"I think/thought the problem is/was not important enough",
                }
              ],
              inputs: [
                {
                  name: "not_important_selected",
                  data : this.props.data ? this.props.data["not_important_selected"]: "",
                }
              ]       
            },
            {
              index : 2,
              texts: [
                {
                  title: "12", 
                },  
                {
                  title:"I was/am confident that I could/can easily resolve it by myself",
                }
              ],
              inputs: [
                {
                  name: "easly_resolve_selected",
                  data : this.props.data? this.props.data["easly_resolve_selected"]: "",
                }
              ]         
            },
            {
              index : 3,
              texts: [
                {
                  title: "13",
                },  
                {
                  title:"I caused the problem / Up to the other party",
                }
              ],
              inputs: [
                {
                  name: "other_party_selected",
                  data : this.props.data? this.props.data["other_party_selected"]: "",
                }
              ]         
            },
            {
                isTitle : true,
                colSpan : "3",
                title: "Involuntary excluded"
            },
            {
              index : 4,
              texts: [
                {
                  title: "21",
                },  
                {
                  title:"I did not know where to go to",
                }
              ],
              inputs: [
                {
                  name: "not_know_selected",
                  data : this.props.data? this.props.data["not_know_selected"]: "",
                }
              ]         
            },
            {
              index : 5,
              texts: [
                {
                  title: "22",
                },  
                {
                  title:"I could not obtain legal assistance",
                }
              ],
              inputs: [
                {
                  name: "obtain_legal_selected",
                  data : this.props.data? this.props.data["obtain_legal_selected"]: "",
                }
              ]         
            },
            {
              index : 6,
              texts: [
                {
                  title: "23",
                },  
                {
                  title:"It was too far away or hard to get to",
                }
              ],
              inputs: [
                {
                  name: "far_away_hard_selected",
                  data : this.props.data? this.props.data["far_away_hard_selected"]: "",
                }
              ]         
            },
            {
              index : 7,
              texts: [
                {
                  title: "24",
                },  
                {
                  title:"It was too expensive or inconvenient",
                }
              ],
              inputs: [
                {
                  name: "expensive_inconvenient_selected",
                  data : this.props.data? this.props.data["expensive_inconvenient_selected"]: "",
                }
              ]         
            },
            {
              index : 8,
              texts: [
                {
                  title: "25",
                },  
                {
                  title:"I did not trust the authorities",
                }
              ],
              inputs: [
                {
                  name: "trust_authorities_selected",
                  data : this.props.data? this.props.data["trust_authorities_selected"]: "",
                }
              ]         
            },
            {
              index : 9,
              texts: [
                {
                  title: "26",
                },  
                {
                  title:"I did not think they could help",
                }
              ],
              inputs: [
                {
                  name: "think_they_could_help_selected",
                  data : this.props.data? this.props.data["think_they_could_help_selected"]: "",
                }
              ]         
            },
            {
              index : 10,
              texts: [
              {
                title: "27",
              },  
              {
                title:"I was afraid of the consequences for me or my family",
              }
              ],
              inputs: [
                {
                  name: "afraid_of_consequences_selected",
                  data : this.props.data? this.props.data["afraid_of_consequences_selected"]: "",
                }
              ]         
            },
          ]

        return (
            <React.Fragment>
            <div className="box-body">
              <div className="col-sm-12">
                <h4>4. Reasons why no dispute resolution mechanism was accessed for the selected dispute (Question AJ5 – distribution of ‘yes’)</h4>
              </div>
              <div className="row">
                <div className="col-sm-8 adaptive-table">
                  <fieldset
                    disabled={this.props.data && (!this.props.data.id || this.props.data.id === null)}
                  >
    
                    <InputTable 
                      headers={headers}
                      rows = {tableData}
                      handleTableChange={this.handleTableChange}
                    />
                  </fieldset>
                </div>
              </div>
            </div>
          </React.Fragment>
            );
    }
}

function mapStateToProps(state) {
    const { data } = state.sdg1633State;
  
    return {
      data
    };
  }
  
  const connectedIndicatorData = connect(mapStateToProps)(DisputeResolutionActionTable);
  
  export { connectedIndicatorData as DisputeResolutionActionTable };
