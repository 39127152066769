import axios from "axios";

export function authHeader() {
  let user = localStorage.getItem("user");

  if (user && user.token) {
    return { Authorization: "Bearer " + user.token };
  } else return {};
}

export function setAuthToken() {
  let token = localStorage.getItem("token");

  axios.defaults.headers.common["Authorization"] = "";
  delete axios.defaults.headers.common["Authorization"];
  

  if (token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common['Pragma'] = 'no-cache';
  }
}
