import React, { Component } from "react";
import { TextArea } from "carbon-components-react";
import { connect } from "react-redux";
import YesNoRadio from "../../../../../common/elements/YesNoRadio";

import { sdg1671cActions } from "../../../../../../_actions";
class Institutions extends Component {
  constructor(props) {
    super(props);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  state = {};

  handleChange(e) {
    const { dispatch } = this.props;

    dispatch(
      sdg1671cActions.updateMetadataField(e.target.name, e.target.value)
    );
  }

  handleRadioChange(name, value) {
    const { dispatch } = this.props;

    dispatch(sdg1671cActions.updateMetadataField(name, value));
  }

  render() {
    return (
      <React.Fragment>
        <div className="box-header with-border">
          <h3 className="box-title metadata-title">Courts/Positions</h3>
        </div>
        <div className="box-body with-border">
          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div className="col-sm-8 adaptive-form">
              <label className="bx--label">
                1) What levels of courts were included in this data collection?
                (NB: focus should be on the formal court system only)
              </label>
            </div>
          </div>
          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div className="col-sm-8 adaptive-form">
              <YesNoRadio
                label="1.1. Supreme/constitutional courts"
                name="supreme_courts"
                defaultValue={
                  this.props.data &&
                  this.props.data["supreme_courts"] !== undefined
                    ? this.props.data["supreme_courts"]
                    : null
                }
                _handleSelectedValue={this.handleRadioChange}
              />
            </div>
          </div>
          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div className="col-sm-8 adaptive-form">
              <YesNoRadio
                label="1.2. Higher-level courts (courts that handle national issues or appeals)"
                name="higher_level_courts"
                defaultValue={
                  this.props.data &&
                  this.props.data["higher_level_courts"] !== undefined
                    ? this.props.data["higher_level_courts"]
                    : null
                }
                _handleSelectedValue={this.handleRadioChange}
              />
            </div>
          </div>
          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div className="col-sm-8 adaptive-form">
              <YesNoRadio
                label="1.3. Lower-level courts (courts – typically of first instance – that commonly handle local issues, such as disputes involving family, land, and government benefits and services)"
                name="lower_level_courts"
                defaultValue={
                  this.props.data &&
                  this.props.data["lower_level_courts"] !== undefined
                    ? this.props.data["lower_level_courts"]
                    : null
                }
                _handleSelectedValue={this.handleRadioChange}
              />
            </div>
          </div>
          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div className="col-sm-8 adaptive-form">
              <label className="bx--label">
                2. Please specify the names – both in English and in the main
                national language - of the national occupational categories
                included under the "judges" category reported below:
              </label>
            </div>
          </div>
          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div className="col-sm-8 adaptive-form">
              <TextArea
                id="jud_occ_cat_national"
                name="jud_occ_cat_national"
                value={
                  this.props.data &&
                  this.props.data["jud_occ_cat_national"]
                    ? this.props.data["jud_occ_cat_national"]
                    : ""
                }
                onChange={this.handleChange}
                labelText="2.1. In the main national language:"
              />
            </div>
          </div>
          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div className="col-sm-8 adaptive-form">
              <TextArea
                id="jud_occ_cat_english"
                name="jud_occ_cat_english"
                value={
                  this.props.data &&
                  this.props.data["jud_occ_cat_english"]
                    ? this.props.data["jud_occ_cat_english"]
                    : ""
                }
                onChange={this.handleChange}
                labelText="2.2. In English:"
              />
            </div>
          </div>
          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div className="col-sm-8 adaptive-form">
              <label className="bx--label">
                3. Please specify the names – both in English and in the main
                national language - of the national occupational categories
                included under the “registrar” category:
              </label>
            </div>
          </div>
          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div className="col-sm-8 adaptive-form">
              <TextArea
                id="reg_occ_cat_national"
                name="reg_occ_cat_national"
                value={
                  this.props.data &&
                  this.props.data["reg_occ_cat_national"]
                    ? this.props.data[
                        "reg_occ_cat_national"
                      ]
                    : ""
                }
                onChange={this.handleChange}
                labelText="3.1. In the main national language:"
              />
            </div>
          </div>
          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div className="col-sm-8 adaptive-form">
              <TextArea
                id="reg_occ_cat_english"
                name="reg_occ_cat_english"
                value={
                  this.props.data &&
                  this.props.data["reg_occ_cat_english"]
                    ? this.props.data[
                        "reg_occ_cat_english"
                      ]
                    : ""
                }
                onChange={this.handleChange}
                labelText="3.2. In English:"
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { data } = state.sdg1671cState;

  return {
    data
  };
}

const connectedInstitutions = connect(mapStateToProps)(Institutions);

export { connectedInstitutions as Institutions };
