import { sdg1671cService } from "../_services";

import { sdg1671cConstants } from "../_constants";
import { notifyError } from "../components/common/notification/NotificationHelper";
import { userActions } from ".";

export const sdg1671cActions = {
  getIndicatorData,
  updateDataField,
  updateMetadataField,
  clearIndicatorData,
  saveIndicatorData,
  validateIndicatorData,
  finalizeIndicatorData,
  updateDenominator,
  saveSubmitErrors,
  updateRatiosField,
  updateProportionsField,

};

function getIndicatorData(id) {
  return dispatch => {
    sdg1671cService.getIndicatorData(id).then(
      data => {
        dispatch(get_success(data));
      },
      error => {
        if (error.response.status === 401) {
          notifyError("Session expired. Please login again!")
          dispatch(userActions.logout());
        }
        if (error.response.status === 500) {
          notifyError(
            "Server not available at the moment. Please try again later."
          );
        }
      }
    );
  };
}

function saveIndicatorData(data) {
  return dispatch => {
        dispatch(get_success(data));
  };
}

function saveSubmitErrors(data) {
  return dispatch => {
    dispatch(save_data_failure(data))
  }
}

function finalizeIndicatorData(finalizeStr, data) {
  return dispatch => {
        dispatch(get_success(data));
  };
}

function validateIndicatorData(validateStr, data) {
  return dispatch => {
    dispatch(get_success(data));
  };
}

function updateDataField(index, name, val) {
  return dispatch => {
    let value = isNaN(parseInt(val)) ? null : val;
    dispatch(update_data_field({ index, name, value }));
  };
}

function updateProportionsField(index, name, val) {
  return dispatch => {
    let value = isNaN(parseFloat(val)) ? null : val;
    dispatch(update_proportions_field({ index, name, value }));
  };
}

function updateRatiosField(index, name, val) {
  return dispatch => {
    let value = isNaN(parseFloat(val)) ? null : val;
    dispatch(update_ratios_field({ index, name, value }));
  };
}

function updateMetadataField(name, val) {
  return dispatch => {
    let value = val === "" ? null : val;
    dispatch(update_metadata_field({ name, value }));
  };
}

function clearIndicatorData() {
  return dispatch => {
    dispatch(clear_indicator_data());
  };
}

function updateDenominator(name, val) {
  return dispatch => {
    let value = val === "" ? null : val;
    dispatch(update_denominator_field({ name, value }));
  };
}

function update_data_field(data) {
  return {
    type: sdg1671cConstants.UPDATE_1671c_DATA_FIELD,
    data
  };
}

function update_proportions_field(data) {
  return {
    type: sdg1671cConstants.UPDATE_1671c_PROPORTIONS_FIELD,
    data
  };
}

function update_ratios_field(data) {
  return {
    type: sdg1671cConstants.UPDATE_1671c_RATIOS_FIELD,
    data
  };
}

function update_metadata_field(data) {
  return {
    type: sdg1671cConstants.UPDATE_1671c_METADATA_FIELD,
    data
  };
}

function get_success(data) {
  return {
    type: sdg1671cConstants.GET_1671c_DATA_SUCCESS,
    data
  };
}

function clear_indicator_data() {
  return {
    type: sdg1671cConstants.CLEAR_1671c_DATA
  };
}

function save_data_failure(error) {
  return {
    type: sdg1671cConstants.SAVE_1671c_DATA_FAILURE,
    error: error
  };
}

function update_denominator_field(data) {
  return {
    type: sdg1671cConstants.UPDATE_1671c_DENOMINATOR_FIELD,
    data
  };
}
