import React, { Component } from "react";
import { connect } from "react-redux";
import { sdg1633Actions } from "../../../../../_actions";
class Introduction extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  state = {};

  handleChange(e) {
    const { dispatch } = this.props;

    dispatch(
      sdg1633Actions.updateMetadataField(e.target.name, e.target.value)
    );
  }

  render() {
    return (
      <React.Fragment>
        {/* <div className="row" style={{ display: "block" }}> */}
        <div className="box-header with-border">
          <div className="col-sm-12" style={{display:"flex", justifyContent:"space-between"}}>
            <h3 style={{paddingTop:"8px"}}>Introduction</h3>
            <div>
              <img
                src="/resources/img/unodc.jpg"
                style={{ height: "40px", marginLeft: "60px", paddingTop:"10px" }}
                alt="UNODC icon"
              />
              <img
                src="/resources/img/OECD.jpg"
                style={{ height: "40px", marginLeft: "60px", paddingTop:"8px" }}
                alt="OECD icon"
              />          
              <img
                src="/resources/img/undp-logo-30.svg"
                style={{ height: "55px", marginLeft: "60px" }}
                alt="UNDP logo"
              />
            </div>
          </div>
        </div>
        <div className="box-body">
          <div className="row" style={{ marginBottom: "10px" }}>
            <div className="col-sm-12">
              <h5>
              SDG 16.3.3: Proportion of the population who have experienced a dispute in the past two years and who accessed a formal or informal dispute resolution mechanism, by type of mechanism.
              </h5>
            </div>
            <div className="col-sm-12">
              <h5>
              Co-custodian agencies: UNDP, UNODC and OECD
              </h5>
            </div>
          </div>{" "}
          <div className="row" style={{ marginBottom: "10px" }}>
            <div className="col-lg-6">
              <img
                src="/resources/img/sdg16_3_3.png"
                className="intro-image"
                alt="1633 intro"
              />
              <div style={{ fontSize: "11px" }}>
                Image credit UNDP Pakistan
              </div>
            </div>
            <div className="col-lg-6">
              <div className="col-sm-12" style={{ marginBottom: "10px" }}>
                <p>
                SDG indicator 16.3.3 measures the accessibility of civil justice institutions (both formal and informal) by those who have experienced a justiciable problem, such as disputes in relation to labour, land tenure, injuries and commercial transactions. The disaggregation by type of dispute resolution mechanism provides additional information about the channels used by citizens in need of enforcing or defending their rights, may they be formal, such as courts or the police, or informal, such as customary law mechanisms managed by traditional or religious leaders. The indicator also provides important information about barriers and reasons for exclusion of some people.
                </p>
              </div>
              <div className="col-sm-12" style={{ marginBottom: "10px" }}>
                <p>
                The Indicator is based on four questions to be included in a household survey; these questions emphasize citizens’ experiences over general perceptions.
                </p>
              </div>
              <div className="col-sm-12">
                Please read carefully the full&nbsp;
                <a
                  href="https://unstats.un.org/sdgs/metadata/files/Metadata-16-03-03.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  metadata&nbsp;
                </a>
                for this indicator before submitting your data.
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              A PDF version of the data reporting form for this indicator can be
              downloaded <a target="_blank" href="/resources/files/16.3.3.pdf">here</a>. This PDF form is only for NSOs’ internal
              documentation and not a mandatory requirement of the reporting
              process.
            </div>
          </div>        
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { data, error } = state.sdg1671cState;

  return {
    data,
    error,
  };
}

const connectedIntroduction = connect(mapStateToProps)(Introduction);
export { connectedIntroduction as Introduction };
