import { RadioButton } from "carbon-components-react";
import React, {Component} from "react";


class DisputeTypeTableSelection extends Component {
    constructor(props) {
        super(props);

        this.handleRadioClick = this.handleRadioClick.bind(this);
    }

    handleRadioClick(e) {
        //console.log(e.target.name, e.target.value);
        if (this.props._handleSelectedValue)
          this.props._handleSelectedValue(e.target.name, e.target.value);
      }

    render () {

        return (
            <React.Fragment>
                <td>
                    <RadioButton type="radio" name={this.props.name} value="yes" checked={this.props.defaultValue === 'yes'} onClick={this.handleRadioClick} />
                </td>
                <td>
                    <RadioButton type="radio"  name={this.props.name} value="diff"  checked={this.props.defaultValue === 'diff'}  onClick={this.handleRadioClick} />
                </td>
                <td>
                    <RadioButton type="radio"  name={this.props.name} value="no"  checked={this.props.defaultValue === 'no'}  onClick={this.handleRadioClick} />
                </td>                
            </React.Fragment>
        )
    }
}


class DisputeTypeTable extends Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this)
    }

    handleClick(name, value) {
        this.props.onChange(name,value);
    }

    render() {
        return (
        <div className="row">
            <div className="col-sm-8 adaptive-table">
                <table
                    className="table table-bordered dataTable"
                    name="question-table"
                    style={{ tableLayout: "fixed" }}
                >
                    <thead>
                    <tr>
                        <th style={{width:"5%"}}></th>
                        <th style={{width:"45%"}}>Types of disputes included in your survey?</th>
                        <th style={{textAlign:"center"}}>Yes, we used the same formulation</th>
                        <th style={{textAlign:"center"}}>Yes, but with a different formulation</th>
                        <th style={{textAlign:"center"}}>No</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>A</td>
                            <td>Problems with LAND, OR BUYING AND SELLING PROPERTY</td>
                            <DisputeTypeTableSelection name="property_problems" defaultValue = {this.props.data ? this.props.data["property_problems"] : null} _handleSelectedValue = {this.handleClick} />
                        </tr>
                        <tr>
                            <td>B</td>
                            <td>Issues with HOUSING</td>
                            <DisputeTypeTableSelection name="housing_issues" defaultValue = {this.props.data ? this.props.data["housing_issues"] : null} _handleSelectedValue = {this.handleClick} />
                        </tr>                        
                        <tr>
                            <td>C</td>
                            <td>Trying to resolve FAMILY ISSUES</td>
                            <DisputeTypeTableSelection name="family_issues" defaultValue = {this.props.data ? this.props.data["family_issues"] : null} _handleSelectedValue = {this.handleClick} />
                        </tr>                        
                        <tr>
                            <td>D</td>
                            <td>Seeking compensation for INJURIES OR ILLNESS</td>
                            <DisputeTypeTableSelection name="injuries_illness_compensation" defaultValue = {this.props.data ? this.props.data["injuries_illness_compensation"] : null} _handleSelectedValue = {this.handleClick} />
                        </tr>                        
                        <tr>
                            <td>E</td>
                            <td>Problems with EMPLOYMENT OR LABOR</td>
                            <DisputeTypeTableSelection name="employment_labor_problems" defaultValue = {this.props.data ? this.props.data["employment_labor_problems"] : null} _handleSelectedValue = {this.handleClick} />
                        </tr>                        
                        <tr>
                            <td>F</td>
                            <td>Problems with GOVERNMENT PAYMENTS</td>
                            <DisputeTypeTableSelection name="goverment_payments_problems" defaultValue = {this.props.data ? this.props.data["goverment_payments_problems"] : null} _handleSelectedValue = {this.handleClick} />
                        </tr>                        
                        <tr>
                            <td>G</td>
                            <td>GOVERNMENT AND PUBLIC SERVICES OTHER THAN PAYMENTS</td>
                            <DisputeTypeTableSelection name="other_services_problems" defaultValue = {this.props.data ? this.props.data["other_services_problems"] : null} _handleSelectedValue = {this.handleClick} />
                        </tr>                        
                        <tr>
                            <td>H</td>
                            <td>Problems with other GOODS AND SERVICES</td>
                            <DisputeTypeTableSelection name="goods_services_problems" defaultValue = {this.props.data ? this.props.data["goods_services_problems"] : null} _handleSelectedValue = {this.handleClick} />
                        </tr>                        
                        <tr>
                            <td>I</td>
                            <td>Issues with MONEY, DEBT OR FINANCIAL SERVICES</td>
                            <DisputeTypeTableSelection name="financial_services_issues" defaultValue = {this.props.data ? this.props.data["financial_services_issues"] : null} _handleSelectedValue = {this.handleClick} />
                        </tr>                        
                        <tr>
                            <td>J</td>
                            <td>ENVIRONMENTAL Issues</td>
                            <DisputeTypeTableSelection name="environmental_issues" defaultValue = {this.props.data ? this.props.data["environmental_issues"] : null} _handleSelectedValue = {this.handleClick} />
                        </tr>                        
                        <tr>
                            <td>K</td>
                            <td>NEIGHBORHOOD disputes</td>
                            <DisputeTypeTableSelection name="neighborhood_disputes" defaultValue = {this.props.data ? this.props.data["neighborhood_disputes"] : null} _handleSelectedValue = {this.handleClick} />
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        );
    }
}


export default DisputeTypeTable;