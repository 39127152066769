import React, { Component } from "react";

import CountrySelection from "../common/CountrySelection";
import NSSContactDetails from "./common/NSSContactDetails";

import UNDPContactDetails from "./common/UNDPContactDetails";

import Button from "carbon-components-react/lib/components/Button";

import "../../styles/components/users.css";
import "../../styles/components/pageheader.css";

import axios from "axios";
import { connect } from "react-redux";
import {
  notifySuccess,
  notifyError,
} from "../common/notification/NotificationHelper";

import { history } from "../../_helpers/history";

class Users extends Component {
  state = {
    nsoUsers: [],
    undpUsers: [],
  };

  constructor() {
    super();

    this.no1Ref = React.createRef();
    this.no2Ref = React.createRef();
    this.no3Ref = React.createRef();

    this.undp1Ref = React.createRef();
    this.undp2Ref = React.createRef();
    this.undp3Ref = React.createRef();

    this.inputRefs = [
      this.no1Ref,
      this.no2Ref,
      this.no3Ref,
      this.undp1Ref,
      this.undp2Ref,
      this.undp3Ref,
    ];

    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onSelectionChanged(selectedValue) {
    const url = "/api/users/?country=" + selectedValue;

    axios.get(url).then((response) => {
      let nsoUsers = [];
      let undpUsers = [];

      response.data.forEach((user) => {
        if (user.user_type === "NSO") nsoUsers.push(user);
        if (user.user_type === "UNDP") undpUsers.push(user);
      });

      this.setState({
        nsoUsers: nsoUsers,
        undpUsers: undpUsers,
        currentCountry: selectedValue,
      });
    });
  }

  componentDidMount() {
    if (this.props.user) {
      this.onSelectionChanged(this.props.user.country);
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.inputRefs.forEach((inputRef) => {
      inputRef.current.setState({ error: {} });
      if (inputRef.current.state.data.id) {
        //data.push(inputRef.current.state);
        if (inputRef.current.isEmpty()) {
          axios.delete("/api/users/" + inputRef.current.state.data.id + "/").then (
            (resp) => {
              notifySuccess("User deleted succesfully");
              inputRef.current.clearState();
            },
            (error) => {
              notifyError("Failed to delete user!");
              inputRef.current.setState({ error: error.response.data });
            }            
          )
        }
        else {
          axios
          .put(
            "/api/users/" + inputRef.current.state.data.id + "/",
            inputRef.current.state.data
          )
          .then(
            (resp) => {
              notifySuccess("User saved succesfully");
              inputRef.current.setState({data: resp.data})
            },
            (error) => {
              notifyError("There are errors on form!");
              inputRef.current.setState({ error: error.response.data });
            }
          );
        }
      } else if (inputRef.current.isValid()) {
        axios.post("/api/users/", inputRef.current.state.data).then(
          (resp) => {
            notifySuccess("User saved succesfully");
            //console.log("resp:", resp.data);
            inputRef.current.setState({data: resp.data})
          },
          (error) => {
            notifyError("There are errors on form!");
            inputRef.current.setState({ error: error.response.data });
          }
        );
      }
    });
  }

  handleNotifyClick = () => {
    history.push("/notify-users");
  };

  handleManageNSOClick = () => {
    history.push("/nso_users");
  };

  handleManageClick = () => {
    history.push("/ogc_users");
  };

  handleManageOECDClick = () => {
    history.push("/oecd_unodc_users");
  };

  render() {
    let countrySelection =
      this.props.user && this.props.user.user_type === "OGC" ? (
        <div className="box">
          <div style={{ padding: "15px" }}>
            <CountrySelection onSelectionChanged={this.onSelectionChanged} />
          </div>
        </div>
      ) : null;

    let undpFocalPoints =
      this.props.user && this.props.user.user_type !== "NSO" ? (
        <React.Fragment>
          {/* <div className="box-header">
            <h3 className="box-title">UNDP Focal Points Details</h3>
          </div> */}
          <div className="box">
            <div className="box-body">
              <div className="col-md-8 col-sm-8 contact">
                <label>UNDP Focal Point 1</label>
                <UNDPContactDetails
                  ref={this.undp1Ref}
                  user={this.state.undpUsers[0]}
                  country={this.state.currentCountry}
                  userid="undp-1"
                />
              </div>
              <div className="col-md-8 col-sm-8 contact">
                <label>UNDP Focal Point 2</label>
                <UNDPContactDetails
                  ref={this.undp2Ref}
                  user={this.state.undpUsers[1]}
                  country={this.state.currentCountry}
                  userid="undp-2"
                />
              </div>
              <div className="col-md-8 col-sm-8 contact">
                <label>UNDP Focal Point 3</label>
                <UNDPContactDetails
                  ref={this.undp3Ref}
                  user={this.state.undpUsers[2]}
                  country={this.state.currentCountry}
                  userid="undp-3"
                />
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : null;

    let notifyUsers =
      this.props.user && this.props.user.user_type === "OGC" ? (
        <Button
          style={{ marginRight: "15px" }}
          onClick={this.handleNotifyClick}
        >
          Go to send notifications page
        </Button>
      ) : (
        <React.Fragment />
      );

      let manageUsers =
      this.props.user && this.props.user.user_type === "OGC" ? ( <React.Fragment> 
    <Button
      style={{ marginRight: "15px" }}
      onClick={this.handleManageNSOClick}
    >
      Manage NSO users
    </Button>

    <Button
      style={{ marginRight: "15px" }}
      onClick={this.handleManageClick}
    >
      Manage OGC users
    </Button>

    <Button
    style={{ marginRight: "15px" }}
    onClick={this.handleManageOECDClick}
    >
    Manage OECD/UNODC users
    </Button> </React.Fragment>
  ) : (
    <React.Fragment />
  );

    let editText =
      this.props.user && this.props.user.user_type === "OGC"
        ? "Please edit below and/or add the contact information of the main focal points in national statistical office for these SDG 16 indicators:"
        : "Please edit below and/or add the contact information of the main focal points in your national statistical office for these SDG 16 indicators:";
    return (
      <div id="content-wrapper" className="content-wrapper">
        <div style={{ marginBottom: "15px" }}>
          <div className="page-header">
            <div className="page-header-left">
              <h1 className="page-header-title">
                Contact information for focal points
              </h1>
            </div>
            <div className="page-header-right">{manageUsers}{notifyUsers}</div>
          </div>
          <div className="row">
            <div
              className="col-sm-12"
              style={{ textAlign: "justify", fontSize: "16px" }}
            >
              {editText}
            </div>
          </div>
        </div>
        {countrySelection}
        <div className="box">
          {/* <div className="box-header with-border">
            <h3 className="box-title">NSO Focal Points Details</h3>
          </div> */}
          <div className="box-body">
            <div className="col-lg-12 col-md-12 col-sm-12 contact">
              <label>NSO Primary</label>
              <NSSContactDetails
                user={this.state.nsoUsers[0]}
                country={this.state.currentCountry}
                ref={this.no1Ref}
                userid="nso-1"
              />
            </div>
            <div className="col-lg-12 col-md-8 col-sm-8 contact">
              <label>NSO Additional 1</label>
              <NSSContactDetails
                user={this.state.nsoUsers[1]}
                country={this.state.currentCountry}
                ref={this.no2Ref}
                userid="nso-2"
              />
            </div>
            <div className="col-lg-12 col-md-8 col-sm-8 contact">
              <label>NSO Additional 2</label>
              <NSSContactDetails
                user={this.state.nsoUsers[2]}
                country={this.state.currentCountry}
                ref={this.no3Ref}
                userid="nso-3"
              />
            </div>
            {/* <div className="col-lg-12 col-md-8 col-sm-8 contact">
                <label>NSO Focal 4</label>
                <NSSContactDetails
                  user={this.state.nsoUsers[3]}
                  country={this.state.currentCountry}
                  ref={this.no4Ref}
                  userid="nso-4"
                />
              </div>
              <div className="col-lg-12 col-md-8 col-sm-8 contact">
                <label>NSO Focal 5</label>
                <NSSContactDetails
                  user={this.state.nsoUsers[4]}
                  country={this.state.currentCountry}
                  ref={this.no5Ref}
                  userid="nso-5"
                />
              </div> */}
          </div>
        </div>
        <div className="box" style={{ marginBottom: "5px" }}>
          {undpFocalPoints}
        </div>
        <div className="box">
          <div className="box-body">
            <div className="row">
              <Button onClick={this.handleSubmit}>Save focal points</Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;

  return {
    user,
  };
}

const connectedUsers = connect(mapStateToProps)(Users);
export { connectedUsers as Users };
