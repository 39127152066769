import axios from "axios";
import { userService } from ".";

import {
  notifyError,
  notifySuccess,
} from "../components/common/notification/NotificationHelper";

export const sdg1633Service = {
  getIndicatorData,
  saveIndicatorData
};

function getIndicatorData(id) {
  return axios.get("/api/v2/sdg1633/" + id + "/").then(response => {
    return response.data;
  });
}

function saveIndicatorData(data) {
  if (data.id !== undefined && data.id !== null) {
    return axios.put("/api/v2/sdg1633/" + data.id + "/", data).then(response => {
      notifySuccess("Data saved succesfully!");
      return response.data;
    });
  } else {
    return axios.post("/api/v2/sdg1633/", data).then(response => {
      notifySuccess("Data saved succesfully!");
      return response.data;
    });
  }
}

