export const sdg1633Constants = {
  GET_1633_DATA_SUCCESS: "GET_1633_DATA_SUCCESS",
  GET_1633_DATA_FAILURE: "GET_1633_DATA_FAILURE",
  UPDATE_1633_DATA_SUCCESS: "UPDATE_1633_DATA_SUCCESS",
  UPDATE_1633_DATA_FAILURE: "UPDATE_1633_DATA_FAILURE",
  UPDATE_1633_DATA_FIELD: "UPDATE_1633_DATA_FIELD",
  UPDATE_1633_RESOLUTIONS_FIELD: "UPDATE_1633_RESOLUTIONS_FIELD",
  UPDATE_1633_METADATA_FIELD: "UPDATE_1633_METADATA_FIELD",
  CLEAR_1633_DATA: "CLEAR_1633_DATA",
  SAVE_1633_DATA_SUCCESS: "SAVE_1633_DATA_SUCCESS",
  SAVE_1633_DATA_FAILURE: "SAVE_1633_DATA_FAILURE",
  UPDATE_1633_DENOMINATOR_FIELD: "UPDATE_1633_DENOMINATOR_FIELD",
  UPDATE_1633_QUESTIONAIRE_FILE: "UPDATE_1633_QUESTIONAIRE_FILE",
  UPDATE_1633_IMPL_MANUAL_FILE: "UPDATE_1633_IMPL_MANUAL_FILE",
  UPDATE_1633_INT_MANUAL_FILE: "UPDATE_1633_INT_MANUAL_FILE"

};
