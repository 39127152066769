import React, { Component } from "react";

import "../../styles/components/welcome.css";
import { connect } from "react-redux";

class Welcome extends Component {
  state = {};
  render() {
    let unauthorizedAccess = localStorage.getItem("unauthorizedUndp") ? (
      <div className="col-sm 12 bg-warning">
        <h5 style={{ textAlign: "center", marginTop: "3rem" }}>
          {
            "This application is used for entering data for SDG 16 indicators. You are not authorized to see any data in the application!"
          }
        </h5>
      </div>
    ) : (
      <React.Fragment />
    );

    return (
      <div style={{ minHeight: "calc(100vh - 160px)" }}>
        <div id="content-wrapper" className="content-wrapper">
          <section className="content-header">
            <h1 style={{ fontWeight: "300" }} className="content-max-width">
              Welcome to UNDP's SDG 16 Data Reporting Platform
            </h1>
          </section>
          {unauthorizedAccess}
          <section className="content" style={{ lineHeight: "1.8vw" }}>
            <div
              className="col-sm-12"
              // style={{ display: "flex", marginBottom: "15px" }}
            >
              <div className="box-body">
                <div className="row" style={{ marginBottom: "10px" }}>
                  <div className="col-lg-6">
                    <img
                      alt="Welcome"
                      style={{ width: "100%" }}
                      src="/resources/img/welcome.jpg"
                    />
                    <div style={{ fontSize: "11px" }}>
                      Image credit UNDP Kenya / Allan Gichigi
                    </div>
                  </div>
                  <div
                    className="col-lg-6"
                    style={{
                      textAlign: "justify",
                      fontSize: "16px"
                    }}
                  >
                    <div>
                      UNDP is the custodian agency for four global SDG 16
                      indicators:
                    </div>
                    <br />
                    <br />
                    <ul style={{ listStyle: "inherit", paddingLeft: "50px" }}>
                      <li>
                        <strong>SDG 16.3.3:</strong> Proportion of the population 
                        who have experienced a dispute in the past two years and 
                        who accessed a formal or informal dispute resolution mechanism, by type of mechanism;
                      </li>
                      <li>
                        <strong>SDG 16.6.2:</strong> Proportion of population
                        satisfied with their last experience of public services,
                        specifically (a) healthcare services, (b) education
                        services and (c) government services (government-issued
                        IDs and civil registration of life events);
                      </li>
                      <li>
                        <strong>SDG 16.7.1b:</strong> Proportions of positions
                        in the public service (national and local), by various
                        characteristics, compared to national distributions;
                      </li>
                      <li>
                        <strong>SDG 16.7.1c:</strong> Proportions of positions
                        in the judiciary (national and local), by various
                        characteristics, compared to national distributions;
                      </li>
                      <li>
                        <strong>SDG 16.7.2:</strong> Proportions of population
                        who believe decision-making is inclusive and responsive,
                        by various characteristics;
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <br /> */}
                <div className="row">
                  <div
                    className="col-sm-12"
                    style={{
                      textAlign: "justify",
                      fontSize: "16px"
                    }}
                  >
                    <div>
                      To fulfill its responsibility as a custodian agency, UNDP
                      – under the oversight of its{" "}
                      <a
                        href="https://www.undp.org/content/oslo-governance-centre/en/home.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Oslo Governance Centre
                      </a>{" "}
                      – has established this reporting platform to facilitate
                      the collection and harmonization of national data on these
                      indicators from National Statistical Systems on an annual
                      basis. The data collected through this platform, once
                      harmonized, will be submitted by UNDP to the{" "}
                      <a
                        href="https://unstats.un.org/sdgs/indicators/database/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        global SDG database
                      </a>{" "}
                      hosted by the United Nations Statistics Division.
                    </div>
                    <br />
                    <div>
                      For help or any queries, please contact:
                      <a href="mailto:sdg16indicators@undp.org">
                        &nbsp;sdg16indicators@undp.org
                      </a>
                      .
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

function mapsStateToProps(state) {
  const { unauthorizedUNDP } = state.authentication;

  return {
    unauthorizedUNDP
  };
}

const connectedWelcome = connect(mapsStateToProps)(Welcome);
export { connectedWelcome as Welcome };
