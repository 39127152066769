import { sdg1662Constants } from "../_constants";

const initialState = {};

export function sdg1662State(state = initialState, action) {
  switch (action.type) {
    case sdg1662Constants.GET_DATA_SUCCESS: {
      return {
        ...state,
        data: action.data,
        error: {}
      };
    }
    case sdg1662Constants.GET_DATA_FAILURE: {
      break;
    }
    case sdg1662Constants.UPDATE_DATA_SUCCESS: {
      break;
    }
    case sdg1662Constants.UPDATE_DATA_FAILURE: {
      break;
    }
    case sdg1662Constants.UPDATE_DATA_FIELD: {
      return {
        ...state,
        data: {
          ...state.data,
          positive_data: state.data.positive_data.map((item, index) => {
            if (index === action.data.index) {
              return {
                ...item,
                [action.data.name]: action.data.value
              };
            }

            return item;
          })
        }
      };
    }
    case sdg1662Constants.UPDATE_DATA_SATISFIED_FIELD: {
      return {
        ...state,
        data: {
          ...state.data,
          satisfied_data: state.data.satisfied_data.map((item, index) => {
            if (index === action.data.index) {
              return {
                ...item,
                [action.data.name]: action.data.value
              };
            }

            return item;
          })
        }
      };
    }    
    case sdg1662Constants.UPDATE_METADATA_FIELD: {
      let value = action.data.value === NaN ? null : action.data.value;
      return {
        ...state,
        data: {
          ...state.data,
          [action.data.name]: value
        }
      };
    }
    case sdg1662Constants.CLEAR_DATA: {
      return {
        ...state,
        data: {},
        error: {}
      };
    }
    case sdg1662Constants.SAVE_DATA_SUCCESS: {
      return {
        ...state,
        data: action.data
      };
    }
    case sdg1662Constants.SAVE_DATA_FAILURE: {
      return {
        ...state,
        error: action.error
      };
    }
    case sdg1662Constants.UPDATE_1662_QUESTIONAIRE_FILE: {
      return {
        ...state,
        data: {
          ...state.data,
          questionaire_file: action.data.data.id,
          questionaire_file_path: action.data.data.file,
        }
      };
    }    
    case sdg1662Constants.UPDATE_1662_IMPL_MANUAL_FILE: {
      return {
        ...state,
        data: {
          ...state.data,
          implementation_manual_file: action.data.data.id,
          implementation_manual_file_path: action.data.data.file,
        }
      };
    }
    case sdg1662Constants.UPDATE_1662_INT_MANUAL_FILE: {
      return {
        ...state,
        data: {
          ...state.data,
          interviewer_manual_file: action.data.data.id,
          interviewer_manual_file_path: action.data.data.file,
        }
      };
    }

    default:
      return state;
  }
}
