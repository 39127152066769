import axios from "axios";
import { userService } from ".";

import {
  notifyError,
  notifySuccess,
} from "../components/common/notification/NotificationHelper";

export const sdg1671cService = {
  getIndicatorData,
  saveIndicatorData
};

function getIndicatorData(id) {
  return axios.get("/api/v2/sdg1671c/" + id + "/").then(response => {

    let parsed = {}

    if (typeof response.data === 'string') {
      let str = response.data;
      str = str.replaceAll('Infinity', 'null');

      console.log('Parsed', str, JSON.parse(str));
      parsed = JSON.parse(str);
    }
    else {
      parsed = response.data;
    }

    return parsed;
  });
}

function saveIndicatorData(data) {
  if (data.id !== undefined && data.id !== null) {
    return axios.put("/api/v2/sdg1671c/" + data.id + "/", data).then(response => {
      notifySuccess("Data saved succesfully!");
      return response.data;
    });
  } else {
    return axios.post("/api/v2/sdg1671c/", data).then(response => {
      notifySuccess("Data saved succesfully!");
      return response.data;
    });
  }
}

