import React, { Component } from "react";

import Select from "carbon-components-react/lib/components/Select";
import SelectItem from "carbon-components-react/lib/components/SelectItem";

import axios from "axios";
import { userService } from "../../_services";
import { notifyError } from "./notification/NotificationHelper";
import { connect } from "react-redux";
import { userActions } from "../../_actions";

class CountrySelection extends Component {
  state = {
    countries: []
  };

  constructor(props) {
    super(props);

    this.handleSelectionChange = this.handleSelectionChange.bind(this);
  }

  componentDidMount() {
    axios.get("/api/countries/?format=json").then(response => {
      this.setState({ countries: response.data });
    }).catch(error => {
      if (error.response.status === 401) {
        notifyError("Session expired. Please login again!")
        const { dispatch } = this.props;
        dispatch(userActions.logout());
      }
    });
  }

  handleSelectionChange(event) {
    this.props.onSelectionChanged(event.target.value);
  }

  render() {
    let countries = this.state.countries;

    let countriesOption = [];

    countriesOption.push(
      <SelectItem
        key="0"
        disabled
        hidden
        value="placeholder-item"
        text="Choose country"
      />
    );

    if (this.props.addAll) {
      countriesOption.push(<SelectItem key={"all"} value={999} text={"Show All Coutries"} />);
    }
    countries.forEach(country => {
      countriesOption.push(
        <SelectItem key={country.id} value={country.id} text={country.name} />
      );
    });

    return (
      <Select
        id="countrySelection"
        labelText={this.props.hideTitle !== undefined && this.props.hideTitle === false ? null: "Select country:"}
        defaultValue="placeholder-item"
        onChange={this.handleSelectionChange}
      >
        {countriesOption}
      </Select>
    );
  }
}

export default connect()(CountrySelection);
