import { sdg1633Constants } from "../_constants";

const initialState = {};

export function sdg1633State(state = initialState, action) {
  switch (action.type) {
    case sdg1633Constants.GET_1633_DATA_SUCCESS: {
      return {
        ...state,
        data: action.data,
        error: {}
      };
    }
    case sdg1633Constants.GET_1633_DATA_FAILURE: {
      break;
    }
    case sdg1633Constants.UPDATE_1633_DATA_SUCCESS: {
      break;
    }
    case sdg1633Constants.UPDATE_1633_DATA_FAILURE: {
      break;
    }
    case sdg1633Constants.UPDATE_1633_DATA_FIELD: {
      return {
        ...state,
        data: {
          ...state.data,
          data: state.data.data.map((item, index) => {
            if (index === action.data.index) {
              return {
                ...item,
                [action.data.name]: action.data.value
              };
            }

            return item;
          })
        }
      };
    }
    case sdg1633Constants.UPDATE_1633_RESOLUTIONS_FIELD: {
      return {
        ...state,
        data: {
          ...state.data,
          despute_resolutions: state.data.despute_resolutions.map((item, index) => {
            if (index === action.data.index) {
              return {
                ...item,
                [action.data.name]: action.data.value
              };
            }

            return item;
          })
        }
      };
    }
    case sdg1633Constants.UPDATE_1633_METADATA_FIELD: {
      let value = action.data.value === NaN ? null : action.data.value;
      return {
        ...state,
        data: {
          ...state.data,
          [action.data.name]: value
        }
      };
    }
    case sdg1633Constants.CLEAR_1633_DATA: {
      return {
        ...state,
        data: {},
        error: {}
      };
    }
    case sdg1633Constants.SAVE_1633_DATA_SUCCESS: {
      return {
        ...state,
        data: action.data
      };
    }
    case sdg1633Constants.SAVE_1633_DATA_FAILURE: {
      return {
        ...state,
        error: action.error
      };
    }
    case sdg1633Constants.UPDATE_1633_DENOMINATOR_FIELD: {
      return {
        ...state,
        data: {
          ...state.data,
          denominator: {
            ...state.data.denominator,
            [action.data.name]: action.data.value
          }
        }
      };
    }
    case sdg1633Constants.UPDATE_1633_QUESTIONAIRE_FILE: {
      return {
        ...state,
        data: {
          ...state.data,
          questionaire_file: action.data.data.id,
          questionaire_file_path: action.data.data.file,
        }
      };
    }
    case sdg1633Constants.UPDATE_1633_IMPL_MANUAL_FILE: {
      return {
        ...state,
        data: {
          ...state.data,
          implementation_manual_file: action.data.data.id,
          implementation_manual_file_path: action.data.data.file,
        }
      };
    }
    case sdg1633Constants.UPDATE_1633_INT_MANUAL_FILE: {
      return {
        ...state,
        data: {
          ...state.data,
          interviewer_manual_file: action.data.data.id,
          interviewer_manual_file_path: action.data.data.file,
        }
      };
    }

    default:
      return state;
  }
}
