import React, { Component } from "react";

import { TableBody } from "./TableBody";
import { connect } from "react-redux";

import { sdg1662Actions } from "../../../../../_actions/sdg1662.actions";
import { Link } from "react-router-dom";

class IndicatorData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      error: props.error
    };

    this.handleTableChange = this.handleTableChange.bind(this);
    this.getTableRows = this.getTableRows.bind(this);
    this.goToMetadata = this.goToMetadata.bind(this);
  }

  getTableRows() {
    return [
      {
        isHead: false,
        title: "Total / National:",
        name: "total_national"
      },
      {
        isHead: true,
        title: "Sex",
        span: "4"
      },
      {
        isHead: false,
        title: "Male",
        name: "male"
      },
      {
        isHead: false,
        title: "Female",
        name: "female"
      },
      {
        isHead: true,
        title: "Urban/rural",
        span: "4"
      },
      {
        isHead: false,
        title: "Urban",
        name: "urban"
      },
      {
        isHead: false,
        title: "Rural",
        name: "rural"
      },
      {
        isHead: true,
        title: "Education level",
        span: "4"
      },
      {
        isHead: false,
        title: "Primary",
        name: "education_primary"
      },
      {
        isHead: false,
        title: "Secondary",
        name: "education_secondary"
      },
      {
        isHead: false,
        title: "Tertiary",
        name: "education_tertiary"
      },
      {
        isHead: true,
        title: "Income or expenditure quintiles",
        span: "4"
      },
      {
        isHead: false,
        title: "Quintile 1",
        name: "income_quintile_1"
      },
      {
        isHead: false,
        title: "Quintile 2",
        name: "income_quintile_2"
      },
      {
        isHead: false,
        title: "Quintile 3",
        name: "income_quintile_3"
      },
      {
        isHead: false,
        title: "Quintile 4",
        name: "income_quintile_4"
      },
      {
        isHead: false,
        title: "Quintile 5",
        name: "income_quintile_5"
      },
      {
        isHead: true,
        title: "Disability status",
        span: "4"
      },
      {
        isHead: false,
        title: "Disabled",
        name: "disabled"
      },
      {
        isHead: false,
        title: "Not disabled",
        name: "not_disabled"
      },
      {
        isHead: true,
        title: "Nationally relevant population group",
        span: "4"
      },
      {
        isHead: false,
        title: "Population group A",
        name: "population_a"
      },
      {
        isHead: false,
        title: "Population group B",
        name: "population_b"
      },
      {
        isHead: false,
        title: "Population group C",
        name: "population_c"
      },
      {
        isHead: true,
        title: "Age group",
        span: "4"
      },
      {
        isHead: false,
        title: "Below 25",
        name: "age_below_25"
      },
      {
        isHead: false,
        title: "25-34",
        name: "age_25_34"
      },
      {
        isHead: false,
        title: "35-44",
        name: "age_35_44"
      },
      {
        isHead: false,
        title: "45-54",
        name: "age_45_54"
      },
      {
        isHead: false,
        title: "55-64",
        name: "age_55_64"
      },
      {
        isHead: false,
        title: "65+",
        name: "age_over_65"
      },
    ];
  }

  handleTableChange(name, value) {
    this.props.onDataChange(name, value);

    let { dispatch } = this.props;

    let target = name.split(":");

    //console.log("Target", target);

    dispatch(
      sdg1662Actions.updateDataField(parseFloat(target[0]), target[1], value)
    );
  }

  goToMetadata() {
    this.props.onPrevious();
  }

  render() {
    let info = this.props.data ? (
      !this.props.data.id || this.props.data.id === null ? (
        <div className="row">
          <div className="col-sm-12" style={{ marginBottom: "15px" }}>
            * Please save <Link onClick={this.goToMetadata}>metadata</Link>{" "}
            before entering indicator data.
          </div>
        </div>
      ) : null
    ) : null;

    return (
      <React.Fragment>
        <div className="box-header with-border">
          <div className="col-sm-8">
            <h3>Data fields</h3>
          </div>
        </div>
        <div className="box-body">
          {info}
          <div className="row" style={{background:"lightblue"}}>
            <div className="col-sm-12">
              <div style={{display:"block"}}><strong>Inclusive Decision Making:</strong>  The indicator recommends the use of the question ‘How much would you say the political system in [country X] allows people like you to have a say in what the government does?’ and to use the response categories ‘1- not at all’, ‘2-very little’, ‘3-some’, ‘4-a lot’, ‘5-a great deal’. If a different scale was used please use insert the % of positive responses according with the scale used. <br /> <br /></div>
            </div>
          </div>
          <div className="row" style={{background:"lightblue"}}>
            <div className="col-sm-12">
              <div style={{display:"block"}}><strong>Responsive Decision Making:</strong>  The indicator recommends the use of the question ‘And how much would you say that the political system in [country] allows people like you to have an influence on politics?’ and to use the response categories ‘1- not at all’, ‘2-very little’, ‘3-some’, ‘4-a lot’, ‘5-a great deal’. If a different scale was used please use insert the % of positive responses according with the scale used.<br /> <br /></div>
            </div>
          </div>
          <br/>
          <div className="row">
            <div className="col-sm-8 adaptive-table">
              <fieldset
                disabled={
                  this.props.data &&
                  (!this.props.data.id || this.props.data.id === null)
                }
              >
                <table className="table table-bordered table-1671b">
                  <thead>
                    <tr>
                      <th className="th-1671b" style={{ width: "35%" }}></th>
                      <th className="th-1671b">
                      % of respondents who have respondent positively to ‘‘How much would you say the political system in [country X] allows people like you to have a say in what the government does?’ (positive responses refer to the answers ‘some’, ‘a lot’, ‘a great deal’)
                      </th>
                      <th className="th-1671b">
                      % of respondents who have respondent positively to ‘‘And how much would you say that the political system in [country] allows people like you to have an influence on politics?’ (positive responses refer to the answers ‘some’, ‘a lot’, ‘a great deal’)
                      </th>
                      <th className="th-1671b">
                      Percentage of individuals that have a positive response to both questions.
                      </th>
                    </tr>
                  </thead>
                  <TableBody name="data" rows={this.getTableRows()} />
                </table>
              </fieldset>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { data, error } = state.sdg1672State;

  return {
    data,
    error
  };
}

const connectedIndicatorData = connect(mapStateToProps)(IndicatorData);

export { connectedIndicatorData as IndicatorData };
