import React, { Component } from "react";
import { RadioButton, RadioButtonGroup, TextArea, TextInput } from "carbon-components-react";
import { connect } from "react-redux";

import { sdg1633Actions } from "../../../../../../_actions";
import YesNoRadio from "../../../../../common/elements/YesNoRadio";
import FileUploader from "../../../../../common/elements/FileUploader";
import axios from "axios";
import SourceSubmission from "../../../../../common/elements/SourceSubmission";


class Source extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anotherSource: this.checkAnotherSource(),
      selectedInterview: null,
      reference_period_from : null,
      reference_period_to : null,
      fieldwork_period_from : null,
      fieldwork_period_to : null,

    };

    this.handleChange = this.handleChange.bind(this);
    this.handleReferenceChange = this.handleReferenceChange.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleAnothersourceRadioChange = this.handleAnothersourceRadioChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleInterviewRadioChange = this.handleInterviewRadioChange.bind(this);

    this.onChangeUploadQuestionaire = this.onChangeUploadQuestionaire.bind(this);
    this.onChangeUploadImplManual = this.onChangeUploadImplManual.bind(this);
    this.onChangeUploadIntManual = this.onChangeUploadIntManual.bind(this);

  }

  handleChange(e) {
    const { dispatch } = this.props;

    dispatch(
      sdg1633Actions.updateMetadataField(e.target.name, e.target.value)
    );
  }

  handleReferenceChange(e) {
    const { dispatch } = this.props;

    //^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$
    let exp = /^(0[1-9]|1[012])[- /.](19|20)\d\d$/;

    if (exp.test(e.target.value)) {
      if (e.target.name == "reference_period_from") {
        this.setState({reference_period_from: null})
      } else {
        this.setState({reference_period_to: null})
      }    
    }
    else {
      if (e.target.name == "reference_period_from") {
        this.setState({reference_period_from: "Field should be in format MM/YYYY"})
      } else {
        this.setState({reference_period_to: "Field should be in format MM/YYYY"})
      }
    }

    if (e.target.value === "") {
      if (e.target.name == "reference_period_from") {
        this.setState({reference_period_from: null})
      } else {
        this.setState({reference_period_to: null})
      }  
    }


    dispatch(
      sdg1633Actions.updateMetadataField(e.target.name, e.target.value)
    );
  }

  handleFieldChange(e) {
    const { dispatch } = this.props;

    //^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$
    let exp = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/;

    if (exp.test(e.target.value)) {
      if (e.target.name == "fieldwork_period_from") {
        this.setState({fieldwork_period_from: null})
      } else {
        this.setState({fieldwork_period_to: null})
      }    
    }
    else {
      if (e.target.name == "fieldwork_period_from") {
        this.setState({fieldwork_period_from: "Field should be in format DD/MM/YYYY"})
      } else {
        this.setState({fieldwork_period_to: "Field should be in format DD/MM/YYYY"})
      }
    }

    if (e.target.value === "") {
      if (e.target.name == "fieldwork_period_from") {
        this.setState({fieldwork_period_from: null})
      } else {
        this.setState({fieldwork_period_to: null})
      }  
    }


    dispatch(
      sdg1633Actions.updateMetadataField(e.target.name, e.target.value)
    );
  }


  handleAnothersourceRadioChange(name, value) {
    this.setState({ anotherSource: value });
  }

  checkAnotherSource() {
    return (
      this.props.data &&
      (this.props.data["nss_1_name"] != null ||
        this.props.data["nss_2_name"] != null ||
        this.props.data["nss_3_name"] != null)
    );
  }

  onChangeUploadQuestionaire(e) {

    let data = new FormData();
    const { dispatch } = this.props;

    data.append("file", e.target.files[0])

    this.setState({
      file_upload_status: "uploading"
    });

    axios
      .post("/api/v2/upload/", data)
      .then(response => {

        let file_name = response.data.file.substring(response.data.file.indexOf("media/")+6);
        dispatch(sdg1633Actions.updateQuestionaireFile({id: response.data.id, file: file_name}));
        this.setState({
          file_upload_status: "edit"
        });
      });

    // setTimeout(() => {
    //   this.setState({
    //     file_upload_status: "edit"
    //   });
    // }, 2000);
  }

  onDeleteQuestionaireUpload = () => {

    if (this.props.data.status !== 'entry') 
      return;

    const { dispatch } = this.props;
    
    this.setState({
      file_upload_status: "none",
    });

        dispatch(sdg1633Actions.updateQuestionaireFile({id: null, file: ""}));
  };

  onDownloadQuestionaireFile = () => {
    return axios
      .get("/media/"+this.props.data.questionaire_file_path, { responseType: "blob" })
      .then(response => {
        let blob = new Blob([response.data], {
            type: "application/xlsx; charset=utf-8"
          }),
          downloadUrl = window.URL.createObjectURL(blob),
          filename = this.props.data.questionaire_file_path.substring(
            this.props.data.questionaire_file_path.lastIndexOf("/" + 1)
          );

        let a = document.createElement("a");
        if (typeof a.download === "undefined") {
          window.location.href = downloadUrl;
        } else {
          a.href = downloadUrl;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
        }
        return response.data;
      });
  };

  onChangeUploadImplManual(e) {

    let data = new FormData();
    const { dispatch } = this.props;

    data.append("file", e.target.files[0])

    this.setState({
      file_upload_status: "uploading"
    });

    axios
      .post("/api/v2/upload/", data)
      .then(response => {

        let file_name = response.data.file.substring(response.data.file.indexOf("media/")+6);
        dispatch(sdg1633Actions.updateImplManualFile({id: response.data.id, file: file_name}));
        this.setState({
          file_upload_status: "edit"
        });
      });

    // setTimeout(() => {
    //   this.setState({
    //     file_upload_status: "edit"
    //   });
    // }, 2000);
  }

  onDeleteImplManualUpload = () => {

    if (this.props.data.status !== 'entry') 
      return;

    const { dispatch } = this.props;
    
    this.setState({
      file_upload_status: "none",
    });

        dispatch(sdg1633Actions.updateImplManualFile({id: null, file: ""}));
  };

  onDownloadImplManualFile = () => {
    return axios
      .get("/media/"+this.props.data.implementation_manual_file_path, { responseType: "blob" })
      .then(response => {
        let blob = new Blob([response.data], {
            type: "application/xlsx; charset=utf-8"
          }),
          downloadUrl = window.URL.createObjectURL(blob),
          filename = this.props.data.implementation_manual_file_path.substring(
            this.props.data.implementation_manual_file_path.lastIndexOf("/" + 1)
          );

        let a = document.createElement("a");
        if (typeof a.download === "undefined") {
          window.location.href = downloadUrl;
        } else {
          a.href = downloadUrl;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
        }
        return response.data;
      });
  };

  onChangeUploadIntManual(e) {

    let data = new FormData();
    const { dispatch } = this.props;

    data.append("file", e.target.files[0])

    this.setState({
      file_upload_status: "uploading"
    });

    axios
      .post("/api/v2/upload/", data)
      .then(response => {

        let file_name = response.data.file.substring(response.data.file.indexOf("media/")+6);
        dispatch(sdg1633Actions.updateIntManualFile({id: response.data.id, file: file_name}));
        this.setState({
          file_upload_status: "edit"
        });
      });

    // setTimeout(() => {
    //   this.setState({
    //     file_upload_status: "edit"
    //   });
    // }, 2000);
  }

  onDeleteIntManualUpload = () => {

    if (this.props.data.status !== 'entry') 
      return;

    const { dispatch } = this.props;
    
    this.setState({
      file_upload_status: "none",
    });

        dispatch(sdg1633Actions.updateIntManualFile({id: null, file: ""}));
  };

  onDownloadIntManualFile = () => {
    return axios
      .get("/media/"+this.props.data.interviewer_manual_file_path, { responseType: "blob" })
      .then(response => {
        let blob = new Blob([response.data], {
            type: "application/xlsx; charset=utf-8"
          }),
          downloadUrl = window.URL.createObjectURL(blob),
          filename = this.props.data.interviewer_manual_file_path.substring(
            this.props.data.interviewer_manual_file_path.lastIndexOf("/" + 1)
          );

        let a = document.createElement("a");
        if (typeof a.download === "undefined") {
          window.location.href = downloadUrl;
        } else {
          a.href = downloadUrl;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
        }
        return response.data;
      });
  };


  handleRadioChange(name, value) {
    const { dispatch } = this.props;

    dispatch(sdg1633Actions.updateMetadataField(name, value));

  }

  handleInterviewRadioChange(e) {
    const { dispatch } = this.props;

    dispatch(sdg1633Actions.updateMetadataField("interviewing_method", e.target.value));
  }


  render() {

    let questionaire_file_name =
    this.props.data && this.props.data.questionaire_file && this.props.data.questionaire_file !== null
      ? this.props.data.questionaire_file_path.substring(
        this.props.data.questionaire_file_path.lastIndexOf("/") + 1
        )
      : "";

      let impl_manual_file_name =
      this.props.data && this.props.data.implementation_manual_file && this.props.data.implementation_manual_file !== null
        ? this.props.data.implementation_manual_file_path.substring(
          this.props.data.implementation_manual_file_path.lastIndexOf("/") + 1
          )
        : "";

        let int_manual_file_name =
        this.props.data && this.props.data.interviewer_manual_file && this.props.data.interviewer_manual_file !== null
          ? this.props.data.interviewer_manual_file_path.substring(
            this.props.data.interviewer_manual_file_path.lastIndexOf("/") + 1
            )
          : "";
  
      let anotherTable = this.state.anotherSource ? (
        <React.Fragment>
          <div className="col-sm-12">
            Please provide here the contact information for your focal point(s) in
            this/these institution(s):
          </div>
          <br />
  
          <div className="col-sm-12">
            <table
              className="table table-bordered dataTable"
              name="question-table"
              style={{ tableLayout: "fixed" }}
            >
              <thead>
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Institution</th>
                  <th>Position</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                <tr role="row">
                  <td>Focal point 1:</td>
                  <td>
                    <TextInput
                      id="nss_1_name"
                      name="nss_1_name"
                      // className="input-1671b"
                      labelText=""
                      onChange={this.handleChange}
                      value={this.props.data ? this.props.data["nss_1_name"] : ""}
                      invalid={
                        this.props.error &&
                        this.props.error["nss_1_name"] !== undefined
                      }
                      invalidText={
                        this.props.error && this.props.error["nss_1_name"]
                          ? this.props.error["nss_1_name"][0]
                          : ""
                      }
                    />
                  </td>
                  <td>
                    <TextInput
                      id="nss_1_institution"
                      name="nss_1_institution"
                      // className="input-1671b"
                      labelText=""
                      onChange={this.handleChange}
                      value={
                        this.props.data
                          ? this.props.data["nss_1_institution"]
                          : ""
                      }
                      invalid={
                        this.props.error &&
                        this.props.error["nss_1_institution"] !== undefined
                      }
                      invalidText={
                        this.props.error && this.props.error["nss_1_institution"]
                          ? this.props.error["nss_1_institution"][0]
                          : ""
                      }
                    />
                  </td>
                  <td>
                    <TextInput
                      id="nss_1_position"
                      name="nss_1_position"
                      // className="input-1671b"
                      labelText=""
                      onChange={this.handleChange}
                      value={
                        this.props.data ? this.props.data["nss_1_position"] : ""
                      }
                      invalid={
                        this.props.error &&
                        this.props.error["nss_1_position"] !== undefined
                      }
                      invalidText={
                        this.props.error && this.props.error["nss_1_position"]
                          ? this.props.error["nss_1_position"][0]
                          : ""
                      }
                    />
                  </td>
                  <td>
                    <TextInput
                      id="nss_1_email"
                      name="nss_1_email"
                      // className="input-1671b"
                      labelText=""
                      onChange={this.handleChange}
                      value={
                        this.props.data ? this.props.data["nss_1_email"] : ""
                      }
                      invalid={
                        this.props.error &&
                        this.props.error["nss_1_email"] !== undefined
                      }
                      invalidText={
                        this.props.error && this.props.error["nss_1_email"]
                          ? this.props.error["nss_1_email"][0]
                          : ""
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>Focal point 2:</td>
                  <td>
                    <TextInput
                      id="nss_2_name"
                      name="nss_2_name"
                      // className="input-1671b"
                      labelText=""
                      onChange={this.handleChange}
                      value={this.props.data ? this.props.data["nss_2_name"] : ""}
                      invalid={
                        this.props.error &&
                        this.props.error["nss_2_name"] !== undefined
                      }
                      invalidText={
                        this.props.error && this.props.error["nss_2_name"]
                          ? this.props.error["nss_2_name"][0]
                          : ""
                      }
                    />
                  </td>
                  <td>
                    <TextInput
                      id="nss_2_institution"
                      name="nss_2_institution"
                      // className="input-1671b"
                      labelText=""
                      onChange={this.handleChange}
                      value={
                        this.props.data
                          ? this.props.data["nss_2_institution"]
                          : ""
                      }
                      invalid={
                        this.props.error &&
                        this.props.error["nss_2_institution"] !== undefined
                      }
                      invalidText={
                        this.props.error && this.props.error["nss_2_institution"]
                          ? this.props.error["nss_2_institution"][0]
                          : ""
                      }
                    />
                  </td>
                  <td>
                    <TextInput
                      id="nss_2_position"
                      name="nss_2_position"
                      // className="input-1671b"
                      labelText=""
                      onChange={this.handleChange}
                      value={
                        this.props.data ? this.props.data["nss_2_position"] : ""
                      }
                      invalid={
                        this.props.error &&
                        this.props.error["nss_2_position"] !== undefined
                      }
                      invalidText={
                        this.props.error && this.props.error["nss_2_position"]
                          ? this.props.error["nss_2_position"][0]
                          : ""
                      }
                    />
                  </td>
                  <td>
                    <TextInput
                      id="nss_2_email"
                      name="nss_2_email"
                      // className="input-1671b"
                      labelText=""
                      onChange={this.handleChange}
                      value={
                        this.props.data ? this.props.data["nss_2_email"] : ""
                      }
                      invalid={
                        this.props.error &&
                        this.props.error["nss_2_email"] !== undefined
                      }
                      invalidText={
                        this.props.error && this.props.error["nss_2_email"]
                          ? this.props.error["nss_2_email"][0]
                          : ""
                      }
                    />
                  </td>
                </tr>
  
                <tr>
                  <td>Focal point 3:</td>
  
                  <td>
                    <TextInput
                      id="nss_3_name"
                      name="nss_3_name"
                      // className="input-1671b"
                      labelText=""
                      onChange={this.handleChange}
                      value={this.props.data ? this.props.data["nss_3_name"] : ""}
                      invalid={
                        this.props.error &&
                        this.props.error["nss_3_name"] !== undefined
                      }
                      invalidText={
                        this.props.error && this.props.error["nss_3_name"]
                          ? this.props.error["nss_3_name"][0]
                          : ""
                      }
                    />
                  </td>
                  <td>
                    <TextInput
                      id="nss_3_institution"
                      name="nss_3_institution"
                      // className="input-1671b"
                      labelText=""
                      onChange={this.handleChange}
                      value={
                        this.props.data
                          ? this.props.data["nss_3_institution"]
                          : ""
                      }
                      invalid={
                        this.props.error &&
                        this.props.error["nss_3_institution"] !== undefined
                      }
                      invalidText={
                        this.props.error && this.props.error["nss_3_institution"]
                          ? this.props.error["nss_3_institution"][0]
                          : ""
                      }
                    />
                  </td>
                  <td>
                    <TextInput
                      id="nss_3_position"
                      name="nss_3_position"
                      // className="input-1671b"
                      labelText=""
                      onChange={this.handleChange}
                      value={
                        this.props.data ? this.props.data["nss_3_position"] : ""
                      }
                      invalid={
                        this.props.error &&
                        this.props.error["nss_3_position"] !== undefined
                      }
                      invalidText={
                        this.props.error && this.props.error["nss_3_position"]
                          ? this.props.error["nss_3_position"][0]
                          : ""
                      }
                    />
                  </td>
                  <td>
                    <TextInput
                      id="nss_3_email"
                      name="nss_3_email"
                      // className="input-1671b"
                      labelText=""
                      onChange={this.handleChange}
                      value={
                        this.props.data ? this.props.data["nss_3_email"] : ""
                      }
                      invalid={
                        this.props.error &&
                        this.props.error["nss_3_email"] !== undefined
                      }
                      invalidText={
                        this.props.error && this.props.error["nss_3_email"]
                          ? this.props.error["nss_3_email"][0]
                          : ""
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment />
      );
    

    return (
      <React.Fragment>
        <div className="box-header with-border">
          <h3 className="box-title metadata-title">Source</h3>
        </div>
        <div className="box-body with-border">
          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div className="adaptive-input col-md-6 col-sm-12">
              <TextInput
                id="reference_period_from"
                name="reference_period_from"
                // className="input-1671c"
                style={{ width: "120px" }}
                labelText="1. Reference period from:"
                placeholder="MM/YYYY"
                onChange={this.handleReferenceChange}
                value={
                  this.props.data && this.props.data["reference_period_from"]
                    ? this.props.data["reference_period_from"]
                    : ""
                }
                invalid={
                  this.props.error && 
                  this.props.error["reference_period_from"] !== undefined || this.state.reference_period_from
                }
                invalidText={
                  this.props.error &&
                  this.props.error["reference_period_from"]
                    ? this.props.error["reference_period_from"][0]
                    : this.state.reference_period_from ? this.state.reference_period_from : ""
                }
                autoFocus={true}
                required
              />
            </div>
            <div className="adaptive-input col-md-6 col-sm-12">
              <TextInput
                id="reference_period_to"
                name="reference_period_to"
                // className="input-1671c"
                style={{ width: "120px" }}
                labelText="Reference period to:"
                placeholder="MM/YYYY"
                onChange={this.handleReferenceChange}
                value={
                  this.props.data && this.props.data["reference_period_to"]
                    ? this.props.data["reference_period_to"]
                    : ""
                }
                invalid={
                  this.props.error && 
                  this.props.error["reference_period_to"] !== undefined || this.state.reference_period_to
                }
                invalidText={
                  this.props.error &&
                  this.props.error["reference_period_to"]
                    ? this.props.error["reference_period_to"][0]
                    : this.state.reference_period_to ? this.state.reference_period_to : ""
                }
              />
            </div>
          </div>
          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div className="adaptive-input col-md-6 col-sm-12">
              <TextInput
                id="fieldwork_period_from"
                name="fieldwork_period_from"
                // className="input-1671c"
                style={{ width: "120px" }}
                labelText="2. Fieldwork period from:"
                placeholder="DD/MM/YYYY"
                onChange={this.handleFieldChange}
                value={
                  this.props.data && this.props.data["fieldwork_period_from"]
                    ? this.props.data["fieldwork_period_from"]
                    : ""
                }
                invalid={
                  this.props.error && 
                  this.props.error["fieldwork_period_from"] !== undefined || this.state.fieldwork_period_from
                }
                invalidText={
                  this.props.error &&
                  this.props.error["fieldwork_period_from"]
                    ? this.props.error["fieldwork_period_from"][0]
                    : this.state.fieldwork_period_from ? this.state.fieldwork_period_from : ""
                }
              />
            </div>
            <div className="adaptive-input col-md-6 col-sm-12">
              <TextInput
                id="fieldwork_period_to"
                name="fieldwork_period_to"
                // className="input-1671c"
                style={{ width: "120px" }}
                labelText="Fieldwork period to:"
                placeholder="DD/MM/YYYY"
                onChange={this.handleFieldChange}
                value={
                  this.props.data && this.props.data["fieldwork_period_to"]
                    ? this.props.data["fieldwork_period_to"]
                    : ""
                }
                invalid={
                  this.props.error && 
                  this.props.error["fieldwork_period_to"] !== undefined || this.state.fieldwork_period_to
                }
                invalidText={
                  this.props.error &&
                  this.props.error["fieldwork_period_to"]
                    ? this.props.error["fieldwork_period_to"][0]
                    : this.state.fieldwork_period_to ? this.state.fieldwork_period_to : ""
                }
              />
            </div>
          </div>
          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div className="col-sm-8 adaptive-form">
              <TextArea
                id="data_source"
                name="data_source"
                value={
                  this.props.data && this.props.data["data_source"]
                    ? this.props.data["data_source"]
                    : ""
                }
                onChange={this.handleChange}
                labelText="3.	Data source used for this indicator (please specify the name and general topic of the questionnaire these questions were embedded in, if applicable):"
              />
            </div>
          </div>
          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div
              className="col-sm-12"
              style={{ display: "flex", marginBottom: "15px" }}
            >
              <div
                className="bx--form-item bx--text-input-wrapper"
                style={{ display: "flex", marginBottom: "15px" }}
              >
                <YesNoRadio
                  label="4.	Is this data source nationally representative?"
                  name="nationally_representative"
                  defaultValue={this.props.data && this.props.data["nationally_representative"]}
                  _handleSelectedValue={this.handleRadioChange}
                />
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{marginBottom: "15px" }}
          >
            <div className="col-sm-8">
              <div>5.	Please upload questionnaire, implementation manual and interviewers’ manual:</div>
              <div
                  className="bx--form-item bx--text-input-wrapper"
                  style={{ marginBottom: "25px" }}
                >
                  <div className="col-sm-4">
                  <FileUploader
                    buttonLabel={questionaire_file_name && questionaire_file_name.length > 0 ? "Replace Questionaire" : "Add Questionaire" }  
                    id="upload_questionaire"
                    labelTitle=""
                    onChange={this.onChangeUploadQuestionaire}
                    onDelete={this.onDeleteQuestionaireUpload}
                    onDownload={this.onDownloadQuestionaireFile}
                    upload_status={this.props.data && this.props.data.questionaire_file !== null ? "edit" : ""}
                    value={questionaire_file_name}
                    enable_delete = {this.props.data && this.props.data.status !== 'validated'}
                  />
                  </div>
                  <div className="col-sm-4">
                  <FileUploader
                    buttonLabel={impl_manual_file_name && impl_manual_file_name.length > 0 ? "Replace Implementation Manual" : "Add Implementation Manual" }  
                    id="upload_impl_manual"
                    labelTitle=""
                    onChange={this.onChangeUploadImplManual}
                    onDelete={this.onDeleteImplManualUpload}
                    onDownload={this.onDownloadImplManualFile}
                    upload_status={this.props.data && this.props.data.implementation_manual_file !== null ? "edit" : ""}
                    value={impl_manual_file_name}
                    enable_delete = {this.props.data && this.props.data.status !== 'validated'}
                  />
                  </div>
                  <div className="col-sm-4">
                  <FileUploader
                    buttonLabel={int_manual_file_name && int_manual_file_name.length > 0 ? "Replace Interviewer's Manual" : "Add Interviewer's Manual" }  
                    id="upload_int_manual"
                    labelTitle=""
                    onChange={this.onChangeUploadIntManual}
                    onDelete={this.onDeleteIntManualUpload}
                    onDownload={this.onDownloadIntManualFile}
                    upload_status={this.props.data && this.props.data.interviewer_manual_file !== null ? "edit" : ""}
                    value={int_manual_file_name}
                    enable_delete = {this.props.data && this.props.data.status !== 'validated'}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{ display: "block", marginBottom: "15px" }}
          >
            <div className="col-sm-8">
              <TextInput
                id="document_languages"
                name="document_languages"
                // className="input-1671c"
                // style={{ width: "120px" }}
                labelText="6.	Please write the language(s) used in the uploaded documents:"
                onChange={this.handleChange}
                value={
                  this.props.data && this.props.data["document_languages"]
                    ? this.props.data["document_languages"]
                    : ""
                }
                invalid={
                  this.props.error && 
                  this.props.error["document_languages"] !== undefined
                }
                invalidText={
                  this.props.error &&
                  this.props.error["document_languages"]
                    ? this.props.error["document_languages"][0]
                    : ""
                }
              />
              </div>
          </div>
          <div
            className="row"
            style={{ display: "block", marginBottom: "15px" }}
          >
            <div className="col-sm-8">
              <div
                className="bx--form-item bx--text-input-wrapper"
                style={{ marginBottom: "12px" }}
              >
                <label htmlFor={this.props.name} className="bx--label">
                7.	Please select the interviewing method used in the survey:
                </label>
                <RadioButtonGroup
                  name="interviewing_method"
                  valueSelected={this.props.data ? this.props.data["interviewing_method"] : null}
                  orientation="vertical"
                >
                  <RadioButton
                    name={"PAPI"}
                    value="PAPI"
                    labelText="PAPI - Pen-and-Paper Personal Interview"
                    onClick={this.handleInterviewRadioChange}
                    selected={this.props.data && this.props.data["interviewing_method"] === "PAPI"}
                  />
                  <RadioButton
                    name={"CAPI"}
                    labelText="CAPI - Computer Assisted Personal Interview"
                    value="CAPI"
                    onClick={this.handleInterviewRadioChange}
                    selected={this.props.data && this.props.data["interviewing_method"] === "CAPI"}
                  />
                  <RadioButton
                    name={"CATI"}
                    labelText="CATI - Computer Assisted Telephone Interview"
                    value="CATI"
                    onClick={this.handleInterviewRadioChange}
                    selected={this.props.data && this.props.data["interviewing_method"] ===  "CATI"}
                  />
                  <RadioButton
                    name={"CAWI"}
                    labelText="CAWI - Computer Assisted Web Interviewing"
                    value="CAWI"
                    onClick={this.handleInterviewRadioChange}
                    selected={this.props.data && this.props.data["interviewing_method"] ===  "CAWI"}
                  />
                  <RadioButton
                    name={"COMBINATION"}
                    labelText="Combination of methods"
                    value="COMBINATION"
                    onClick={this.handleInterviewRadioChange}
                    selected={this.props.data && this.props.data["interviewing_method"] ===  "COMBINATION"}
                  />                                    
                </RadioButtonGroup>
              </div>
              <TextInput 
                id="other_interviewing_method"
                labelText="Please explain:" 
                onChange={this.handleChange}
                disabled={this.props.data && this.props.data["interviewing_method"] !== "COMBINATION"}
                name= "other_interviewing_method"
                value={
                  this.props.data && this.props.data["other_interviewing_method"]
                    ? this.props.data["other_interviewing_method"]
                    : ""
                }
                invalid={
                  this.props.error && 
                  this.props.error["other_interviewing_method"] !== undefined
                }
                invalidText={
                  this.props.error &&
                  this.props.error["other_interviewing_method"]
                    ? this.props.error["other_interviewing_method"][0]
                    : ""
                }              
              />
            </div>
          </div>          
          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div
              className="col-sm-12"
              style={{ display: "flex", marginBottom: "15px" }}
            >
              <div
                className="bx--form-item bx--text-input-wrapper"
                style={{ display: "flex", marginBottom: "15px" }}
              >
                <YesNoRadio
                  label="8.	Was this data collected by an entity other than the NSO, such as another agency/ministry in the National Statistical System?"
                  name="collecting_entity"
                  defaultValue={this.state.anotherSource}
                  _handleSelectedValue={this.handleAnothersourceRadioChange}
                />
              </div>
            </div>
            {anotherTable}
          </div>
          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div
              className="col-sm-8"
              style={{ display: "block", marginBottom: "15px" }}
            >
              <div
                className="bx--form-item bx--text-input-wrapper"
                style={{ display: "flex", marginBottom: "15px" }}
              >
                <label htmlFor="compatible_previous_submission" className="bx--label">
                9.	Are the results of the current submission fully compatible with the previous submission?
                </label>
                <SourceSubmission 
                  name="compatible_previous_submission" 
                  handleRadioChange={this.handleRadioChange}
                  defaultValue={this.props.data &&
                    this.props.data[
                    "compatible_previous_submission"
                    ] !== undefined
                    ? this.props.data[
                    "compatible_previous_submission"
                    ]
                    : null} />
              </div>
              <TextInput 
                labelText="Please briefly explain methodoligical differences:" 
                onChange={this.handleChange}
                disabled={this.props.data && this.props.data["compatible_previous_submission"] !== "no"}
                name= "methodological_differences"
                id= "methodological_differences"
                value={
                  this.props.data && this.props.data["methodological_differences"]
                    ? this.props.data["methodological_differences"]
                    : ""
                }
                invalid={
                  this.props.error && 
                  this.props.error["methodological_differences"] !== undefined
                }
                invalidText={
                  this.props.error &&
                  this.props.error["methodological_differences"]
                    ? this.props.error["methodological_differences"][0]
                    : ""
                }              
              />

            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { data, error } = state.sdg1633State;

  return {
    data,
    error
  };
}

const connectedSource = connect(mapStateToProps)(Source);

export { connectedSource as Source };
