import { userConstants } from "../_constants";

let token = localStorage.getItem("token");

const initialState = token ? { loggedIn: true, token } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
        loginFailure: false,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user,
        loginFailure: false,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        loginFailure: true,
      };
    case userConstants.LOGOUT:
      return {};
    case userConstants.UNDP_AUTH_REQUEST:
      return {
        authenticating: true,
      };
    case userConstants.UNDP_NOT_AUTH:
      return {
        unauthorizedUNDP: true,
      };
    default:
      return state;
  }
}
