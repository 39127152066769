import React, { Component } from "react";

import DataTable, {
  TableToolbar,
  Table,
  TableHead,
  TableRow,
  TableExpandHeader,
  TableHeader,
  TableBody,
  TableContainer,
  TableToolbarContent,
  TableToolbarSearch
} from "carbon-components-react/lib/components/DataTable/";

import NSOUserTableRow from "./UsersTable/NSOUserTableRow";

import axios from "axios";
import { Button, Modal, TextInput } from "carbon-components-react";

import { userService } from "../../_services";

import { history } from "../../_helpers";
import { connect } from "react-redux";
import CountrySelection from "../common/CountrySelection";

class NSOUsersTable extends Component {
  constructor(props) {
    super(props);

    this.exportToExcel = this.exportToExcel.bind(this);
    this.handleAddNewClick = this.handleAddNewClick.bind(this);
    this.handleNotifyClick = this.handleNotifyClick.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);

  }
  state = {
    initialRows: [],
    add : false,
    newUser: "",
    firstName: ""
  };

  componentDidMount() {
    axios.get("/api/users/").then(response => {
      let data = [];
      let row_metadata = [];
      response.data
        .filter(usr => usr.user_type === "NSO")
        .forEach(user => {
          data.push({
            id: user.id.toString(),
            country_name: user.country_name,
            // user_type: user.user_type,
            last_name: user.last_name,
            position: user.position,
            institution: user.institution,
            email: user.email,
            // email_2: user.email_2
          });
          row_metadata.push({
            url: user.url,
            id: user.id,
            country: user.country
          });
         });

         this.setState({ initialRows: data, metadata: row_metadata });
    });
  }

  exportToExcel() {
    userService.exportContactsToExcel();
  }

  handleAddNewClick() {
    history.push("/users");
  }

  handleNotifyClick() {
    history.push("/notify-users");
  }

  handleModal() {
    this.setState({add: true});
  }

  onSelectionChanged(selectedValue) {

    const url = selectedValue === "999" ? "/api/users/" : "/api/users/?country=" + selectedValue;

    axios.get(url).then(response => {
      let data = [];
      let row_metadata = [];
      response.data
        .filter(usr => usr.user_type === "NSO")
        .forEach(user => {
          data.push({
            id: user.id.toString(),
            country_name: user.country_name,
            // user_type: user.user_type,
            last_name: user.last_name,
            position: user.position,
            institution: user.institution,
            email: user.email,
            // email_2: user.email_2
          });
          row_metadata.push({
            url: user.url,
            id: user.id,
            country: user.country
          });
         });

         this.setState({ initialRows: data, metadata: row_metadata });
    });
}


  submit() {
    //console.log("Submit");

    let newUser = this.state.newUser;

    let data = {};
    data.email = this.state.newUser;
    data.user_type = "OGC"
    data.country = 249

    axios.post("/api/users/", data).then(response => {
      
      axios.get("/api/users/").then(response => {
        let data = [];
        let row_metadata = [];
        response.data
          .filter(usr => usr.user_type === "NSO")
          .forEach(user => {
            data.push({
              id: user.id.toString(),
              country_name: user.country_name,
              // user_type: user.user_type,
              last_name: user.last_name,
              position: user.position,
              institution: user.institution,
              email: user.email,
              // email_2: user.email_2
            });
            row_metadata.push({
              url: user.url,
              id: user.id,
              country: user.country
            });
          });
  
        this.setState({ initialRows: data, metadata: row_metadata, add: false });
      });
    });

  }

  handleDelete(id) {

    axios.delete("/api/users/" + id + "/").then(response => {
      
      axios.get("/api/users/").then(response => {
        let data = [];
        let row_metadata = [];
        response.data
          .filter(usr => usr.user_type === "NSO")
          .forEach(user => {
            data.push({
              id: user.id.toString(),
              country_name: user.country_name,
              // user_type: user.user_type,
              last_name: user.last_name,
              position: user.position,
              institution: user.institution,
              email: user.email,
              // email_2: user.email_2
            });
            row_metadata.push({
              url: user.url,
              id: user.id,
              country: user.country
            });
          });
  
        this.setState({ initialRows: data, metadata: row_metadata });
      });
    });

  }

  onNewuser(e) { 
    this.setState({newUser: e.target.value})
  }

  close() {
    this.setState({add: false})
  }

  render() {
    const headers = [
      {
        key: "country_name",
        header: "Country"
      },
      {
        key: "last_name",
        header: "Name"
      },
      {
        key: "position",
        header: "Position"
      },
      {
        key: "institution",
        header: "Organization"
      },

      {
        key: "email",
        header: "E-mail"
      },
      {
        key: "val",
        header: "Delete User"
      }
      // {
      //   key: "email_2",
      //   header: "E-mail2"
      // }
    ];

    const initialRows = this.state.initialRows;

    console.log("Initial rows", initialRows, this.state.metadata);

    return (
      <div id="content-wrapper" className="content-wrapper">
        {/* <PageHeader title="Focal Points" url="/users" /> */}

        {this.state.add?     <Modal
      open
      modalHeading="Add new OGC user"
      modalLabel=""
      primaryButtonText="Add"
      secondaryButtonText="Cancel"
      onRequestSubmit={this.submit.bind(this)}
      onRequestClose={this.close.bind(this)}
      >
      
      <TextInput
        data-modal-primary-focus
        id="text-input-1"
        labelText="UNDP email"
        placeholder="someone@undp.org"
        style={{ marginBottom: '1rem' }}
        onChange={this.onNewuser.bind(this)}
        value = {this.state.newUser}
      />
    </Modal> : null}

        <div className="box">
          <div className="box-body">
            <div className="row">
              <div className="col-sm-12">
                Please edit below the information about country NSO users.
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <DataTable
            headers={headers}
            rows={initialRows}
            render={({ rows, headers, getRowProps, onInputChange }) => (
              <TableContainer>
                <TableToolbar style={{height:"5rem"}}>
                  <TableToolbarContent>
                    <CountrySelection addAll={true} onSelectionChanged={this.onSelectionChanged} hideTitle={false}/>
                    {/* <Button onClick={this.handleModal}>Add new user</Button> */}
                  </TableToolbarContent>
                </TableToolbar>
                <Table>
                  <TableHead>
                    <TableRow>
                      {headers.map(header => (
                        <TableHeader key={header.key}>
                          {header.header}
                        </TableHeader>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => {
                      return (
                        this.state.metadata[index] && <NSOUserTableRow
                        id={this.state.metadata[index].id}
                        key={this.state.metadata[index].id}
                        row={this.state.metadata[index]}
                        row_props={{ ...getRowProps({ row }) }}
                        user={initialRows[index]}
                        country={this.state.metadata[index].country}
                        url={this.state.metadata[index].url}
                        handleDelete={this.handleDelete}
                      />
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          />
        </section>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;

  return {
    user
  };
}

const connectedUsersTable = connect(mapStateToProps)(NSOUsersTable);
export { connectedUsersTable as NSOUsersTable };
