import React, { Component } from "react";

import { connect } from "react-redux";

import { sdg1662Actions } from "../../../../../../_actions";
import InputTable from "../../../../../common/table/InputTable";


class GovernmentData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      error: props.error
    };

    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleTableOverallChange = this.handleTableOverallChange.bind(this);
    this.goToMetadata = this.goToMetadata.bind(this);
  }

  handleTableChange(name, value) {
    let { dispatch } = this.props;

    let target = name.split(":");

    dispatch(
      sdg1662Actions.updateDataField(parseInt(target[0]), target[1], value.lastIndexOf(".") === value.length - 1 ? value : parseFloat(value))
    );
  }

  handleTableOverallChange(name, value) {
    let { dispatch } = this.props;

    let target = name.split(":");

    dispatch(
      sdg1662Actions.updateDataSatisfiedField(parseInt(target[0]), target[1], value.lastIndexOf(".") === value.length - 1 ? value : parseFloat(value))
    );
  }
  goToMetadata() {
    this.props.onPrevious();
  }

  
  render() {

    let headers = [
      {title: ""},
      {title: "Accessibility (“The office, website or [toll free] telephone number was easily accessible.”)"},
      {title: "Affordability (“The fees you needed to pay for the ID or the certificate were affordable to you/your household.”)"},
      {title: "Quality of facilities (“The process for applying and obtaining the ID or the certificate was simple and easy to understand.”)"},
      {title: "Equal treatment for everyone (“All people are treated equally in receiving government services in your area.”)"},
      {title: "Timeliness (“The amount of time it took to obtain the ID or the certificate was reasonable.”)"},
      {title: "Simple average of positive responses for the five attribute questions combined"},

    ]

    let index = 18;

    let tableData = [
      {
        texts: [
          {title: "Total/National",},  
        ],
        inputs: [
          {
            name: `${index + 0}:total`,
            data : this.props.data ?  this.props.data.positive_data[index + 0]["total"]: "",
            invalid: this.props.error && 
                      this.props.error.positive_data && 
                      this.props.error.positive_data[index + 0]["total"] &&
                      this.props.error.positive_data[index + 0]["total"][0] ? this.props.error.positive_data[index + 0]["total"][0] : null
          },
          {
            name: `${index + 1}:total`,
            data : this.props.data ? this.props.data.positive_data[index + 1]["total"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index + 1]["total"] &&
            this.props.error.positive_data[index + 1]["total"][0] ? this.props.error.positive_data[index + 1]["total"][0] : null
          },
          {
            name: `${index + 2}:total`,
            data : this.props.data ? this.props.data.positive_data[index+2]["total"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index + 2]["total"] &&
            this.props.error.positive_data[index+2]["total"][0] ? this.props.error.positive_data[index+2]["total"][0] : null
          },
          {
            name: `${index + 3}:total`,
            data : this.props.data ? this.props.data.positive_data[index+3]["total"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+3]["total"] &&
            this.props.error.positive_data[index+3]["total"][0] ? this.props.error.positive_data[index+3]["total"][0] : null
          },
          {
            name: `${index + 4}:total`,
            data : this.props.data ? this.props.data.positive_data[index+4]["total"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+4]["total"] &&
            this.props.error.positive_data[index+4]["total"][0] ? this.props.error.positive_data[index+4]["total"][0] : null
          },
          {
            name: `${index + 5}:total`,
            data : this.props.data ? this.props.data.positive_data[index+5]["total"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+5]["total"] &&
            this.props.error.positive_data[index+5]["total"][0] ? this.props.error.positive_data[index+5]["total"][0] : null
          },        
        ]         
      },
      {
        isTitle : true,
        colSpan : "7",
        title: "Male/Female"
      },
      {
        texts: [
          {title: "Male",},  
        ],
        inputs: [
          {
            name: `${index + 0}:male`,
            data : this.props.data ?  this.props.data.positive_data[index+0]["male"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+0]["male"] &&
            this.props.error.positive_data[index+0]["male"][0] ? this.props.error.positive_data[index+0]["male"][0] : null
          },
          {
            name: `${index + 1}:male`,
            data : this.props.data ? this.props.data.positive_data[index+1]["male"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+1]["male"] &&
            this.props.error.positive_data[index+1]["male"][0] ? this.props.error.positive_data[index+1]["male"][0] : null
          },
          {
            name: `${index + 2}:male`,
            data : this.props.data ? this.props.data.positive_data[index+2]["male"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+2]["male"] &&
            this.props.error.positive_data[index+2]["male"][0] ? this.props.error.positive_data[index+2]["male"][0] : null
},
          {
            name:`${index + 3}:male`,
            data : this.props.data ? this.props.data.positive_data[index+3]["male"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+3]["male"] &&
            this.props.error.positive_data[index+3]["male"][0] ? this.props.error.positive_data[index+3]["male"][0] : null
},
          {
            name: `${index + 4}:male`,
            data : this.props.data ? this.props.data.positive_data[index+4]["male"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+4]["male"] &&
            this.props.error.positive_data[index+4]["male"][0] ? this.props.error.positive_data[index+4]["male"][0] : null
          },
          {
            name: `${index + 5}:male`,
            data : this.props.data ? this.props.data.positive_data[index+5]["male"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+5]["male"] &&
            this.props.error.positive_data[index+5]["male"][0] ? this.props.error.positive_data[index+5]["male"][0] : null
          },        
        ]         
      },
      {
        texts: [
          {title: "Female",},  
        ],
        inputs: [
          {
            name: `${index + 0}:female`,
            data : this.props.data ?  this.props.data.positive_data[index+0]["female"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+0]["female"] &&
            this.props.error.positive_data[index+0]["female"][0] ? this.props.error.positive_data[index+0]["female"][0] : null
          },
          {
            name: `${index + 1}:female`,
            data : this.props.data ? this.props.data.positive_data[index+1]["female"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+1]["female"] &&
            this.props.error.positive_data[index+1]["female"][0] ? this.props.error.positive_data[index+1]["female"][0] : null
          },
          {
            name: `${index + 2}:female`,
            data : this.props.data ? this.props.data.positive_data[index+2]["female"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+2]["female"] &&
            this.props.error.positive_data[index+2]["female"][0] ? this.props.error.positive_data[index+2]["female"][0] : null
          },
          {
            name: `${index + 3}:female`,
            data : this.props.data ? this.props.data.positive_data[index+3]["female"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+3]["female"] &&
            this.props.error.positive_data[index+3]["female"][0] ? this.props.error.positive_data[index+3]["female"][0] : null
          },
          {
            name: `${index + 4}:female`,
            data : this.props.data ? this.props.data.positive_data[index+4]["female"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+4]["female"] &&
            this.props.error.positive_data[index+4]["female"][0] ? this.props.error.positive_data[index+4]["female"][0] : null
          },
          {
            name: `${index + 5}:female`,
            data : this.props.data ? this.props.data.positive_data[index+5]["female"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+5]["female"] &&
            this.props.error.positive_data[index+5]["female"][0] ? this.props.error.positive_data[index+5]["female"][0] : null
          },        
        ]         
      },
      {
        isTitle : true,
        colSpan : "7",
        title: "Urban/Rural"
      },
      {
        index : 4,
        data : this.props.data,
        texts: [
          {title: "Urban",}
        ],
        inputs: [
          {
            name: `${index + 0}:urban`,
            data : this.props.data ?  this.props.data.positive_data[index+0]["urban"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+0]["urban"] &&
            this.props.error.positive_data[index+0]["urban"][0] ? this.props.error.positive_data[index+0]["urban"][0] : null
          },
          {
            name: `${index + 1}:urban`,
            data : this.props.data ? this.props.data.positive_data[index+1]["urban"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+1]["urban"] &&
            this.props.error.positive_data[index+1]["urban"][0] ? this.props.error.positive_data[index+1]["urban"][0] : null
          },
          {
            name: `${index + 2}:urban`,
            data : this.props.data ? this.props.data.positive_data[index+2]["urban"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+2]["urban"] &&
            this.props.error.positive_data[index+2]["urban"][0] ? this.props.error.positive_data[index+2]["urban"][0] : null
          },
          {
            name: `${index + 3}:urban`,
            data : this.props.data ? this.props.data.positive_data[index+3]["urban"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+3]["urban"] &&
            this.props.error.positive_data[index+3]["urban"][0] ? this.props.error.positive_data[index+3]["urban"][0] : null
          },
          {
            name: `${index + 4}:urban`,
            data : this.props.data ? this.props.data.positive_data[index+4]["urban"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+4]["urban"] &&
            this.props.error.positive_data[index+4]["urban"][0] ? this.props.error.positive_data[index+4]["urban"][0] : null
          },
          {
            name: `${index + 5}:urban`,
            data : this.props.data ? this.props.data.positive_data[index+5]["urban"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+5]["urban"] &&
            this.props.error.positive_data[index+5]["urban"][0] ? this.props.error.positive_data[index+5]["urban"][0] : null
          },        
        ]         
      },
      {
        index : 5,
        data : this.props.data,
        texts: [
          {title: "Rural",}
        ],
        inputs: [
          {
            name: `${index + 0}:rural`,
            data : this.props.data ?  this.props.data.positive_data[index+0]["rural"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+0]["rural"] &&
            this.props.error.positive_data[index+0]["rural"][0] ? this.props.error.positive_data[index+0]["rural"][0] : null
          },
          {
            name: `${index + 1}:rural`,
            data : this.props.data ? this.props.data.positive_data[index+1]["rural"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+1]["rural"] &&
            this.props.error.positive_data[index+1]["rural"][0] ? this.props.error.positive_data[index+1]["rural"][0] : null
          },
          {
            name: `${index + 2}:rural`,
            data : this.props.data ? this.props.data.positive_data[index+2]["rural"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+2]["rural"] &&
            this.props.error.positive_data[index+2]["rural"][0] ? this.props.error.positive_data[index+2]["rural"][0] : null
          },
          {
            name: `${index + 3}:rural`,
            data : this.props.data ? this.props.data.positive_data[index+3]["rural"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+3]["rural"] &&
            this.props.error.positive_data[index+3]["rural"][0] ? this.props.error.positive_data[index+3]["rural"][0] : null
          },
          {
            name: `${index + 4}:rural`,
            data : this.props.data ? this.props.data.positive_data[index+4]["rural"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+4]["rural"] &&
            this.props.error.positive_data[index+4]["rural"][0] ? this.props.error.positive_data[index+4]["rural"][0] : null
          },
          {
            name: `${index + 5}:rural`,
            data : this.props.data ? this.props.data.positive_data[index+5]["rural"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+5]["rural"] &&
            this.props.error.positive_data[index+5]["rural"][0] ? this.props.error.positive_data[index+5]["rural"][0] : null
          },        
        ]         
      },
      {
        isTitle : true,
        colSpan : "7",
        title: "Income or expenditure quintiles"
      },
      {
        texts: [
          {title: "Quintile 1",}
        ],
        inputs: [
          {
            name: `${index + 0}:quintile_1`,
            data : this.props.data ?  this.props.data.positive_data[index+0]["quintile_1"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+0]["quintile_1"] &&
            this.props.error.positive_data[index+0]["quintile_1"][0] ? this.props.error.positive_data[index+0]["quintile_1"][0] : null
          },
          {
            name: `${index + 1}:quintile_1`,
            data : this.props.data ? this.props.data.positive_data[index+1]["quintile_1"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+1]["quintile_1"] &&
            this.props.error.positive_data[index+1]["quintile_1"][0] ? this.props.error.positive_data[index+1]["quintile_1"][0] : null
          },
          {
            name: `${index + 2}:quintile_1`,
            data : this.props.data ? this.props.data.positive_data[index+2]["quintile_1"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+2]["quintile_1"] &&
            this.props.error.positive_data[index+2]["quintile_1"][0] ? this.props.error.positive_data[index+2]["quintile_1"][0] : null
          },
          {
            name: `${index + 3}:quintile_1`,
            data : this.props.data ? this.props.data.positive_data[index+3]["quintile_1"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+3]["quintile_1"] &&
            this.props.error.positive_data[index+3]["quintile_1"][0] ? this.props.error.positive_data[index+3]["quintile_1"][0] : null
          },
          {
            name: `${index + 4}:quintile_1`,
            data : this.props.data ? this.props.data.positive_data[index+4]["quintile_1"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+4]["quintile_1"] &&
            this.props.error.positive_data[index+4]["quintile_1"][0] ? this.props.error.positive_data[index+4]["quintile_1"][0] : null
          },
          {
            name: `${index + 5}:quintile_1`,
            data : this.props.data ? this.props.data.positive_data[index+5]["quintile_1"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+5]["quintile_1"] &&
            this.props.error.positive_data[index+5]["quintile_1"][0] ? this.props.error.positive_data[index+5]["quintile_1"][0] : null
          },        
        ]         
      },
      {
        index : 7,
        data : this.props.data,
        texts: [
          {title: "Quintile 2",}
        ],
        inputs: [
          {
            name: `${index + 0}:quintile_2`,
            data : this.props.data ?  this.props.data.positive_data[index+0]["quintile_2"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+0]["quintile_2"] &&
            this.props.error.positive_data[index+0]["quintile_2"][0] ? this.props.error.positive_data[index+0]["quintile_2"][0] : null
          },
          {
            name: `${index + 1}:quintile_2`,
            data : this.props.data ? this.props.data.positive_data[index+1]["quintile_2"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+1]["quintile_2"] &&
            this.props.error.positive_data[index+1]["quintile_2"][0] ? this.props.error.positive_data[index+1]["quintile_2"][0] : null
          },
          {
            name: `${index + 2}:quintile_2`,
            data : this.props.data ? this.props.data.positive_data[index+2]["quintile_2"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+2]["quintile_2"] &&
            this.props.error.positive_data[index+2]["quintile_2"][0] ? this.props.error.positive_data[index+2]["quintile_2"][0] : null
          },
          {
            name: `${index + 3}:quintile_2`,
            data : this.props.data ? this.props.data.positive_data[index+3]["quintile_2"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+3]["quintile_2"] &&
            this.props.error.positive_data[index+3]["quintile_2"][0] ? this.props.error.positive_data[index+3]["quintile_2"][0] : null
          },
          {
            name: `${index + 4}:quintile_2`,
            data : this.props.data ? this.props.data.positive_data[index+4]["quintile_2"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+4]["quintile_2"] &&
            this.props.error.positive_data[index+4]["quintile_2"][0] ? this.props.error.positive_data[index+4]["quintile_2"][0] : null
          },
          {
            name: `${index + 5}:quintile_2`,
            data : this.props.data ? this.props.data.positive_data[index+5]["quintile_2"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+5]["quintile_2"] &&
            this.props.error.positive_data[index+5]["quintile_2"][0] ? this.props.error.positive_data[index+5]["quintile_2"][0] : null
          },        
        ]         
      },
      {
        texts: [
          {title: "Quintile 3",}
        ],
        inputs: [
          {
            name: `${index + 0}:quintile_3`,
            data : this.props.data ?  this.props.data.positive_data[index+0]["quintile_3"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+0]["quintile_3"] &&
            this.props.error.positive_data[index+0]["quintile_3"][0] ? this.props.error.positive_data[index+0]["quintile_3"][0] : null
          },
          {
            name: `${index + 1}:quintile_3`,
            data : this.props.data ? this.props.data.positive_data[index+1]["quintile_3"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+1]["quintile_3"] &&
            this.props.error.positive_data[index+1]["quintile_3"][0] ? this.props.error.positive_data[index+1]["quintile_3"][0] : null
          },
          {
            name: `${index + 2}:quintile_3`,
            data : this.props.data ? this.props.data.positive_data[index+2]["quintile_3"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+2]["quintile_3"] &&
            this.props.error.positive_data[index+2]["quintile_3"][0] ? this.props.error.positive_data[index+2]["quintile_3"][0] : null
          },
          {
            name: `${index + 3}:quintile_3`,
            data : this.props.data ? this.props.data.positive_data[index+3]["quintile_3"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+3]["quintile_3"] &&
            this.props.error.positive_data[index+3]["quintile_3"][0] ? this.props.error.positive_data[index+3]["quintile_3"][0] : null
          },
          {
            name: `${index + 4}:quintile_3`,
            data : this.props.data ? this.props.data.positive_data[index+4]["quintile_3"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+4]["quintile_3"] &&
            this.props.error.positive_data[index+4]["quintile_3"][0] ? this.props.error.positive_data[index+4]["quintile_3"][0] : null
          },
          {
            name: `${index + 5}:quintile_3`,
            data : this.props.data ? this.props.data.positive_data[index+5]["quintile_3"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+5]["quintile_3"] &&
            this.props.error.positive_data[index+5]["quintile_3"][0] ? this.props.error.positive_data[index+5]["quintile_3"][0] : null
          },        
        ]         
      },
      {
        texts: [
          {title: "Quintile 4",}
        ],
        inputs: [
          {
            name: `${index + 0}:quintile_4`,
            data : this.props.data ?  this.props.data.positive_data[index+0]["quintile_4"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+0]["quintile_4"] &&
            this.props.error.positive_data[index+0]["quintile_4"][0] ? this.props.error.positive_data[index+0]["quintile_4"][0] : null
          },
          {
            name: `${index + 1}:quintile_4`,
            data : this.props.data ? this.props.data.positive_data[index+1]["quintile_4"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+1]["quintile_4"] &&
            this.props.error.positive_data[index+1]["quintile_4"][0] ? this.props.error.positive_data[index+1]["quintile_4"][0] : null
          },
          {
            name: `${index + 2}:quintile_4`,
            data : this.props.data ? this.props.data.positive_data[index+2]["quintile_4"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+2]["quintile_4"] &&
            this.props.error.positive_data[index+2]["quintile_4"][0] ? this.props.error.positive_data[index+2]["quintile_4"][0] : null
          },
          {
            name: `${index + 3}:quintile_4`,
            data : this.props.data ? this.props.data.positive_data[index+3]["quintile_4"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+3]["quintile_4"] &&
            this.props.error.positive_data[index+3]["quintile_4"][0] ? this.props.error.positive_data[index+3]["quintile_4"][0] : null
          },
          {
            name: `${index + 4}:quintile_4`,
            data : this.props.data ? this.props.data.positive_data[index+4]["quintile_4"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+4]["quintile_4"] &&
            this.props.error.positive_data[index+4]["quintile_4"][0] ? this.props.error.positive_data[index+4]["quintile_4"][0] : null
          },
          {
            name: `${index + 5}:quintile_4`,
            data : this.props.data ? this.props.data.positive_data[index+5]["quintile_4"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+5]["quintile_4"] &&
            this.props.error.positive_data[index+5]["quintile_4"][0] ? this.props.error.positive_data[index+5]["quintile_4"][0] : null
          },        
        ]         
      },
      {
        texts: [
          {title: "Quintile 5",}
        ],
        inputs: [
          {
            name: `${index + 0}:quintile_5`,
            data : this.props.data ?  this.props.data.positive_data[index+0]["quintile_5"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+0]["quintile_5"] &&
            this.props.error.positive_data[index+0]["quintile_5"][0] ? this.props.error.positive_data[index+0]["quintile_5"][0] : null
          },
          {
            name: `${index + 1}:quintile_5`,
            data : this.props.data ? this.props.data.positive_data[index+1]["quintile_5"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+1]["quintile_5"] &&
            this.props.error.positive_data[index+1]["quintile_5"][0] ? this.props.error.positive_data[index+1]["quintile_5"][0] : null
          },
          {
            name: `${index + 2}:quintile_5`,
            data : this.props.data ? this.props.data.positive_data[index+2]["quintile_5"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+2]["quintile_5"] &&
            this.props.error.positive_data[index+2]["quintile_5"][0] ? this.props.error.positive_data[index+2]["quintile_5"][0] : null
          },
          {
            name: `${index + 3}:quintile_5`,
            data : this.props.data ? this.props.data.positive_data[index+3]["quintile_5"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+3]["quintile_5"] &&
            this.props.error.positive_data[index+3]["quintile_5"][0] ? this.props.error.positive_data[index+3]["quintile_5"][0] : null
          },
          {
            name: `${index + 4}:quintile_5`,
            data : this.props.data ? this.props.data.positive_data[index+4]["quintile_5"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+4]["quintile_5"] &&
            this.props.error.positive_data[index+4]["quintile_5"][0] ? this.props.error.positive_data[index+4]["quintile_5"][0] : null
          },
          {
            name: `${index + 5}:quintile_5`,
            data : this.props.data ? this.props.data.positive_data[index+5]["quintile_5"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+5]["quintile_5"] &&
            this.props.error.positive_data[index+5]["quintile_5"][0] ? this.props.error.positive_data[index+5]["quintile_5"][0] : null
          },        
        ]         
      },
      {
        isTitle : true,
        colSpan : "7",
        title: "Disability status"
      },
      {
        texts: [
          {title: "Disabled",}
        ],
        inputs: [
          {
            name: `${index + 0}:disabled`,
            data : this.props.data ?  this.props.data.positive_data[index+0]["disabled"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+0]["disabled"] &&
            this.props.error.positive_data[index+0]["disabled"][0] ? this.props.error.positive_data[index+0]["disabled"][0] : null
          },
          {
            name: `${index + 1}:disabled`,
            data : this.props.data ? this.props.data.positive_data[index+1]["disabled"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+1]["disabled"] &&
            this.props.error.positive_data[index+1]["disabled"][0] ? this.props.error.positive_data[index+1]["disabled"][0] : null
          },
          {
            name: `${index + 2}:disabled`,
            data : this.props.data ? this.props.data.positive_data[index+2]["disabled"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+2]["disabled"] &&
            this.props.error.positive_data[index+2]["disabled"][0] ? this.props.error.positive_data[index+2]["disabled"][0] : null
          },
          {
            name: `${index + 3}:disabled`,
            data : this.props.data ? this.props.data.positive_data[index+3]["disabled"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+3]["disabled"] &&
            this.props.error.positive_data[index+3]["disabled"][0] ? this.props.error.positive_data[index+3]["disabled"][0] : null
          },
          {
            name: `${index + 4}:disabled`,
            data : this.props.data ? this.props.data.positive_data[index+4]["disabled"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+4]["disabled"] &&
            this.props.error.positive_data[index+4]["disabled"][0] ? this.props.error.positive_data[index+4]["disabled"][0] : null
          },
          {
            name: `${index + 5}:disabled`,
            data : this.props.data ? this.props.data.positive_data[index+5]["disabled"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+5]["disabled"] &&
            this.props.error.positive_data[index+5]["disabled"][0] ? this.props.error.positive_data[index+5]["disabled"][0] : null
          },        
        ]         
      },
      {
        texts: [
          {title: "Not disabled",}
        ],
        inputs: [
          {
            name: `${index + 0}:not_disabled`,
            data : this.props.data ?  this.props.data.positive_data[index+0]["not_disabled"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+0]["not_disabled"] &&
            this.props.error.positive_data[index+0]["not_disabled"][0] ? this.props.error.positive_data[index+0]["not_disabled"][0] : null
          },
          {
            name: `${index + 1}:not_disabled`,
            data : this.props.data ? this.props.data.positive_data[index+1]["not_disabled"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+1]["not_disabled"] &&
            this.props.error.positive_data[index+1]["not_disabled"][0] ? this.props.error.positive_data[index+1]["not_disabled"][0] : null
          },
          {
            name: `${index + 2}:not_disabled`,
            data : this.props.data ? this.props.data.positive_data[index+2]["not_disabled"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+2]["not_disabled"] &&
            this.props.error.positive_data[index+2]["not_disabled"][0] ? this.props.error.positive_data[index+2]["not_disabled"][0] : null
          },
          {
            name: `${index + 3}:not_disabled`,
            data : this.props.data ? this.props.data.positive_data[index+3]["not_disabled"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+3]["not_disabled"] &&
            this.props.error.positive_data[index+3]["not_disabled"][0] ? this.props.error.positive_data[index+3]["not_disabled"][0] : null
          },
          {
            name: `${index + 4}:not_disabled`,
            data : this.props.data ? this.props.data.positive_data[index+4]["not_disabled"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+4]["not_disabled"] &&
            this.props.error.positive_data[index+4]["not_disabled"][0] ? this.props.error.positive_data[index+4]["not_disabled"][0] : null
          },
          {
            name: `${index + 5}:not_disabled`,
            data : this.props.data ? this.props.data.positive_data[index+5]["not_disabled"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+5]["not_disabled"] &&
            this.props.error.positive_data[index+5]["not_disabled"][0] ? this.props.error.positive_data[index+5]["not_disabled"][0] : null
          },        
        ]         
      },
      {
        isTitle : true,
        colSpan : "7",
        title: "Nationally relevant population groups"
      },
      {
        texts: [
          {title: "Population group A",}
        ],
        inputs: [
          {
            name: `${index + 0}:population_a`,
            data : this.props.data ?  this.props.data.positive_data[index+0]["population_a"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+0]["population_a"] &&
            this.props.error.positive_data[index+0]["population_a"][0] ? this.props.error.positive_data[index+0]["population_a"][0] : null
          },
          {
            name: `${index + 1}:population_a`,
            data : this.props.data ? this.props.data.positive_data[index+1]["population_a"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+1]["population_a"] &&
            this.props.error.positive_data[index+1]["population_a"][0] ? this.props.error.positive_data[index+1]["population_a"][0] : null
},
          {
            name: `${index + 2}:population_a`,
            data : this.props.data ? this.props.data.positive_data[index+2]["population_a"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+2]["population_a"] &&
            this.props.error.positive_data[index+2]["population_a"][0] ? this.props.error.positive_data[index+2]["population_a"][0] : null
},
          {
            name: `${index + 3}:population_a`,
            data : this.props.data ? this.props.data.positive_data[index+3]["population_a"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+3]["population_a"] &&
            this.props.error.positive_data[index+3]["population_a"][0] ? this.props.error.positive_data[index+3]["population_a"][0] : null
          },
          {
            name: `${index + 4}:population_a`,
            data : this.props.data ? this.props.data.positive_data[index+4]["population_a"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+4]["population_a"] &&
            this.props.error.positive_data[index+4]["population_a"][0] ? this.props.error.positive_data[index+4]["population_a"][0] : null
          },
          {
            name: `${index + 5}:population_a`,
            data : this.props.data ? this.props.data.positive_data[index+5]["population_a"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+5]["population_a"] &&
            this.props.error.positive_data[index+5]["population_a"][0] ? this.props.error.positive_data[index+5]["population_a"][0] : null
          },        
        ]         
      },
      {
        texts: [
          {title: "Population group B",}
        ],
        inputs: [
          {
            name: `${index + 0}:population_b`,
            data : this.props.data ?  this.props.data.positive_data[index+0]["population_b"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+0]["population_b"] &&
            this.props.error.positive_data[index+0]["population_b"][0] ? this.props.error.positive_data[index+0]["population_b"][0] : null
          },
          {
            name: `${index + 1}:population_b`,
            data : this.props.data ? this.props.data.positive_data[index+1]["population_b"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+1]["population_b"] &&
            this.props.error.positive_data[index+1]["population_b"][0] ? this.props.error.positive_data[index+1]["population_b"][0] : null
          },
          {
            name: `${index + 2}:population_b`,
            data : this.props.data ? this.props.data.positive_data[index+2]["population_b"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+2]["population_b"] &&
            this.props.error.positive_data[index+2]["population_b"][0] ? this.props.error.positive_data[index+2]["population_b"][0] : null
},
          {
            name: `${index + 3}:population_b`,
            data : this.props.data ? this.props.data.positive_data[index+3]["population_b"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+3]["population_b"] &&
            this.props.error.positive_data[index+3]["population_b"][0] ? this.props.error.positive_data[index+3]["population_b"][0] : null
          },
          {
            name: `${index + 4}:population_b`,
            data : this.props.data ? this.props.data.positive_data[index+4]["population_b"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+4]["population_b"] &&
            this.props.error.positive_data[index+4]["population_b"][0] ? this.props.error.positive_data[index+4]["population_b"][0] : null
          },
          {
            name: `${index + 5}:population_b`,
            data : this.props.data ? this.props.data.positive_data[index+5]["population_b"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+5]["population_b"] &&
            this.props.error.positive_data[index+5]["population_b"][0] ? this.props.error.positive_data[index+5]["population_b"][0] : null
          },        
        ]         
      },
      {
        texts: [
          {title: "Population group C",}
        ],
        inputs: [
          {
            name: `${index + 0}:population_c`,
            data : this.props.data ?  this.props.data.positive_data[index+0]["population_c"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+0]["population_c"] &&
            this.props.error.positive_data[index+0]["population_c"][0] ? this.props.error.positive_data[index+0]["population_c"][0] : null
          },
          {
            name: `${index + 1}:population_c`,
            data : this.props.data ? this.props.data.positive_data[index+1]["population_c"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+1]["population_c"] &&
            this.props.error.positive_data[index+1]["population_c"][0] ? this.props.error.positive_data[index+1]["population_c"][0] : null
          },
          {
            name: `${index + 2}:population_c`,
            data : this.props.data ? this.props.data.positive_data[index+2]["population_c"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+2]["population_c"] &&
            this.props.error.positive_data[index+2]["population_c"][0] ? this.props.error.positive_data[index+2]["population_c"][0] : null
          },
          {
            name: `${index + 3}:population_c`,
            data : this.props.data ? this.props.data.positive_data[index+3]["population_c"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+3]["population_c"] &&
            this.props.error.positive_data[index+3]["population_c"][0] ? this.props.error.positive_data[index+3]["population_c"][0] : null
          },
          {
            name: `${index + 4}:population_c`,
            data : this.props.data ? this.props.data.positive_data[index+4]["population_c"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+4]["population_c"] &&
            this.props.error.positive_data[index+4]["population_c"][0] ? this.props.error.positive_data[index+4]["population_c"][0] : null
          },
          {
            name: `${index + 5}:population_c`,
            data : this.props.data ? this.props.data.positive_data[index+5]["population_c"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+5]["population_c"] &&
            this.props.error.positive_data[index+5]["population_c"][0] ? this.props.error.positive_data[index+5]["population_c"][0] : null
          },        
        ]         
      },
      {
        isTitle : true,
        colSpan : "7",
        title: "Age groups"
      },
      {
        texts: [
          {title: "Below 25",}
        ],
        inputs: [
          {
            name: `${index + 0}:age_below_25`,
            data : this.props.data ?  this.props.data.positive_data[index+0]["age_below_25"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+0]["age_below_25"] &&
            this.props.error.positive_data[index+0]["age_below_25"][0] ? this.props.error.positive_data[index+0]["age_below_25"][0] : null
          },
          {
            name: `${index + 1}:age_below_25`,
            data : this.props.data ? this.props.data.positive_data[index+1]["age_below_25"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+1]["age_below_25"] &&
            this.props.error.positive_data[index+1]["age_below_25"][0] ? this.props.error.positive_data[index+1]["age_below_25"][0] : null
          },
          {
            name: `${index + 2}:age_below_25`,
            data : this.props.data ? this.props.data.positive_data[index+2]["age_below_25"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+2]["age_below_25"] &&
            this.props.error.positive_data[index+2]["age_below_25"][0] ? this.props.error.positive_data[index+2]["age_below_25"][0] : null
          },
          {
            name: `${index + 3}:age_below_25`,
            data : this.props.data ? this.props.data.positive_data[index+3]["age_below_25"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+3]["age_below_25"] &&
            this.props.error.positive_data[index+3]["age_below_25"][0] ? this.props.error.positive_data[index+3]["age_below_25"][0] : null
          },
          {
            name: `${index + 4}:age_below_25`,
            data : this.props.data ? this.props.data.positive_data[index+4]["age_below_25"]: "",
            invalid: this.props.error && this.props.error.positive_data && 
            this.props.error.positive_data[index+4]["age_below_25"] &&
            this.props.error.positive_data[index+4]["age_below_25"][0] ? this.props.error.positive_data[index+4]["age_below_25"][0] : null
          },
          {
            name: `${index + 5}:age_below_25`,
            data : this.props.data ? this.props.data.positive_data[index+5]["age_below_25"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+5]["age_below_25"] &&
            this.props.error.positive_data[index+5]["age_below_25"][0] ? this.props.error.positive_data[index+5]["age_below_25"][0] : null
          },        
        ]         
      },
      {
        texts: [
          {title: "25-34",}
        ],
        inputs: [
          {
            name: `${index + 0}:age_25_34`,
            data : this.props.data ?  this.props.data.positive_data[index+0]["age_25_34"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+0]["age_25_34"] &&
            this.props.error.positive_data[index+0]["age_25_34"][0] ? this.props.error.positive_data[index+0]["age_25_34"][0] : null
          },
          {
            name: `${index + 1}:age_25_34`,
            data : this.props.data ? this.props.data.positive_data[index+1]["age_25_34"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+1]["age_25_34"] &&
            this.props.error.positive_data[index+1]["age_25_34"][0] ? this.props.error.positive_data[index+1]["age_25_34"][0] : null
          },
          {
            name: `${index + 2}:age_25_34`,
            data : this.props.data ? this.props.data.positive_data[index+2]["age_25_34"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+2]["age_25_34"] &&
            this.props.error.positive_data[index+2]["age_25_34"][0] ? this.props.error.positive_data[index+2]["age_25_34"][0] : null
          },
          {
            name: `${index + 3}:age_25_34`,
            data : this.props.data ? this.props.data.positive_data[index+3]["age_25_34"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+3]["age_25_34"] &&
            this.props.error.positive_data[index+3]["age_25_34"][0] ? this.props.error.positive_data[index+3]["age_25_34"][0] : null
          },
          {
            name: `${index + 4}:age_25_34`,
            data : this.props.data ? this.props.data.positive_data[index+4]["age_25_34"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+4]["age_25_34"] &&
            this.props.error.positive_data[index+4]["age_25_34"][0] ? this.props.error.positive_data[index+4]["age_25_34"][0] : null
          },
          {
            name: `${index + 5}:age_25_34`,
            data : this.props.data ? this.props.data.positive_data[index+5]["age_25_34"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+5]["age_25_34"] &&
            this.props.error.positive_data[index+5]["age_25_34"][0] ? this.props.error.positive_data[index+5]["age_25_34"][0] : null
          },        
        ]         
      },
      {
        texts: [
          {title: "35-44",}
        ],
        inputs: [
          {
            name: `${index + 0}:age_35_44`,
            data : this.props.data ?  this.props.data.positive_data[index+0]["age_35_44"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+0]["age_35_44"] &&
            this.props.error.positive_data[index+0]["age_35_44"][0] ? this.props.error.positive_data[index+0]["age_35_44"][0] : null
          },
          {
            name: `${index + 1}:age_35_44`,
            data : this.props.data ? this.props.data.positive_data[index+1]["age_35_44"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+1]["age_35_44"] &&
            this.props.error.positive_data[index+1]["age_35_44"][0] ? this.props.error.positive_data[index+1]["age_35_44"][0] : null
          },
          {
            name: `${index + 2}:age_35_44`,
            data : this.props.data ? this.props.data.positive_data[index+2]["age_35_44"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+2]["age_35_44"] &&
            this.props.error.positive_data[index+2]["age_35_44"][0] ? this.props.error.positive_data[index+2]["age_35_44"][0] : null
          },
          {
            name: `${index + 3}:age_35_44`,
            data : this.props.data ? this.props.data.positive_data[index+3]["age_35_44"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+3]["age_35_44"] &&
            this.props.error.positive_data[index+3]["age_35_44"][0] ? this.props.error.positive_data[index+3]["age_35_44"][0] : null
          },
          {
            name: `${index + 4}:age_35_44`,
            data : this.props.data ? this.props.data.positive_data[index+4]["age_35_44"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+4]["age_35_44"] &&
            this.props.error.positive_data[index+4]["age_35_44"][0] ? this.props.error.positive_data[index+4]["age_35_44"][0] : null
          },
          {
            name: `${index + 5}:age_35_44`,
            data : this.props.data ? this.props.data.positive_data[index+5]["age_35_44"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+5]["age_35_44"] &&
            this.props.error.positive_data[index+5]["age_35_44"][0] ? this.props.error.positive_data[index+5]["age_35_44"][0] : null
          },        
        ]         
      },
      {
        texts: [
          {title: "45-54",}
        ],
        inputs: [
          {
            name: `${index + 0}:age_45_54`,
            data : this.props.data ?  this.props.data.positive_data[index+0]["age_45_54"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+0]["age_45_54"] &&
            this.props.error.positive_data[index+0]["age_45_54"][0] ? this.props.error.positive_data[index+0]["age_45_54"][0] : null
          },
          {
            name: `${index + 1}:age_45_54`,
            data : this.props.data ? this.props.data.positive_data[index+1]["age_45_54"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+1]["age_45_54"] &&
            this.props.error.positive_data[index+1]["age_45_54"][0] ? this.props.error.positive_data[index+1]["age_45_54"][0] : null
          },
          {
            name: `${index + 2}:age_45_54`,
            data : this.props.data ? this.props.data.positive_data[index+2]["age_45_54"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+2]["age_45_54"] &&
            this.props.error.positive_data[index+2]["age_45_54"][0] ? this.props.error.positive_data[index+2]["age_45_54"][0] : null
          },
          {
            name: `${index + 3}:age_45_54`,
            data : this.props.data ? this.props.data.positive_data[index+3]["age_45_54"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+3]["age_45_54"] &&
            this.props.error.positive_data[index+3]["age_45_54"][0] ? this.props.error.positive_data[index+3]["age_45_54"][0] : null
          },
          {
            name: `${index + 4}:age_45_54`,
            data : this.props.data ? this.props.data.positive_data[index+4]["age_45_54"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+4]["age_45_54"] &&
            this.props.error.positive_data[index+4]["age_45_54"][0] ? this.props.error.positive_data[index+4]["age_45_54"][0] : null
          },
          {
            name: `${index + 5}:age_45_54`,
            data : this.props.data ? this.props.data.positive_data[index+5]["age_45_54"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+5]["age_45_54"] &&
            this.props.error.positive_data[index+5]["age_45_54"][0] ? this.props.error.positive_data[index+5]["age_45_54"][0] : null
          },        
        ]         
      },
      {
        texts: [
          {title: "55-64",}
        ],
        inputs: [
          {
            name: `${index + 0}:age_55_64`,
            data : this.props.data ?  this.props.data.positive_data[index+0]["age_55_64"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+0]["age_55_64"] &&
            this.props.error.positive_data[index+0]["age_55_64"][0] ? this.props.error.positive_data[index+0]["age_55_64"][0] : null
          },
          {
            name: `${index + 1}:age_55_64`,
            data : this.props.data ? this.props.data.positive_data[index+1]["age_55_64"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+1]["age_55_64"] &&
            this.props.error.positive_data[index+1]["age_55_64"][0] ? this.props.error.positive_data[index+1]["age_55_64"][0] : null
          },
          {
            name: `${index + 2}:age_55_64`,
            data : this.props.data ? this.props.data.positive_data[index+2]["age_55_64"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+2]["age_55_64"] &&
            this.props.error.positive_data[index+2]["age_55_64"][0] ? this.props.error.positive_data[index+2]["age_55_64"][0] : null
          },
          {
            name: `${index + 3}:age_55_64`,
            data : this.props.data ? this.props.data.positive_data[index+3]["age_55_64"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+3]["age_55_64"] &&
            this.props.error.positive_data[index+3]["age_55_64"][0] ? this.props.error.positive_data[index+3]["age_55_64"][0] : null
          },
          {
            name: `${index + 4}:age_55_64`,
            data : this.props.data ? this.props.data.positive_data[index+4]["age_55_64"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+4]["age_55_64"] &&
            this.props.error.positive_data[index+4]["age_55_64"][0] ? this.props.error.positive_data[index+4]["age_55_64"][0] : null
          },
          {
            name: `${index + 5}:age_55_64`,
            data : this.props.data ? this.props.data.positive_data[index+5]["age_55_64"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+5]["age_55_64"] &&
            this.props.error.positive_data[index+5]["age_55_64"][0] ? this.props.error.positive_data[index+5]["age_55_64"][0] : null
          },        
        ]         
      },
      {
        texts: [
          {title: "Over 65",}
        ],
        inputs: [
          {
            name: `${index + 0}:age_over_65`,
            data : this.props.data ?  this.props.data.positive_data[index+0]["age_over_65"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+0]["age_over_65"] &&
            this.props.error.positive_data[index+0]["age_over_65"][0] ? this.props.error.positive_data[index+0]["age_over_65"][0] : null
          },
          {
            name: `${index + 1}:age_over_65`,
            data : this.props.data ? this.props.data.positive_data[index+1]["age_over_65"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+1]["age_over_65"] &&
            this.props.error.positive_data[index+1]["age_over_65"][0] ? this.props.error.positive_data[index+1]["age_over_65"][0] : null
          },
          {
            name: `${index + 2}:age_over_65`,
            data : this.props.data ? this.props.data.positive_data[index+2]["age_over_65"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+2]["age_over_65"] &&
            this.props.error.positive_data[index+2]["age_over_65"][0] ? this.props.error.positive_data[index+2]["age_over_65"] : null
          },
          {
            name: `${index + 3}:age_over_65`,
            data : this.props.data ? this.props.data.positive_data[index+3]["age_over_65"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+3]["age_over_65"] &&
            this.props.error.positive_data[index+3]["age_over_65"][0] ? this.props.error.positive_data[index+3]["age_over_65"][0] : null
          },
          {
            name: `${index + 4}:age_over_65`,
            data : this.props.data ? this.props.data.positive_data[index+4]["age_over_65"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+4]["age_over_65"] &&
            this.props.error.positive_data[index+4]["age_over_65"][0] ? this.props.error.positive_data[index+4]["age_over_65"][0] : null
          },
          {
            name: `${index + 5}:age_over_65`,
            data : this.props.data ? this.props.data.positive_data[index+5]["age_over_65"]: "",
            invalid: this.props.error && 
            this.props.error.positive_data && 
            this.props.error.positive_data[index+5]["age_over_65"] &&
            this.props.error.positive_data[index+5]["age_over_65"][0] ? this.props.error.positive_data[index+5]["age_over_65"][0] : null
          },        
        ]         
      },

    ]

    let headers_overall = [
      {title: "Share of respondents who say that overall, they are satisfied with the quality of government services (i.e. ‘very satisfied’ or ‘satisfied’)",},
      {title: "", width : "25%",},

    ]

    let tableData_overall = [
      {
        data : this.props.data,
        texts: [
          {title: "Total/National",},  
        ],
        inputs: [
          {
            name: "3:total",
            data : this.props.data ? this.props.data.satisfied_data[3]["total"]: "",
          },
        ]       
      },
      {
        isTitle : true,
        colSpan : "2",
        title: "Male/Female"
      },
      {
        data : this.props.data,
        texts: [
          {title: "Male",},  
        ],
        inputs: [
          {
            name: "3:male",
            data : this.props.data ? this.props.data.satisfied_data[3]["male"]: "",
          },
        ]
      },
      {
        data : this.props.data,
        texts: [
          {title: "Female",},  
        ],
        inputs: [
          {
            name: "3:female",
            data : this.props.data ? this.props.data.satisfied_data[3]["female"]: "",
          },
        ]         
      },
      {
        isTitle : true,
        colSpan : "2",
        title: "Urban/Rural"
      },
      {
        data : this.props.data,
        texts: [
          {title: "Urban",}
        ],
        inputs: [
          {
            name: "3:urban",
            data : this.props.data ? this.props.data.satisfied_data[3]["urban"]: "",
          },
        ]         
      },
      {
        data : this.props.data,
        texts: [
          {title: "Rural",}
        ],
        inputs: [
          {
            name: "3:rural",
            data : this.props.data ? this.props.data.satisfied_data[3]["rural"]: "",
          },
        ]         
      },
      {
        isTitle : true,
        colSpan : "2",
        title: "Income or expenditure quintiles"
      },
      {
        data : this.props.data,
        texts: [
          {title: "Quintile 1",}
        ],
        inputs: [
          {
            name: "3:quintile_1",
            data : this.props.data ? this.props.data.satisfied_data[3]["quintile_1"]: "",
          },
        ]         
      },
      {
        data : this.props.data,
        texts: [
          {title: "Quintile 2",}
        ],
        inputs: [
          {
            name: "3:quintile_2",
            data : this.props.data ? this.props.data.satisfied_data[3]["quintile_2"]: "",
          },
        ]         
      },
      {
        data : this.props.data,
        texts: [
          {title: "Quintile 3",}
        ],
        inputs: [
          {
            name: "3:quintile_3",
            data : this.props.data ? this.props.data.satisfied_data[3]["quintile_3"]: "",
          },
        ]         
      },
      {
        data : this.props.data,
        texts: [
          {title: "Quintile 4",}
        ],
        inputs: [
          {
            name: "3:quintile_4",
            data : this.props.data ? this.props.data.satisfied_data[3]["quintile_4"]: "",
          },
        ]         
      },
      {
        data : this.props.data,
        texts: [
          {title: "Quintile 5",}
        ],
        inputs: [
          {
            name: "3:quintile_5",
            data : this.props.data ? this.props.data.satisfied_data[3]["quintile_5"]: "",
          },
        ]         
      },
      {
        isTitle : true,
        colSpan : "2",
        title: "Disability status"
      },
      {
        data : this.props.data,
        texts: [
          {title: "Disabled",}
        ],
        inputs: [
          {
            name: "3:disabled",
            data : this.props.data ? this.props.data.satisfied_data[3]["disabled"]: "",
          },
        ]         
      },
      {
        data : this.props.data,
        texts: [
          {title: "Not disabled",}
        ],
        inputs: [
          {
            name: "3:not_disabled",
            data : this.props.data ? this.props.data.satisfied_data[3]["not_disabled"]: "",
          },
        ]         
      },
      {
        isTitle : true,
        colSpan : "2",
        title: "Nationally relevant population groups"
      },
      {
        data : this.props.data,
        texts: [
          {title: "Population group A",}
        ],
        inputs: [
          {
            name: "3:population_a",
            data : this.props.data ? this.props.data.satisfied_data[3]["population_a"]: "",
          },
        ]         
      },
      {
        data : this.props.data,
        texts: [
          {title: "Population group B",}
        ],
        inputs: [
          {
            name: "3:population_b",
            data : this.props.data ? this.props.data.satisfied_data[3]["population_b"]: "",
          },
        ]         
      },
      {
        data : this.props.data,
        texts: [
          {title: "Population group C",}
        ],
        inputs: [
          {
            name: "3:population_c",
            data : this.props.data ? this.props.data.satisfied_data[3]["population_c"]: "",
          },
        ]         
      },
      {
        isTitle : true,
        colSpan : "2",
        title: "Age groups"
      },
      {
        data : this.props.data,
        texts: [
          {title: "Below 25",}
        ],
        inputs: [
          {
            name: "3:age_below_25",
            data : this.props.data ? this.props.data.satisfied_data[3]["age_below_25"]: "",
          },
        ]         
      },
      {
        data : this.props.data,
        texts: [
          {title: "25-34",}
        ],
        inputs: [
          {
            name: "3:age_25_34",
            data : this.props.data ? this.props.data.satisfied_data[3]["age_25_34"]: "",
          },
        ]         
      },
      {
        data : this.props.data,
        texts: [
          {title: "35-44",}
        ],
        inputs: [
          {
            name: "3:age_35_44",
            data : this.props.data ? this.props.data.satisfied_data[3]["age_35_44"]: "",
          },
        ]         
      },
      {
        index : 9,
        data : this.props.data,
        texts: [
          {title: "45-54",}
        ],
        inputs: [
          {
            name: "3:age_45_54",
            data : this.props.data ? this.props.data.satisfied_data[3]["age_45_54"]: "",
          },
        ]         
      },
      {
        data : this.props.data,
        texts: [
          {title: "55-64",}
        ],
        inputs: [
          {
            name: "3:age_55_64",
            data : this.props.data ? this.props.data.satisfied_data[3]["age_55_64"]: "",
          },
        ]         
      },
      {
        data : this.props.data,
        texts: [
          {title: "Over 65",}
        ],
        inputs: [
          {
            name: "3:age_over_65",
            data : this.props.data ? this.props.data.satisfied_data[3]["age_over_65"]: "",
          },
        ]         
      },

    ]    

    return (
      <React.Fragment>
        <div className="box-body">
          <div className="col-sm-12">
            <h4>3.	Government services</h4>
          </div>
          <div className="row">
            <div className="col-sm-12 adaptive-table">
              <fieldset
                disabled={this.props.data && (!this.props.data.id || this.props.data.id === null)}
              >

                <InputTable 
                  headers={headers}
                  rows = {tableData}
                  handleTableChange={this.handleTableChange}
                />
              </fieldset>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-8 adaptive-table">
              <fieldset
                disabled={this.props.data && (!this.props.data.id || this.props.data.id === null)}
              >

                <InputTable 
                  headers={headers_overall}
                  rows = {tableData_overall}
                  handleTableChange={this.handleTableOverallChange}
                />
              </fieldset>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { data, error } = state.sdg1662State;

  return {
    data,
    error
  };
}

const connectedIndicatorData = connect(mapStateToProps)(GovernmentData);

export { connectedIndicatorData as GovernmentData };
