import React, { Component } from "react";

import { TextInput } from "carbon-components-react";

class HeaderRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headers : props.headers,
    }
  }

  render() {
    return (

      <thead  style={{fontSize:"0.95vw"}}>
      <tr>

      {this.state.headers.map((header, index) => {
        return <th key={header.title} style={header.width? {width: header.width}: {}}  colSpan={header.colSpan ? header.colSpan : null}>{header.title}</th>
      })}

      </tr>
    </thead>

    )
  }
}

class RowRenderer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputs: props.inputs,
      texts: props.texts,
    }

    this.handleTableChange = this.handleTableChange.bind(this);
  }

  componentDidUpdate

  handleTableChange(e) {
    // console.log("name", e.target.name, e.target.value)
    this.props.handleTableChange(e.target.name,e.target.value);
  }


  render() {

    return (
      <React.Fragment>
        <tr>
          {
            this.props.texts.map((element, index) => {
              return (
                <td key={element.title}>
                  {element.title}
                </td>
              )
            })
          }
          {
            this.props.inputs.map((element, index) => {
              return (
                <td  key={element.name}>
                  <TextInput
                    id={element.name}
                    name={element.name}
                    labelText=""
                    onChange={this.handleTableChange}
                    value={element.data ? element.data: ""}
                    invalid={
                      element.invalid !== undefined && element.invalid !== null
                    }
                    invalidText={
                      element.invalid 
                        ? element.invalid
                        : ""
                    }
                  />
                </td>
              ) 
            })
          }
          </tr>
      </React.Fragment>
    )
  }

}



class InputTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      headers: props.headers,
      rows: props.rows,
    };

    this.handleTableChange = this.handleTableChange.bind(this);
  }

  handleTableChange(name, value) {

    this.props.handleTableChange(name, value);
  }

  render() {

    let headers = this.state.headers;

    let tableData = this.props.rows;

    return (
      <React.Fragment>
        <table className="table table-bordered table-1671b">

            <HeaderRenderer headers = {headers} />

            <tbody  style={{fontSize:"0.95vw"}}>
            {
                tableData.map((data, index) => {
                if (data.isTitle) {
                  return <tr key={data.title} style={{fontWeight: "bold"}}><td colSpan={data.colSpan} >{data.title}</td></tr>
                }  
                else {
                  return <RowRenderer key = {index}
                    texts = {data.texts}  
                    inputs = {data.inputs}
                    invalid = {data.invalid}
                    handleTableChange={this.handleTableChange}/>
                }
                })
            }
            </tbody>
        </table>
      </React.Fragment>
    );
  }
}

export default InputTable;
