import { sdg1671bConstants } from "../_constants";

const initialState = {};

export function sdg1671bState(state = initialState, action) {
  switch (action.type) {
    case sdg1671bConstants.GET_1671b_DATA_SUCCESS: {
      return {
        ...state,
        data: action.data,
        error: {}
      };
    }
    case sdg1671bConstants.GET_1671b_DATA_FAILURE: {
      return {
        ...state,
        error: action.data
      }
    }
    case sdg1671bConstants.UPDATE_1671b_DATA_SUCCESS: {
      break;
    }
    case sdg1671bConstants.UPDATE_1671b_DATA_FAILURE: {
      break;
    }
    case sdg1671bConstants.UPDATE_1671b_DATA_FIELD: {

      let value = isNaN(action.data.value) ? null : action.data.value;
      //console.log("Action value:", action.data.value);

      return {
        ...state,
        data: {
          ...state.data,
          data: state.data.data.map((item, index) => {
            if (index === action.data.index) {
              return {
                ...item,
                [action.data.name]: value
              };
            }

            return item;
          })
        }
      };
    }
    case sdg1671bConstants.UPDATE_1671b_SOURCE_DATA_FIELD: {
      return {
        ...state,
        data: {
          ...state.data,
          source: {
            ...state.data.source,
            source_data: state.data.source.source_data.map((item, index) => {
              if (index === action.data.index) {
                return {
                  ...item,
                  [action.data.name]: action.data.value
                };
              }
  
              return item;
            })
          } 
        }
      };
    }
    case sdg1671bConstants.UPDATE_1671b_UNIT_DATA_FIELD: {
      console.log("found", action.data.index, action.data.value)
      return {
        ...state,
        data: {
          ...state.data,
          source: {
            ...state.data.source,
            unit_data: state.data.source.unit_data.map((item, index) => {
              if (index === action.data.index) {
                return {
                  ...item,
                  [action.data.name]: action.data.value
                };
              }
  
              return item;
            })
          } 
        }
      };
    }    
    case sdg1671bConstants.UPDATE_1671b_METADATA_FIELD: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.data.name]: action.data.value
        }
      };
    }
    case sdg1671bConstants.UPDATE_1671b_SOURCE_FIELD: {
      return {
        ...state,
        data: {
          ...state.data,
          source: {
            ...state.data.source,
            [action.data.name]: action.data.value
          }
        }
      };
    }    
    case sdg1671bConstants.UPDATE_1671b_OCCUPATIONAL_FIELD: {
      return {
        ...state,
        data: {
          ...state.data,
          occupational: {
            ...state.data.occupational,
            [action.data.name]: action.data.value
          }
        }
      };
    }
    case sdg1671bConstants.UPDATE_1671b_OCCUPATIONAL_FILE: {
      return {
        ...state,
        data: {
          ...state.data,
          occupational: {
            ...state.data.occupational,
            clasification_file: action.data.data.id,
            clasification_file_path: action.data.data.file,
          }
        }
      };
    }
    case sdg1671bConstants.UPDATE_1671b_CAREER_DATA_FIELD: {
      return {
        ...state,
        data: {
          ...state.data,
          occupational: {
            ...state.data.occupational,
            career_data: state.data.occupational.career_data.map((item, index) => {
                if (index === action.data.index) {
                  return {
                    ...item,
                    [action.data.name]: action.data.value
                  };
                }
    
                return item;
              })
            
          }
        }
      };
    }    
    case sdg1671bConstants.UPDATE_1671b_DISAGGREGATION_FIELD: {
      return {
        ...state,
        data: {
          ...state.data,
          disaggregation: {
            ...state.data.disaggregation,
            [action.data.name]: action.data.value
          }
        }
      };
    }
    case sdg1671bConstants.UPDATE_1671b_DISAGGREGATION_POPULATION_FIELD: {
      return {
        ...state,
        data: {
          ...state.data,
          disaggregation: {
            ...state.data.disaggregation,
            population_groups: state.data.disaggregation.population_groups.map((item, index) => {
              if (index === action.data.index) {
                return {
                  ...item,
                  [action.data.name]: action.data.value
                };
              }
              return item;
            })
          }
        }
      };
    }          
    case sdg1671bConstants.UPDATE_1671b_DENOMINATOR_FIELD: {
      return {
        ...state,
        data: {
          ...state.data,
          denominator: {
            ...state.data.denominator,
            [action.data.name]: action.data.value
          }
        }
      };
    }   
    case sdg1671bConstants.UPDATE_1671b_PROPORTIONS_FIELD: {
      return {
        ...state,
        data: {
          ...state.data,
          proportions: state.data.proportions.map((item, index) => {
            if (index === 0) {
              return {
                ...item,
                [action.data.name]: action.data.value
              };
            }

            return item;
          })
        }
      };
    }   
    case sdg1671bConstants.UPDATE_1671b_RATIOS_FIELD: {
      return {
        ...state,
        data: {
          ...state.data,
          ratios: state.data.ratios.map((item, index) => {
            if (index === 0) {
              return {
                ...item,
                [action.data.name]: action.data.value
              };
            }

            return item;
          })
        }
      };
    }   
    case sdg1671bConstants.CLEAR_1671b_DATA: {
      return {
        ...state,
        data: {},
        error: {}
      };
    }
    case sdg1671bConstants.SAVE_1671b_DATA_SUCCESS: {
      return {
        ...state,
        data: action.data,
        error: {}
      };
    }
    case sdg1671bConstants.SAVE_1671b_DATA_FAILURE: {
      return {
        ...state,
        error: action.error
      };
    }
    default:
      return state;
  }
}
