import React, { Component } from "react";

import Form from "carbon-components-react/lib/components/Form";
import Button from "carbon-components-react/lib/components/Button";

import "../../styles/components/login/login.css";

import { connect } from "react-redux";

import { userActions } from "../../_actions";

class Login extends Component {
  constructor(props) {
    super(props);
    this.handleUndpLogin = this.handleUndpLogin.bind(this);
    this.handleNsoLogin = this.handleNsoLogin.bind(this);
  }
  state = {};

  handleUndpLogin() {
    const { dispatch } = this.props;
    dispatch(userActions.undpLogin());
  }

  handleNsoLogin() {
    this.props.history.push("/login/nso");
  }

  render() {
    return (
      <div>
        <div className="content-wrapper login-content">
          <section className="content">
            <div className="login-container">
              {/* <img
                src="/resources/img/sdg-en-16.png"
                className="sdg-icon"
                alt=""
              /> */}
              <img
                src="/resources/img/cover-2.jpg"
                className="sdg-image"
                alt=""
              />
              <div className="login-details">
                <label className="login-label">Choose Login</label>
                <Form>
                  {/* <TextInput
                    className="login-input"
                    labelText="Username"
                  ></TextInput>
                  <TextInput labelText="Password" type="password"></TextInput> */}
                  <Button
                    onClick={this.handleUndpLogin}
                    className="login-button"
                    style={{ width: "220px" }}
                  >
                    UNDP staff
                  </Button>
                  <Button
                    onClick={this.handleNsoLogin}
                    className="login-button"
                    style={{ paddingRight: "12px" }}
                  >
                    Login with username and password
                  </Button>
                </Form>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  return {
    loggingIn
  };
}

const connectedLoginPage = connect(mapStateToProps)(Login);

export { connectedLoginPage as Login };
