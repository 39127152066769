import React, { Component } from "react";

import "../../../styles/carbon-components.css";

class SideNavLinkCustom extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  state = {};

  handleClick(e) {
    this.props.onClick(e);
  }

  render() {
    let elem = this.props.isActive ? (
      <li className="bx--side-nav__item">
        <a
          className="bx--side-nav__link  bx--side-nav__link--current"
          onClick={this.handleClick}
        >
          {/* ::before */}
          <div className="bx--side-nav__icon bx--side-nav__icon--small">
            <img src={this.props.icon} style={{ width: "26px" }} alt="icon" />
          </div>
          <div className="bx--side-nav__link-text">
            <div className="bx--side-nav__link-text">{this.props.name}</div>
            <div
              className="bx--side-nav__link-text"
              style={{ fontSize: "9px" }}
            >
              {this.props.description}
            </div>
          </div>
        </a>
      </li>
    ) : (
      <li className="bx--side-nav__item">
        <a className="bx--side-nav__link" onClick={this.handleClick}>
          <div className="bx--side-nav__icon bx--side-nav__icon--small">
            <img src={this.props.icon} style={{ width: "26px" }} alt="icon" />
          </div>
          <div className="bx--side-nav__link-text">
            <div className="bx--side-nav__link-text">{this.props.name}</div>
            <div
              className="bx--side-nav__link-text"
              style={{ fontSize: "9px" }}
            >
              {this.props.description}
            </div>
          </div>
        </a>
      </li>
    );

    return <React.Fragment>{elem}</React.Fragment>;
  }
}

export default SideNavLinkCustom;
