import React, { Component } from "react";
import { connect } from "react-redux";
import { sdg1671cActions } from "../../../../../_actions";
class Introduction extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  state = {};

  handleChange(e) {
    const { dispatch } = this.props;

    dispatch(
      sdg1671cActions.updateMetadataField(e.target.name, e.target.value)
    );
  }

  render() {
    return (
      <React.Fragment>
        {/* <div className="row" style={{ display: "block" }}> */}
        <div className="box-header with-border">
          <div className="col-sm-12" style={{display:"flex", justifyContent: "space-between"}}>
            <h3>Introduction</h3>
            <img
              src="/resources/img/undp-logo-30.svg"
              style={{ height: "55px", marginLeft: "60px" }}
              alt="UNDP logo"
            />
          </div>
        </div>
        <div className="box-body">
          <div className="row" style={{ marginBottom: "10px" }}>
            <div className="col-sm-12">
              <h5>
                SDG 16.7.1c: Proportions of positions in the judiciary (national
                and local), by various characteristics, compared to national
                distributions
              </h5>
            </div>
          </div>
          <div className="row" style={{ marginBottom: "10px" }}>
            <div className="col-lg-6">
              <img
                src="/resources/img/sdg16_7_1c.JPG"
                className="intro-image"
                alt="1671c intro img"
              />
              <div style={{ fontSize: "11px" }}>
                Image credit UNDP DRC/ Aude Rossignol
              </div>
            </div>
            <div className="col-lg-6">
              <div className="col-sm-12" style={{ marginBottom: "10px" }}>
                <p>
                  SDG indicator 16.7.1.c. measures representation in the
                  judiciary with respect to the sex, age, disability and
                  population group status, and assesses how these correspond to
                  the proportion of these groups in society as a whole. More
                  specifically, this indicator measures the proportional
                  representation of various demographic groups (women, youth,
                  persons with disability, and nationally relevant population
                  groups) across two key decision-making positions in the
                  judiciary (judges and registrars) as well as across three
                  'levels' of courts, namely supreme/constitutional courts,
                  higher-level courts and lower-level courts.
                </p>
              </div>
              <div className="col-sm-12" style={{ marginBottom: "10px" }}>
                <p>
                  The indicator is sourced from administrative sources, usually
                  a human resources information management system of Judicial
                  Service Commissions, Ministries of Justice, or other similar
                  competent bodies with oversight over the judiciary.
                </p>
              </div>
              <div className="col-sm-12">
                Please read carefully the full&nbsp;
                <a
                  href="https://unstats.un.org/sdgs/metadata/files/Metadata-16-07-1c.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  metadata&nbsp;
                </a>
                for this indicator before submitting your data.
              </div>
            </div>
          </div>
          <div className="row" style={{ marginBottom: "10px" }}>
            {/* <div className="col-sm-12">
              Please read carefully the full&nbsp;
              <a
                href="https://unstats.un.org/sdgs/metadata/files/Metadata-16-07-01c.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                metadata&nbsp;
              </a>
              for this indicator before submitting your data.
            </div>
          </div>
          <div className="row"> */}
            <div className="col-sm-12">
              A PDF version of the data reporting form for this indicator can be
              downloaded <a target="_blank" href="/resources/files/16.7.1c.pdf">here</a>. This PDF form is only for NSOs’ internal
              documentation and not a mandatory requirement of the reporting
              process.
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { data, error } = state.sdg1671cState;

  return {
    data,
    error,
  };
}

const connectedIntroduction = connect(mapStateToProps)(Introduction);
export { connectedIntroduction as Introduction };
