import { sdg1633Service, commonService } from "../_services";

import { sdg1633Constants } from "../_constants";
import { notifyError } from "../components/common/notification/NotificationHelper";
import { userActions } from ".";

export const sdg1633Actions = {
  getIndicatorData,
  updateDataField,
  updateMetadataField,
  clearIndicatorData,
  saveIndicatorData,
  validateIndicatorData,
  finalizeIndicatorData,
  updateDenominator,
  saveSubmitErrors,
  updateResolutionsField,
  updateQuestionaireFile,
  updateImplManualFile,
  updateIntManualFile,


};

function getIndicatorData(id) {
  return dispatch => {
    sdg1633Service.getIndicatorData(id).then(
      data => {
        dispatch(get_success(data));
      },
      error => {
        if (error.response.status === 401) {
          notifyError("Session expired. Please login again!")
          dispatch(userActions.logout());
        }

        if (error.response.status === 500) {
          notifyError(
            "Server not available at the moment. Please try again later."
          );
        }
      }
    );
  };
}

function saveIndicatorData(data) {
  return dispatch => {
        dispatch(get_success(data));
  };
}

function saveSubmitErrors(data) {
  return dispatch => {
    dispatch(save_data_failure(data))
  }
}

function finalizeIndicatorData(finalizeStr, data) {
  return dispatch => {
        dispatch(get_success(data));
  };
}

function validateIndicatorData(validateStr, data) {
  return dispatch => {
    dispatch(get_success(data));
};}


function updateDataField(index, name, val) {
  return dispatch => {
    let value = isNaN(parseInt(val)) ? null : val;
    dispatch(update_data_field({ index, name, value }));
  };
}

function updateResolutionsField(index, name, val) {
  return dispatch => {
    let value = isNaN(parseInt(val)) ? null : val;
    dispatch(update_resolutions_field({ index, name, value }));
  };
}

function updateMetadataField(name, val) {
  return dispatch => {
    let value = val === "" ? null : val;
    dispatch(update_metadata_field({ name, value }));
  };
}

function clearIndicatorData() {
  return dispatch => {
    dispatch(clear_indicator_data());
  };
}

function updateDenominator(name, val) {
  return dispatch => {
    let value = val === "" ? null : val;
    dispatch(update_denominator_field({ name, value }));
  };
}

function updateQuestionaireFile(data) {
  return dispatch => {
    dispatch(update_questionaire_file({ data }));
  };
}

function updateImplManualFile(data) {
  return dispatch => {
    dispatch(update_impl_manual_file({ data }));
  };
}

function updateIntManualFile(data) {
  return dispatch => {
    dispatch(update_int_manual_file({ data }));
  };
}

function update_data_field(data) {
  return {
    type: sdg1633Constants.UPDATE_1633_DATA_FIELD,
    data
  };
}

function update_resolutions_field(data) {
  return {
    type: sdg1633Constants.UPDATE_1633_RESOLUTIONS_FIELD,
    data
  };
}

function update_metadata_field(data) {
  return {
    type: sdg1633Constants.UPDATE_1633_METADATA_FIELD,
    data
  };
}

function get_success(data) {
  return {
    type: sdg1633Constants.GET_1633_DATA_SUCCESS,
    data
  };
}

function clear_indicator_data() {
  return {
    type: sdg1633Constants.CLEAR_1633_DATA
  };
}

function save_data_failure(error) {
  return {
    type: sdg1633Constants.SAVE_1633_DATA_FAILURE,
    error: error
  };
}

function update_denominator_field(data) {
  return {
    type: sdg1633Constants.UPDATE_1633_DENOMINATOR_FIELD,
    data
  };
}

function update_questionaire_file(data) {
  return {
    type: sdg1633Constants.UPDATE_1633_QUESTIONAIRE_FILE,
    data
  };
}

function update_impl_manual_file(data) {
  return {
    type: sdg1633Constants.UPDATE_1633_IMPL_MANUAL_FILE,
    data
  };
}

function update_int_manual_file(data) {
  return {
    type: sdg1633Constants.UPDATE_1633_INT_MANUAL_FILE,
    data
  };
}
