import React, { Component } from "react";
import { NumberInput, TextArea, TextInput } from "carbon-components-react";
import { connect } from "react-redux";

import { sdg1662Actions } from "../../../../../../_actions";
import YesNoRadio from "../../../../../common/elements/YesNoRadio";
import InputTable from "../../../../../common/table/InputTable";

class RespondentsTable extends Component {
  constructor(props) {
      super(props);

      this.handleTableChange = this.handleTableChange.bind(this);
      this.goToMetadata = this.goToMetadata.bind(this);
    }
  
    handleTableChange(name, value) {
      
      this.props.handleTableChange(name, value);
      
    }
  
    goToMetadata() {
      this.props.onPrevious();
    }
  
  render() {

      let headers = [
          {title: "Service areas", width: "75%"},
          {title: "Number of respondents (respondents who used the service in the past 12 months)"}
        ]

        let tableData = [
          {
            texts: [
              {
                title: "Healthcare", 
              },  
            ],
            inputs: [
              {
                name: "healthcare",
                data : this.props.data ? this.props.data["healthcare"]: "",
                invalid:this.props.error && this.props.error["healthcare"] ? this.props.error["healthcare"][0] : null
              }
            ]       
          },
          {
            texts: [
              {
                title: "Primary education", 
              },  
            ],
            inputs: [
              {
                name: "primary_education",
                data : this.props.data ? this.props.data["primary_education"]: "",
                invalid:this.props.error && this.props.error["primary_education"] ? this.props.error["primary_education"][0] : null
              }
            ]         
          },
          {
            texts: [
              {
                title: "Secondary education",
              },  
            ],
            inputs: [
              {
                name: "secondary_education",
                data : this.props.data? this.props.data["secondary_education"]: "",
                invalid:this.props.error && this.props.error["secondary_education"] ? this.props.error["secondary_education"][0] : null
              }
            ]         
          },
          {
            texts: [
              {
                title: "Government services (government-issued IDs and civil registration of life events)",
              },  
            ],
            inputs: [
              {
                name: "government_services",
                data : this.props.data? this.props.data["government_services"]: "",
                invalid:this.props.error && this.props.error["government_services"] ? this.props.error["government_services"][0] : null
              }
            ]         
          },
        ]

      return (
          <React.Fragment>
            <div className="row">
              <div className="col-sm-8 adaptive-table">
                <p>17.	For each of the four service areas covered by this indicator, please specify the number of respondents who actually used the respective service in the past 12 months and whose responses are therefore counted in the estimates provided:</p>
                <fieldset
                  disabled={this.props.data && (!this.props.data.id || this.props.data.id === null)}
                >
  
                  <InputTable
                    headers={headers}
                    rows = {tableData}
                    handleTableChange={this.handleTableChange}
                  />
                </fieldset>
              </div>
          </div>
        </React.Fragment>
          );
  }
}

class Sample extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sexDisaggregation: false,

    };

    this.handleChange = this.handleChange.bind(this);
    this.handlePopulationChange = this.handlePopulationChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleAnothersourceRadioChange = this.handleAnothersourceRadioChange.bind(
      this
    );
    this.handleSampleIntChange = this.handleSampleIntChange.bind(this);
    this.handleSampleFloatChange = this.handleSampleFloatChange.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);

  }

  handleChange(e) {
    const { dispatch } = this.props;

    dispatch(
      sdg1662Actions.updateMetadataField(e.target.name, e.target.value)
    );
  }

  handleSampleIntChange(e) {
    const { dispatch } = this.props;

    dispatch(
      sdg1662Actions.updateMetadataField(e.target.name, parseInt(e.target.value))
    );
  }

  handleSampleFloatChange(e) {
    const { dispatch } = this.props;

    const value = e.target.value;

    dispatch(
      sdg1662Actions.updateMetadataField(e.target.name, value.lastIndexOf(".") === value.length - 1 ? value : parseFloat(e.target.value))
    );
  }

  handleTableChange(name, value) {
    const { dispatch } = this.props;

    dispatch(
      sdg1662Actions.updateMetadataField(name, parseInt(value))
    );
  }  

  handlePopulationChange(e) {
    const { dispatch } = this.props;

    let target = e.target.name.split(":");

    dispatch(
      sdg1662Actions.updateDisaggregationPopulationField(parseInt(target[0]), target[1], e.target.value)
    );
  }  

  handleAnothersourceRadioChange(name, value) {
    this.setState({ anotherSource: value });
  }

  handleSexDisaggregationChange(name, value) {
    this.setState({ sexDisaggregation: value });
  }

  handleRadioChange(name, value) {
    const { dispatch } = this.props;

    dispatch(sdg1662Actions.updateMetadataField(name, value));
  }



  handleCheckBoxChange(e, name) {
    // console.log("Event", e, name);
    const { dispatch } = this.props;

    let value = false;
    if (e && e !== undefined) {
      value = e;
    }

    dispatch(
      sdg1662Actions.updateMetadataField(name, !value)
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="box-header with-border">
          <h3 className="box-title metadata-title">Sample</h3>
        </div>
        <div className="box-body with-border">
          <div className="row"
            style={{ display: "flex", marginBottom: "15px" }}>
          <div className="col-sm-8 adaptive-form">
              <TextArea
                id="target_population"
                name="target_population"
                value={
                  this.props.data && this.props.data["target_population"]
                    ? this.props.data["target_population"]
                    : ""
                }
                onChange={this.handleChange}
                labelText="10.	Target population (specify age- and legal residence status limitations, as applies):"
              />
            </div>
          </div>
          <div className="row"
            style={{ display: "flex", marginBottom: "15px" }}>
          <div className="col-sm-6 adaptive-form">
          <TextInput
                id="older_than_18"
                name="older_than_18"
                // className="input-1671c"
                style={{ width: "120px" }}
                labelText="11. Sample size for persons aged 18 years or older:"
                onChange={this.handleSampleIntChange}
                value={
                  this.props.data && this.props.data["older_than_18"]
                    ? this.props.data["older_than_18"]
                    : ""
                }
              />
            </div>
          </div>
          <div
            className="row"
            style={{ display: "block", marginBottom: "15px" }}
          >
            <div className="col-sm-12"
            >
              <YesNoRadio
                name="used_probability_sample"
                id="used_probability_sample"
                label="12.	Did you use a probability sample?"
                defaultValue={
                  this.props.data &&
                    this.props.data["used_probability_sample"] !==
                    undefined
                    ? this.props.data["used_probability_sample"]
                    : null
                }
                _handleSelectedValue={this.handleRadioChange}
              />
            </div>
          </div>
          <div className="row"
            style={{ display: "flex", marginBottom: "15px" }}>
          <div className="col-sm-8 adaptive-form">
              <TextArea
                id="describe_sampling_method"
                name="describe_sampling_method"
                value={
                  this.props.data && this.props.data["describe_sampling_method"]
                    ? this.props.data["describe_sampling_method"]
                    : ""
                }
                onChange={this.handleChange}
                labelText="13.	Please briefly describe the sampling method and how the sample was drawn:"
              />
            </div>
          </div>
          <div className="row"
            style={{ display: "flex", marginBottom: "15px" }}>
          <div className="col-sm-8 adaptive-form">
          <TextInput
                  id={`coverage_rate`}
                  name={`coverage_rate`}
                  // className="input-1671b"
                  style={{width: "120px"}}
                  onChange={this.handleSampleFloatChange}
                  value={
                    this.props.data && this.props.data["coverage_rate"]
                      ? this.props.data["coverage_rate"]
                      : ""
                  }
                  labelText="14.	Coverage rate (%, estimated proportion of individuals in the target population with a non-zero probability for sampling inclusion):"
                  invalid={
                    this.props.error && 
                    this.props.error["coverage_rate"] !== undefined
                  }
                  invalidText={
                    this.props.error &&
                    this.props.error["coverage_rate"]
                      ? this.props.error["coverage_rate"][0]
                      : ""
                  }
              />
            </div>
          </div>
          <div className="row"
            style={{ display: "flex", marginBottom: "15px" }}>
          <div className="col-sm-8 adaptive-form">
              <TextArea
                id="survey_excluded"
                name="survey_excluded"
                value={
                  this.props.data && this.props.data["survey_excluded"]
                    ? this.props.data["survey_excluded"]
                    : ""
                }
                invalid={
                  this.props.error && 
                  this.props.error["coverage_rate"] !== undefined
                }
                invalidText={
                  this.props.error &&
                  this.props.error["coverage_rate"]
                    ? this.props.error["coverage_rate"][0]
                    : ""
                }
                onChange={this.handleChange}
                labelText="15.	Were any groups of individuals systematically excluded from the survey? (e.g. certain geographical regions of the country that are difficult to reach, certain linguistic groups whose language is not one of the interviewing languages, a specific gender in settings where the gender of the interviewer restrains whom he can speak to, etc.)"
              />
            </div>
          </div>
          <div className="row"
            style={{ display: "flex", marginBottom: "15px" }}>
          <div className="col-sm-8 adaptive-form">
              <TextArea
                id="selection_protocol"
                name="selection_protocol"
                value={
                  this.props.data && this.props.data["selection_protocol"]
                    ? this.props.data["selection_protocol"]
                    : ""
                }
                onChange={this.handleChange}
                labelText="16.	Please briefly describe the selection protocol for sampling at the individual (within-household) level:"
              />
            </div>
          </div>
              <RespondentsTable 
              handleTableChange={this.handleTableChange}
              data={this.props.data} 
              error={this.props.error}/>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { data, error } = state.sdg1662State;

  return {
    data,
    error,
  };
}

const connectedSource = connect(mapStateToProps)(Sample);

export { connectedSource as Sample };
