import React, { Component } from "react";
import { connect } from "react-redux";
import { TextArea, TextInput } from "carbon-components-react";

import { sdg1672Actions } from "../../../../../../_actions";

class Questionnaire extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  state = {};

  handleChange(e) {
    const { dispatch } = this.props;

    dispatch(sdg1672Actions.updateMetadataField(e.target.name, e.target.value));
  }
  render() {
    return (
      <React.Fragment>
        <div className="box-header with-border">
          <h3 className="box-title metadata-title">Questionnaire</h3>
        </div>
        <div className="box-body with-border">
        <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div className="col-sm-8  adaptive-form">
              <TextInput
                id="questionnaire_languages"
                name="questionnaire_languages"
                value={
                  this.props.data &&
                  this.props.data["questionnaire_languages"]
                    ? this.props.data["questionnaire_languages"]
                    : ""
                }
                onChange={this.handleChange}
                labelText="17.	In what language(s) were the questions asked?"
              />
            </div>
          </div>
          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div className="col-sm-8  adaptive-form">
              <div className="bx--form-item bx--text-input-wrapper">
                <label className="bx--label">
                  18. Please specify the exact question wording used for the first
                  question ("How much would you say the political system in
                  [country X] allows people like you to have a say in what the
                  government does?")
                </label>
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div className="col-sm-8  adaptive-form">
              <TextArea
                id="inclusivness_question_national"
                name="inclusivness_question_national"
                value={
                  this.props.data &&
                  this.props.data["inclusivness_question_national"]
                    ? this.props.data["inclusivness_question_national"]
                    : ""
                }
                onChange={this.handleChange}
                labelText="In the main national language:"
              />
            </div>
          </div>
          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div className="col-sm-8  adaptive-form">
              <TextArea
                id="inclusivness_question_english"
                name="inclusivness_question_english"
                value={
                  this.props.data &&
                  this.props.data["inclusivness_question_english"]
                    ? this.props.data["inclusivness_question_english"]
                    : ""
                }
                onChange={this.handleChange}
                labelText="In English:"
              />
            </div>
          </div>
          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div className="col-sm-8 adaptive-form">
              <div className="bx--form-item bx--text-input-wrapper">
                <label className="bx--label">
                  19. Please specify the exact question wording used for the second
                  question ("How much would you say the political system in
                  [country X] allows people like you to have an influence on
                  politics?")
                </label>
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div className="col-sm-8 adaptive-form">
              <TextArea
                id="responsiveness_question_national"
                name="responsiveness_question_national"
                value={
                  this.props.data &&
                  this.props.data["responsiveness_question_national"]
                    ? this.props.data["responsiveness_question_national"]
                    : ""
                }
                onChange={this.handleChange}
                labelText="In the main national language:"
              />
            </div>
          </div>
          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div className="col-sm-8 adaptive-form">
              <TextArea
                id="responsiveness_question_english"
                name="responsiveness_question_english"
                value={
                  this.props.data &&
                  this.props.data["responsiveness_question_english"]
                    ? this.props.data["responsiveness_question_english"]
                    : ""
                }
                onChange={this.handleChange}
                labelText="In English:"
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { data } = state.sdg1672State;

  return {
    data
  };
}

const connectedQuestionnaire = connect(mapStateToProps)(Questionnaire);

export { connectedQuestionnaire as Questionnaire };
