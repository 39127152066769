import React, { Component } from "react";
import { connect } from "react-redux";

import { sdg1662Actions } from "../../../../../../_actions";
import { RadioButton, RadioButtonGroup, TextArea } from "carbon-components-react";

class QuestionnaireSelection extends Component {
  constructor(props) {
    super(props);
    this.handleRadioClick = this.handleRadioClick.bind(this);
  }

  handleRadioClick(e) {
    //console.log(e.target, this.props);
    if (this.props.handleRadioChange)
      this.props.handleRadioChange(e.target.name, e.target.value);
  }

  render() {
    return (
      <React.Fragment>
        <RadioButtonGroup name={this.props.name} valueSelected={this.props.defaultValue ? this.props.defaultValue : null}>
          <RadioButton style={{marginRight: "35px"}} labelText="Yes" type="radio" name={this.props.name} value="yes" selected={this.props.defaultValue === "yes"} onClick={this.handleRadioClick} />
          <RadioButton labelText="No" type="radio" name={this.props.name} value="no" selected={this.props.defaultValue === "no"} onClick={this.handleRadioClick} />
          <RadioButton labelText="Yes, but different formulation" type="radio" name={this.props.name} value="diff" selected={this.props.defaultValue === "diff"} onClick={this.handleRadioClick} />
        </RadioButtonGroup>
      </React.Fragment>
    )
  }
}

class Questionnaire extends Component {
  constructor(props) {
    super(props);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  state = {};

  handleRadioChange(name, value) {
    const { dispatch } = this.props;

    dispatch(sdg1662Actions.updateMetadataField(name, value));
  }

  handleChange(e) {
    const { dispatch } = this.props;

    dispatch(sdg1662Actions.updateMetadataField(e.target.name, e.target.value));
  }

  render() {
    return (
      <React.Fragment>
        <div className="box-header with-border">
          <h3 className="box-title metadata-title">Questionnaire</h3>
        </div>
        <div className="box-body with-border">
          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div className="col-sm-8 adaptive-form">
              <div className="bx--form-item bx--text-input-wrapper">
                <label htmlFor="question-table" className="bx--label">
                  Did you include all 5 attributes questions recommended in the
                  metadata for each service areas, in your survey? Please
                  specify any deviation in the table below:
                </label>
                <table
                  className="table table-bordered dataTable"
                  name="question-table"
                  style={{ tableLayout: "fixed" }}
                >
                  <thead>
                    <tr role="row">
                      <th style={{width:"20%"}}>Attributes questions for each service areas</th>
                      <th style={{width:"60%"}}>Attributes included in your survey</th>
                      <th>Comments</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr role="row">
                      <td>Healthcare</td>
                      <td>
                          <div>1.	<strong>Accessibility</strong> (“It was easy to get to the place where you received healthcare services.”)</div>
                        <div style={{ paddingTop: "10px" }}>
                          <QuestionnaireSelection 
                          name = "healthcare_accessibility"
                          defaultValue={this.props.data &&
                            this.props.data[
                            "healthcare_accessibility"
                            ] !== undefined
                            ? this.props.data[
                            "healthcare_accessibility"
                            ]
                            : null} 
                            handleRadioChange={this.handleRadioChange}/>
                        </div>
                        <div style={{ paddingTop: "15px" }}>
                          <div>2.	<strong>Affordability</strong> (“Expenses for healthcare services were affordable to you/your household.”)</div>
                        </div>
                        <div style={{ paddingTop: "10px" }}>
                          <QuestionnaireSelection 
                          name="healthcare_affordability"
                          defaultValue={this.props.data &&
                            this.props.data[
                            "healthcare_affordability"
                            ] !== undefined
                            ? this.props.data[
                            "healthcare_affordability"
                            ]
                            : null} 
                            handleRadioChange={this.handleRadioChange}/>
                        </div>
                        <div style={{ paddingTop: "15px" }}>
                          <div>3.	<strong>Quality of facilities</strong> (“The healthcare facilities were clean and in good condition.”)</div>
                        </div>
                        <div style={{ paddingTop: "10px" }}>
                          <QuestionnaireSelection 
                          name="healthcare_quality_of_facilities"
                          defaultValue={this.props.data &&
                            this.props.data[
                            "healthcare_quality_of_facilities"
                            ] !== undefined
                            ? this.props.data[
                            "healthcare_quality_of_facilities"
                            ]
                            : null} 
                            handleRadioChange={this.handleRadioChange}/>
                        </div>
                        <div style={{ paddingTop: "15px" }}>
                          <div>4.	<strong>Equal treatment for everyone</strong> (“All people are treated equally in receiving healthcare services in your area.”)</div>
                        </div>
                        <div style={{ paddingTop: "10px" }}>
                          <QuestionnaireSelection 
                          name="healthcare_equal_treatment_for_everyone"
                          defaultValue={this.props.data &&
                            this.props.data[
                            "healthcare_equal_treatment_for_everyone"
                            ] !== undefined
                            ? this.props.data[
                            "healthcare_equal_treatment_for_everyone"
                            ]
                            : null} 
                            handleRadioChange={this.handleRadioChange}/>
                        </div>
                        <div style={{ paddingTop: "15px" }}>
                          <div>5.	<strong>Attitude/courtesy of healthcare staff</strong> (“The doctor or other healthcare staff you saw spent enough time with you [or your child] during the consultation.”)</div>
                        </div>
                        <div style={{ paddingTop: "10px" }}>
                          <QuestionnaireSelection 
                          name="healthcare_attitude_of_healthcare_staff" 
                          defaultValue={this.props.data &&
                            this.props.data[
                            "healthcare_attitude_of_healthcare_staff"
                            ] !== undefined
                            ? this.props.data[
                            "healthcare_attitude_of_healthcare_staff"
                            ]
                            : null} 
                            handleRadioChange={this.handleRadioChange}/>
                        </div>
                      </td>
                      <td>
                        <TextArea
                        labelText=""
                        id="healthcare_comment"
                        name="healthcare_comment"
                        style={{minWidth:"100%"}}
                        value={
                          this.props.data && this.props.data["healthcare_comment"]
                            ? this.props.data["healthcare_comment"]
                            : ""
                        } 
                        onChange={this.handleChange}
                        />
                      </td>
                    </tr>
                    <tr role="row">
                      <td>Primary education</td>
                      <td>
                          <div>1.	<strong>Accessibility</strong> (“The school can be reached by public or private transportation, or by walk, in less than 30 minutes and without difficulties.”)</div>
                        <div style={{ paddingTop: "10px" }}>
                          <QuestionnaireSelection 
                            name="primary_education_accessibility" 
                            defaultValue={this.props.data &&
                            this.props.data[
                            "primary_education_accessibility"
                            ] !== undefined
                            ? this.props.data[
                            "primary_education_accessibility"
                            ]
                            : null} 
                            handleRadioChange={this.handleRadioChange}/>
                        </div>
                        <div style={{ paddingTop: "15px" }}>
                          <div>2.	<strong>Affordability</strong> (“School-related expenses (including administrative fees, books, uniforms and transportation) are affordable to you/your household.”)</div>
                        </div>
                        <div style={{ paddingTop: "10px" }}>
                          <QuestionnaireSelection 
                          name="primary_education_affordability"
                          defaultValue={this.props.data &&
                            this.props.data[
                            "primary_education_affordability"
                            ] !== undefined
                            ? this.props.data[
                            "primary_education_affordability"
                            ]
                            : null} 
                            handleRadioChange={this.handleRadioChange}/>
                        </div>
                        <div style={{ paddingTop: "15px" }}>
                          <div>3.	<strong>Quality of facilities</strong> (“School facilities are in good condition.”)</div>
                        </div>
                        <div style={{ paddingTop: "10px" }}>
                          <QuestionnaireSelection 
                            name="primary_education_quality_of_facilities"
                          defaultValue={this.props.data &&
                            this.props.data[
                            "primary_education_quality_of_facilities"
                            ] !== undefined
                            ? this.props.data[
                            "primary_education_quality_of_facilities"
                            ]
                            : null} 
                            handleRadioChange={this.handleRadioChange}/>
                        </div>
                        <div style={{ paddingTop: "15px" }}>
                          <div>4.	<strong>Equal treatment for everyone</strong> (“All children are treated equally in the school attended by the child/children in your household, including admission to these schools.”)</div>
                        </div>
                        <div style={{ paddingTop: "10px" }}>
                          <QuestionnaireSelection 
                            name="primary_education_equal_treatment_for_everyone"
                            defaultValue={this.props.data &&
                            this.props.data[
                            "primary_education_equal_treatment_for_everyone"
                            ] !== undefined
                            ? this.props.data[
                            "primary_education_equal_treatment_for_everyone"
                            ]
                            : null} 
                            handleRadioChange={this.handleRadioChange}/>
                        </div>
                        <div style={{ paddingTop: "15px" }}>
                          <div>5.	<strong>Quality of teaching</strong> (“The quality of teaching is good.”)</div>
                        </div>
                        <div style={{ paddingTop: "10px" }}>
                          <QuestionnaireSelection name="primary_education_quality_of_teaching"
                            defaultValue={this.props.data &&
                            this.props.data[
                            "primary_education_quality_of_teaching"
                            ] !== undefined
                            ? this.props.data[
                            "primary_education_quality_of_teaching"
                            ]
                            : null} 
                            handleRadioChange={this.handleRadioChange}/>
                        </div>
                      </td>
                      <td>
                      <TextArea 
                        labelText=""
                        id="primary_education_comment"
                        name="primary_education_comment"
                        style={{minWidth:"100%"}}
                        value={
                          this.props.data && this.props.data["primary_education_comment"]
                            ? this.props.data["primary_education_comment"]
                            : ""
                        } 
                        onChange={this.handleChange}
                        />
                      </td>
                    </tr>
                    <tr role="row">
                      <td>Secondary education</td>
                      <td>
                          <div>1.	<strong>Accessibility</strong> (“The school can be reached by public or private transportation, or by walk, in less than 30 minutes and without difficulties.”)</div>
                        <div style={{ paddingTop: "10px" }}>
                          <QuestionnaireSelection name="secondary_education_accessibility" 
                          defaultValue={this.props.data &&
                            this.props.data[
                            "secondary_education_accessibility"
                            ] !== undefined
                            ? this.props.data[
                            "secondary_education_accessibility"
                            ]
                            : null} 
                            handleRadioChange={this.handleRadioChange}/>
                        </div>
                        <div style={{ paddingTop: "15px" }}>
                          <div>2.	<strong>Affordability</strong> (“School-related expenses (including administrative fees, books, uniforms and transportation) are affordable to you/your household.”)</div>
                        </div>
                        <div style={{ paddingTop: "10px" }}>
                          <QuestionnaireSelection name="secondary_education_affordability" 
                          defaultValue={this.props.data &&
                            this.props.data[
                            "secondary_education_affordability"
                            ] !== undefined
                            ? this.props.data[
                            "secondary_education_affordability"
                            ]
                            : null} 
                            handleRadioChange={this.handleRadioChange}/>
                        </div>
                        <div style={{ paddingTop: "15px" }}>
                          <div>3.	<strong>Quality of facilities</strong> (“School facilities are in good condition.”)</div>
                        </div>
                        <div style={{ paddingTop: "10px" }}>
                          <QuestionnaireSelection name="secondary_education_quality_of_facilities" 
                          defaultValue={this.props.data &&
                            this.props.data[
                            "secondary_education_quality_of_facilities"
                            ] !== undefined
                            ? this.props.data[
                            "secondary_education_quality_of_facilities"
                            ]
                            : null} 
                            handleRadioChange={this.handleRadioChange}/>
                        </div>
                        <div style={{ paddingTop: "15px" }}>
                          <div>4.	<strong>Equal treatment for everyone</strong> (“All children are treated equally in the school attended by the child/children in your household, including admission to these schools.”)</div>
                        </div>
                        <div style={{ paddingTop: "10px" }}>
                          <QuestionnaireSelection name="secondary_education_equal_treatment_for_everyone" defaultValue={this.props.data &&
                            this.props.data[
                            "secondary_education_equal_treatment_for_everyone"
                            ] !== undefined
                            ? this.props.data[
                            "secondary_education_equal_treatment_for_everyone"
                            ]
                            : null} 
                            handleRadioChange={this.handleRadioChange}/>
                        </div>
                        <div style={{ paddingTop: "15px" }}>
                          <div>5.	<strong>Quality of teaching</strong> (“The quality of teaching is good.”)</div>
                        </div>
                        <div style={{ paddingTop: "10px" }}>
                          <QuestionnaireSelection name="secondary_education_quality_of_teaching" 
                          defaultValue={this.props.data &&
                            this.props.data[
                            "secondary_education_quality_of_teaching"
                            ] !== undefined
                            ? this.props.data[
                            "secondary_education_quality_of_teaching"
                            ]
                            : null} 
                            handleRadioChange={this.handleRadioChange}/>
                        </div>
                      </td>
                      <td>
                      <TextArea 
                        labelText=""
                        id="secondary_education_comment"
                        name="secondary_education_comment"
                        style={{minWidth:"100%"}}
                        value={
                          this.props.data && this.props.data["secondary_education_comment"]
                            ? this.props.data["secondary_education_comment"]
                            : ""
                        } 
                        onChange={this.handleChange}
                        />

                      </td>
                    </tr>
                    <tr role="row">
                      <td>
                        Government services (government-issued IDs and civil
                        registration of life events)
                      </td>
                      <td>
                          <div>1.	<strong>Accessibility</strong> (“The office, website or [toll free] telephone number was easily accessible.”)</div>
                        <div style={{ paddingTop: "10px" }}>
                          <QuestionnaireSelection name="government_services_accessibility" 
                          defaultValue={this.props.data &&
                            this.props.data[
                            "government_services_accessibility"
                            ] !== undefined
                            ? this.props.data[
                            "government_services_accessibility"
                            ]
                            : null} 
                            handleRadioChange={this.handleRadioChange}/>
                        </div>
                        <div style={{ paddingTop: "15px" }}>
                          <div>2.	<strong>Affordability</strong> (“The fees you needed to pay for the ID or the certificate were affordable to you/your household.”)</div>
                        </div>
                        <div style={{ paddingTop: "10px" }}>
                          <QuestionnaireSelection name="government_services_affordability" 
                          defaultValue={this.props.data &&
                            this.props.data[
                            "government_services_affordability"
                            ] !== undefined
                            ? this.props.data[
                            "government_services_affordability"
                            ]
                            : null} 
                            handleRadioChange={this.handleRadioChange}/>
                        </div>
                        <div style={{ paddingTop: "15px" }}>
                          <div>3.	<strong>Effective service delivery process</strong> (“The process for applying and obtaining the ID or the certificate was simple and easy to understand.”)</div>
                        </div>
                        <div style={{ paddingTop: "10px" }}>
                          <QuestionnaireSelection name="government_services_effective_service_delivery_process" 
                          defaultValue={this.props.data &&
                            this.props.data[
                            "government_services_effective_service_delivery_process"
                            ] !== undefined
                            ? this.props.data[
                            "government_services_effective_service_delivery_process"
                            ]
                            : null} 
                            handleRadioChange={this.handleRadioChange}/>
                        </div>
                        <div style={{ paddingTop: "15px" }}>
                          <div>4.	<strong>Equal treatment for everyone</strong> (“All people are treated equally in receiving government services in your area.”)</div>
                        </div>
                        <div style={{ paddingTop: "10px" }}>
                          <QuestionnaireSelection name="government_services_equal_treatment_for_everyone" 
                          defaultValue={this.props.data &&
                            this.props.data[
                            "government_services_equal_treatment_for_everyone"
                            ] !== undefined
                            ? this.props.data[
                            "government_services_equal_treatment_for_everyone"
                            ]
                            : null} 
                            handleRadioChange={this.handleRadioChange}/>
                        </div>
                        <div style={{ paddingTop: "15px" }}>
                          <div>5.	<strong>Timeliness</strong> (“The amount of time it took to obtain the ID or the certificate was reasonable.”)</div>
                        </div>
                        <div style={{ paddingTop: "10px" }}>
                          <QuestionnaireSelection name="government_services_timeliness" 
                          defaultValue={this.props.data &&
                            this.props.data[
                            "government_services_timeliness"
                            ] !== undefined
                            ? this.props.data[
                            "government_services_timeliness"
                            ]
                            : null} 
                            handleRadioChange={this.handleRadioChange}/>
                        </div>
                      </td>
                      <td>
                      <TextArea 
                        labelText=""
                        id="government_services_comment"
                        name="government_services_comment"
                        style={{minWidth:"100%"}}
                        value={
                          this.props.data && this.props.data["government_services_comment"]
                            ? this.props.data["government_services_comment"]
                            : ""
                        } 
                        onChange={this.handleChange}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { data } = state.sdg1662State;

  return {
    data
  };
}

const connectedQuestionnaire = connect(mapStateToProps)(Questionnaire);

export { connectedQuestionnaire as Questionnaire };
