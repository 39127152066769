import React, { Component } from "react";

import Button from "carbon-components-react/lib/components/Button";
import Form from "carbon-components-react/lib/components/Form";

import "../../../../styles/components/sdgs/sdg_16_7_1b.css";
import "../../../../styles/css/AdminLTE.css";
import "../../../../styles/components/introduction.css";

import { notifyError, notifySuccess } from "../../../common/notification/NotificationHelper";
import { connect } from "react-redux";

import { Information32 } from "@carbon/icons-react";
import { Metadata } from "./components/Metadata";
import { IndicatorData } from "./components/IndicatorData";

import { sdg1662Actions, userActions } from "../../../../_actions";
import { commonService } from "../../../../_services";
import Spinner from "../../../common/spinner/Spinner";

class SDG16_6_2 extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onValidate = this.onValidate.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onSaveWork = this.onSaveWork.bind(this);
    this.onNext = this.onNext.bind(this);
    this.onPrevious = this.onPrevious.bind(this);
  }

  state = {
    page: 1
  };

  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    const { dispatch } = this.props;
    if (params.id !== undefined) {
      dispatch(sdg1662Actions.getIndicatorData(params.id));
    } else {
      dispatch(sdg1662Actions.clearIndicatorData());

      dispatch(
        sdg1662Actions.updateMetadataField(
          "country",
          this.props.user ? this.props.user.country : null
        )
      );
    }
  }

  onSubmit(event) {
    event.preventDefault();
    let data = this.props.data;

    const { dispatch } = this.props;

    dispatch(sdg1662Actions.saveIndicatorData(data));
  }

  onSubmitUndp = () => {
    const { dispatch } = this.props;
    let data = this.props.data;

    let finalizeStr = "sdg1662/" + this.props.data.id + "/review/";
    dispatch(sdg1662Actions.finalizeIndicatorData(finalizeStr, data));
  };

  handleChange(e) {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  }

  handleTableChange(name, value) {
    this.setState({ data: { ...this.state.data, [name]: value } });
  }

  onValidate(e) {
    let data = { pk: this.props.data.id };

    let validateStr = this.props.data.status !== 'validated'
      ? "sdg1662/" + this.props.data.id + "/validate/"
      : "sdg1662/" + this.props.data.id + "/unvalidate/";

      const { dispatch } = this.props;

      this.setState({processingData: true});
      commonService.validateIndicatorData(validateStr, data).then(
        data => {
          this.setState({processingData: false});
          dispatch(sdg1662Actions.validateIndicatorData(validateStr, data));
        },
        error => {
          this.setState({processingData: true});
          if (error.response.status === 500) {
            notifyError(
              "Server not available at the moment. Please try again later."
            );
          } else if (error.response.status === 401) {
            notifyError("Session expired. Please login again!")
            const { dispatch } = this.props;
            dispatch(userActions.logout());
          } 
          else {
            notifyError(
              "There are arrors on the form, please review data before submitting!"
            );
            // dispatch(save_data_failure(error.response.data));
          }
        }
      );
    }

  onSelectChange(e) {
    // console.log(e.target.value);
    this.setState({ nature_of_data: e.target.value });
  }

  onSaveWork() {
    localStorage.setItem("sdg1662", JSON.stringify(this.state.data));
    notifySuccess("Data saved succesfully!");
  }

  onNext() {
    let page = this.state.page;

    this.setState({ page: page + 1 });
  }

  onPrevious() {
    let page = this.state.page;

    this.setState({ page: page - 1 });
  }

  render() {
    let validateTitle =
    this.props.data && this.props.data.status === 'validated'
        ? "Unlock for edit"
        : "Validate / Lock";

    // let saveButton =
    //   this.props.data && !this.props.data.validated ? (
    //     <div className="col-sm-3">
    //       <Button onClick={this.onSaveWork}>Save work</Button>
    //     </div>
    //   ) : null;

    let disableEntry = this.props.user ? 
      this.props.user.user_type === 'OGC' && this.props.data && this.props.data.status === "validated" ? true :
      this.props.data && this.props.user.user_type === 'NSO' && this.props.data.status !== "entry" ? true : false : false; 

    let contentData =
      this.state.page === 1 ? (
        <Metadata
          // data={this.state.data}
          error={this.state.error}
          onHandleChange={this.handleChange}
        />
      ) : (
        <IndicatorData
          onDataChange={this.handleTableChange}
          onPrevious={this.onPrevious}
        />
      );

    let validateButton =
      this.props.user && this.props.user.user_type === "OGC" ? (
        // <div className="col-sm-12">
        <Button onClick={this.onValidate}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {validateTitle}
        </Button>
      ) : // </div>
      null;

    let notifyButton =
      this.state.page === 2 &&
      this.props.data &&
      !(this.props.data.status === 3) &&
      this.props.user &&
      this.props.user.user_type === "NSO" ? (
        <Button
          onClick={this.onSubmitUndp}
          style={{ marginRight: "25px" }}
          className="button-bottom"
        >
          Submit to UNDP
        </Button>
      ) : null;

      let saveButton = this.props.data &&
      this.props.user &&
      ((this.props.user.user_type === "NSO" && this.props.data.status === 'entry') ||
        (this.props.user.user_type === "OGC" && this.props.data.status !== 'validated')) ? (
          <Button
            onClick={this.onSubmit}
            style={{ marginRight: "25px" }}
            className="button-bottom"
          >
            Save data
          </Button>) : null;


    let submitButton =
      (
        <div className="col-sm-12">
          {saveButton}
          {notifyButton}
          {validateButton}
        </div>
      );

    let content = (
      <div>
        <Form onSubmit={this.onSubmit}>
          <fieldset disabled={disableEntry}>
            {contentData}
          </fieldset>
          <div className="row introduction-bottom">
            <div className="col-sm-12">
              {this.state.page !== 1 ? (
                <Button
                  onClick={this.onPrevious}
                  disabled={this.state.page === 1}
                  style={{ width: "135px" }}
                >
                  &lt;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Back
                </Button>
              ) : null}
              {this.state.page !== 2 ? (
                <Button
                  onClick={this.onNext}
                  disabled={this.state.page === 3}
                  style={{
                    marginLeft: "50px",
                    width: "135px",
                    float: "right"
                  }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Continue&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&gt;
                </Button>
              ) : null}

              {/* <div > */}
              {/* <button
                style={{ position: "absolute", right: "0" }}
                className="bx--introduction__action"
                type="button"
                
              >
                <ArrowRight32 />
              </button> */}
              {/* </div> */}
            </div>
          </div>
          <div className="row" style={{ textAlign: "center" }}>
            {submitButton}
            {/* {validateButton} */}
          </div>
        </Form>
      </div>
    );

    return (
      <div id="content-wrapper" className="content-wrapper">
         {this.state.processingData && <Spinner />}
        <section className="indicator-header" style={{ display: "flex" }}>
          <img
            src="/resources/img/Icon_16-6-2.png"
            style={{ height: "55px", marginRight: "10px" }}
            alt="sdg1662"
          />
          <h1>SDG 16.6.2: Satisfaction with public services</h1>
          <div style={{ paddingTop: "10px" }}>
            <a
              title="Click to see indicator brief"
              target="_blank"
              rel="noopener noreferrer"
              href="https://unstats.un.org/sdgs/metadata/files/Metadata-16-06-02.pdf"
            >
              <Information32 />
            </a>
          </div>
        </section>
        <section className="content">
          <div className="box">
            <div className="box-body">{content}</div>
          </div>
        </section>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  const { data } = state.sdg1662State;

  return {
    user,
    data
  };
}
const connectedIndicator = connect(mapStateToProps)(SDG16_6_2);
export { connectedIndicator as SDG16_6_2 };
