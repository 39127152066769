import React, { Component } from "react";

import { connect } from "react-redux";

import { sdg1671cActions } from "../../../../../../_actions";
import { TextInput } from "carbon-components-react";


class RowRenderer extends Component {
  constructor(props) {
    super(props);

    this.handleTableChange = this.handleTableChange.bind(this);
    this.getProportionsColumns = this.getProportionsColumns.bind(this);
    this.getProportionsOfYoung = this.getProportionsOfYoung.bind(this);
  }

  handleTableChange(e) {
    // console.log("name", e.target.name, e.target.value)
    this.props.handleTableChange(e.target.name,e.target.value);
  }

  getProportionsColumns(index, name) {

      const male = this.props.data.data[index]["male"] ? parseInt(this.props.data.data[index]["male"]) : 0
      const female = this.props.data.data[index]["female"] ? parseInt(this.props.data.data[index]["female"]) : 0

      if ((male > 0 || female > 0) &&
          this.props.data && 
          this.props.data.proportions &&
          this.props.data.proportions.length > 0 && 
          this.props.data.data[index][name] &&
          ( this.props.data.proportions[index][name] === null || 
            (parseFloat(this.props.data.proportions[index][name]).toFixed(2)) !== parseFloat(this.props.data.data[index][name]/(female + male)*100.00).toFixed(2)))
       
      {
          this.props.updateProportionsField(index, name, (this.props.data.data[index][name]/(female + male)*100.00).toFixed(2))
   
      }
      else {
        if (this.props.data && this.props.data.data[index][name] === null && this.props.data.proportions.length > 0 && this.props.data.proportions[index][name]) {
          
          this.props.updateProportionsField(index, name, null);
        }
      }
      return female + male > 0 && this.props.data.data[index][name] ? (this.props.data.data[index][name]/(female + male)*100.00).toFixed(2): "";
  }

  getProportionsOfYoung(index) {
    if (this.props.data && this.props.data.data && this.props.data.data[index]) {
      const male = this.props.data.data[index]["male"] ? parseInt(this.props.data.data[index]["male"]) : 0
      const female = this.props.data.data[index]["female"] ? parseInt(this.props.data.data[index]["female"]) : 0
      const age_under_35 = this.props.data.data[index]["age_under_35"] ? parseInt(this.props.data.data[index]["age_under_35"]) : 0
      const between_35_44 = this.props.data.data[index]["between_35_44"] ? parseInt(this.props.data.data[index]["between_35_44"]) : 0

      if (this.props.data.proportions && this.props.data.proportions.length > 0 && (this.props.data.data[index]["between_35_44"] || this.props.data.data[index]["age_under_35"]) && (male + female) > 0
          && (parseFloat(this.props.data.proportions[index]["age_under_44"]).toFixed(2)) !== parseFloat((age_under_35+between_35_44)/(female + male)*100.00).toFixed(2)) {
        this.props.updateProportionsField(index, "age_under_44", ((age_under_35+between_35_44)/(male + female)*100).toFixed(2));
      }
      return (this.props.data.data[index]["between_35_44"] || this.props.data.data[index]["age_under_35"]) && (male + female) > 0 ?
      ((age_under_35+between_35_44)/(male + female)*100).toFixed(2) : "";
    } 
  }

  render() {
    return (
      <React.Fragment>
        <tr>
          <td style={{background:this.props.title.indexOf("Total") !== -1 ? "#FFC000": "white"}}>
            {this.props.title}
          </td>
          <td>
            <TextInput
              id={`${this.props.index}:proportion:female:judges`}
              name={`${this.props.index}:proportion:female:judges`}
              // className="input-1671b"
              labelText=""
              // onChange={this.handleTableChange}
              value={this.getProportionsColumns(this.props.index, "female")}
            />
          </td>
          <td>
            <TextInput
              id={`${this.props.index}:proportion:female:registrars`}
              name={`${this.props.index}:proportion:female:registrars`}
              // className="input-1671b"
              labelText=""
              onChange={this.handleTableChange}
              value={this.getProportionsColumns(this.props.index+4, "female")}
            />
          </td>
          <td>
            <TextInput
              id={`${this.props.index}:proportion:young:judges`}
              name={`${this.props.index}:proportion:young:judges`}
              // className="input-1671b"
              labelText=""
              // onChange={this.handleTableChange}
              value={this.getProportionsOfYoung(this.props.index)
              }
            />
          </td>
          <td>
            <TextInput
              id={`${this.props.index}:proportion:young:registrars`}
              name={`${this.props.index}:proportion:young:registrars`}
              // className="input-1671b"
              labelText=""
              onChange={this.handleTableChange}
              value={this.getProportionsOfYoung(this.props.index+4)}
            />
          </td>
          <td>
            <TextInput
              id={`${this.props.index}:proportion:disabled:judges`}
              name={`${this.props.index}:proportion:disabled:judges`}
              // className="input-1671b"
              labelText=""
              // onChange={this.handleTableChange}
              value={this.getProportionsColumns(this.props.index, "disabled")
              }
            />
          </td>
          <td>
            <TextInput
              id={`${this.props.index}:proportion:disabled:registrars`}
              name={`${this.props.index}:proportion:disabled:registrars`}
              // className="input-1671b"
              labelText=""
              onChange={this.handleTableChange}
              value={this.getProportionsColumns(this.props.index+4, "disabled")
              }
            />
          </td>
          <td>
            <TextInput
              id={`${this.props.index}:proportion:group_a:judges`}
              name={`${this.props.index}:proportion:group_a:judges`}
              // className="input-1671b"
              labelText=""
              // onChange={this.handleTableChange}
              value={this.getProportionsColumns(this.props.index, "group_a")
              }
            />
          </td>
          <td>
            <TextInput
              id={`${this.props.index}:proportion:group_a:registrars`}
              name={`${this.props.index}:proportion:group_a:registrars`}
              // className="input-1671b"
              labelText=""
              onChange={this.handleTableChange}
              value={this.getProportionsColumns(this.props.index+4, "group_a")}
            />
          </td>
          <td>
            <TextInput
              id={`${this.props.index}:proportion:group_b:judges`}
              name={`${this.props.index}:proportion:group_b:judges`}
              // className="input-1671b"
              labelText=""
              // onChange={this.handleTableChange}
              value={this.getProportionsColumns(this.props.index, "group_b")
              }
            />
          </td>
          <td>
            <TextInput
              id={`${this.props.index}:proportion:group_b:registrars`}
              name={`${this.props.index}:proportion:group_b:registrars`}
              // className="input-1671b"
              labelText=""
              onChange={this.handleTableChange}
              value={this.getProportionsColumns(this.props.index+4, "group_b")
              }
            />
          </td>
          <td>
            <TextInput
              id={`${this.props.index}:proportion:group_c:judges`}
              name={`${this.props.index}:proportion:group_c:judges`}
              // className="input-1671b"
              labelText=""
              // onChange={this.handleTableChange}
              value={this.getProportionsColumns(this.props.index, "group_c")
              }
            />
          </td>
          <td>
            <TextInput
              id={`${this.props.index}:proportion:group_c:registrars`}
              name={`${this.props.index}:proportion:group_c:registrars`}
              // className="input-1671b"
              labelText=""
              onChange={this.handleTableChange}
              value={this.getProportionsColumns(this.props.index+4, "group_c")
              }
            />
          </td>        
          </tr>
      </React.Fragment>
    )
  }

}



class ProportionsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      error: props.error
    };

    this.updateProportionsField = this.updateProportionsField.bind(this);
    this.goToMetadata = this.goToMetadata.bind(this);
  }

  updateProportionsField(index, name, value) {
    let { dispatch } = this.props;

    dispatch(
      sdg1671cActions.updateProportionsField(index, name, value)
    );     

  }

  goToMetadata() {
    this.props.onPrevious();
  }

  getTotalRowNumber(row) {
    let val = 0;

    if (this.props.data && this.props.data.data[0][row] !== null) {
      val += parseInt(this.props.data.data[0][row])
    }

    if (this.props.data && this.props.data.data[1][row] !== null) {
      val += parseInt(this.props.data.data[1][row])
    }

    return val;
  }

  getTotalNumber(index, prefix) {
    let val = 0;

    if (this.props.data && this.props.data.data[index][prefix+"_officials"] !== null) {
      val += parseInt(this.props.data.data[index][prefix+"_officials"])
    }

    if (this.props.data && this.props.data.data[index][prefix+"_executives"] !== null) {
      val += parseInt(this.props.data.data[index][prefix+"_executives"])
    }

    if (this.props.data && this.props.data.data[index][prefix+"_professionals"] !== null) {
      val += parseInt(this.props.data.data[index][prefix+"_professionals"])
    }

    if (this.props.data && this.props.data.data[index][prefix+"_associate"] !== null) {
      val += parseInt(this.props.data.data[index][prefix+"_associate"])
    }

    if (this.props.data && this.props.data.data[index][prefix+"_general"] !== null) {
      val += parseInt(this.props.data.data[index][prefix+"_general"])
    }

    return val;
  }

  getProportionsColumns(index, column1, column2) {

    if (this.props.data && this.props.data.data[index][column1] && this.props.data.data[2][column2] && (this.props.data.data[2][column2]  !== 0)) {
      return ((parseInt(this.props.data.data[index][column1])/parseInt(this.props.data.data[2][column2]))*100.00).toFixed(2);
    }
    else return 0;
  }

  getProportionsTotalsRow(column1, column2) {

    if (this.props.data && this.props.data.data[2][column1] && parseInt(this.props.data.data[2][column1]) !== 0) {
      return ((parseInt(this.props.data.data[2][column1])/parseInt(this.props.data.data[2][column2]))*100.00).toFixed(2);
    }
    else return 0;
  }
  
  getProportionsRowTotals(column1) {

    if (this.props.data && this.props.data.data[2][column1] && parseInt(this.props.data.data[2][column1]) !== 0) {
      return ((parseInt(this.props.data.data[2][column1])/(parseInt(this.getTotalNumber(2,"total"))))*100.00).toFixed(2);
    }
    else return 0;
  }

  getProportionsColumnTotals(index, column1) {

    if (this.props.data && parseInt(this.getTotalNumber(index, column1)) !== 0) {
      return ((parseInt(this.getTotalNumber(index, column1))/(parseInt(this.getTotalNumber(2,"total"))))*100.00).toFixed(2);
    }
    else return 0;
  }
  
  
  render() {

    return (
      <React.Fragment>
        <div className="box-body">
          <div className="col-sm-8">
            <h4>Step 2: Proportional representation of various demographic groups among judges and registrars</h4>
          </div>
          <div className="row">
            <div className="col-sm-12 adaptive-table">
              <fieldset
                disabled={this.props.data && (!this.props.data.id || this.props.data.id === null)}
              >
                <table className="table table-bordered table-1671b">
                  <thead  style={{fontSize:"0.95vw"}}>
                    <tr>
                      <th rowSpan="2" style={{ width: "18%" }}></th>
                      <th colSpan="2" style={{background:"#A9D08E"}}>Proportion of female judges and registrars</th>
                      <th colSpan="2" style={{background:"#A9D08E"}}>Proportion of ‘young’ judges and registrars aged 44 and below</th>
                      <th colSpan="2" style={{background:"#A9D08E"}}>Proportion of judges and registrars with a disability</th>
                      <th colSpan="2" style={{background:"#A9D08E"}}>Proportion of judges and registrars in population group(s) A</th>
                      <th colSpan="2" style={{background:"#A9D08E"}}>Proportion of judges and registrars in population group(s) B</th>
                      <th colSpan="2" style={{background:"#A9D08E"}}>Proportion of judges and registrars in population group(s) C</th>
                    </tr>
                    <tr>
                      <th style={{background:"#A9D08E"}}>Judges</th>
                      <th style={{background:"#A9D08E"}}>Registrars</th>
                      <th style={{background:"#A9D08E"}}>Judges</th>
                      <th style={{background:"#A9D08E"}}>Registrars</th>
                      <th style={{background:"#A9D08E"}}>Judges</th>
                      <th style={{background:"#A9D08E"}}>Registrars</th>
                      <th style={{background:"#A9D08E"}}>Judges</th>
                      <th style={{background:"#A9D08E"}}>Registrars</th>
                      <th style={{background:"#A9D08E"}}>Judges</th>
                      <th style={{background:"#A9D08E"}}>Registrars</th>
                      <th style={{background:"#A9D08E"}}>Judges</th>
                      <th style={{background:"#A9D08E"}}>Registrars</th>
                    </tr>
                  </thead>
                  <tbody  style={{fontSize:"0.95vw"}}>
                    <RowRenderer
                      index={2}
                      data={this.props.data}
                      title={"Constitutional / supreme courts"}
                      updateProportionsField= {this.updateProportionsField}
                      />
                    <RowRenderer
                      title={"Higher-level courts"}
                      index={0}
                      data={this.props.data}
                      updateProportionsField= {this.updateProportionsField}
                      />
                    <RowRenderer
                      title={"Lower-level courts"}
                      index={1}
                      data={this.props.data}
                      updateProportionsField= {this.updateProportionsField}
                      />
                      <RowRenderer
                      title={"Total (across all levels of courts)"}
                      index={3}
                      data={this.props.data}
                      updateProportionsField= {this.updateProportionsField}
                      />  
                  </tbody>
                </table>
              </fieldset>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { data } = state.sdg1671cState;

  return {
    data
  };
}

const connectedIndicatorData = connect(mapStateToProps)(ProportionsTable);

export { connectedIndicatorData as ProportionsTable };
