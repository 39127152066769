import React from "react";

import { NavLink } from "react-router-dom";

import "../../styles/components/header.css";

import {
  Header,
  HeaderName,
  HeaderGlobalBar,
  HeaderGlobalAction,
  HeaderMenuButton,
  HeaderContainer,
  SideNavItems,
  SideNav,
  SideNavMenu
} from "carbon-components-react/lib/components/UIShell";
import { UserAvatarFilled32, Logout32 } from "@carbon/icons-react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
// import Welcome from "./welcome";

import { history } from "../../_helpers";
import SideNavLinkCustom from "./elements/SideNavLinkCustom";
import SideNavLinkFocals from "./elements/SideNavLinkFocals";
import SideNavLinkContact from "./elements/SideNavLinkContact";

class AppHeader extends React.Component {
  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
    this.onClickHandler = this.onClickHandler.bind(this);
    this.onContactsClickHandler = this.onContactsClickHandler.bind(this);
    this.on1662ClickHandler = this.on1662ClickHandler.bind(this);
    this.on1633ClickHandler = this.on1633ClickHandler.bind(this);
    this.on1671bClickHandler = this.on1671bClickHandler.bind(this);
    this.on1671cClickHandler = this.on1671cClickHandler.bind(this);
    this.on1672ClickHandler = this.on1672ClickHandler.bind(this);
    this.onSideNavOpenClick = this.onSideNavOpenClick.bind(this);
  }

  state = {
    expanded: false
  };

  logout() {
    const { dispatch } = this.props;

    dispatch(userActions.logout());
  }

  onClickHandler() {
    history.push("/");
    this.resetState({});
    this.setState({ welcome: true });
  }

  onContactsClickHandler(e) {
    history.push("/users");

    this.resetState({});
    this.setState({ contacts: true });
  }

  resetState() {
    this.setState({
      active1662: false,
      active1633: false,
      active1671b: false,
      active1671c: false,
      active1672: false,
      contacts: false,
      welcome: false
    });
  }

  on1662ClickHandler() {
    history.push("/sdg1662-view");
    this.resetState();
    this.setState({ active1662: true });

    if (this.state.expanded) {
      document.getElementById("content-wrapper").style.paddingLeft = "16rem";
    }
  }

  on1633ClickHandler() {
    history.push("/sdg1633-view");
    this.resetState();
    this.setState({ active1633: true });

    if (this.state.expanded) {
      document.getElementById("content-wrapper").style.paddingLeft = "16rem";
    }
  }

  on1671bClickHandler() {
    history.push("/sdg1671b-view");
    this.resetState();
    this.setState({ active1671b: true });
    if (this.state.expanded) {
      document.getElementById("content-wrapper").style.paddingLeft = "16rem";
    }
  }

  on1671cClickHandler() {
    history.push("/sdg1671c-view");
    this.resetState();
    this.setState({ active1671c: true });
    if (this.state.expanded)
      document.getElementById("content-wrapper").style.paddingLeft = "16rem";
  }

  on1672ClickHandler() {
    history.push("/sdg1672-view");
    this.resetState();
    this.setState({ active1672: true });
    if (this.state.expanded)
      document.getElementById("content-wrapper").style.paddingLeft = "16rem";
  }

  onSideNavOpenClick() {
    if (this.state.expanded) {
      document.getElementById("content-wrapper").style.paddingLeft = "4rem";
      document.getElementsByClassName("bx--side-nav")[0].style.width = "4rem";
    } else {
      document.getElementById("content-wrapper").style.paddingLeft = "16rem";
      document.getElementsByClassName("bx--side-nav")[0].style.width = "16rem";
    }

    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    let manageContactsNav = this.props.user && this.props.user.user_type !== "OECD_UNODC"  ? (
      <SideNavLinkFocals
        renderIcon={UserAvatarFilled32}
        onClick={this.onContactsClickHandler}
        isActive={this.state.contacts ? this.state.contacts : false}
        name="Focal Points"
        description=""
      >
        Focal Points
      </SideNavLinkFocals>
    ) : (
      <React.Fragment />
    );

    let indicatorsNav =
      this.props.user && this.props.user.user_type !== "UNDP" ? this.props.user.user_type === "OECD_UNODC" ? (
        <React.Fragment>
        <SideNavLinkCustom
          onClick={this.on1633ClickHandler}
          isActive={this.state.active1633 ? this.state.active1633 : false}
          name="SDG 16.3.3"
          description={'Population who have experienced dispute'}
          icon="/resources/img/Icon_16-3-3.png"
        />          
        </React.Fragment>)
      :(
        <React.Fragment>
          <SideNavLinkCustom
            onClick={this.on1633ClickHandler}
            isActive={this.state.active1633 ? this.state.active1633 : false}
            name="SDG 16.3.3"
            description={'Population who have experienced dispute'}
            icon="/resources/img/Icon_16-3-3.png"
          />          
          <SideNavLinkCustom
            onClick={this.on1662ClickHandler}
            isActive={this.state.active1662 ? this.state.active1662 : false}
            name="SDG 16.6.2"
            description="Satisfaction with public services"
            icon="/resources/img/Icon_16-6-2.png"
          />
          <SideNavLinkCustom
            onClick={this.on1671bClickHandler}
            isActive={this.state.active1671b ? this.state.active1671b : false}
            name="SDG 16.7.1b"
            description="Inclusive representation in the public service"
            icon="/resources/img/Icon_16-7-1b.png"
          />
          <SideNavLinkCustom
            onClick={this.on1671cClickHandler}
            isActive={this.state.active1671c ? this.state.active1671c : false}
            name="SDG 16.7.1c"
            description="Inclusive representation in the judiciary"
            icon="/resources/img/Icon_16-7-1c.png"
          />
          <SideNavLinkCustom
            onClick={this.on1672ClickHandler}
            isActive={this.state.active1672 ? this.state.active1672 : false}
            name="SDG 16.7.2"
            description="Inclusive and responsive decision-making"
            icon="/resources/img/Icon_16-7-2.png"
          />
        </React.Fragment>
      ) : (
        <SideNavMenu className="hidden-nav" title="Indicators" />
      );

      let welcomeName = this.props.user && this.props.loggedIn ? this.props.user.last_name && this.props.user.last_name.length > 0 ?
                        this.props.user.last_name : this.props.user.email : "";

    return (
      <HeaderContainer
        render={({ isSideNavExpanded, onClickSideNavExpand }) => (
          <Header className="main-header" aria-label="OGC SDG Platform"
          onClick={
            isSideNavExpanded === true ? onClickSideNavExpand : null
          }>
          
            {this.props.loggedIn ? (
              <HeaderMenuButton
                aria-label="Open menu"
                isCollapsible
                onClick={onClickSideNavExpand}
                isActive={isSideNavExpanded}
              />
            ) : (
              <React.Fragment></React.Fragment>
            )}
            <NavLink className="header-icon" to="/">
              <img
                alt="UNDP Logo"
                className="img-logo"
                src="/resources/img/sdg-en-16-symbol.png"
              />
            </NavLink>
            {/* <SkipToContent /> */}
            <HeaderName className="title-header" prefix="">
              UNDP's SDG 16 Data Reporting Platform
            </HeaderName>
            <HeaderGlobalBar>
              {this.props.user && this.props.loggedIn ? (
                <span className="header-user">
                  {"Welcome " + welcomeName}
                </span>
              ) : (
                <React.Fragment></React.Fragment>
              )}
              {this.props.loggedIn ? (
                <React.Fragment>
                  <HeaderGlobalAction
                    onClick={this.logout}
                    aria-label="logout"
                    style={{ height: "100%" }}
                  >
                    <Logout32 />
                  </HeaderGlobalAction>
                  <SideNav
                    id="main-side-nav"
                    expanded={isSideNavExpanded}
                    aria-label="Application navigation"
                  >
                    <SideNavItems>
                      <SideNavLinkCustom
                        name="Welcome"
                        description=""
                        onClick={this.onClickHandler}
                        isActive={
                          this.state.welcome ? this.state.welcome : false
                        }
                        icon="/resources/img/sdg-en-16-symbol.png"
                      >
                        Welcome
                      </SideNavLinkCustom>
                      {indicatorsNav}
                      {manageContactsNav}
                      <SideNavLinkContact
                        // renderIcon={Email32}
                        href="mailto:sdg16indicators@undp.org"
                        name="Contact Us"
                        description=""
                        // isActive={
                        //   this.state.contacts ? this.state.contacts : false
                        // }
                      />
                    </SideNavItems>
                  </SideNav>
                </React.Fragment>
              ) : localStorage.getItem("unauthorizedUndp") ? (
                <React.Fragment>
                  <HeaderGlobalAction
                    onClick={this.logout}
                    aria-label="logout"
                    style={{ height: "100%" }}
                  >
                    <Logout32 />
                  </HeaderGlobalAction>
                </React.Fragment>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </HeaderGlobalBar>
          </Header>
        )}
      />
    );
  }
}

function mapStateToProps(state) {
  const { user, loggedIn, unauthorizedUNDP } = state.authentication;

  return {
    user,
    loggedIn,
    unauthorizedUNDP
  };
}

const connectedHeader = connect(mapStateToProps)(AppHeader);
export { connectedHeader as AppHeader };
