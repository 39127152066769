import React, { Component } from "react";
import { TextArea } from "carbon-components-react";
import { connect } from "react-redux";

import { sdg1662Actions } from "../../../../../../_actions";

class Other extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const { dispatch } = this.props;

    dispatch(sdg1662Actions.updateMetadataField(e.target.name, e.target.value));
  }

  state = {};
  render() {
    return (
      <React.Fragment>
        <div className="box-header with-border">
          <h3 className="box-title metadata-title">Other</h3>
        </div>
        <div className="box-body with-border">
          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div className="col-sm-8 adaptive-form">
              <TextArea
                id="other_notes"
                name="other_notes"
                value={this.props.data && this.props.data["other_notes"] ? this.props.data["other_notes"] : ""}
                onChange={this.handleChange}
                maxCount={1000}
                enableCounter
                labelText="19.	Other methodological notes / deviations from global metadata:"
                
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { data } = state.sdg1662State;

  return {
    data
  };
}

const connectedOther = connect(mapStateToProps)(Other);

export { connectedOther as Other };
