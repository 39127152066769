import { sdg1671cConstants } from "../_constants";

const initialState = {};

export function sdg1671cState(state = initialState, action) {
  switch (action.type) {
    case sdg1671cConstants.GET_1671c_DATA_SUCCESS: {
      return {
        ...state,
        data: action.data,
        error: {}
      };
    }
    case sdg1671cConstants.GET_1671c_DATA_FAILURE: {
      break;
    }
    case sdg1671cConstants.UPDATE_1671c_DATA_SUCCESS: {
      break;
    }
    case sdg1671cConstants.UPDATE_1671c_DATA_FAILURE: {
      break;
    }
    case sdg1671cConstants.UPDATE_1671c_DATA_FIELD: {
      return {
        ...state,
        data: {
          ...state.data,
          data: state.data.data.map((item, index) => {
            if (index === action.data.index) {
              return {
                ...item,
                [action.data.name]: action.data.value
              };
            }

            return item;
          })
        }
      };
    }
    case sdg1671cConstants.UPDATE_1671c_PROPORTIONS_FIELD: {
      return {
        ...state,
        data: {
          ...state.data,
          proportions: state.data.proportions.map((item, index) => {
            if (index === action.data.index) {
              return {
                ...item,
                [action.data.name]: action.data.value
              };
            }

            return item;
          })
        }
      };
    }
    case sdg1671cConstants.UPDATE_1671c_RATIOS_FIELD: {
      return {
        ...state,
        data: {
          ...state.data,
          ratios: state.data.ratios.map((item, index) => {
            if (index === action.data.index) {
              return {
                ...item,
                [action.data.name]: action.data.value
              };
            }

            return item;
          })
        }
      };
    }
    case sdg1671cConstants.UPDATE_1671c_METADATA_FIELD: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.data.name]: action.data.value
        }
      };
    }
    case sdg1671cConstants.CLEAR_1671c_DATA: {
      return {
        ...state,
        data: {},
        error: {}
      };
    }
    case sdg1671cConstants.SAVE_1671c_DATA_SUCCESS: {
      return {
        ...state,
        data: action.data
      };
    }
    case sdg1671cConstants.SAVE_1671c_DATA_FAILURE: {
      return {
        ...state,
        error: action.error
      };
    }
    case sdg1671cConstants.UPDATE_1671c_DENOMINATOR_FIELD: {
      return {
        ...state,
        data: {
          ...state.data,
          denominator: {
            ...state.data.denominator,
            [action.data.name]: action.data.value
          }
        }
      };
    }   
    default:
      return state;
  }
}
