import React, { Component } from "react";

import { connect } from "react-redux";

import { sdg1671cActions } from "../../../../../../_actions";
import { TextInput } from "carbon-components-react";


class RowRenderer extends Component {
  constructor(props) {
    super(props);

    this.handleTableChange = this.handleTableChange.bind(this);
  }

  componentDidUpdate

  handleTableChange(e) {
    // console.log("name", e.target.name, e.target.value)
    this.props.handleTableChange(e.target.name,e.target.value);
  }


  render() {
    return (
      <React.Fragment>
        <td>{this.props.title}</td>
        <td>
          <TextInput
            id={`${this.props.index}:male`}
            name={`${this.props.index}:male`}
            // className="input-1671b"
            labelText=""
            onChange={this.handleTableChange}
            value={
              this.props.data && this.props.data["male"]  !== null
                ? this.props.data["male"]
                : ""
            }
          />
        </td>
        <td>
          <TextInput
            id={`${this.props.index}:female`}
            name={`${this.props.index}:female`}
            // className="input-1671b"
            labelText=""
            onChange={this.handleTableChange}
            value={
              this.props.data && this.props.data["female"] !== null
                ? this.props.data["female"]
                : ""
            }
          />
        </td>
        <td>
          <TextInput
            id={`${this.props.index}:age_under_35`}
            name={`${this.props.index}:age_under_35`}
            // className="input-1671b"
            labelText=""
            onChange={this.handleTableChange}
            value={
              this.props.data && this.props.data["age_under_35"] !== null
                ? this.props.data["age_under_35"]
                : ""
            }
          />
        </td>
        <td>
          <TextInput
            id={`${this.props.index}:between_35_44`}
            name={`${this.props.index}:between_35_44`}
            // className="input-1671b"
            labelText=""
            onChange={this.handleTableChange}
            value={
              this.props.data && this.props.data["between_35_44"] !== null
                ? this.props.data["between_35_44"]
                : ""
            }
          />
        </td>
        <td>
          <TextInput
            id={`${this.props.index}:between_45_54`}
            name={`${this.props.index}:between_45_54`}
            // className="input-1671b"
            labelText=""
            onChange={this.handleTableChange}
            value={
              this.props.data && this.props.data["between_45_54"] !== null
                ? this.props.data["between_45_54"]
                : ""
            }
          />
        </td>
        <td>
          <TextInput
            id={`${this.props.index}:between_55_64`}
            name={`${this.props.index}:between_55_64`}
            // className="input-1671b"
            labelText=""
            onChange={this.handleTableChange}
            value={
              this.props.data && this.props.data["between_55_64"] !== null
                ? this.props.data["between_55_64"]
                : ""
            }
          />
        </td>
        <td>
          <TextInput
            id={`${this.props.index}:above_65`}
            name={`${this.props.index}:above_65`}
            // className="input-1671b"
            labelText=""
            onChange={this.handleTableChange}
            value={
              this.props.data && this.props.data["above_65"] !== null
                ? this.props.data["above_65"]
                : ""
            }
          />
        </td>
        <td>
          <TextInput
            id={`${this.props.index}:disabled`}
            name={`${this.props.index}:disabled`}
            // className="input-1671b"
            labelText=""
            onChange={this.handleTableChange}
            value={
              this.props.data && this.props.data["disabled"] !== null
                ? this.props.data["disabled"]
                : ""
            }
          />
        </td>
        <td>
          <TextInput
            id={`${this.props.index}:not_disabled`}
            name={`${this.props.index}:not_disabled`}
            // className="input-1671b"
            labelText=""
            onChange={this.handleTableChange}
            value={
              this.props.data && this.props.data["not_disabled"] !== null
                ? this.props.data["not_disabled"]
                : ""
            }
          />
        </td>
        <td>
          <TextInput
            id={`${this.props.index}:group_a`}
            name={`${this.props.index}:group_a`}
            // className="input-1671b"
            labelText=""
            onChange={this.handleTableChange}
            value={
              this.props.data && this.props.data["group_a"] !== null
                ? this.props.data["group_a"]
                : ""
            }
          />
        </td>
        <td>
          <TextInput
            id={`${this.props.index}:group_b`}
            name={`${this.props.index}:group_b`}
            // className="input-1671b"
            labelText=""
            onChange={this.handleTableChange}
            value={
              this.props.data && this.props.data["group_b"] !== null
                ? this.props.data["group_b"]
                : ""
            }
          />
        </td>
        <td>
          <TextInput
            id={`${this.props.index}:group_c`}
            name={`${this.props.index}:group_c`}
            // className="input-1671b"
            labelText=""
            onChange={this.handleTableChange}
            value={
              this.props.data && this.props.data["group_c"] !== null
                ? this.props.data["group_c"]
                : ""
            }
          />
        </td>
      </React.Fragment>
    )
  }

}


class RawDataTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      error: props.error,
    };

    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleTotalChange = this.handleTotalChange.bind(this);
    this.goToMetadata = this.goToMetadata.bind(this);
    this.getRegistrarsTotals = this.getRegistrarsTotals.bind(this);
    this.getJudgesTotals = this.getJudgesTotals.bind(this);
    this.checkAgeGroupCourts = this.checkAgeGroupCourts.bind(this);
  }

  handleTableChange(name, value) {
    let { dispatch } = this.props;

    let target = name.split(":");

    // console.log("Target", target);

    dispatch(
      sdg1671cActions.updateDataField(parseInt(target[0]), target[1], parseInt(value))
    );

    if (parseInt(target[0]) < 3) {
      this.getJudgesTotals(parseInt(target[0]), target[1], parseInt(value));
    }
    else {
      this.getRegistrarsTotals(parseInt(target[0]), target[1], parseInt(value));
    }

  }

  handleTotalChange(e) {
    let { dispatch } = this.props;

    let target = e.target.name.split(":");

    if (parseInt(target[0]) === 3) {
      if ((this.props.data.data[0][target[1]] !== null && this.props.data.data[0][target[1]] !== "") ||
      (this.props.data.data[1][target[1]] !== null && this.props.data.data[1][target[1]] !== "") ||
      (this.props.data.data[2][target[1]] !== null && this.props.data.data[2][target[1]] !== "")
        ) {
          return;
        }
    }
    
    if (parseInt(target[0]) === 7) {
      if ((this.props.data.data[4][target[1]] !== null && this.props.data.data[4][target[1]] !== "") ||
      (this.props.data.data[5][target[1]] !== null && this.props.data.data[5][target[1]] !== "") ||
      (this.props.data.data[6][target[1]] !== null && this.props.data.data[6][target[1]] !== "")
        ) {
          return;
        }
    }    

    dispatch(
      sdg1671cActions.updateDataField(parseInt(target[0]), target[1], parseInt(e.target.value))
    );
  }

  goToMetadata() {
    this.props.onPrevious();
  }

  getJudgesTotals(index, name, value) {

    let val = isNaN(value) ? 0 : value;

    if (index === 0  && this.props.data) {
      if (this.props.data.data[1][name] !== null)
        val += this.props.data.data[1][name]
        if (this.props.data.data[2][name] !== null)
        val += this.props.data.data[2][name]
    }

    if (index === 1 && this.props.data) {
      if (this.props.data.data[0][name] !== null)
        val += this.props.data.data[0][name]
        if (this.props.data.data[2][name] !== null)
        val += this.props.data.data[2][name]

    }

    if (index === 2 && this.props.data) {
      if (this.props.data.data[0][name] !== null)
        val += this.props.data.data[0][name]
        if (this.props.data.data[1][name] !== null)
        val += this.props.data.data[1][name]
    }

      let { dispatch } = this.props;

      dispatch(
        sdg1671cActions.updateDataField(3, name, parseInt(val))
      );
  
  }

  getRegistrarsTotals(index, name, value) {
    let val = isNaN(value) ? 0 : value;

    if (index === 4  && this.props.data) {
      if (this.props.data.data[5][name] !== null)
        val += this.props.data.data[5][name]
        if (this.props.data.data[6][name] !== null)
        val += this.props.data.data[6][name]
    }

    if (index === 5 && this.props.data) {
      if (this.props.data.data[4][name] !== null)
        val += this.props.data.data[4][name]
        if (this.props.data.data[6][name] !== null)
        val += this.props.data.data[6][name]

    }

    if (index === 6 && this.props.data) {
      if (this.props.data.data[4][name] !== null)
        val += this.props.data.data[4][name]
        if (this.props.data.data[5][name] !== null)
        val += this.props.data.data[5][name]
    }

      let { dispatch } = this.props;

      dispatch(
        sdg1671cActions.updateDataField(7, name, parseInt(val))
      );
  }

  checkAgeGroupCourts() {
    for (var i = 0; i <=7; i++) {
      console.log(this.props.data);
      var male = this.props.data && this.props.data.data[i]["male"] ? this.props.data.data[i]["male"] : 0;
      var female = this.props.data && this.props.data.data[i]["female"] ? this.props.data.data[i]["female"] : 0;
      var age_under_35 = this.props.data && this.props.data.data[i]["age_under_35"] ? this.props.data.data[i]["age_under_35"] : 0;
      var between_35_44 = this.props.data && this.props.data.data[i]["between_35_44"] ? this.props.data.data[i]["between_35_44"] : 0;
      var between_45_54 = this.props.data && this.props.data.data[i]["between_45_54"] ? this.props.data.data[i]["between_45_54"] : 0;
      var between_55_64 = this.props.data && this.props.data.data[i]["between_55_64"] ? this.props.data.data[i]["between_55_64"] : 0;
      var above_65 = this.props.data && this.props.data.data[i]["above_65"] ? this.props.data.data[i]["above_65"] : 0;

      if ((male + female) < (age_under_35 + between_35_44 + between_45_54 + between_55_64 + above_65))
        return true;
    }

    return false;
  }

  render() {

    return (
      <React.Fragment>
        <div className="box-body">
          <div className="col-sm-12">
            <h4>Step 1: Number of judges and registrars from various demographic groups</h4>
            </div>  
          <div className="row">
            <div className="col-sm-12 adaptive-table">
              <fieldset
                disabled={!this.props.data.id || this.props.data.id === null}
              >
                <table className="table table-bordered table-1671b">
                  <thead style={{fontSize:"0.95vw"}}>
                    <tr>
                      <th colSpan="2" style={{ width: "18%" }}></th>
                      <th colSpan="2" style={{background:"#A9D08E"}}>Sex (number)</th>
                      <th colSpan="5" style={{background:"#A9D08E"}}>
                        <p>Age group (number)</p>
                        {this.checkAgeGroupCourts() && <strong style={{color:"brown", fontSize: "1rem"}}>WARNING: Sum of age groups should be equal to the sum of the court level!</strong>}
                      </th>
                      <th colSpan="2" style={{background:"#A9D08E"}}>Disability (number)</th>
                      <th colSpan="3" style={{background:"#A9D08E"}}>Population Groups (number)</th>
                    </tr>
                    <tr>
                      <th colSpan="2"></th>
                      <th style={{background:"#A9D08E"}}>Male</th>
                      <th style={{background:"#A9D08E"}}>Female</th>
                      <th style={{background:"#A9D08E"}}>{"<35"}</th>
                      <th style={{background:"#A9D08E"}}>35-44</th>
                      <th style={{background:"#A9D08E"}}>45-54</th>
                      <th style={{background:"#A9D08E"}}>55-64</th>
                      <th style={{background:"#A9D08E"}}>65+</th>
                      <th style={{background:"#A9D08E"}}>Disabled</th>
                      <th style={{background:"#A9D08E"}}>Not disabled</th>
                      <th style={{background:"#A9D08E"}}>Group A</th>
                      <th style={{background:"#A9D08E"}}>Group B</th>
                      <th style={{background:"#A9D08E"}}>Group C</th>
                    </tr>
                  </thead>
                  <tbody  style={{fontSize:"0.95vw"}}>
                    <tr>
                      <td rowSpan="2">Constitutional/ Supreme courts</td>
                      <RowRenderer 
                        title="Judges"
                        index={2}
                        handleTableChange={this.handleTableChange}
                        data={this.props.data.data[2]}
                      />
                    </tr>
                    <tr>
                    <RowRenderer 
                        title="Registrars"
                        index={6}
                        handleTableChange={this.handleTableChange}
                        data={this.props.data.data[6]}
                      />
                    </tr>
                    <tr>
                      <td rowSpan="2">Higher-level courts</td>
                      <RowRenderer 
                        title="Judges"
                        index={0}
                        handleTableChange={this.handleTableChange}
                        data={this.props.data.data[0]}
                      />
                    </tr>
                    <tr>
                      <RowRenderer 
                        title="Registrars"
                        index={4}
                        handleTableChange={this.handleTableChange}
                        data={this.props.data.data[4]}
                      />
                    </tr>
                    <tr>
                      <td rowSpan="2">Lower-level courts</td>
                      <RowRenderer 
                        title="Judges"
                        index={1}
                        handleTableChange={this.handleTableChange}
                        data={this.props.data.data[1]}
                      />
                    </tr>
                    <tr>
                    <RowRenderer 
                        title="Registrars"
                        index={5}
                        handleTableChange={this.handleTableChange}
                        data={this.props.data.data[5]}
                      />
                    </tr>
                    <tr>
                      <td rowSpan="2" style={{background:"#FFC000"}}>TOTAL (across all levels of courts)</td>
                      <td style={{background:"#FFC000"}}>Judges</td>
                      <td>
                        <TextInput
                          id={`3:male`}
                          name={`3:male`}
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTotalChange}
                          value={this.props.data.data[3]["male"] !== null ? this.props.data.data[3]["male"]:""}
                          className = "yellow-input"

                        />
                      </td>
                      <td>
                        <TextInput
                          id={`3:female`}
                          name={`3:female`}
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTotalChange}
                          value={this.props.data.data[3]["female"] !== null?this.props.data.data[3]["female"]:""}
                          className = "yellow-input"

                        />
                      </td>
                      <td>
                        <TextInput
                          id={`3:age_under_35`}
                          name={`3:age_under_35`}
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTotalChange}
                          value={this.props.data.data[3]["age_under_35"] !== null?this.props.data.data[3]["age_under_35"]:""}
                          
                        />
                      </td>
                      <td>
                        <TextInput
                          id={`3:between_35_44`}
                          name={`3:between_35_44`}
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTotalChange}
                          value={this.props.data.data[3]["between_35_44"] !== null?this.props.data.data[3]["between_35_44"]:""}
                        />
                      </td>
                      <td>
                        <TextInput
                          id={`3:between_45_54`}
                          name={`3:between_45_54`}
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTotalChange}
                          value={this.props.data.data[3]["between_45_54"] !== null?this.props.data.data[3]["between_45_54"]:""}
                        />
                      </td>
                      <td>
                        <TextInput
                          id={`3:between_55_64`}
                          name={`3:between_55_64`}
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTotalChange}
                          value={this.props.data.data[3]["between_55_64"] !== null?this.props.data.data[3]["between_55_64"]:""}
                        />
                      </td>
                      <td>
                        <TextInput
                          id={`3:above_65`}
                          name={`3:above_65`}
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTotalChange}
                          value={this.props.data.data[3]["above_65"] !== null?this.props.data.data[3]["above_65"]:""}
                        />
                      </td>
                      <td>
                        <TextInput
                          id={`3:disabled`}
                          name={`3:disabled`}
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTotalChange}
                          value={this.props.data.data[3]["disabled"] !== null?this.props.data.data[3]["disabled"]:""}
                          className = "yellow-input"

                        />
                      </td>
                      <td>
                        <TextInput
                          id={`3:not_disabled`}
                          name={`3:not_disabled`}
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTotalChange}
                          value={this.props.data.data[3]["not_disabled"] !== null?this.props.data.data[3]["not_disabled"]:""}
                          className = "yellow-input"

                        />
                      </td>
                      <td>
                        <TextInput
                          id={`3:group_a`}
                          name={`3:group_a`}
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTotalChange}
                          value={this.props.data.data[3]["group_a"] !== null?this.props.data.data[3]["group_a"]:""}
                        />
                      </td>
                      <td>
                        <TextInput
                          id={`3:group_b`}
                          name={`3:group_b`}
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTotalChange}
                          value={this.props.data.data[3]["group_b"] !== null?this.props.data.data[3]["group_b"]:""}
                        />
                      </td>
                      <td>
                        <TextInput
                          id={`3:group_c`}
                          name={`3:group_c`}
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTotalChange}
                          value={this.props.data.data[3]["group_c"] !== null?this.props.data.data[3]["group_c"]:""}
                        />
                      </td>
                    </tr>
                    <tr>
                    <td style={{background:"#FFC000"}}>Registrars</td>
                      <td>
                        <TextInput
                          id={`7:male`}
                          name={`7:male`}
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTotalChange}
                          value={this.props.data.data[7]["male"] !== null?this.props.data.data[7]["male"]:""}
                          className = "yellow-input"

                        />
                      </td>
                      <td>
                        <TextInput
                          id={`7:female`}
                          name={`7:female`}
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTotalChange}
                          value={this.props.data.data[7]["female"] !== null?this.props.data.data[7]["female"]:""}
                          className = "yellow-input"

                        />
                      </td>
                      <td>
                        <TextInput
                          id={`7:age_under_35`}
                          name={`7:age_under_35`}
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTotalChange}
                          value={this.props.data.data[7]["age_under_35"] !== null?this.props.data.data[7]["age_under_35"]:""}
                        />
                      </td>
                      <td>
                        <TextInput
                          id={`7:between_35_44`}
                          name={`7:between_35_44`}
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTotalChange}
                          value={this.props.data.data[7]["between_35_44"] !== null?this.props.data.data[7]["between_35_44"]:""}
                        />
                      </td>
                      <td>
                        <TextInput
                          id={`7:between_45_54`}
                          name={`7:between_45_54`}
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTotalChange}
                          value={this.props.data.data[7]["between_45_54"] !== null?this.props.data.data[7]["between_45_54"]:""}
                        />
                      </td>
                      <td>
                        <TextInput
                          id={`7:between_55_64`}
                          name={`7:between_55_64`}
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTotalChange}
                          value={this.props.data.data[7]["between_55_64"] !== null?this.props.data.data[7]["between_55_64"]:""}
                        />
                      </td>
                      <td>
                        <TextInput
                          id={`7:above_65`}
                          name={`7:above_65`}
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTotalChange}
                          value={this.props.data.data[7]["above_65"] !== null?this.props.data.data[7]["above_65"]:""}
                        />
                      </td>
                      <td>
                        <TextInput
                          id={`7:disabled`}
                          name={`7:disabled`}
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTotalChange}
                          value={this.props.data.data[7]["disabled"] !== null?this.props.data.data[7]["disabled"]:""}
                          className = "yellow-input"

                        />
                      </td>
                      <td>
                        <TextInput
                          id={`7:not_disabled`}
                          name={`7:not_disabled`}
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTotalChange}
                          value={this.props.data.data[7]["not_disabled"] !== null?this.props.data.data[7]["not_disabled"]:""}
                          className = "yellow-input"

                        />
                      </td>
                      <td>
                        <TextInput
                          id={`7:group_a`}
                          name={`7:group_a`}
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTotalChange}
                          value={this.props.data.data[7]["group_a"] !== null?this.props.data.data[7]["group_a"]:""}
                        />
                      </td>
                      <td>
                        <TextInput
                          id={`7:group_b`}
                          name={`7:group_b`}
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTotalChange}
                          value={this.props.data.data[7]["group_b"] !== null?this.props.data.data[7]["group_b"]:""}
                        />
                      </td>
                      <td>
                        <TextInput
                          id={`7:group_c`}
                          name={`7:group_c`}
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTotalChange}
                          value={this.props.data.data[7]["group_c"] !== null?this.props.data.data[7]["group_c"]:""}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </fieldset>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { data, error } = state.sdg1671cState;

  return {
    data,
    error
  };
}

const connectedIndicatorData = connect(mapStateToProps)(RawDataTable);

export { connectedIndicatorData as RawDataTable };
