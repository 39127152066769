import React, { Component } from "react";

import TextInput from "carbon-components-react/lib/components/TextInput";
import { connect } from "react-redux";

import { sdg1672Actions } from "../../../../../_actions";
import { NumberInput } from "carbon-components-react";

class TableRow extends Component {
  constructor(props) {
    super(props);

    this.handleVal1 = this.handleVal1.bind(this);
    this.handleVal2 = this.handleVal2.bind(this);
    this.handleVal3 = this.handleVal3.bind(this);
    this.mapGroupToPopulation = this.mapGroupToPopulation.bind(this);
    this.handleMetadataChange = this.handleMetadataChange.bind(this);
  }
  state = {
    val1:
      this.props.data && this.props.data.data
        ? this.props.data.data[0][this.props.name]
        : "",
    val2:
      this.props.data && this.props.data.data
        ? this.props.data.data[1][this.props.name]
        : "",
    val3:
      this.props.data && this.props.data.data
        ? this.props.data.data[2][this.props.name]
        : ""
  };

  componentDidUpdate(prevProps, prevState) {
    // if (
    //   this.props.data &&
    //   this.props.data.data &&
    //   (prevState.val1 !== this.props.data.data[0][this.props.name] ||
    //     prevState.val2 !== this.props.data.data[1][this.props.name] ||
    //     prevState.val3 !== this.props.data.data[2][this.props.name])
    // ) {
    //   this.setState({
    //     val1: this.props.data.data[0][this.props.name],
    //     val2: this.props.data.data[1][this.props.name],
    //     val3: this.props.data.data[2][this.props.name]
    //   });
    // }
  }

  handleMetadataChange(e) {
    let { dispatch } = this.props;

    dispatch(sdg1672Actions.updateMetadataField(e.target.name, e.target.value));
  }

  handleVal1(e) {
    let val1 = e.target.value !== "" ? parseFloat(e.target.value) : null;

    const { dispatch } = this.props;

      dispatch(sdg1672Actions.updateDataField(0, this.props.name, val1));
  }

  handleVal2(e) {
    let val2 = e.target.value !== "" ? parseFloat(e.target.value): null;
    const { dispatch } = this.props;

    dispatch(sdg1672Actions.updateDataField(1, this.props.name, val2));
  }

  handleVal3(e) {
    let val2 = e.target.value !== "" ? parseFloat(e.target.value): null;
    const { dispatch } = this.props;

    dispatch(sdg1672Actions.updateDataField(2, this.props.name, val2));
  }

  mapGroupToPopulation(group) {
    if (group.includes("population_a")) return "population_group_a";
    else if (group.includes("population_b")) return "population_group_b";
    else if (group.includes("population_c")) return "population_group_c";
    else return "";
  }

  render() {
    let th = this.props.title.includes("Population") ? (
      <th key={"th" + this.props.index} style={{ width: "260px" }}>
        {this.props.title}
        <div style={{ display: "flex" }}>
          <p style={{ paddingTop: "5px", paddingRight: "5px" }}>Specify:</p>
          <TextInput
            id={this.props.name}
            name={this.mapGroupToPopulation(this.props.name)}
            value={
              this.props.data && this.props.data[this.mapGroupToPopulation(this.props.name)]
                ? this.props.data[this.mapGroupToPopulation(this.props.name)]
                : ""
            }
            labelText=""
            // className="input-1671b"
            onChange={this.handleMetadataChange}
          />
        </div>
      </th>
    ) : (
      <th key={"th" + this.props.index} style={{ width: "260px" }}>
        {this.props.title}
      </th>
    );

    return (
      <tr key={"1672" + this.props.index}>
        {th}
        <td key={"0:" + this.props.name}>
          <NumberInput
            id={"0:" + this.props.name}
            name={"0:" + this.props.name}
            value={
              this.props.data.data[0][this.props.name]  !== null
                ? this.props.data.data[0][this.props.name]
                : null 
            }            // className="input-1671b"
            onChange={this.handleVal1}
            invalid={
              this.props.error &&
              this.props.error.data &&
              this.props.error.data[0][this.props.name] !== undefined
            }
            invalidText={
              this.props.error &&
              this.props.error.data &&
              this.props.error.data[0][this.props.name] !== undefined
                ? this.props.error.data[0][this.props.name]
                : ""
            }
            inputMode="numeric"
            pattern="[-+]?[0-9]*[.,]?[0-9]+"
          />
        </td>
        <td key={"1:" + this.props.name}>
          <NumberInput
            id={"1:" + this.props.name}
            name={"1:" + this.props.name}
            value={
              this.props.data.data[1][this.props.name]  !== null
                ? this.props.data.data[1][this.props.name]
                : null
            }            // className="input-1671b"
            onChange={this.handleVal2}
            invalid={
              this.props.error &&
              this.props.error.data &&
              this.props.error.data[1][this.props.name] !== undefined
            }
            invalidText={
              this.props.error &&
              this.props.error.data &&
              this.props.error.data[1][this.props.name] !== undefined
                ? this.props.error.data[1][this.props.name]
                : ""
            }
            inputMode="numeric"
          />
        </td>
        <td key={"2:" + this.props.name}>
          <NumberInput
            id={"2:" + this.props.name}
            name={"21:" + this.props.name}
            value={
              this.props.data.data[2][this.props.name]  !== null
                ? this.props.data.data[2][this.props.name]
                : null
            }            // className="input-1671b"
            // className="input-1671b"
            onChange={this.handleVal3}
            invalid={
              this.props.error &&
              this.props.error.data &&
              this.props.error.data[2][this.props.name] !== undefined
            }
            invalidText={
              this.props.error &&
              this.props.error.data &&
              this.props.error.data[2][this.props.name] !== undefined
                ? this.props.error.data[2][this.props.name]
                : ""
            }
          />
        </td>
      </tr>
    );
  }
}

function mapStateToProps(state) {
  const { data, error } = state.sdg1672State;

  return {
    data,
    error
  };
}

const connectedRow = connect(mapStateToProps)(TableRow);
export { connectedRow as TableRow };
