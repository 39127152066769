export const sdg1672Constants = {
  GET_1672_DATA_SUCCESS: "GET_1672_DATA_SUCCESS",
  GET_1672_DATA_FAILURE: "GET_1672_DATA_FAILURE",
  UPDATE_1672_DATA_SUCCESS: "UPDATE_1672_DATA_SUCCESS",
  UPDATE_1672_DATA_FAILURE: "UPDATE_1672_DATA_FAILURE",
  UPDATE_1672_DATA_FIELD: "UPDATE_1672_DATA_FIELD",
  UPDATE_1672_METADATA_FIELD: "UPDATE_1672_METADATA_FIELD",
  CLEAR_1672_DATA: "CLEAR_1672_DATA",
  SAVE_1672_DATA_SUCCESS: "SAVE_1672_DATA_SUCCESS",
  SAVE_1672_DATA_FAILURE: "SAVE_1672_DATA_FAILURE",
  UPDATE_1672_QUESTIONAIRE_FILE: "UPDATE_1672_QUESTIONAIRE_FILE",
  UPDATE_1672_IMPL_MANUAL_FILE: "UPDATE_1672_IMPL_MANUAL_FILE",
  UPDATE_1672_INT_MANUAL_FILE: "UPDATE_1672_INT_MANUAL_FILE"
};
