import React, { Component } from "react";
import { RadioButton, RadioButtonGroup, TextArea, TextInput, Tooltip } from "carbon-components-react";
import { connect } from "react-redux";

import { sdg1671bActions } from "../../../../../../_actions";
import YesNoRadio from "../../../../../common/elements/YesNoRadio";
import FileUploader from "../../../../../common/elements/FileUploader";

import axios from "axios";

class Managers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      file_upload_status:
        this.props.data && this.props.data.occupational && this.props.data.occupational.clasification_file !== null
          ? "edit"
          : ""
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);

    this.handleCareerDataRadioChange = this.handleCareerDataRadioChange.bind(this);
    this.handleCareerDataChange = this.handleCareerDataChange.bind(this);

    this.onChangeUpload = this.onChangeUpload.bind(this);

  }

  componentDidMount() {
    this.setState({file_upload_status: this.props.data && this.props.data.occupational && this.props.data.occupational.clasification_file !== null
      ? "edit"
      : ""})
  }

  handleChange(e) {
    const { dispatch } = this.props;

    dispatch(
      sdg1671bActions.updateOccupationalField(e.target.name, e.target.value)
    );
  }

  handleRadioChange(name, value) {
    const { dispatch } = this.props;

    dispatch(sdg1671bActions.updateOccupationalField(name, value));
  }

  handleCareerDataRadioChange(name, value) {
    const { dispatch } = this.props;

    let target = name.split(":");

    dispatch(sdg1671bActions.updateCareerDataField(parseInt(target[0]), target[1], value))
  }

  handleCareerDataChange(e) {
    const { dispatch } = this.props;

    let target = e.target.name.split(":");

    dispatch(sdg1671bActions.updateCareerDataField(parseInt(target[0]), target[1], e.target.value))
  }

  onChangeUpload(e) {

    let data = new FormData();
    const { dispatch } = this.props;

    data.append("file", e.target.files[0])

    this.setState({
      file_upload_status: "uploading"
    });

    axios
      .post("/api/v2/upload/", data)
      .then(response => {

        let file_name = response.data.file.substring(response.data.file.indexOf("media/")+6);
        dispatch(sdg1671bActions.updateOccupationalFile({id: response.data.id, file: file_name}));
        this.setState({
          file_upload_status: "edit"
        });
      });

    // setTimeout(() => {
    //   this.setState({
    //     file_upload_status: "edit"
    //   });
    // }, 2000);
  }

  onDeleteUpload = () => {

    if (this.props.data.status !== 'entry') 
      return;

    const { dispatch } = this.props;
    
    this.setState({
      file_upload_status: "none",
    });

        dispatch(sdg1671bActions.updateOccupationalFile({id: null, file: ""}));
  };

  onDownloadFile = () => {
    return axios
      .get("/media/"+this.props.data.occupational.clasification_file_path, { responseType: "blob" })
      .then(response => {
        let blob = new Blob([response.data], {
            type: "application/xlsx; charset=utf-8"
          }),
          downloadUrl = window.URL.createObjectURL(blob),
          filename = this.props.data.occupational.clasification_file_path.substring(
            this.props.data.occupational.clasification_file_path.lastIndexOf("/" + 1)
          );

        let a = document.createElement("a");
        if (typeof a.download === "undefined") {
          window.location.href = downloadUrl;
        } else {
          a.href = downloadUrl;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
        }
        return response.data;
      });
  };


  state = {};
  render() {

    let standardizedCategoriesTable = true ? (
      <React.Fragment>
        <br />

        <div className="col-sm-12">
          <table
            className="table table-bordered dataTable"
            name="question-table"
            style={{ tableLayout: "fixed" }}
          >
            <thead>
              <tr>
                <th>Standardized occupational categories</th>
                <th>ISCO-08 code</th>
                <th>
                  Corresponding grades, levels or occupations included in the national classification of public servant positions
                  <Tooltip
                    direction={'left'}
                  >
                    <p>
                      If your country has incorporated ISCO-08 and there are no differences, please write “ISCO” in each line.
                    </p>
                  </Tooltip> 
                </th>
              </tr>
            </thead>
            <tbody>
              <tr role="row">
                <td>
                  Senior Government Officials
                  <Tooltip
                    direction={'right'}
                  >
                    <p>
                      Senior government officials advise governments on policy matters, oversee the interpretation and implementation of government policies and legislation by government departments and agencies, represent their country abroad and act on its behalf, or carry out similar tasks in intergovernmental organizations. They plan, organize, direct, control and evaluate the overall activities of municipal or local, regional and national government departments, boards, agencies or commissions in accordance with legislation and policies established by government and legislative bodies. 
                      Tasks include – <br />
                      (a) advising national, state, regional or local governments and legislators on policy matters; <br />(b) advising on the preparation of government budgets, laws and regulations, including amendments;<br />
                      (c) establishing objectives for government departments or agencies in accordance with government legislation and policy; <br />
                      (d) formulating or approving and evaluating programmes and procedures for the implementation of government policies in conjunction or consultation with government, <br />
                      (e) recommending, reviewing, evaluating and approving documents, briefs and reports submitted by middle managers and senior staff members; <br />
                      (f) ensuring appropriate systems and procedures are developed and implemented to provide budgetary control; <br />
                      (g) coordinating activities with other senior government managers and officials; <br />
                      (h) making presentations to legislative and other government committees regarding policies, programmes or budgets; <br />
                      (i) overseeing the interpretation and implementation of government policies and legislation by government departments and agencies. <br />

                      Examples of the occupations classified here: <br />
                      ▪ Ambassador<br />
                      ▪ City administrator <br />
                      ▪ Civil service commissioner<br /> 
                      ▪ Consul-general<br /> ▪ Director-general (government department) <br />
                      ▪ Director-general (intergovernmental organization)<br /> 
                      ▪ Fire commissioner<br /> ▪ Inspector-general (police) <br />
                      ▪ Permanent head (government department)<br />
                      ▪ Police chief constable<br /> ▪ Police commissioner <br />
                      ▪ Secretary-general (government administration)<br /> 
                      ▪ Under-secretary (government)<br />

                    </p>
                  </Tooltip> 

                </td>
                <td>
                  1112
                </td>
                <td>
                  <TextInput
                    id="officials"
                    name="officials"
                    // className="input-1671b"
                    labelText=""
                    onChange={this.handleChange}
                    value={
                      this.props.data && this.props.data.occupational["officials"]
                        ? this.props.data.occupational["officials"]
                        : ""
                    }
                    invalid={
                      this.props.error && this.props.error.occupational &&
                      this.props.error.occupational["officials"] !== undefined
                    }
                    invalidText={
                      this.props.error && this.props.error.occupational &&
                      this.props.error.occupational["officials"]
                        ? this.props.error.occupational["officials"][0]
                        : ""
                    }
                      />
                </td>
              </tr>
              <tr>
                <td>Managing Directors and Chief Executives
                <Tooltip
                    direction={'right'}
                  >
                    <p>
                      Managing directors and chief executives formulate and review the policies, and plan, direct, coordinate and evaluate the overall activities, of enterprises or organizations (except special-interest organizations and government departments) with the support of other managers, usually within guidelines established by a board of directors or a governing body to whom they are answerable for the operations undertaken and results.<br/> Tasks include: <br/> 
                      (a) planning, directing and coordinating the general functioning of an enterprise or organization; <br/>
                      (b) reviewing the operations and results of the enterprise or organization and reporting to boards of directors and governing bodies; <br/>
                      (c) determining objectives, strategies, policies and programmes for the enterprise or organization; <br/>
                      (d) providing overall leadership and management to the enterprise or organization; <br/>
                      (e) establishing and managing budgets, controlling expenditure and ensuring the efficient use of resources;<br/> 
                      (f) authorizing material, human and financial resources to implement organizational policies and programmes;<br/> 
                      (g) monitoring and evaluating performance of the organization or enterprise against established objectives and policies; <br/>
                      (h) consulting with senior subordinate staff and reviewing recommendations and reports; <br/>
                      (i) representing the organization at official occasions and board meetings, in negotiations and at conventions, seminars, public hearings and forums;<br/>
                      (j) selecting or approving the selection of senior staff;<br/> 
                      (k) ensuring the organization complies with relevant legislation and regulations. <br/>

                      Examples of the occupations classified here: <br/>
                      ▪ Chief executive <br/>
                      ▪ Managing director <br/>
                      ▪ Regional manager
                    </p>
                  </Tooltip> 
                </td>
                <td>
                  112
                  </td>
                <td>
                  <TextInput
                    id="executives"
                    name="executives"
                    labelText=""
                    onChange={this.handleChange}
                    value={
                      this.props.data && this.props.data.occupational["executives"]
                        ? this.props.data.occupational["executives"]
                        : ""
                    }
                    invalid={
                      this.props.error && this.props.error.occupational &&
                      this.props.error.occupational["executives"] !== undefined
                    }
                    invalidText={
                      this.props.error && this.props.error.occupational &&
                      this.props.error.occupational["executives"]
                        ? this.props.error.occupational["executives"][0]
                        : ""
                    }
                  />
                </td>
              </tr>

              <tr>
                <td>
                  Business Services and Administration Managers
                  <Tooltip
                    direction={'right'}
                  >
                    <p>
                    Business services and administration managers plan, organize, direct, control and coordinate the financial, administrative, human resource, policy and planning activities of organizations, or of enterprises that provide such services to other enterprises and organizations. Tasks performed usually include: formulating and administering policy advice. and strategic and financial planning; 
                    establishing and directing operational and administrative procedures; 
                    implementing, monitoring and evaluating strategies and policies; 
                    providing advice to senior managers and board members on financial, administrative, strategic, policy, programme and legislative issues; 
                    ensuring compliance with relevant legislation, regulations and standards; 
                    controlling selection, training and performance of staff; 
                    preparing budgets and overseeing financial operations; consulting with the chief executive and with managers of other departments or sections; 
                    controlling expenditure and ensuring the efficient use of resources; 
                    representing the organization in negotiations, and at conventions, seminars, public hearings and forums.

                    Occupations in this minor group are classified into the following unit groups:<br /> 
                    1211 Finance Managers <br />
                    1212 Human Resource Managers <br />
                    1213 Policy and Planning Managers <br />
                    1219 Business Services and Administration Managers Not Elsewhere Classified
                    </p>
                  </Tooltip> 
                
                </td>

                <td>
                  121
                  <Tooltip direction="right">
                    <p>1211 <br/>
                      1212  <br/>
                      1213 <br/>
                      1219
                      </p>
                  </Tooltip>
                  </td>
                <td>
                  <TextInput
                    id="managers"
                    name="managers"
                    labelText=""
                    onChange={this.handleChange}
                    value={
                      this.props.data && this.props.data.occupational["managers"]
                        ? this.props.data.occupational["managers"]
                        : ""
                    }
                    invalid={
                      this.props.error && this.props.error.occupational &&
                      this.props.error.occupational["managers"] !== undefined
                    }
                    invalidText={
                      this.props.error && this.props.error.occupational &&
                      this.props.error.occupational["managers"]
                        ? this.props.error.occupational["managers"][0]
                        : ""
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Administration Professionals
                  <Tooltip
                    direction={'right'}
                  >
                    <p>
                    Administration professionals apply various concepts and theories related to improving the effectiveness of organizations and the individuals within the organization. Tasks performed usually include: evaluating the structure of organizations and suggesting areas of improvement; 
                    ensuring that the operational activities of an organization are consistent with the policy objectives of the organization; 
                    recruiting, training, developing and counselling personnel within an organization. <br/>

                    Occupations in this minor group are classified into the following unit groups: <br/>
                    ▪ Management and Organization Analysts <br/>
                    ▪ Policy Administration Professionals <br/>
                    ▪ Personnel and Careers Professionals <br/>
                    ▪ Training and Staff Development Professionals
                    </p>
                  </Tooltip> 
                
                </td>

                <td>
                  21, 242, 25, 26
                  <Tooltip direction="right">
                    <p>
                      Includes all minor groups and unit groups under the sub major group
                    </p>
                  </Tooltip>
                  </td>
                <td>
                  <TextInput
                    id="professionals"
                    name="professionals"
                    labelText=""
                    onChange={this.handleChange}
                    value={
                      this.props.data && this.props.data.occupational["professionals"]
                        ? this.props.data.occupational["professionals"]
                        : ""
                    }
                    invalid={
                      this.props.error && this.props.error.occupational &&
                      this.props.error.occupational["professionals"] !== undefined
                    }
                    invalidText={
                      this.props.error && this.props.error.occupational &&
                      this.props.error.occupational["professionals"]
                        ? this.props.error.occupational["professionals"][0]
                        : ""
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Business and Administration Associate Professionals
                  <Tooltip
                    direction={'right'}
                  >
                    <p>
                      Business and administration associate professionals perform mostly technical tasks connected with the practical application of knowledge relating to financial accounting and transaction matters, 
                      mathematical calculations, human resource development, selling and buying financial instruments, specialized secretarial tasks, and enforcing or applying government rules. 
                      Also included are workers who provide business services such as customs clearance, conference planning, job placements, buying and selling real estate or bulk commodities, 
                      and serving as agents for performers such as athletes and artists. 
                      Competent performance in most occupations in this sub-major group requires skills at the third ISCO skill level. Tasks performed by workers in this sub-major group usually include recording and 
                      transmitting buy and sell orders for securities, stocks, bonds or other financial instruments and for foreign exchange for future or immediate delivery; submitting credit and loan 
                      applications to management with recommendations for approval or rejection; approving or rejecting applications within authorized limits ensuring that credit standards of the institution are respected; 
                      maintaining complete records of all financial transactions of an undertaking according to general bookkeeping principles, with guidance from accountants; assisting in planning and performing mathematical, statistical, actuarial, accounting and related calculations; selling and buying financial instruments. 

                      Occupations in this sub-major group are classified into the following minor groups: <br/>
                      ▪ Financial and Mathematical Associate Professionals  <br/>
                      ▪ Sales and Purchasing Agents and Brokers <br/>
                      ▪ Business Services Agents <br/>
                      ▪ Administrative and Specialized Secretaries <br/> 
                      ▪ Government Regulatory Associate Professionals
                    </p>
                  </Tooltip>                 
                </td>
                <td>
                  31, 33, 34, 35
                  <Tooltip direction="right">
                    <p>
                    Includes all minor groups and unit groups under the sub major group
                    </p>
                  </Tooltip>
                  </td>
                <td>
                  <TextInput
                    id="associate"
                    name="associate"
                    labelText=""
                    onChange={this.handleChange}
                    value={
                      this.props.data && this.props.data.occupational["associate"]
                        ? this.props.data.occupational["associate"]
                        : ""
                    }
                    invalid={
                      this.props.error && this.props.error.occupational &&
                      this.props.error.occupational["associate"] !== undefined
                    }
                    invalidText={
                      this.props.error && this.props.error.occupational &&
                      this.props.error.occupational["associate"]
                        ? this.props.error.occupational["associate"][0]
                        : ""
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>General and Keyboard Clerks
                <Tooltip
                    direction={'right'}
                  >
                    <p>
                    General and keyboard clerks record, organize, store and retrieve information and perform a wide range of clerical and administrative tasks according to established procedures. Competent performance in most occupations in this sub-major group requires skills at the second ISCO skill level. Tasks performed by workers in this sub-major group usually include: recording, preparing, sorting, classifying and filing information; sorting, opening and sending mail; preparing reports and correspondence of a routine nature; photocopying and faxing documents; operating personal computers, word processors or typewriters to record, input and process text and data; proofreading and correcting copy; preparing invoices and checking figures. 
                    Occupations in this sub-major group are classified into the following minor groups: <br/>
                    ▪ General Office Clerks <br/>
                    ▪ Secretaries (general) <br/>
                    ▪ Keyboard Operators
                    </p>
                  </Tooltip>                 

                </td>

                <td>
                  41
                  <Tooltip direction="right">
                    <p>
                    4110 General Office Clerks <br/>
                    4120 Secretaries (general)<br/>
                    4131 Typists and Word Processing Operators<br/> 
                    4132 Data Entry Clerks<br/>
                    4211 Bank Tellers and Related Clerks<br/> 
                    4212 Bookmakers, Croupiers and Related Gaming Workers<br/> 
                    4213 Pawnbrokers and Money-lenders <br/>
                    4214 Debt Collectors and Related Workers<br/>
                    4221 Travel Consultants and Clerks <br/>
                    4222 Contact Centre Information Clerks <br/>
                    4223 Telephone Switchboard Operators <br/>
                    4224 Hotel Receptionists <br/>
                    4225 Inquiry Clerks <br/>
                    4226 Receptionists (general) <br/>
                    4227 Survey and Market Research Interviewers <br/>
                    4229 Client Information Workers Not Elsewhere Classified<br/>
                    4311 Accounting and Bookkeeping Clerks <br/>
                    4312 Statistical, Finance and Insurance Clerks<br/> 
                    4313 Payroll Clerks<br/>
                    4411 Library Clerks <br/>
                    4412 Mail Carriers and Sorting Clerks<br/> 
                    4413 Coding, Proof-reading and Related clerks<br/> 
                    4414 Scribes and Related Workers <br/>
                    4415 Filing and Copying Clerks <br/>
                    4416 Personnel Clerks <br/>
                    4419 Clerical Support Workers Not Elsewhere Classified<br/>
                    </p>
                  </Tooltip>
                  </td>
                <td>
                  <TextInput
                    id="general"
                    name="general"
                    labelText=""
                    onChange={this.handleChange}
                    value={
                      this.props.data && this.props.data.occupational["general"]
                        ? this.props.data.occupational["general"]
                        : ""
                    }
                    invalid={
                      this.props.error && this.props.error.occupational &&
                      this.props.error.occupational["general"] !== undefined
                    }
                    invalidText={
                      this.props.error && this.props.error.occupational &&
                      this.props.error.occupational["general"]
                        ? this.props.error.occupational["general"][0]
                        : ""
                    }
                  />
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </React.Fragment>
    ) : (
        <React.Fragment />
      );

    let seniorManagers = true ? (
      <React.Fragment>
        <br />

        <div className="col-sm-12">
          <table
            className="table table-bordered dataTable"
            name="question-table"
            style={{ tableLayout: "fixed" }}
          >
            <thead>
              <tr>
                <th></th>
                <th>Appointed</th>
                <th>Career civil servants</th>
                <th>Both appointed and career public servants (insert number of appointed and public servants if know)</th>
              </tr>
            </thead>
            <tbody>
              <tr role="row">
                <td>Senior managers</td>
                <td>
                <YesNoRadio
                    name="1:is_appointed"
                    defaultValue={
                      this.props.data && this.props.data.occupational &&
                        this.props.data.occupational.career_data[1]["is_appointed"] !==
                        undefined
                        ? this.props.data.occupational.career_data[1]["is_appointed"]
                        : null
                    }
                    _handleSelectedValue={this.handleCareerDataRadioChange}
                  />
                </td>
                <td>
                <YesNoRadio
                    name="1:is_career"
                    defaultValue={
                      this.props.data && this.props.data.occupational &&
                        this.props.data.occupational.career_data[1]["is_career"] !==
                        undefined
                        ? this.props.data.occupational.career_data[1]["is_career"]
                        : null
                    }
                    _handleSelectedValue={this.handleCareerDataRadioChange}
                  />
                </td>
                <td>
                  <div style={{display:"block"}}>
                    <div className="col-sm-12">
                      <TextInput
                        id="1:appointed"
                        name="1:appointed"
                        // className="input-1671b"
                        labelText=""
                        onChange={this.handleCareerDataChange}
                        value={
                          this.props.data && this.props.data.occupational &&
                            this.props.data.occupational.career_data[1]["appointed"]
                            ? this.props.data.occupational.career_data[1]["appointed"]
                            : ""
                        }
                        invalid={
                          this.props.error && this.props.error.occupational && this.props.error.occupational.career_data &&
                          this.props.error.occupational.career_data[1]["appointed"] !== undefined
                        }
                        invalidText={
                          this.props.error && this.props.error.occupational && this.props.error.occupational.career_data &&
                          this.props.error.occupational.career_data[1]["appointed"] !== undefined
                            ? this.props.error.occupational.career_data[1]["appointed"][0]
                            : ""
                        }
                      />
                    </div>
                    <div className="col-sm-12">
                    are appointed and 
                    </div>
                  </div>
                  <div style={{display:"block"}}>
                    <div className="col-sm-12">
                  <TextInput
                    id="1:career"
                    name="1:career"
                    // className="input-1671b"
                    labelText=""
                    onChange={this.handleCareerDataChange}
                    value={
                      this.props.data && this.props.data.occupational &&
                        this.props.data.occupational.career_data[1]["career"]
                        ? this.props.data.occupational.career_data[1]["career"]
                        : ""
                    }
                    invalid={
                      this.props.error && this.props.error.occupational && this.props.error.occupational.career_data &&
                      this.props.error.occupational.career_data[1]["career"] !== undefined
                    }
                    invalidText={
                      this.props.error && this.props.error.occupational && this.props.error.occupational.career_data &&
                      this.props.error.occupational.career_data[1]["career"] !== undefined
                        ? this.props.error.occupational.career_data[1]["career"][0]
                        : ""
                    }

                  />
                  </div>
                    <div className="col-sm-12">
                  are career public servants
                  </div>
                  </div>

                </td>
              </tr>
              <tr>
                <td>Other managers</td>
                <td>
                <YesNoRadio
                    name="0:is_appointed"
                    defaultValue={
                      this.props.data && this.props.data.occupational &&
                        this.props.data.occupational.career_data[0]["is_appointed"] !==
                        undefined
                        ? this.props.data.occupational.career_data[0]["is_appointed"]
                        : null
                    }
                    _handleSelectedValue={this.handleCareerDataRadioChange}
                  />
                </td>
                <td>
                <YesNoRadio
                    name="0:is_career"
                    defaultValue={
                      this.props.data && this.props.data.occupational &&
                        this.props.data.occupational.career_data[0]["is_career"] !==
                        undefined
                        ? this.props.data.occupational.career_data[0]["is_career"]
                        : null
                    }
                    _handleSelectedValue={this.handleCareerDataRadioChange}
                  />
                </td>
                <td>
                <div style={{display:"block"}}>
                    <div className="col-sm-12">
                  <TextInput
                    id="0:appointed"
                    name="0:appointed"
                    // className="input-1671b"
                    labelText=""
                    onChange={this.handleCareerDataChange}
                    value={
                      this.props.data && this.props.data.occupational &&
                        this.props.data.occupational.career_data[0]["appointed"]
                        ? this.props.data.occupational.career_data[0]["appointed"]
                        : ""
                    }
                    invalid={
                      this.props.error && this.props.error.occupational && this.props.error.occupational.career_data &&
                      this.props.error.occupational.career_data[0]["appointed"] !== undefined
                    }
                    invalidText={
                      this.props.error && this.props.error.occupational && this.props.error.occupational.career_data &&
                      this.props.error.occupational.career_data[0]["appointed"] !== undefined
                        ? this.props.error.occupational.career_data[0]["appointed"][0]
                        : ""
                    }
                  /> 
                    </div>
                    <div className="col-sm-12">
                  are appointed and 
                  </div>
                  </div>
                  <div style={{display:"block"}}>
                    <div className="col-sm-12">
                  <TextInput
                    id="0:career"
                    name="0:career"
                    // className="input-1671b"
                    labelText=""
                    onChange={this.handleCareerDataChange}
                    value={
                      this.props.data && this.props.data.occupational &&
                        this.props.data.occupational.career_data[0]["career"]
                        ? this.props.data.occupational.career_data[0]["career"]
                        : ""
                    }
                    invalid={
                      this.props.error && this.props.error.occupational && this.props.error.occupational.career_data &&
                      this.props.error.occupational.career_data[0]["career"] !== undefined
                    }
                    invalidText={
                      this.props.error && this.props.error.occupational && this.props.error.occupational.career_data &&
                      this.props.error.occupational.career_data[0]["career"] !== undefined
                        ? this.props.error.occupational.career_data[0]["career"][0]
                        : ""
                    }
                  />
                                      </div>
                    <div className="col-sm-12">
 
                  are career public servants
                  </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </React.Fragment>
    ) : (
        <React.Fragment />
      );
    
    let appointedTable = (
    <React.Fragment>
      <div className="col-sm-12">
        <table 
          className="table table-bordered dataTable"
          name="appointed-table"
        >
          <tbody>
            <tr>
              <td>
                Senior Government Officials  (code 1112) are appointed 
              </td>
              <td>
              <YesNoRadio
                    name="appointed_officials"
                    defaultValue={
                      this.props.data &&
                        this.props.data.occupational["appointed_officials"] !==
                        undefined
                        ? this.props.data.occupational["appointed_officials"]
                        : null
                    }
                    _handleSelectedValue={this.handleRadioChange}
                  />

              </td>
            </tr>
            <tr>
              <td>
              Managing Directors and Chief Executives  (code 112) are appointed 
              </td>
              <td>
              <YesNoRadio
                    name="appointed_executives"
                    defaultValue={
                      this.props.data &&
                        this.props.data.occupational["appointed_executives"] !==
                        undefined
                        ? this.props.data.occupational["appointed_executives"]
                        : null
                    }
                    _handleSelectedValue={this.handleRadioChange}
                  />

              </td>
            </tr>
            <tr>
              <td>
              Business Services and Administration Managers  (code 121) are appointed 
              </td>
              <td>
              <YesNoRadio
                    name="appointed_managers"
                    defaultValue={
                      this.props.data &&
                        this.props.data.occupational["appointed_managers"] !==
                        undefined
                        ? this.props.data.occupational["appointed_managers"]
                        : null
                    }
                    _handleSelectedValue={this.handleRadioChange}
                  />

              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </React.Fragment>
    )

    let seniorsAppointed = (
      <React.Fragment>
      <div
        className="bx--form-item bx--text-input-wrapper"
        style={{ marginBottom: "12px" }}
      >
        <RadioButtonGroup
          name={this.props.name? this.props.name:""}
          valueSelected={this.state.selected}
        >
          <RadioButton
            name={"seniors_appointed_type_all"}
            value="All"
            labelText="All"
            onClick={this.handleRadioClick}
            selected={this.state.selected === "All"}
          />
          <RadioButton
            name={"seniors_appointed_type_Some"}
            labelText="Some"
            value="Some"
            onClick={this.handleRadioClick}
            selected={this.state.selected === "Some"}
          />
        </RadioButtonGroup>
      </div>
      </React.Fragment> );

    let file_name =
    this.props.data && this.props.data.occupational && this.props.data.occupational.clasification_file !== null
      ? this.props.data.occupational.clasification_file_path.substring(
        this.props.data.occupational.clasification_file_path.lastIndexOf("/") + 1
        )
      : "";

      // console.log("filename", file_name, file_name.length)

    return (
      <React.Fragment>
        <div className="box-header with-border">
          <h3 className="box-title metadata-title">
            ISCO-08 occupational categories
          </h3>
        </div>
        <div className="box-body with-border">
          <div
            className="row"
            style={{ display: "block", marginBottom: "15px" }}
          >
            <div>1.	Drawing from your national classification of public servant positions, please specify the grades, levels or occupations included under each one of the standardized ISCO-08 occupational categories used by this indicator:</div>
            <div>
              {standardizedCategoriesTable}
            </div>
          </div>
          <div
            className="row"
            style={{ display: "block", marginBottom: "15px" }}
          >
            <div>2.	Public servant figures classified by the standardized ISCO-08 occupational categories used by this indicator may not be available for all ministries, agencies and departments constituting the public service in your country. If this is the case, please specify for which institutional units this information is not available, so we can know which ministries, agencies or departments were excluded from the figures reported for each occupational category:</div>
            <div className="col-sm-12">
              <TextArea
                id="manager_category_description"
                name="manager_category_description"
                labelText=""
                value={
                  this.props.data && this.props.data.occupational
                    ? this.props.data.occupational["manager_category_description"]
                    : ""
                }
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div
            className="row"
            style={{ display: "block", marginBottom: "15px" }}
          >
            <div>3.	Some countries may find it challenging to map their national public service classification onto these standardized ISCO-08 categories. Please feel free to share with us readily available public servant data following your national classification by uploading a file here:</div>
            <div
                className="bx--form-item bx--text-input-wrapper"
                style={{ marginBottom: "25px" }}
              >
                <FileUploader
                  buttonLabel={file_name && file_name.length > 0 ? "Replace File" : "Add File" }  
                  id="upload_file"
                  labelTitle=""
                  onChange={this.onChangeUpload}
                  onDelete={this.onDeleteUpload}
                  onDownload={this.onDownloadFile}
                  upload_status={this.props.data && this.props.data.occupational && this.props.data.occupational.clasification_file !== null ? "edit" : ""}
                  value={file_name}
                  enable_delete = {this.props.data && this.props.data.status !== 'validated'}
                />
              </div>
          </div>
          <div
            className="row"
            style={{ display: "block", marginBottom: "15px" }}
          >
            <div>4.	Are ‘Senior managers’ and ‘Other managers’ appointed by the government (or by the head of government), or are they career public servant positions obtained on the basis of merit and seniority?</div>
            <div>
              {seniorManagers}
            </div>
          </div>

          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div>In some countries, senior positions in the public service are appointed by the government (or by the head of government). If this is the case in your country, can you please tell us:</div>
            <div className="col-sm-12">
              <div>
                A)	Are senior positions appointed in all ministries, agencies and departments or only in some of them?
              </div>
              {seniorsAppointed}
            </div>
            <div className="col-sm-12">
              <div>B)	At what level(s) are positions appointed?</div>
              
              {appointedTable}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { data, error } = state.sdg1671bState;
  const { user } = state.authentication;

  return {
    user,
    data,
    error
  };
}

const connectedManagers = connect(mapStateToProps)(Managers);

export { connectedManagers as Managers };
