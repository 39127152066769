import React, { Component } from "react";

import TextInput from "carbon-components-react/lib/components/TextInput";

class NSSContactDetails extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.isValid = this.isValid.bind(this);
    this.userNeedsUpdate = this.userNeedsUpdate.bind(this);
    this.countryNeedsUpdate = this.countryNeedsUpdate.bind(this);
  }

  state = {
    data: {
      id: this.props.user ? this.props.user.id : null,
      last_name: this.props.user ? this.props.user.last_name : "",
      position: this.props.user ? this.props.user.position : "",
      institution: this.props.user ? this.props.user.institution : "",
      email: this.props.user ? this.props.user.email : "",
      email_2: this.props.user ? this.props.user.email_2 : "",
      country: this.props.country ? this.props.country : null,
      country_name: this.props.user ? this.props.user.country_name : "",
      user_type: "NSO"
    },
    error: {}
  };

  userNeedsUpdate(oldUser, newUser) {
    let needsUpdate = false;

    if (oldUser === undefined && newUser === undefined) return false;
    else if (oldUser !== undefined && newUser === undefined) return true;
    else if (
      oldUser === undefined ||
      oldUser.id !== newUser.id ||
      oldUser.last_name !== newUser.last_name ||
      oldUser.position !== newUser.position ||
      oldUser.institution !== newUser.institution ||
      oldUser.email !== newUser.email ||
      oldUser.email_2 !== newUser.email_2
    )
      needsUpdate = true;

    return needsUpdate;
  }

  countryNeedsUpdate(oldCountry, newCountry) {
    let needsUpdate = false;

    if (oldCountry === undefined && newCountry === undefined) return false;
    else if (oldCountry === undefined || oldCountry !== newCountry)
      needsUpdate = true;

    return needsUpdate;
  }

  componentDidUpdate(lastProps) {
    if (
      this.userNeedsUpdate(lastProps.user, this.props.user) ||
      this.countryNeedsUpdate(lastProps.country, this.props.country)
    ) {
      this.setState({
        data: {
          id: this.props.user ? this.props.user.id : null,
          last_name: this.props.user ? this.props.user.last_name : "",
          position: this.props.user ? this.props.user.position : "",
          institution: this.props.user ? this.props.user.institution : "",
          email: this.props.user ? this.props.user.email : "",
          email_2: this.props.user ? this.props.user.email_2 : "",
          country_name: this.props.user ? this.props.user.country_name : "",
          country: this.props.country ? this.props.country : null,
          user_type: "NSO"
        },
      });
    }
  }

  handleChange(e) {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  }

  isValid() {
    return (
      (this.state.data.last_name !== null && this.state.data.last_name !== "") ||
      (this.state.data.position !== null && this.state.data.position !== "") ||
      (this.state.data.institution !== null && this.state.data.institution !== "") ||
      (this.state.data.email !== null && this.state.data.email !== "")
    );
  }

  isEmpty() {
    return (this.state.data.last_name === null || this.state.data.last_name === "") &&
           (this.state.data.position === null || this.state.data.position === "") &&
           (this.state.data.institution === null || this.state.data.institution === "") &&
           (this.state.data.email === null || this.state.data.email === "") &&
           (this.state.data.email_2 === null || this.state.data.email_2 === "");
  }

  clearState() {
    this.setState({
      data: {
        id: null,
        last_name: "",
        email: "",
        country_name: this.props.user ? this.props.user.country_name : "",
        country: this.props.country ? this.props.country : null,
        user_type: "NSO"
      },
      error: {}
    })
  }

  render() {

    return (
      <div
        className="row"
        style={{
          paddingLeft: "1vw",
          paddingTop: "15px",
          paddingBottom: "10px",
          paddingRight: "1vw",
          display: "flex"
        }}
      >
        <div style={{ paddingRight: "1vw" }}>
          <TextInput
            value={this.state.data.last_name}
            id={"last_name" + this.props.userid}
            name="last_name"
            labelText="Name"
            onChange={this.handleChange}
            invalid={
              this.state.error && this.state.error["last_name"] !== undefined
            }
            invalidText={
              this.state.error && this.state.error["last_name"]
                ? this.state.error["last_name"][0]
                : ""
            }
          />
        </div>
        <div style={{ paddingRight: "1vw" }}>
          <TextInput
            value={this.state.data.position || ""}
            id={"position" + this.props.userid}
            name="position"
            labelText="Position"
            onChange={this.handleChange}
            invalid={
              this.state.error && this.state.error["position"] !== undefined
            }
            invalidText={
              this.state.error && this.state.error["position"]
                ? this.state.error["position"][0]
                : ""
            }
          />
        </div>
        <div style={{ paddingRight: "1vw" }}>
          <TextInput
            value={this.state.data.institution || ""}
            id={"institution" + this.props.userid}
            name="institution"
            labelText="Organization"
            onChange={this.handleChange}
            invalid={
              this.state.error && this.state.error["institution"] !== undefined
            }
            invalidText={
              this.state.error && this.state.error["institution"]
                ? this.state.error["institution"][0]
                : ""
            }
          />
        </div>
        <div style={{ paddingRight: "1vw" }}>
          <TextInput
            value={this.state.data.email}
            id={"email" + this.props.userid}
            name="email"
            labelText="E-mail 1"
            onChange={this.handleChange}
            invalid={
              this.state.error && this.state.error["email"] !== undefined
            }
            invalidText={
              this.state.error && this.state.error["email"]
                ? this.state.error["email"][0]
                : ""
            }
          />
        </div>
        <div style={{ paddingRight: "1vw" }}>
          <TextInput
            value={this.state.data.email_2 || ""}
            id={"email_2" + this.props.userid}
            name="email_2"
            labelText="E-mail 2"
            onChange={this.handleChange}
            invalid={
              this.state.error && this.state.error["email_2"] !== undefined
            }
            invalidText={
              this.state.error && this.state.error["email_2"]
                ? this.state.error["email_2"][0]
                : ""
            }
          />
        </div>
      </div>
    );
  }
}

export default NSSContactDetails;
