import React, { Component } from "react";

import { connect } from "react-redux";

import { sdg1671bActions } from "../../../../../../_actions";
import { TextInput } from "carbon-components-react";

class RawDataTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      error: props.error
    };

    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleTotalTableChange = this.handleTotalTableChange.bind(this);
    this.goToMetadata = this.goToMetadata.bind(this);
  }

  handleTableChange(e) {
    let { dispatch } = this.props;

    let target = e.target.name.split(":");

    // console.log("Target", target);

    if (parseInt(target[0]) === 2) {
      if ((this.props.data.data[0][target[1]] !== null && this.props.data.data[0][target[1]] !== "") ||
      (this.props.data.data[1][target[1]] !== null && this.props.data.data[1][target[1]] !== "")
        ) {
          return;
        }
    }

    dispatch(
      sdg1671bActions.updateDataField(parseInt(target[0]), target[1], parseInt(e.target.value))
    );

    if (parseInt(target[0]) !== 2) {
      this.updateTotalRowNumber(parseInt(target[0]), target[1], parseInt(e.target.value));
    }

    // this.updateTotalColumnNumber(parseInt(target[0]), target[1], parseInt(e.target.value));

  }

  handleTotalTableChange(e) {
    let { dispatch } = this.props;

    let target = e.target.name.split(":");

    // console.log("Target", target);

    if (parseInt(target[0]) === 2) {
      if ((this.props.data.data[0][target[1]] !== null && this.props.data.data[0][target[1]] !== "") ||
      (this.props.data.data[1][target[1]] !== null && this.props.data.data[1][target[1]] !== "")
        ) {
          return;
        }
    }

    let prefix_split = target[1].split("_");

    let prefix = prefix_split[0];

    if (prefix === null || prefix === undefined) return;

    if (this.props.data && this.props.data.data[target[0]][prefix+"_officials"] !== null 
    || this.props.data.data[target[0]][prefix+"_executives"] !== null || 
    this.props.data.data[target[0]][prefix+"_professionals"] !== null || 
    this.props.data.data[target[0]][prefix+"_associate"] !== null || 
    this.props.data && this.props.data.data[target[0]][prefix+"_general"] !== null) {
      return;
    }

    dispatch(
      sdg1671bActions.updateDataField(parseInt(target[0]), target[1], parseInt(e.target.value))
    );

    if (parseInt(target[0]) !== 2) {
      this.updateTotalRowNumber(parseInt(target[0]), target[1], parseInt(e.target.value));
    }

  }
  

  goToMetadata() {
    this.props.onPrevious();
  }

  getTotalRowNumber(row) {

    let { dispatch } = this.props;

    let val = 0;

    if (this.props.data && this.props.data.data[0][row]) {
      val += parseInt(this.props.data.data[0][row])
    }

    if (this.props.data && this.props.data.data[1][row]) {
      val += parseInt(this.props.data.data[1][row])
    }

    return val;

  }

  updateTotalRowNumber(index, row, value) {

    let { dispatch } = this.props;

    let val = isNaN(value) ? 0 : value;

    if (index === 0 && this.props.data && this.props.data.data[1][row] !== null) {
      val += parseInt(this.props.data.data[1][row])
    }

    if (index === 1 && this.props.data && this.props.data.data[0][row] !== null) {
      val += parseInt(this.props.data.data[0][row])
    }

    dispatch(
      sdg1671bActions.updateDataField(2, row, val === 0 && isNaN(value) ? value : val)
    );

  }

  checkServicePersonel() {
    const total = this.props.data && this.props.data.data[2]["total_national"] ? this.props.data.data[2]["total_national"] : 0
    const total_officials  = this.props.data && this.props.data.data[2]["total_officials"] ? this.props.data.data[2]["total_officials"] : 0
    const total_executives = this.props.data && this.props.data.data[2]["total_executives"] ? this.props.data.data[2]["total_executives"] : 0
    const total_professionals = this.props.data && this.props.data.data[2]["total_professionals"] ? this.props.data.data[2]["total_professionals"] : 0
    
    const total_associate = this.props.data && this.props.data.data[2]["total_associate"] ? this.props.data.data[2]["total_associate"] : 0
    const total_general = this.props.data && this.props.data.data[2]["total_general"] ? this.props.data.data[2]["total_general"] : 0

    if ((total_officials + total_executives + total_professionals + total_associate + total_general) > 0 && total <= (total_officials + total_executives + total_professionals + total_associate + total_general)) {
      return true;
    }
    else {
      return false;
    }
  }

  chackAgeDisaggregations() {
    const total = this.props.data && this.props.data.data[2]["total_national"] ? this.props.data.data[2]["total_national"] : 0
    const below_25 = this.props.data && this.props.data.data[2]["below_25"] ? this.props.data.data[2]["below_25"] : 0
    const age_25_34 = this.props.data && this.props.data.data[2]["age_25_34"] ? this.props.data.data[2]["age_25_34"] : 0
    const age_35_44 = this.props.data && this.props.data.data[2]["age_35_44"] ? this.props.data.data[2]["age_35_44"] : 0
    const age_45_54 = this.props.data && this.props.data.data[2]["age_45_54"] ? this.props.data.data[2]["age_45_54"] : 0
    const age_55_64 = this.props.data && this.props.data.data[2]["age_55_64"] ? this.props.data.data[2]["age_55_64"] : 0
    const age_65_above = this.props.data && this.props.data.data[2]["age_65_above"] ? this.props.data.data[2]["age_65_above"] : 0

    console.log("DATA",total,  below_25, age_25_34, age_35_44, age_45_54, age_55_64, age_65_above);

    if ((below_25 + age_25_34 + age_35_44 + age_45_54 + age_55_64 + age_65_above) > 0 && total != (below_25 + age_25_34 + age_35_44 + age_45_54 + age_55_64 + age_65_above)) {
      return true;
    }
    else {
      return false;
    }
  }


  updateTotalColumnNumber(index, row, value) {

    //console.log("update total column")

    let { dispatch } = this.props;

    let val = isNaN(value) ? 0 : value;

    let prefix = row.split("_")[0];

    if (index === 2 && (this.props.data.data[0][prefix+"_national"] !== null || this.props.data.data[1][prefix+"_national"] !== null)) return;

    if (row !== prefix+"_officials" && this.props.data.data[index][prefix+"_officials"] !== null) {
      val += parseInt(this.props.data.data[index][prefix+"_officials"])
    }

    if (row !== prefix+"_executives" && this.props.data.data[index][prefix+"_executives"] !== null) {
      val += parseInt(this.props.data.data[index][prefix+"_executives"])
    }

    if (row !== prefix+"_professionals" && this.props.data.data[index][prefix+"_professionals"] !== null) {
      val += parseInt(this.props.data.data[index][prefix+"_professionals"])
    }

    if (row !== prefix+"_associate" && this.props.data.data[index][prefix+"_associate"] !== null) {
      val += parseInt(this.props.data.data[index][prefix+"_associate"])
    }

    if (row !== prefix+"_general" && this.props.data.data[index][prefix+"_general"] !== null) {
      val += parseInt(this.props.data.data[index][prefix+"_general"])
    }


    dispatch(
      sdg1671bActions.updateDataField(index, prefix + "_national", val === 0 && isNaN(value) ? value : val)
    );

    if (index !== 2) {
      this.updateTotalRowNumber(index, prefix + "_national", val === 0 && isNaN(value) ? value : val);
    }

  }

  getTotalNumber(index, prefix) {
    let val = 0;

    if (this.props.data && this.props.data.data[index][prefix+"_officials"] !== null) {
      val += parseInt(this.props.data.data[index][prefix+"_officials"])
    }

    if (this.props.data && this.props.data.data[index][prefix+"_executives"] !== null) {
      val += parseInt(this.props.data.data[index][prefix+"_executives"])
    }

    if (this.props.data && this.props.data.data[index][prefix+"_professionals"] !== null) {
      val += parseInt(this.props.data.data[index][prefix+"_professionals"])
    }

    if (this.props.data && this.props.data.data[index][prefix+"_associate"] !== null) {
      val += parseInt(this.props.data.data[index][prefix+"_associate"])
    }

    if (this.props.data && this.props.data.data[index][prefix+"_general"] !== null) {
      val += parseInt(this.props.data.data[index][prefix+"_general"])
    }

    return val;
  }

  getProportionsColumns(index, column1, column2) {

    if (this.props.data && this.props.data.data[index][column1] !== null) {
      return (parseInt(this.props.data.data[index][column1])/parseInt(this.getTotalRowNumber(column2)))*100.00;
    }
    else return 0;
  }


  render() {
    return (
      <React.Fragment>
        <div className="box-body">
          <div className="col-sm-8">
            <h3>TABLE 1: Raw Numbers</h3>
          </div>
          <div className="col-sm-12" style={{background:"#FFC000", fontStyle:"italic"}}>
            Note 1: Orange-highlighted cells are mandatory for official reporting 
          </div>
          <div className="row">
            <div className="col-sm-12 adaptive-table">
              <fieldset
                disabled={this.props.data && (!this.props.data.id || this.props.data.id === null)}
              >
                <table className="table table-bordered table-1671b">
                  <thead>
                    <tr>
                      <th style={{ width: "65%" }} colSpan="2">
                      </th>
                      <th className="header-column-count">
                        Number of men
                      </th>
                      <th className="header-column-count">
                        Number of women
                      </th>
                      <th className="header-column-count">
                        Total
                      </th>
                    </tr>
                    <tr>
                      <th style={{background:"green"}} colSpan="5">
                        <p>Section 1: Disaggregation by occupational categories</p>
                        {this.checkServicePersonel() && <strong style={{color:"brown"}}>WARNING: Total national-level public service personnel should be greater than the sum of the occupational categories!</strong>}
                      </th>
                    </tr>
                    <tr>
                      <th style={{background:"#A9D08E"}} colSpan="5">
                        Total public service personel
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan="2">
                        Senior Managers: Senior Government Officials
                      </td>
                      <td>
                        <TextInput
                          id="0:total_officials"
                          name="0:total_officials"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["total_officials"] !== null
                              ? this.props.data.data[0]["total_officials"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:total_officials"
                          name="1:total_officials"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["total_officials"] !== null
                              ? this.props.data.data[1]["total_officials"]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:total_officials"
                          name="2:total_officials"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={ this.props.data && this.props.data.data[2]["total_officials"] !== null
                          ? this.props.data.data[2]["total_officials"]
                          : ""}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        Other Managers: Managing Directors and Chief Executives and Business Services and Administration Managers
                      </td>
                      <td>
                        <TextInput
                          id="0:total_executives"
                          name="0:total_executives"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["total_executives"] !== null
                              ? this.props.data.data[0]["total_executives"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:total_executives"
                          name="1:total_executives"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["total_executives"] !== null
                              ? this.props.data.data[1]["total_executives"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:total_executives"
                          name="2:total_executives"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={ this.props.data && this.props.data.data[2]["total_executives"] !== null
                          ? this.props.data.data[2]["total_executives"]
                          : ""}                          
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                      Administration Professionals
                      </td>
                      <td>
                        <TextInput
                          id="0:total_professionals"
                          name="0:total_professionals"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["total_professionals"] !== null
                              ? this.props.data.data[0]["total_professionals"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:total_professionals"
                          name="1:total_professionals"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["total_professionals"] !== null
                              ? this.props.data.data[1]["total_professionals"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:total_professionals"
                          name="2:total_professionals"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={ this.props.data && this.props.data.data[2]["total_professionals"] !== null
                          ? this.props.data.data[2]["total_professionals"]
                          : ""}                          
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        Business and Administration Associate Professionals
                      </td>
                      <td>
                        <TextInput
                          id="0:total_associate"
                          name="0:total_associate"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["total_associate"] !== null
                              ? this.props.data.data[0]["total_associate"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"] !== null
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:total_associate"
                          name="1:total_associate"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["total_associate"] !== null
                              ? this.props.data.data[1]["total_associate"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"] !== null
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:total_associate"
                          name="2:total_associate"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={ this.props.data && this.props.data.data[2]["total_associate"] !== null
                          ? this.props.data.data[2]["total_associate"]
                          : ""}                          
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        General and Keyboard Clerks
                      </td>
                      <td>
                        <TextInput
                          id="0:total_general"
                          name="0:total_general"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["total_general"] !== null
                              ? this.props.data.data[0]["total_general"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:total_general"
                          name="1:total_general"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["total_general"] !== null
                              ? this.props.data.data[1]["total_general"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:total_general"
                          name="2:total_general"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={ this.props.data && this.props.data.data[2]["total_general"] !== null
                          ? this.props.data.data[2]["total_general"]
                          : ""}                          
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{background:"yellow"}} colSpan="2">
                        Total national-level public service personnel (including police, education, health, front-desk administrative and all other public service personnel)
                      </td>
                      <td>
                        <TextInput
                          id="0:total_national"
                          name="0:total_national"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["total_national"] !== null
                              ? this.props.data.data[0]["total_national"]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:total_national"
                          name="1:total_national"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["total_national"] !== null
                              ? this.props.data.data[1]["total_national"]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:total_national"
                          name="2:total_national"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[2]["total_national"] !== null
                              ? this.props.data.data[2]["total_national"]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th style={{background:"#A9D08E"}} colSpan="5">
                        <p>Public service personnel in selected sectors</p>
                      </th>
                    </tr>
                    <tr style={{ width: "25%" }} >
                      <td rowSpan="6">
                        Police personnel
                      </td>
                      <td>
                        Senior Managers: Senior Government Officials
                      </td>
                      <td>
                        <TextInput
                          id="0:police_officials"
                          name="0:police_officials"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["police_officials"] !== null
                              ? this.props.data.data[0]["police_officials"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:police_officials"
                          name="1:police_officials"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["police_officials"] !== null
                              ? this.props.data.data[1]["police_officials"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:police_officials"
                          name="2:police_officials"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={ this.props.data && this.props.data.data[2]["police_officials"] !== null
                          ? this.props.data.data[2]["police_officials"]
                          : ""}                          
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Other Managers: Managing Directors and Chief Executives and Business Services and Administration Managers
                      </td>
                      <td>
                        <TextInput
                          id="0:police_executives"
                          name="0:police_executives"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["police_executives"] !== null
                              ? this.props.data.data[0]["police_executives"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:police_executives"
                          name="1:police_executives"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["police_executives"] !== null
                              ? this.props.data.data[1]["police_executives"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:police_executives"
                          name="2:police_executives"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={ this.props.data && this.props.data.data[2]["police_executives"] !== null
                          ? this.props.data.data[2]["police_executives"]
                          : ""}                          
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Administration Professionals
                      </td>
                      <td>
                        <TextInput
                          id="0:police_professionals"
                          name="0:police_professionals"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["police_professionals"] !== null
                              ? this.props.data.data[0]["police_professionals"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:police_professionals"
                          name="1:police_professionals"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["police_professionals"] !== null
                              ? this.props.data.data[1]["police_professionals"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:police_professionals"
                          name="2:police_professionals"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={ this.props.data && this.props.data.data[2]["police_professionals"] !== null
                          ? this.props.data.data[2]["police_professionals"]
                          : ""}                          
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Business and Administration Associate Professionals
                      </td>
                      <td>
                        <TextInput
                          id="0:police_associate"
                          name="0:police_associate"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["police_associate"] !== null
                              ? this.props.data.data[0]["police_associate"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:police_associate"
                          name="1:police_associate"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["police_associate"] !== null
                              ? this.props.data.data[1]["police_associate"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:police_associate"
                          name="2:police_associate"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={ this.props.data && this.props.data.data[2]["police_associate"] !== null
                          ? this.props.data.data[2]["police_associate"]
                          : ""}                          
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        General and Keyboard Clerks
                      </td>
                      <td>
                        <TextInput
                          id="0:police_general"
                          name="0:police_general"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["police_general"] !== null
                              ? this.props.data.data[0]["police_general"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:police_general"
                          name="1:police_general"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["police_general"] !== null
                              ? this.props.data.data[1]["police_general"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:police_general"
                          name="2:police_general"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={ this.props.data && this.props.data.data[2]["police_general"] !== null
                          ? this.props.data.data[2]["police_general"]
                          : ""}                          
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{background:"yellow"}}>
                        Total national-level police personnel
                      </td>
                      <td>
                        <TextInput
                          id="0:police_national"
                          name="0:police_national"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["police_national"] !== null
                              ? this.props.data.data[0]["police_national"]
                              : ""
                          }                        
                          />
                      </td>
                      <td>
                        <TextInput
                          id="1:police_national"
                          name="1:police_national"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["police_national"] !== null
                              ? this.props.data.data[1]["police_national"]
                              : ""
                          }  
                          />
                      </td>
                      <td>
                        <TextInput
                          id="2:police_national"
                          name="2:police_national"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[2]["police_national"] !== null
                              ? this.props.data.data[2]["police_national"]
                              : ""
                          }  
                        />
                      </td>
                    </tr>
                    <tr style={{ width: "25%" }} >
                      <td rowSpan="6">
                        Education personnel
                      </td>
                      <td>
                        Senior Managers: Senior Government Officials
                      </td>
                      <td>
                        <TextInput
                          id="0:education_officials"
                          name="0:education_officials"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["education_officials"] !== null
                              ? this.props.data.data[0]["education_officials"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:education_officials"
                          name="1:education_officials"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["education_officials"] !== null
                              ? this.props.data.data[1]["education_officials"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:education_officials"
                          name="2:education_officials"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={ this.props.data && this.props.data.data[2]["education_officials"] !== null
                          ? this.props.data.data[2]["education_officials"]
                          : ""}                          
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Other Managers: Managing Directors and Chief Executives and Business Services and Administration Managers
                      </td>
                      <td>
                        <TextInput
                          id="0:education_executives"
                          name="0:education_executives"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["education_executives"] !== null
                              ? this.props.data.data[0]["education_executives"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:education_executives"
                          name="1:education_executives"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["education_executives"] !== null
                              ? this.props.data.data[1]["education_executives"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:education_executives"
                          name="2:education_executives"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={ this.props.data && this.props.data.data[2]["education_executives"] !== null
                          ? this.props.data.data[2]["education_executives"]
                          : ""}                          
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Administration Professionals
                      </td>
                      <td>
                        <TextInput
                          id="0:education_professionals"
                          name="0:education_professionals"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["education_professionals"] !== null
                              ? this.props.data.data[0]["education_professionals"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:education_professionals"
                          name="1:education_professionals"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["education_professionals"] !== null
                              ? this.props.data.data[1]["education_professionals"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:education_professionals"
                          name="2:education_professionals"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={ this.props.data && this.props.data.data[2]["education_professionals"] !== null
                          ? this.props.data.data[2]["education_professionals"]
                          : ""}                          
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Business and Administration Associate Professionals
                      </td>
                      <td>
                        <TextInput
                          id="0:education_associate"
                          name="0:education_associate"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["education_associate"] !== null
                              ? this.props.data.data[0]["education_associate"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:education_associate"
                          name="1:education_associate"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["education_associate"] !== null
                              ? this.props.data.data[1]["education_associate"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:education_associate"
                          name="2:education_associate"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={ this.props.data && this.props.data.data[2]["education_associate"] !== null
                          ? this.props.data.data[2]["education_associate"]
                          : ""}                          
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        General and Keyboard Clerks
                      </td>
                      <td>
                        <TextInput
                          id="0:education_general"
                          name="0:education_general"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["education_general"] !== null
                              ? this.props.data.data[0]["education_general"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:education_general"
                          name="1:education_general"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["education_general"] !== null
                              ? this.props.data.data[1]["education_general"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:education_general"
                          name="2:education_general"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={ this.props.data && this.props.data.data[2]["education_general"] !== null
                          ? this.props.data.data[2]["education_general"]
                          : ""}                          
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{background:"yellow"}}>
                        Total national-level education personnel
                      </td>
                      <td>
                        <TextInput
                          id="0:education_national"
                          name="0:education_national"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["education_national"] !== null
                              ? this.props.data.data[0]["education_national"]
                              : ""
                          }  
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:education_national"
                          name="1:education_national"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["education_national"] !== null
                              ? this.props.data.data[1]["education_national"]
                              : ""
                          }  
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:education_national"
                          name="2:education_national"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[2]["education_national"] !== null
                              ? this.props.data.data[2]["education_national"]
                              : ""
                          }  
                        />
                      </td>
                    </tr>
                    <tr style={{ width: "25%" }} >
                      <td rowSpan="6">
                        Health personnel
                      </td>
                      <td>
                        Senior Managers: Senior Government Officials
                      </td>
                      <td>
                        <TextInput
                          id="0:health_officials"
                          name="0:health_officials"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["health_officials"] !== null
                              ? this.props.data.data[0]["health_officials"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:health_officials"
                          name="1:health_officials"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["health_officials"] !== null
                              ? this.props.data.data[1]["health_officials"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:health_officials"
                          name="2:health_officials"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={ this.props.data && this.props.data.data[2]["health_officials"] !== null
                          ? this.props.data.data[2]["health_officials"]
                          : ""}  
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Other Managers: Managing Directors and Chief Executives and Business Services and Administration Managers
                      </td>
                      <td>
                        <TextInput
                          id="0:health_executives"
                          name="0:health_executives"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["health_executives"] !== null
                              ? this.props.data.data[0]["health_executives"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:health_executives"
                          name="1:health_executives"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["health_executives"] !== null
                              ? this.props.data.data[1]["health_executives"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:health_executives"
                          name="2:health_executives"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={ this.props.data && this.props.data.data[2]["health_executives"] !== null
                          ? this.props.data.data[2]["health_executives"]
                          : ""}  
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Administration Professionals
                      </td>
                      <td>
                        <TextInput
                          id="0:health_professionals"
                          name="0:health_professionals"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["health_professionals"] !== null
                              ? this.props.data.data[0]["health_professionals"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:health_professionals"
                          name="1:health_professionals"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["health_professionals"] !== null
                              ? this.props.data.data[1]["health_professionals"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:health_professionals"
                          name="2:health_professionals"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={ this.props.data && this.props.data.data[2]["health_professionals"] !== null
                          ? this.props.data.data[2]["health_professionals"]
                          : ""} 
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Business and Administration Associate Professionals
                      </td>
                      <td>
                        <TextInput
                          id="0:health_associate"
                          name="0:health_associate"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["health_associate"] !== null
                              ? this.props.data.data[0]["health_associate"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:health_associate"
                          name="1:health_associate"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["health_associate"] !== null
                              ? this.props.data.data[1]["health_associate"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:health_associate"
                          name="2:health_associate"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={ this.props.data && this.props.data.data[2]["health_associate"] !== null
                          ? this.props.data.data[2]["health_associate"]
                          : ""} 
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        General and Keyboard Clerks
                      </td>
                      <td>
                        <TextInput
                          id="0:health_general"
                          name="0:health_general"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["health_general"] !== null
                              ? this.props.data.data[0]["health_general"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:health_general"
                          name="1:health_general"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["health_general"] !== null
                              ? this.props.data.data[1]["health_general"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:health_general"
                          name="2:health_general"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={ this.props.data && this.props.data.data[2]["health_general"] !== null
                          ? this.props.data.data[2]["health_general"]
                          : ""} 
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{background:"yellow"}}>
                        Total national-level health personnel
                      </td>
                      <td>
                        <TextInput
                          id="0:health_national"
                          name="0:health_national"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["health_national"] !== null
                              ? this.props.data.data[0]["health_national"]
                              : ""
                          }  
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:health_national"
                          name="1:health_national"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["health_national"] !== null
                              ? this.props.data.data[1]["health_national"]
                              : ""
                          }                          
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:health_national"
                          name="2:health_national"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[2]["health_national"] !== null
                              ? this.props.data.data[2]["health_national"]
                              : ""
                          }                          
                        />
                      </td>
                    </tr>
                    <tr style={{ width: "25%" }} >
                      <td rowSpan="6">
                        Front-desk administrative personnel (namely services related to registration, licenses, taxation and social benefits)
                      </td>
                      <td>
                        Senior Managers: Senior Government Officials
                      </td>
                      <td>
                        <TextInput
                          id="0:frontdesk_officials"
                          name="0:frontdesk_officials"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["frontdesk_officials"] !== null
                              ? this.props.data.data[0]["frontdesk_officials"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:frontdesk_officials"
                          name="1:frontdesk_officials"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["frontdesk_officials"] !== null
                              ? this.props.data.data[1]["frontdesk_officials"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:frontdesk_officials"
                          name="2:frontdesk_officials"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={ this.props.data && this.props.data.data[2]["frontdesk_officials"] !== null
                          ? this.props.data.data[2]["frontdesk_officials"]
                          : ""} 
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Other Managers: Managing Directors and Chief Executives and Business Services and Administration Managers
                      </td>
                      <td>
                        <TextInput
                          id="0:frontdesk_executives"
                          name="0:frontdesk_executives"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["frontdesk_executives"] !== null
                              ? this.props.data.data[0]["frontdesk_executives"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:frontdesk_executives"
                          name="1:frontdesk_executives"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["frontdesk_executives"] !== null
                              ? this.props.data.data[1]["frontdesk_executives"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:frontdesk_executives"
                          name="2:frontdesk_executives"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={ this.props.data && this.props.data.data[2]["frontdesk_executives"] !== null
                          ? this.props.data.data[2]["frontdesk_executives"]
                          : ""}
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Administration Professionals
                      </td>
                      <td>
                        <TextInput
                          id="0:frontdesk_professionals"
                          name="0:frontdesk_professionals"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["frontdesk_professionals"] !== null
                              ? this.props.data.data[0]["frontdesk_professionals"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:frontdesk_professionals"
                          name="1:frontdesk_professionals"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["frontdesk_professionals"] !== null
                              ? this.props.data.data[1]["frontdesk_professionals"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:frontdesk_professionals"
                          name="2:frontdesk_professionals"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={ this.props.data && this.props.data.data[2]["frontdesk_professionals"] !== null
                          ? this.props.data.data[2]["frontdesk_professionals"]
                          : ""}
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Business and Administration Associate Professionals
                      </td>
                      <td>
                        <TextInput
                          id="0:frontdesk_associate"
                          name="0:frontdesk_associate"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["frontdesk_associate"] !== null
                              ? this.props.data.data[0]["frontdesk_associate"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:frontdesk_associate"
                          name="1:frontdesk_associate"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["frontdesk_associate"] !== null
                              ? this.props.data.data[1]["frontdesk_associate"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:frontdesk_associate"
                          name="2:frontdesk_associate"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={ this.props.data && this.props.data.data[2]["frontdesk_associate"] !== null
                          ? this.props.data.data[2]["frontdesk_associate"]
                          : ""}
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        General and Keyboard Clerks
                      </td>
                      <td>
                        <TextInput
                          id="0:frontdesk_general"
                          name="0:frontdesk_general"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["frontdesk_general"] !== null
                              ? this.props.data.data[0]["frontdesk_general"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:frontdesk_general"
                          name="1:frontdesk_general"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["frontdesk_general"] !== null
                              ? this.props.data.data[1]["frontdesk_general"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:frontdesk_general"
                          name="2:frontdesk_general"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={ this.props.data && this.props.data.data[2]["frontdesk_general"] !== null
                          ? this.props.data.data[2]["frontdesk_general"]
                          : ""}
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{background:"yellow"}}>
                        Total national-level front-desk personnel
                      </td>
                      <td>
                        <TextInput
                          id="0:frontdesk_national"
                          name="0:frontdesk_national"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["frontdesk_national"] !== null
                              ? this.props.data.data[0]["frontdesk_national"]
                              : ""
                          }                            
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:frontdesk_national"
                          name="1:frontdesk_national"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["frontdesk_national"] !== null
                              ? this.props.data.data[1]["frontdesk_national"]
                              : ""
                          }                          
                          />
                      </td>
                      <td>
                        <TextInput
                          id="2:frontdesk_national"
                          name="2:frontdesk_national"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[2]["frontdesk_national"] !== null
                              ? this.props.data.data[2]["frontdesk_national"]
                              : ""
                          }                          
                        />
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <th style={{background:"green"}} colSpan="5">
                        <p>Section 2: Disaggregation by age groups</p>
                        {this.chackAgeDisaggregations() && <strong style={{color:"brown"}}>WARNING: Age Disaggregation total is different than total national-level public service personnel!</strong>}
                      </th>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        Below 25 years
                      </td>
                      <td>
                        <TextInput
                          id="0:below_25"
                          name="0:below_25"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["below_25"] !== null
                              ? this.props.data.data[0]["below_25"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:below_25"
                          name="1:below_25"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["below_25"] !== null
                              ? this.props.data.data[1]["below_25"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:below_25"
                          name="2:below_25"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={ this.props.data && this.props.data.data[2]["below_25"] !== null
                          ? this.props.data.data[2]["below_25"]
                          : ""}
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        Age 25-34
                      </td>
                      <td>
                        <TextInput
                          id="0:age_25_34"
                          name="0:age_25_34"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["age_25_34"] !== null
                              ? this.props.data.data[0]["age_25_34"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:age_25_34"
                          name="1:age_25_34"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["age_25_34"] !== null
                              ? this.props.data.data[1]["age_25_34"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:age_25_34"
                          name="2:age_25_34"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={ this.props.data && this.props.data.data[2]["age_25_34"] !== null
                          ? this.props.data.data[2]["age_25_34"]
                          : ""}
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        Age 35-44
                      </td>
                      <td>
                        <TextInput
                          id="0:age_35_44"
                          name="0:age_35_44"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["age_35_44"] !== null
                              ? this.props.data.data[0]["age_35_44"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:age_35_44"
                          name="1:age_35_44"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["age_35_44"] !== null
                              ? this.props.data.data[1]["age_35_44"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:age_35_44"
                          name="2:age_35_44"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={ this.props.data && this.props.data.data[2]["age_35_44"] !== null
                          ? this.props.data.data[2]["age_35_44"]
                          : ""}
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        Age 45-54
                      </td>
                      <td>
                        <TextInput
                          id="0:age_45_54"
                          name="0:age_45_54"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["age_45_54"] !== null
                              ? this.props.data.data[0]["age_45_54"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:age_45_54"
                          name="1:age_45_54"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["age_45_54"] !== null
                              ? this.props.data.data[1]["age_45_54"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:age_45_54"
                          name="2:age_45_54"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={ this.props.data && this.props.data.data[2]["age_45_54"] !== null
                          ? this.props.data.data[2]["age_45_54"]
                          : ""}
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        Age 55-64
                      </td>
                      <td>
                        <TextInput
                          id="0:age_55_64"
                          name="0:age_55_64"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["age_55_64"] !== null
                              ? this.props.data.data[0]["age_55_64"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:age_55_64"
                          name="1:age_55_64"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["age_55_64"] !== null
                              ? this.props.data.data[1]["age_55_64"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:age_55_64"
                          name="2:age_55_64"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={ this.props.data && this.props.data.data[2]["age_55_64"] !== null
                          ? this.props.data.data[2]["age_55_64"]
                          : ""}
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        Age 65 and above
                      </td>
                      <td>
                        <TextInput
                          id="0:age_65_above"
                          name="0:age_65_above"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["age_65_above"] !== null
                              ? this.props.data.data[0]["age_65_above"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:age_65_above"
                          name="1:age_65_above"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["age_65_above"] !== null
                              ? this.props.data.data[1]["age_65_above"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:age_65_above"
                          name="2:age_65_above"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={ this.props.data && this.props.data.data[2]["age_65_above"] !== null
                          ? this.props.data.data[2]["age_65_above"]
                          : ""}
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>

                  </tbody>
                  <tbody>
                    <tr>
                      <th style={{background:"green"}} colSpan="5">
                        Section 3: Disaggregation by disability status
                      </th>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        Disability
                      </td>
                      <td>
                        <TextInput
                          id="0:disability"
                          name="0:disability"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["disability"] !== null
                              ? this.props.data.data[0]["disability"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:disability"
                          name="1:disability"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["disability"] !== null
                              ? this.props.data.data[1]["disability"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:disability"
                          name="2:disability"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={ this.props.data && this.props.data.data[2]["disability"] !== null
                          ? this.props.data.data[2]["disability"]
                          : ""}
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        No Disability
                      </td>
                      <td>
                        <TextInput
                          id="0:no_disability"
                          name="0:no_disability"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["no_disability"] !== null
                              ? this.props.data.data[0]["no_disability"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:no_disability"
                          name="1:no_disability"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["no_disability"] !== null
                              ? this.props.data.data[1]["no_disability"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:no_disability"
                          name="2:no_disability"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={ this.props.data && this.props.data.data[2]["no_disability"] !== null
                          ? this.props.data.data[2]["no_disability"]
                          : ""}
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <th style={{background:"green"}} colSpan="5">
                        Section 4: Disaggregation by contextually relevant population groups
                      </th>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        Population Group A:
                      </td>
                      <td>
                        <TextInput
                          id="0:group_a_value"
                          name="0:group_a_value"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["group_a_value"] !== null
                              ? this.props.data.data[0]["group_a_value"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:group_a_value"
                          name="1:group_a_value"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["group_a_value"] !== null
                              ? this.props.data.data[1]["group_a_value"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:group_a_value"
                          name="2:group_a_value"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={ this.props.data && this.props.data.data[2]["group_a_value"] !== null
                          ? this.props.data.data[2]["group_a_value"]
                          : ""}
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        Population Group B:
                      </td>
                      <td>
                        <TextInput
                          id="0:group_b_value"
                          name="0:group_b_value"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["group_b_value"] !== null
                              ? this.props.data.data[0]["group_b_value"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:group_b_value"
                          name="1:group_b_value"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["group_b_value"] !== null
                              ? this.props.data.data[1]["group_b_value"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:group_b_value"
                          name="2:group_b_value"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={ this.props.data && this.props.data.data[2]["group_b_value"] !== null
                          ? this.props.data.data[2]["group_b_value"]
                          : ""}
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        Population Group C:
                      </td>
                      <td>
                        <TextInput
                          id="0:group_c_value"
                          name="0:group_c_value"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[0]["group_c_value"] !== null
                              ? this.props.data.data[0]["group_c_value"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="1:group_c_value"
                          name="1:group_c_value"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={
                            this.props.data && this.props.data.data[1]["group_c_value"] !== null
                              ? this.props.data.data[1]["group_c_value"]
                              : ""
                          }
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                      <td>
                        <TextInput
                          id="2:group_c_value"
                          name="2:group_c_value"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleTableChange}
                          value={ this.props.data && this.props.data.data[2]["group_c_value"] !== null
                          ? this.props.data.data[2]["group_c_value"]
                          : ""}
                          invalid={
                            this.props.error &&
                            this.props.error["nss_1_institution"] !== undefined
                          }
                          invalidText={
                            this.props.error && this.props.error["nss_1_institution"]
                              ? this.props.error["nss_1_institution"][0]
                              : ""
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </fieldset>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { data } = state.sdg1671bState;

  return {
    data
  };
}

const connectedIndicatorData = connect(mapStateToProps)(RawDataTable);

export { connectedIndicatorData as RawDataTable };
