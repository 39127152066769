import React, { Component } from "react";
import { Checkbox, TextInput } from "carbon-components-react";
import { connect } from "react-redux";

import { sdg1671bActions } from "../../../../../../_actions";
import YesNoRadio from "../../../../../common/elements/YesNoRadio";

class Disaggregation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anotherSource: this.checkAnotherSource(),
      sexDisaggregation: false,

    };

    this.handleChange = this.handleChange.bind(this);
    this.handlePopulationChange = this.handlePopulationChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleAnothersourceRadioChange = this.handleAnothersourceRadioChange.bind(
      this
    );
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
  }

  handleChange(e) {
    const { dispatch } = this.props;

    dispatch(
      sdg1671bActions.updateDisaggregationField(e.target.name, e.target.value)
    );
  }

  handlePopulationChange(e) {
    const { dispatch } = this.props;

    let target = e.target.name.split(":");

    dispatch(
      sdg1671bActions.updateDisaggregationPopulationField(parseInt(target[0]), target[1], e.target.value)
    );
  }


  handleAnothersourceRadioChange(name, value) {
    this.setState({ anotherSource: value });
  }

  handleSexDisaggregationChange(name, value) {
    this.setState({ sexDisaggregation: value });
  }

  checkAnotherSource() {
    return (
      this.props.data &&
      (this.props.data["nss_1_name"] != null ||
        this.props.data["nss_2_name"] != null ||
        this.props.data["nss_3_name"] != null)
    );
  }

  handleRadioChange(name, value) {
    const { dispatch } = this.props;

    dispatch(sdg1671bActions.updateDisaggregationField(name, value));
  }

  handleCheckBoxChange(e, name) {
    const { dispatch } = this.props;

    let value = false;
    if (e && e !== undefined) {
      value = e;
    }

    dispatch(
      sdg1671bActions.updateDisaggregationField(name, !value)
    );
  }


  render() {
    let anotherTable = true ? (
      <React.Fragment>
        <br />

        <div className="col-sm-12">
          <table
            className="table table-bordered dataTable"
            name="question-table"
            style={{ tableLayout: "fixed" }}
          >
            <thead>
              <tr>
                <th></th>
                <th>Data available</th>
              </tr>
            </thead>
            <tbody>
              <tr role="row">
                <td>Below 25 years</td>
                <td>
                  <YesNoRadio
                    name="below_25"
                    defaultValue={
                      this.props.data &&
                        this.props.data.disaggregation["below_25"] !==
                        undefined
                        ? this.props.data.disaggregation["below_25"]
                        : null
                    }
                    _handleSelectedValue={this.handleRadioChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Age 25-34</td>
                <td>
                  <YesNoRadio
                    name="age_25_34"
                    defaultValue={
                      this.props.data &&
                        this.props.data.disaggregation["age_25_34"] !==
                        undefined
                        ? this.props.data.disaggregation["age_25_34"]
                        : null
                    }
                    _handleSelectedValue={this.handleRadioChange}
                  />
                </td>
              </tr>

              <tr>
                <td>Other youth age group(s) used by your country:</td>

                <td>
                  <TextInput
                    id="other_youth_group"
                    name="other_youth_group"
                    // className="input-1671b"
                    labelText=""
                    onChange={this.handleChange}
                    value={
                      this.props.data && this.props.data.disaggregation["other_youth_group"]
                        ? this.props.data.disaggregation["other_youth_group"]
                        : ""
                    }
                    invalid={
                      this.props.error && this.props.error.disaggregation &&
                      this.props.error.disaggregation["other_youth_group"] !== undefined
                    }
                    invalidText={
                      this.props.error && this.props.error.disaggregation &&
                      this.props.error.disaggregation["other_youth_group"] !== undefined
                        ? this.props.error.disaggregation["other_youth_group"][0]
                        : ""
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </React.Fragment>
    ) : (
        <React.Fragment />
      );

    let sourceTable = true ? (
      <React.Fragment>
        <br />
        <div className="col-sm-12">
          <table
            className="table table-bordered dataTable"
            name="question-table"
            style={{ tableLayout: "fixed" }}
          >

            <tbody>
              <tr role="row">
                <td>
                  <div style={{ display: "flex" }}>
                    {/* <div className="col-sm-2">2.</div> */}
                    <div className="col-sm-12" style={{ display: "flex" }}>
                      <div>1.</div>
                      <TextInput
                        id="0:group_name"
                        name="0:group_name"
                        // className="input-1671b"
                        labelText=""
                        onChange={this.handlePopulationChange}
                        value={
                          this.props.data && this.props.data.disaggregation.population_groups[0].group_name
                            ? this.props.data.disaggregation.population_groups[0].group_name
                            : ""
                        }
                        invalid={
                          this.props.error && this.props.error.disaggregation && this.props.error.disaggregation.population_groups &&
                          this.props.error.disaggregation.population_groups[0]["group_name"] !== undefined
                        }
                        invalidText={
                          this.props.error && this.props.error.disaggregation && this.props.error.disaggregation.population_groups &&
                          this.props.error.disaggregation.population_groups[0]["group_name"] !== undefined
                            ? this.props.error.disaggregation.population_groups[0]["group_name"][0]
                            : ""
                        }
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div style={{ display: "flex" }}>
                    {/* <div className="col-sm-2">2.</div> */}
                    <div className="col-sm-12" style={{ display: "flex" }}>
                      <div>2.</div>
                      <TextInput
                        id="1:group_name"
                        name="1:group_name"
                        // className="input-1671b"
                        labelText=""
                        onChange={this.handlePopulationChange}
                        value={
                          this.props.data && this.props.data.disaggregation.population_groups[1].group_name
                            ? this.props.data.disaggregation.population_groups[1].group_name
                            : ""
                        }
                        invalid={
                          this.props.error && this.props.error.disaggregation && this.props.error.disaggregation.population_groups &&
                          this.props.error.disaggregation.population_groups[1]["group_name"] !== undefined
                        }
                        invalidText={
                          this.props.error && this.props.error.disaggregation && this.props.error.disaggregation.population_groups &&
                          this.props.error.disaggregation.population_groups[1]["group_name"] !== undefined
                            ? this.props.error.disaggregation.population_groups[1]["group_name"][0]
                            : ""
                        }

                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div style={{ display: "flex" }}>
                    {/* <div className="col-sm-2">2.</div> */}
                    <div className="col-sm-12" style={{ display: "flex" }}>
                      <div>3.</div>
                      <TextInput
                        id="2:group_name"
                        name="2:group_name"
                        // className="input-1671b"
                        labelText=""
                        onChange={this.handlePopulationChange}
                        value={
                          this.props.data && this.props.data.disaggregation.population_groups[2].group_name
                            ? this.props.data.disaggregation.population_groups[2].group_name
                            : ""
                        }
                        invalid={
                          this.props.error && this.props.error.disaggregation && this.props.error.disaggregation.population_groups &&
                          this.props.error.disaggregation.population_groups[2]["group_name"] !== undefined
                        }
                        invalidText={
                          this.props.error && this.props.error.disaggregation && this.props.error.disaggregation.population_groups &&
                          this.props.error.disaggregation.population_groups[2]["group_name"] !== undefined
                            ? this.props.error.disaggregation.population_groups[2]["group_name"][0]
                            : ""
                        }
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div style={{ display: "flex" }}>
                    {/* <div className="col-sm-2">2.</div> */}
                    <div className="col-sm-12" style={{ display: "flex" }}>
                      <div>4.</div>
                      <TextInput
                        id="3:group_name"
                        name="3:group_name"
                        // className="input-1671b"
                        labelText=""
                        onChange={this.handlePopulationChange}
                        value={
                          this.props.data && this.props.data.disaggregation.population_groups[3].group_name
                            ? this.props.data.disaggregation.population_groups[3].group_name
                            : ""
                        }
                        invalid={
                          this.props.error && this.props.error.disaggregation && this.props.error.disaggregation.population_groups &&
                          this.props.error.disaggregation.population_groups[3]["group_name"] !== undefined
                        }
                        invalidText={
                          this.props.error && this.props.error.disaggregation && this.props.error.disaggregation.population_groups &&
                          this.props.error.disaggregation.population_groups[3]["group_name"] !== undefined
                            ? this.props.error.disaggregation.population_groups[3]["group_name"][0]
                            : ""
                        }

                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div style={{ display: "flex" }}>
                    {/* <div className="col-sm-2">2.</div> */}
                    <div className="col-sm-12" style={{ display: "flex" }}>
                      <div>5.</div>
                      <TextInput
                        id="4:group_name"
                        name="4:group_name"
                        // className="input-1671b"
                        labelText=""
                        onChange={this.handlePopulationChange}
                        value={
                          this.props.data && this.props.data.disaggregation.population_groups[4].group_name
                            ? this.props.data.disaggregation.population_groups[4].group_name
                            : ""
                        }
                        invalid={
                          this.props.error && this.props.error.disaggregation && this.props.error.disaggregation.population_groups &&
                          this.props.error.disaggregation.population_groups[4]["group_name"] !== undefined
                        }
                        invalidText={
                          this.props.error && this.props.error.disaggregation && this.props.error.disaggregation.population_groups &&
                          this.props.error.disaggregation.population_groups[4]["group_name"] !== undefined
                            ? this.props.error.disaggregation.population_groups[4]["group_name"][0]
                            : ""
                        }

                      />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </React.Fragment>
    ) : (
        <React.Fragment />
      );

    return (
      <React.Fragment>
        <div className="box-header with-border">
          <h3 className="box-title metadata-title">Disaggregation</h3>
        </div>
        <div className="box-body with-border">
          <div
            className="row"
            style={{ display: "block", marginBottom: "15px" }}
          >
            <div>
              1.	Is public servant data disaggregated by sex available?
            </div>
            <div className="col-sm-4">
              <YesNoRadio
                name="sex_disaggregated"
                defaultValue={
                  this.props.data &&
                    this.props.data.disaggregation["sex_disaggregated"] !==
                    undefined
                    ? this.props.data.disaggregation["sex_disaggregated"]
                    : null
                }
                _handleSelectedValue={this.handleRadioChange}
              />
            </div>
            {
              this.props.data &&
              this.props.data.disaggregation["sex_disaggregated"] && <React.Fragment>
                <div className="col-sm-6">
                  <TextInput
                    id="sex_disaggregated_source"
                    name="sex_disaggregated_source"
                    // className="input-1671b"
                    labelText="Source:"
                    onChange={this.handleChange}
                    value={
                      this.props.data && this.props.data.disaggregation["sex_disaggregated_source"]
                        ? this.props.data.disaggregation["sex_disaggregated_source"]
                        : ""
                    }
                    invalid={
                      this.props.error && this.props.error.disaggregation && 
                      this.props.error.disaggregation["sex_disaggregated_source"] !== undefined
                    }
                    invalidText={
                      this.props.error && this.props.error.disaggregation && 
                      this.props.error.disaggregation["sex_disaggregated_source"] !== undefined
                        ? this.props.error.disaggregation["sex_disaggregated_source"][0]
                        : ""
                    }

                  />
                </div>
                <div className="col-sm-6">
                  <TextInput
                    id="sex_disaggregated_url"
                    name="sex_disaggregated_url"
                    // className="input-1671b"
                    labelText="URL (if available):"
                    onChange={this.handleChange}
                    value={
                      this.props.data && this.props.data.disaggregation["sex_disaggregated_url"]
                        ? this.props.data.disaggregation["sex_disaggregated_url"]
                        : ""
                    }
                    invalid={
                      this.props.error && this.props.error.disaggregation && 
                      this.props.error.disaggregation["sex_disaggregated_url"] !== undefined
                    }
                    invalidText={
                      this.props.error && this.props.error.disaggregation && 
                      this.props.error.disaggregation["sex_disaggregated_url"] !== undefined
                        ? this.props.error.disaggregation["sex_disaggregated_url"][0]
                        : ""
                    }
                  />
                </div>
              </React.Fragment>
            }
          </div>
          <div className="row" style={{ display: "block", marginBottom: "15px" }}>
            <div>
              2.	Is public servant data available for the two youth age groups set by this indicator, or which other youth age groups do you use?
              {anotherTable}
            </div>
          </div>
          <div
            className="row"
            style={{ display: "block", marginBottom: "15px" }}
          >
            <div>
              3.	Is public servant data disaggregated by disability status available?
            </div>
            <div className="col-sm-4">
              <YesNoRadio
                name="disability_disaggregated"
                id="disability_disaggregated"
                defaultValue={
                  this.props.data &&
                    this.props.data.disaggregation["disability_disaggregated"] !==
                    undefined
                    ? this.props.data.disaggregation["disability_disaggregated"]
                    : null
                }
                _handleSelectedValue={this.handleRadioChange}
              />
            </div>
            {
              this.props.data &&
              this.props.data.disaggregation["disability_disaggregated"] && <React.Fragment>
                <div className="col-sm-6">
                  <TextInput
                    id="disability_disaggregated_source"
                    name="disability_disaggregated_source"
                    // className="input-1671b"
                    labelText="Source:"
                    onChange={this.handleChange}
                    value={
                      this.props.data && this.props.data.disaggregation["disability_disaggregated_source"]
                        ? this.props.data.disaggregation["disability_disaggregated_source"]
                        : ""
                    }
                    invalid={
                      this.props.error && this.props.error.disaggregation && 
                      this.props.error.disaggregation["disability_disaggregated_source"] !== undefined
                    }
                    invalidText={
                      this.props.error && this.props.error.disaggregation && 
                      this.props.error.disaggregation["disability_disaggregated_source"] !== undefined
                        ? this.props.error.disaggregation["disability_disaggregated_source"][0]
                        : ""
                    }
                  />
                </div>
                <div className="col-sm-6">
                  <TextInput
                    id="disability_disaggregated_url"
                    name="disability_disaggregated_url"
                    // className="input-1671b"
                    labelText="URL (if available):"
                    onChange={this.handleChange}
                    value={
                      this.props.data && this.props.data.disaggregation["disability_disaggregated_url"]
                        ? this.props.data.disaggregation["disability_disaggregated_url"]
                        : ""
                    }
                    invalid={
                      this.props.error && this.props.error.disaggregation && 
                      this.props.error.disaggregation["disability_disaggregated_url"] !== undefined
                    }
                    invalidText={
                      this.props.error && this.props.error.disaggregation && 
                      this.props.error.disaggregation["disability_disaggregated_url"] !== undefined
                        ? this.props.error.disaggregation["disability_disaggregated_url"][0]
                        : ""
                    }
                  />
                </div>
                <br />
                <div className="col-sm-12">
                  How was disability status assessed?
                </div>
                <div className="col-sm-6">
                  <Checkbox
                    id="disability_self_report"
                    name="disability_self_report"
                    labelText={`Self-report by employee`} 
                    onChange={() => this.handleCheckBoxChange(this.props.data.disaggregation["disability_self_report"], "disability_self_report")}
                    checked={this.props.data.disaggregation["disability_self_report"]? this.props.data.disaggregation["disability_self_report"]: false}
                  />
                </div>
                <div className="col-sm-6">
                  <Checkbox
                    id="disability_medical_certification"
                    name="disability_medical_certification"
                    labelText={`Medical certification provided by employee`}
                    onChange={() => this.handleCheckBoxChange(this.props.data.disaggregation["disability_medical_certification"], "disability_medical_certification")}
                    checked={this.props.data.disaggregation["disability_medical_certification"]? this.props.data.disaggregation["disability_medical_certification"]: false}
                    /> 
                    
                </div>
                <div className="col-sm-6">
                  <Checkbox 
                    id="disability_other"
                    name="disability_other"                  
                    labelText={`Other`} 
                    onChange={() => this.handleCheckBoxChange(this.props.data.disaggregation["disability_other"], "disability_other")}
                    checked={this.props.data.disaggregation["disability_other"]? this.props.data.disaggregation["disability_other"]: false}
                  />
                </div>
              </React.Fragment>
            }
          </div>
          <div
            className="row"
            style={{ display: "block", marginBottom: "15px" }}
          >
            <div>
              4.	Is public servant data disaggregated by nationally relevant population groups available?
            </div>
            <div className="col-sm-4">
              <YesNoRadio
                name="population_group_disaggregated"
                defaultValue={
                  this.props.data &&
                    this.props.data.disaggregation["population_group_disaggregated"] !==
                    undefined
                    ? this.props.data.disaggregation["population_group_disaggregated"]
                    : null
                }
                _handleSelectedValue={this.handleRadioChange}
              />
            </div>
            {
              this.props.data &&
              this.props.data.disaggregation["population_group_disaggregated"] && <React.Fragment>
                <div className="col-sm-6">
                  <TextInput
                    id="population_group_disaggregated_source"
                    name="population_group_disaggregated_source"
                    // className="input-1671b"
                    labelText="Source:"
                    onChange={this.handleChange}
                    value={
                      this.props.data && this.props.data.disaggregation["population_group_disaggregated_source"]
                        ? this.props.data.disaggregation["population_group_disaggregated_source"]
                        : ""
                    }
                    invalid={
                      this.props.error && this.props.error.disaggregation && 
                      this.props.error.disaggregation["population_group_disaggregated_source"] !== undefined
                    }
                    invalidText={
                      this.props.error && this.props.error.disaggregation && 
                      this.props.error.disaggregation["population_group_disaggregated_source"] !== undefined
                        ? this.props.error.disaggregation["population_group_disaggregated_source"][0]
                        : ""
                    }
                  />
                </div>
                <div className="col-sm-6">
                  <TextInput
                    id="population_group_disaggregated_url"
                    name="population_group_disaggregated_url"
                    // className="input-1671b"
                    labelText="URL (if available):"
                    onChange={this.handleChange}
                    value={
                      this.props.data && this.props.data.disaggregation["population_group_disaggregated_url"]
                        ? this.props.data.disaggregation["population_group_disaggregated_url"]
                        : ""
                    }
                    invalid={
                      this.props.error && this.props.error.disaggregation && 
                      this.props.error.disaggregation["population_group_disaggregated_url"] !== undefined
                    }
                    invalidText={
                      this.props.error && this.props.error.disaggregation && 
                      this.props.error.disaggregation["population_group_disaggregated_url"] !== undefined
                        ? this.props.error.disaggregation["population_group_disaggregated_url"][0]
                        : ""
                    }
                  />
                </div>
                <div>
                  <div>
                    For which population groups is public servant data available?
              </div>
                  {sourceTable}
                </div>
              </React.Fragment>
            }


          </div>

        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { data, error } = state.sdg1671bState;

  return {
    data,
    error,
  };
}

const connectedSource = connect(mapStateToProps)(Disaggregation);

export { connectedSource as Disaggregation };
