import React, { Component } from "react";
import { TextArea, TextInput } from "carbon-components-react";
import { connect } from "react-redux";

import { sdg1671bActions } from "../../../../../../_actions";
import YesNoRadio from "../../../../../common/elements/YesNoRadio";
import { SourceUnitData } from "./components/SourcesUnitData";

class Source extends Component {
  constructor(props) {
    super(props);

    this.state = {
      federalStructure: this.checkFederalStructure(),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleFederalStructureRadioChange = this.handleFederalStructureRadioChange.bind(
      this
    );
    this.handleSourceChange = this.handleSourceChange.bind(this);
    this.handleUnitDataRadioChange = this.handleUnitDataRadioChange.bind(this);
    this.handleUnitChange = this.handleUnitChange.bind(this);
  }

  handleChange(e) {
    const { dispatch } = this.props;

    dispatch(
      sdg1671bActions.updateSourceField(e.target.name, e.target.value)
    );
  }

  componentDidMount() {
    this.setState({federalStructure:this.checkFederalStructure()})
  }

  handleFederalStructureRadioChange(name, value) {
    const { dispatch } = this.props;

    dispatch(
      sdg1671bActions.updateSourceField(name, value)
    );


    this.setState({ federalStructure: value });
  }

  checkFederalStructure() {
    return (
      this.props.data && this.props.data.source &&
      this.props.data.source["is_federal_structure"] 
    );
  }

  handleSourceChange(e) {
    let { dispatch } = this.props;

    let target = e.target.name.split(":");

    dispatch(
      sdg1671bActions.updateSourceDataField(parseInt(target[0]), target[1], e.target.value)
    );
  }

  handleUnitChange(e) {
    let { dispatch } = this.props;

    let target = e.target.name.split(":");

    dispatch(
      sdg1671bActions.updateUnitDataField(parseInt(target[0]), target[1], e.target.value)
    );
  }  

  handleUnitDataRadioChange(name, value) {
    const { dispatch } = this.props;

    let target = name.split(":");

    dispatch(sdg1671bActions.updateUnitDataField(parseInt(target[0]), target[1], value));
  }

  render() {

    let sourceTable =  (
      <React.Fragment>
        <div >
          c)	Please specify the data sources used for the public service personnel figures reported in the next section:
          </div>
        <br />

        <div className="col-sm-12">
          <table
            className="table table-bordered dataTable"
            name="question-table"
            style={{ tableLayout: "fixed" }}
          >
            <thead>
              <tr>
                <th></th>
                <th>At central level:</th>
                <th>At sub-central level</th>

              </tr>
            </thead>
            <tbody>
              <tr role="row">
                <td>Total public service personnel</td>
                <td>
                  <div style={{ display: "flex" }}>
                    <div className="col-sm-4">Data source(s):</div>
                    <div className="col-sm-8">
                      <TextInput
                        id="0:data_source"
                        name="0:data_source"
                        // className="input-1671b"
                        labelText=""
                        onChange={this.handleSourceChange}
                        value={
                          this.props.data && 
                          this.props.data.source &&
                          this.props.data.source.source_data[0]["data_source"]
                            ? this.props.data.source.source_data[0]["data_source"]
                            : ""
                        }
                        invalid={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[0]["data_source"] !== undefined
                        }
                        invalidText={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[0]["data_source"] !== undefined
                            ? this.props.error.source.source_data[0]["data_source"][0]
                            : ""
                        }
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="col-sm-4">URL (if available):</div>
                    <div className="col-sm-8">
                      <TextInput
                        id="0:url"
                        name="0:url"
                        // className="input-1671b"
                        labelText=""
                        onChange={this.handleSourceChange}
                        value={
                          this.props.data && 
                          this.props.data.source &&
                          this.props.data.source.source_data[0]["url"]
                            ? this.props.data.source.source_data[0]["url"]
                            : ""
                        }
                        invalid={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[0]["url"] !== undefined
                        }
                        invalidText={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[0]["url"] !== undefined
                            ? this.props.error.source.source_data[0]["url"][0]
                            : ""
                        }
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div style={{ display: "flex" }}>
                    <div className="col-sm-4">Data source(s):</div>
                    <div className="col-sm-8">
                      <TextInput
                        id="5:data_source"
                        name="5:data_source"
                        // className="input-1671b"
                        labelText=""
                        onChange={this.handleSourceChange}
                        value={
                          this.props.data && 
                          this.props.data.source &&
                          this.props.data.source.source_data[5]["data_source"]
                            ? this.props.data.source.source_data[5]["data_source"]
                            : ""
                        }                        
                        invalid={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[5]["data_source"] !== undefined
                        }
                        invalidText={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[5]["data_source"] !== undefined
                            ? this.props.error.source.source_data[5]["data_source"][0]
                            : ""
                        }
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="col-sm-4">URL (if available):</div>
                    <div className="col-sm-8">
                      <TextInput
                        id="5:url"
                        name="5:url"
                        // className="input-1671b"
                        labelText=""
                        onChange={this.handleSourceChange}
                        value={
                          this.props.data && 
                          this.props.data.source &&
                          this.props.data.source.source_data[5]["url"]
                            ? this.props.data.source.source_data[5]["url"]
                            : ""
                        }
                        invalid={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[5]["url"] !== undefined
                        }
                        invalidText={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[5]["url"] !== undefined
                            ? this.props.error.source.source_data[5]["url"][0]
                            : ""
                        }

                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Police personnel</td>
                <td>
                  <div style={{ display: "flex" }}>
                    <div className="col-sm-4">Data source(s):</div>
                    <div className="col-sm-8">
                      <TextInput
                        id="1:data_source"
                        name="1:data_source"
                        // className="input-1671b"
                        labelText=""
                        onChange={this.handleSourceChange}
                        value={
                          this.props.data && 
                          this.props.data.source &&
                          this.props.data.source.source_data[1]["data_source"]
                            ? this.props.data.source.source_data[1]["data_source"]
                            : ""
                        }
                        invalid={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[1]["data_source"] !== undefined
                        }
                        invalidText={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[1]["data_source"] !== undefined
                            ? this.props.error.source.source_data[1]["data_source"][0]
                            : ""
                        }
                        
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="col-sm-4">URL (if available):</div>
                    <div className="col-sm-8">
                      <TextInput
                        id="1:url"
                        name="1:url"
                        // className="input-1671b"
                        labelText=""
                        onChange={this.handleSourceChange}
                        value={
                          this.props.data && 
                          this.props.data.source &&
                          this.props.data.source.source_data[1]["url"]
                            ? this.props.data.source.source_data[1]["url"]
                            : ""
                        }
                        invalid={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[1]["url"] !== undefined
                        }
                        invalidText={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[1]["url"] !== undefined
                            ? this.props.error.source.source_data[1]["url"][0]
                            : ""
                        }

                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div style={{ display: "flex" }}>
                    <div className="col-sm-4">Data source(s):</div>
                    <div className="col-sm-8">
                      <TextInput
                        id="6:data_source"
                        name="6:data_source"
                        // className="input-1671b"
                        labelText=""
                        onChange={this.handleSourceChange}
                        value={
                          this.props.data && 
                          this.props.data.source &&
                          this.props.data.source.source_data[6]["data_source"]
                            ? this.props.data.source.source_data[6]["data_source"]
                            : ""
                        }
                        invalid={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[6]["data_source"] !== undefined
                        }
                        invalidText={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[6]["data_source"] !== undefined
                            ? this.props.error.source.source_data[6]["data_source"][0]
                            : ""
                        }
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="col-sm-4">URL (if available):</div>
                    <div className="col-sm-8">
                      <TextInput
                        id="6:url"
                        name="6:url"
                        // className="input-1671b"
                        labelText=""
                        onChange={this.handleSourceChange}
                        value={
                          this.props.data && 
                          this.props.data.source &&
                          this.props.data.source.source_data[6]["url"]
                            ? this.props.data.source.source_data[6]["url"]
                            : ""
                        }
                        invalid={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[6]["url"] !== undefined
                        }
                        invalidText={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[6]["url"] !== undefined
                            ? this.props.error.source.source_data[6]["url"][0]
                            : ""
                        }

                      />
                    </div>
                  </div>
                </td>
              </tr>

              <tr>
                <td>Education personnel</td>

                <td>
                  <div style={{ display: "flex" }}>
                    <div className="col-sm-4">Data source(s):</div>
                    <div className="col-sm-8">
                      <TextInput
                        id="2:data_source"
                        name="2:data_source"
                        // className="input-1671b"
                        labelText=""
                        onChange={this.handleSourceChange}
                        value={
                          this.props.data && 
                          this.props.data.source &&
                          this.props.data.source.source_data[2]["data_source"]
                            ? this.props.data.source.source_data[2]["data_source"]
                            : ""
                        }
                        invalid={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[2]["data_source"] !== undefined
                        }
                        invalidText={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[2]["data_source"] !== undefined
                            ? this.props.error.source.source_data[2]["data_source"][0]
                            : ""
                        }
                        
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="col-sm-4">URL (if available):</div>
                    <div className="col-sm-8">
                      <TextInput
                        id="2:url"
                        name="2:url"
                        // className="input-1671b"
                        labelText=""
                        onChange={this.handleSourceChange}
                        value={
                          this.props.data && 
                          this.props.data.source &&
                          this.props.data.source.source_data[2]["url"]
                            ? this.props.data.source.source_data[2]["url"]
                            : ""
                        }
                        invalid={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[2]["url"] !== undefined
                        }
                        invalidText={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[2]["url"] !== undefined
                            ? this.props.error.source.source_data[2]["url"][0]
                            : ""
                        }

                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div style={{ display: "flex" }}>
                    <div className="col-sm-4">Data source(s):</div>
                    <div className="col-sm-8">
                      <TextInput
                        id="7:data_source"
                        name="7:data_source"
                        // className="input-1671b"
                        labelText=""
                        onChange={this.handleSourceChange}
                        value={
                          this.props.data && 
                          this.props.data.source &&
                          this.props.data.source.source_data[7]["data_source"]
                            ? this.props.data.source.source_data[7]["data_source"]
                            : ""
                        }
                        invalid={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[7]["data_source"] !== undefined
                        }
                        invalidText={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[7]["data_source"] !== undefined
                            ? this.props.error.source.source_data[7]["data_source"][0]
                            : ""
                        }

                      />
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="col-sm-4">URL (if available):</div>
                    <div className="col-sm-8">
                      <TextInput
                        id="7:url"
                        name="7:url"
                        // className="input-1671b"
                        labelText=""
                        onChange={this.handleSourceChange}
                        value={
                          this.props.data && 
                          this.props.data.source &&
                          this.props.data.source.source_data[7]["url"]
                            ? this.props.data.source.source_data[7]["url"]
                            : ""
                        }
                        invalid={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[7]["url"] !== undefined
                        }
                        invalidText={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[7]["url"] !== undefined
                            ? this.props.error.source.source_data[7]["url"][0]
                            : ""
                        }

                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Health personnel</td>

                <td>
                  <div style={{ display: "flex" }}>
                    <div className="col-sm-4">Data source(s):</div>
                    <div className="col-sm-8">
                      <TextInput
                        id="3:data_source"
                        name="3:data_source"
                        // className="input-1671b"
                        labelText=""
                        onChange={this.handleSourceChange}
                        value={
                          this.props.data && 
                          this.props.data.source &&
                          this.props.data.source.source_data[3]["data_source"]
                            ? this.props.data.source.source_data[3]["data_source"]
                            : ""
                        }
                        invalid={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[3]["data_source"] !== undefined
                        }
                        invalidText={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[3]["data_source"] !== undefined
                            ? this.props.error.source.source_data[3]["data_source"][0]
                            : ""
                        }

                      />
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="col-sm-4">URL (if available):</div>
                    <div className="col-sm-8">
                      <TextInput
                        id="3:url"
                        name="3:url"
                        // className="input-1671b"
                        labelText=""
                        onChange={this.handleSourceChange}
                        value={
                          this.props.data && 
                          this.props.data.source &&
                          this.props.data.source.source_data[3]["url"]
                            ? this.props.data.source.source_data[3]["url"]
                            : ""
                        }
                        invalid={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[3]["url"] !== undefined
                        }
                        invalidText={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[3]["url"] !== undefined
                            ? this.props.error.source.source_data[3]["url"][0]
                            : ""
                        }
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div style={{ display: "flex" }}>
                    <div className="col-sm-4">Data source(s):</div>
                    <div className="col-sm-8">
                      <TextInput
                        id="8:data_source"
                        name="8:data_source"
                        // className="input-1671b"
                        labelText=""
                        onChange={this.handleSourceChange}
                        value={
                          this.props.data && 
                          this.props.data.source &&
                          this.props.data.source.source_data[8]["data_source"]
                            ? this.props.data.source.source_data[8]["data_source"]
                            : ""
                        }
                        invalid={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[8]["data_source"] !== undefined
                        }
                        invalidText={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[8]["data_source"] !== undefined
                            ? this.props.error.source.source_data[8]["data_source"][0]
                            : ""
                        }

                      />
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="col-sm-4">URL (if available):</div>
                    <div className="col-sm-8">
                      <TextInput
                        id="8:url"
                        name="8:url"
                        // className="input-1671b"
                        labelText=""
                        onChange={this.handleSourceChange}
                        value={
                          this.props.data && 
                          this.props.data.source &&
                          this.props.data.source.source_data[8]["url"]
                            ? this.props.data.source.source_data[8]["url"]
                            : ""
                        }
                        invalid={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[8]["url"] !== undefined
                        }
                        invalidText={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[8]["url"] !== undefined
                            ? this.props.error.source.source_data[8]["url"][0]
                            : ""
                        }

                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Front-desk administrative personnel (examples: registration/ licenses/taxation/
                social benefits)
                </td>

                <td>
                  <div style={{ display: "flex" }}>
                    <div className="col-sm-4">Data source(s):</div>
                    <div className="col-sm-8">
                      <TextInput
                        id="4:data_source"
                        name="4:data_source"
                        // className="input-1671b"
                        labelText=""
                        onChange={this.handleSourceChange}
                        value={
                          this.props.data && 
                          this.props.data.source &&
                          this.props.data.source.source_data[4]["data_source"]
                            ? this.props.data.source.source_data[4]["data_source"]
                            : ""
                        }
                        invalid={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[4]["data_source"] !== undefined
                        }
                        invalidText={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[4]["data_source"] !== undefined
                            ? this.props.error.source.source_data[4]["data_source"][0]
                            : ""
                        }
                        
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="col-sm-4">URL (if available):</div>
                    <div className="col-sm-8">
                      <TextInput
                        id="4:url"
                        name="4:url"
                        // className="input-1671b"
                        labelText=""
                        onChange={this.handleSourceChange}
                        value={
                          this.props.data && 
                          this.props.data.source &&
                          this.props.data.source.source_data[4]["url"]
                            ? this.props.data.source.source_data[4]["url"]
                            : ""
                        }
                        invalid={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[4]["url"] !== undefined
                        }
                        invalidText={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[4]["url"] !== undefined
                            ? this.props.error.source.source_data[4]["url"][0]
                            : ""
                        }

                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div style={{ display: "flex" }}>
                    <div className="col-sm-4">Data source(s):</div>
                    <div className="col-sm-8">
                      <TextInput
                        id="9:data_source"
                        name="9:data_source"
                        // className="input-1671b"
                        labelText=""
                        onChange={this.handleSourceChange}
                        value={
                          this.props.data && 
                          this.props.data.source &&
                          this.props.data.source.source_data[9]["data_source"]
                            ? this.props.data.source.source_data[9]["data_source"]
                            : ""
                        }
                        invalid={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[9]["data_source"] !== undefined
                        }
                        invalidText={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[9]["data_source"] !== undefined
                            ? this.props.error.source.source_data[9]["data_source"][0]
                            : ""
                        }

                      />
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="col-sm-4">URL (if available):</div>
                    <div className="col-sm-8">
                      <TextInput
                        id="9:url"
                        name="9:url"
                        // className="input-1671b"
                        labelText=""
                        onChange={this.handleSourceChange}
                        value={
                          this.props.data && 
                          this.props.data.source &&
                          this.props.data.source.source_data[9]["url"]
                            ? this.props.data.source.source_data[9]["url"]
                            : ""
                        }
                        invalid={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[9]["url"] !== undefined
                        }
                        invalidText={
                          this.props.error && this.props.error.source && this.props.error.source.source_data &&
                          this.props.error.source.source_data[9]["url"] !== undefined
                            ? this.props.error.source.source_data[9]["url"][0]
                            : ""
                        }

                      />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </React.Fragment>
    ) ;

    return (
      <React.Fragment>
        <div className="box-header with-border">
          <h3 className="box-title metadata-title">Source</h3>
        </div>
        <div className="box-body with-border">
          <div
            className="row"
            style={{ display: "block", marginBottom: "15px" }}
          >
            <div>
              1.	Reference period (YYYY):
            </div>
            <div className="col-sm-4">
              <TextInput
                id="collection_year"
                name="collection_year"
                // className="input-1671b"
                style={{ width: "80px" }}
                labelText=""
                onChange={this.handleChange}
                value={
                  this.props.data && this.props.data.source["collection_year"]
                    ? this.props.data.source["collection_year"]
                    : ""
                }
                required
                invalid={
                  this.props.error && this.props.error.source &&
                  this.props.error.source["collection_year"] !== undefined
                }
                invalidText={
                  this.props.error && this.props.error.source &&
                  this.props.error.source["collection_year"]
                    ? this.props.error.source["collection_year"][0]
                    : ""
                }
                autoFocus={true}
              />
            </div>
          </div>
          <div className="row" style={{ display: "block", marginBottom: "15px" }}>
            <div>
              2.	This indicator requires reporting at two levels:
            </div>
            <div>
              <ul style={{ listStyle: "inherit", paddingLeft: "50px" }}>
                <li>
                  Public service employment in central government; and
                </li>
                <li>Public service employment in sub-central government, designated as ‘state government units’ in the 2008 SNA, and described as “institutional units whose fiscal, legislative and executive authority extends only over the individual ‘states’ into which the country as a whole may be divided. Such ‘states’ may be described by different terms in different countries. In some countries, especially small countries, individual states and state governments may not exist. However, in large countries, especially those that have federal constitutions, considerable powers and responsibilities may be assigned to state governments.”  </li>
              </ul>
            </div>
          </div>
          <div className="row" style={{ display: "block", marginBottom: "15px" }}>
            <div>
              In several countries, sub-central government corresponds to state or provincial government.
            </div>
            <div>
              <dl style={{ listStyle: "inherit", paddingLeft: "50px" }}>
                <dd>
                  <div>

                  </div>
                  <div>
                    <YesNoRadio
                      label="a)  Is there a federal structure to the public service in your country?"
                      name="is_federal_structure"
                      defaultValue={this.props.data && this.props.data.source &&
                        this.props.data.source["is_federal_structure"]}
                      _handleSelectedValue={this.handleFederalStructureRadioChange}
                    />
                  </div>
                </dd>
                {<dd>
                  <div>
                    <TextArea
                      id="sub_central_level"
                      name="sub_central_level"
                      value={
                        this.props.data && this.props.data.source["sub_central_level"]
                          ? this.props.data.source["sub_central_level"]
                          : ""
                      }
                      onChange={this.handleChange}
                      labelText="b)  What sub-central level(s) of public service exist(s) in your country?"
                      disabled={!(this.props.data && this.props.data.source &&
                        this.props.data.source["is_federal_structure"])}
                    />
                  </div>
                </dd>}
                <dd>{sourceTable}</dd>
              </dl>
            </div>
          </div>
          <div className="row" style={{ display: "block", marginBottom: "15px" }}>
            <div>
              3.	The metadata recommends that the institutional units listed below be excluded when calculating public servant figures. Please specify whether you were able to exclude these units when compiling public servant figures:
            </div>
            <div>
              <SourceUnitData />
            </div>
          </div>

        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { data, error } = state.sdg1671bState;

  //console.log("Update props", state.sdg1671bState)

  return {
    data,
    error,
  };
}

const connectedSource = connect(mapStateToProps)(Source);

export { connectedSource as Source };
