import React, { Component } from "react";
import { connect } from "react-redux";

import { sdg1633Actions } from "../../../../../../_actions";
import DisputeTypeTable from "./components/DisputeTypeTable";
import DisputeResolutionTable from "./components/DisputeResolutionTable";
import DisputeResolutionActionTable from "./components/DisputeResolutionActionTable";
class Questionnaire extends Component {
  constructor(props) {
    super(props);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  state = {};

  handleChange(e) {
    const { dispatch } = this.props;

    dispatch(
      sdg1633Actions.updateMetadataField(e.target.name, e.target.value)
    );
  }

  handleRadioChange(name, value) {
    const { dispatch } = this.props;

    dispatch(sdg1633Actions.updateMetadataField(name, value));
  }

  handleTableChange(name, value) {
    const { dispatch } = this.props;

    dispatch(sdg1633Actions.updateMetadataField(name, value));
  }

  render() {
    return (
      <React.Fragment>
        <div className="box-header with-border">
          <h3 className="box-title metadata-title">Questionnaire</h3>
        </div>
        <div className="box-body with-border">
          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div className="col-sm-12 adaptive-form">
              <label className="bx--label">
                17.	Did you include in your survey the 11 types of disputes recommended in the metadata? Please indicate which disputes were included in your survey here: 
              </label>
            </div>
          </div>
          <DisputeTypeTable data={this.props.data} onChange={this.handleTableChange} />
          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div className="col-sm-12 adaptive-form">
              <label className="bx--label">
                18.	Did you include in your survey all 9 standard forms of dispute resolution mechanisms recommended in the metadata? Please specify any deviation here:
              </label>
            </div>
          </div>
          <DisputeResolutionTable  data={this.props.data} onChange={this.handleTableChange} />
          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div className="col-sm-12 adaptive-form">
              <label className="bx--label">
              19.	Did you include in your survey all 10 reasons for not taking action to resolve the dispute that were recommended in the metadata?  Please specify any deviation here:
              </label>
            </div>
          </div>
          <DisputeResolutionActionTable  data={this.props.data} onChange={this.handleTableChange} />
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { data } = state.sdg1633State;
  console.log("updated data");
  return {
    data
  };
}

const connectedInstitutions = connect(mapStateToProps)(Questionnaire);

export { connectedInstitutions as Questionnaire };
