import React, { Component } from "react";
import YesNoRadio from "../../../../../common/elements/YesNoRadio";
import { TextArea } from "carbon-components-react";
import { connect } from "react-redux";

import { sdg1662Actions } from "../../../../../../_actions";

class ResponseScales extends Component {
  constructor(props) {
    super(props);

    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  state = {
    satisfactionAddition:
      this.props.data && this.props.data["satisfaction_scale"] !== undefined
        ? this.props.data["satisfaction_scale"]
        : true,
    attributesAddition:
      this.props.data && this.props.data["attributes_scale"] !== undefined
        ? this.props.data["attributes_scale"]
        : true,
    responseOutside:
      this.props.data &&
      this.props.data["response_outside_regular_scale"] !== undefined
        ? this.props.data["response_outside_regular_scale"]
        : true
  };

  handleRadioChange(name, value) {
    // console.log("Name:", name);
    if (name === "attributes_scale")
      this.setState({ attributesAddition: value });
    else if (name === "satisfaction_scale") {
      this.setState({ satisfactionAddition: value });
    } else {
      this.setState({ responseOutside: value });
    }

    const { dispatch } = this.props;

    dispatch(sdg1662Actions.updateMetadataField(name, value));
  }

  handleChange(e) {
    const { dispatch } = this.props;

    dispatch(sdg1662Actions.updateMetadataField(e.target.name, e.target.value));
  }

  render() {
    let attributesQuestionsAddition = !this.state.attributesAddition ? (
      <div>
        <TextArea
          name="attributes_scale_description"
          labelText="What other response scale did you use?"
          value={
            this.props.data && this.props.data["attributes_scale_description"]
              ? this.props.data["attributes_scale_description"]
              : ""
          }
          onChange={this.handleChange}
        />
        <TextArea
          name="attributes_scale_counted"
          value={
            this.props.data && this.props.data["attributes_scale_counted"] ? this.props.data["attributes_scale_counted"] : ""
          }
          onChange={this.handleChange}
          labelText="Which labels on this other scale were counted as 'positive' responses, when calculating the share of respondents who responded positively to attributes questions for a given service?"
        />
      </div>
    ) : null;

    let satisfactionQuestionsAddition = !this.state.satisfactionAddition ? (
      <div>
        <TextArea
          name="satisfaction_scale_description"
          labelText="What other response scale did you use?"
          value={
            this.props.data && this.props.data["satisfaction_scale_description"]
              ? this.props.data["satisfaction_scale_description"]
              : ""
          }
          onChange={this.handleChange}
        />
        <TextArea
          name="satisfaction_scale_counted"
          labelText="Which labels on this other scale were counted as 'positive' responses, when calculating the share of respondents who responded positively to the overall satisfaction question for a given service?"
          value={
            this.props.data && this.props.data["satisfaction_scale_counted"] ? this.props.data["satisfaction_scale_counted"] : ""
          }
          onChange={this.handleChange}
        />
      </div>
    ) : null;

    let responseOutsideAddition = !this.state.responseOutside ? (
      <div>
        <TextArea
          id="response_outside_regular_scale_description"
          name="response_outside_regular_scale_description"
          value={
            this.props.data && this.props.data["response_outside_regular_scale_description"]
              ? this.props.data["response_outside_regular_scale_description"]
              : ""
          }
          onChange={this.handleChange}
          labelText="Please specify how responses outside the regular part of the response scale (e.g. “don’t know”, “no response”, etc.) were dealt with in the calculation of the percentage of “positive” responses:"
        />
      </div>
    ) : null;

    return (
      <React.Fragment>
        <div className="box-header with-border">
          <h3 className="box-title metadata-title">Response Scales</h3>
        </div>
        <div className="box-body with-border">
          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div className="col-sm-8 adaptive-form">
              <div className="bx--form-item bx--text-input-wrapper">
                <label htmlFor="question-table" className="bx--label">
                  Did you use the specific response scales recommended in the
                  metadata for this indicator?
                </label>
                <table
                  className="table table-bordered dataTable"
                  name="question-table"
                  style={{ tableLayout: "fixed" }}
                >
                  <tbody>
                    <tr role="row">
                      <td>
                        Recommended response scale for attributes questions
                        (strongly agree, agree, disagree, strongly disagree)
                      </td>
                      <td>
                        <YesNoRadio
                          label="Used the recommended response scale?"
                          name="attributes_scale"
                          defaultValue={
                            this.props.data &&
                            this.props.data["attributes_scale"] !== undefined
                              ? this.props.data["attributes_scale"]
                              : null
                          }
                          _handleSelectedValue={this.handleRadioChange}
                        />
                        {attributesQuestionsAddition}
                      </td>
                    </tr>
                    <tr role="row">
                      <td>
                        Recommended response scale for overall satisfaction
                        questions (very satisfied, satisfied, dissatisfied, very
                        dissatisfied)
                      </td>
                      <td>
                        <YesNoRadio
                          label="Used the recommended response scale?"
                          name="satisfaction_scale"
                          _handleSelectedValue={this.handleRadioChange}
                          defaultValue={
                            this.props.data &&
                            this.props.data["satisfaction_scale"] !== undefined
                              ? this.props.data["satisfaction_scale"]
                              : null
                          }
                        />
                        {satisfactionQuestionsAddition}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div className="col-sm-8 adaptive-form">
              <YesNoRadio
                label="Were responses outside the regular part of the response scale (e.g. “don’t know”, “no response”, etc.) excluded when calculating the cumulative share of “positive” responses?"
                name="response_outside_regular_scale"
                _handleSelectedValue={this.handleRadioChange}
                defaultValue={
                  this.props.data &&
                  this.props.data["response_outside_regular_scale"] !==
                    undefined
                    ? this.props.data["response_outside_regular_scale"]
                    : null
                }
              />
              {responseOutsideAddition}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { data } = state.sdg1662State;

  return {
    data
  };
}

const connectedResponseScales = connect(mapStateToProps)(ResponseScales);

export { connectedResponseScales as ResponseScales };
