import axios from "axios";
import { userService } from ".";
import { notifyError, notifySuccess } from "../components/common/notification/NotificationHelper";

export const commonService = {
  validateIndicatorData,
  finalizeIndicatorData
};

function validateIndicatorData(validateStr, data) {
  return axios.post("/api/v2/" + validateStr, data).then(response => {
    if (validateStr.indexOf("/validate") !== -1)
    notifySuccess("Data succesfully validated!");
    else
    notifySuccess("Data unlocked for edit!");
    return response.data;
  })
}

function finalizeIndicatorData(reviewStr) {
  return axios.post("/api/v2/" + reviewStr).then(response => {
    notifySuccess("Data submitted to UNDP succesfully!")
    return response.data;
  })
}
