import React, { Component } from "react";
import YesNoRadio from "../../../../../common/elements/YesNoRadio";
import { TextArea } from "carbon-components-react";
import { connect } from "react-redux";

import { sdg1672Actions } from "../../../../../../_actions";

class ResponseScales extends Component {
  constructor(props) {
    super(props);

    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  state = {
    satisfactionAddition: true,
    attributesAddition: true,
    responseOutside: this.props.data
      ? this.props.data["response_outside_regular_scale"]
      : true
  };

  handleRadioChange(name, value) {
    if (name === "attributes-scale")
      this.setState({ attributesAddition: value });
    else if (name === "satisfaction-scale") {
      this.setState({ satisfactionAddition: value });
    } else {
      this.setState({ responseOutside: value });
    }

    const { dispatch } = this.props;

    dispatch(sdg1672Actions.updateMetadataField(name, value));
  }

  handleChange(e) {
    const { dispatch } = this.props;

    dispatch(sdg1672Actions.updateMetadataField(e.target.name, e.target.value));
  }

  render() {
    let responseOutsideAddition = !this.state.responseOutside ? (
      <div>
        <TextArea
          id="response_outside_regular_scale_description"
          name="response_outside_regular_scale_description"
          value={
            this.props.data &&
            this.props.data["response_outside_regular_scale_description"]
              ? this.props.data["response_outside_regular_scale_description"]
              : ""
          }
          onChange={this.handleChange}
          labelText="Please specify how responses outside the regular part of the response scale (e.g. “don’t know”, “no response”, etc.) were dealt with in the calculation of the percentage of “positive” responses:"
        />
      </div>
    ) : null;

    return (
      <React.Fragment>
        <div className="box-header with-border">
          <h3 className="box-title metadata-title">Response Scales</h3>
        </div>
        <div className="box-body with-border">
          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div className="col-sm-8 adaptive-form">
              <div className="bx--form-item bx--text-input-wrapper">
                <label className="bx--label">
                  20. Please specify the exact response scale used for measuring the
                  two questions
                </label>
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div className="col-sm-8 adaptive-form">
              <TextArea
                id="response_scale_national"
                name="response_scale_national"
                value={
                  this.props.data && this.props.data["response_scale_national"]
                    ? this.props.data["response_scale_national"]
                    : ""
                }
                onChange={this.handleChange}
                labelText="In the main national language:"
              />
            </div>
          </div>
          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div className="col-sm-8 adaptive-form">
              <TextArea
                id="response_scale_english"
                name="response_scale_english"
                value={
                  this.props.data && this.props.data["response_scale_english"]
                    ? this.props.data["response_scale_english"]
                    : ""
                }
                onChange={this.handleChange}
                labelText="In English:"
              />
            </div>
          </div>
          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div className="col-sm-8 adaptive-form">
              <TextArea
                id="positive_responsive"
                name="positive_responsive"
                value={
                  this.props.data && this.props.data["positive_responsive"]
                    ? this.props.data["positive_responsive"]
                    : ""
                }
                onChange={this.handleChange}
                labelText="Which responses were identified as “positive” on that scale?"
              />
            </div>
          </div>
          <div
            className="row"
            style={{ display: "flex", marginBottom: "15px" }}
          >
            <div className="col-sm-8 adaptive-form">
              <YesNoRadio
                label="21. Were responses outside the regular part of the response scale (e.g. “don’t know”, “no response”, etc.) excluded when calculating the cumulative share of “positive” responses?"
                name="response_outside_regular_scale"
                _handleSelectedValue={this.handleRadioChange}
                value={
                  this.props.data &&
                  this.props.data["response_outside_regular_scale"]
                    ? this.props.data["response_outside_regular_scale"]
                    : ""
                }
              />
              {responseOutsideAddition}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { data } = state.sdg1672State;

  return {
    data
  };
}

const connectedResponseScales = connect(mapStateToProps)(ResponseScales);

export { connectedResponseScales as ResponseScales };
