import React, { Component } from "react";

import TextInput from "carbon-components-react/lib/components/TextInput";

class UNDPContactDetails extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.isValid = this.isValid.bind(this);
    this.userNeedsUpdate = this.userNeedsUpdate.bind(this);
    this.countryNeedsUpdate = this.countryNeedsUpdate.bind(this);
  }

  state = {
    data: {
      id: this.props.user ? this.props.user.id : null,
      last_name: this.props.user ? this.props.user.last_name : "",
      email: this.props.user ? this.props.user.email : "",
      country: this.props.country ? this.props.country : "",
      user_type: "UNDP"
    },
    error: {}
  };

  userNeedsUpdate(oldUser, newUser) {
    let needsUpdate = false;

    if (oldUser === undefined && newUser === undefined) return false;
    else if (oldUser !== undefined && newUser === undefined) return true;
    else if (
      oldUser === undefined ||
      oldUser.id !== newUser.id ||
      oldUser.last_name !== newUser.last_name ||
      oldUser.position !== newUser.position ||
      oldUser.institution !== newUser.institution ||
      oldUser.email !== newUser.email ||
      oldUser.email_2 !== newUser.email_2
    )
      needsUpdate = true;

    return needsUpdate;
  }

  countryNeedsUpdate(oldCountry, newCountry) {
    let needsUpdate = false;

    if (oldCountry === undefined && newCountry === undefined) return false;
    else if (oldCountry === undefined || oldCountry !== newCountry)
      needsUpdate = true;

    return needsUpdate;
  }

  componentDidUpdate(lastProps) {
    if (
      this.userNeedsUpdate(lastProps.user, this.props.user) ||
      this.countryNeedsUpdate(lastProps.country, this.props.country)
    ) {
      this.setState({
        data: {
          id: this.props.user ? this.props.user.id : null,
          last_name: this.props.user ? this.props.user.last_name : "",
          email: this.props.user ? this.props.user.email : "",
          country: this.props.country,
          user_type: "UNDP"
        }
      });
    }
  }

  handleChange(e) {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  }

  isValid() {
    // return true;
    return (
      (this.state.data.last_name !== null && this.state.data.last_name !== "") ||
      (this.state.data.email !== null && this.state.data.email !== "")
    );
  }

  isEmpty() {
    return (this.state.data.last_name === null || this.state.data.last_name === "") &&
           (this.state.data.email === null || this.state.data.email === "");
  }

  clearState() {
    this.setState({
      data: {
        id: null,
        last_name: "",
        email: "",
        country: this.props.country ? this.props.country : "",
        user_type: "UNDP"
      },
      error: {}
    })
  }

  render() {
    return (
      <div
        className="row"
        style={{
          paddingLeft: "1vw",
          paddingTop: "15px",
          paddingBottom: "10px",
          paddingRight: "1vw",
          display: "flex"
        }}
      >
        <div style={{ paddingRight: "1vw" }}>
          <TextInput
            value={this.state.data.last_name || ""}
            id={"last_name" + this.props.userid}
            name="last_name"
            labelText="Name"
            onChange={this.handleChange}
            invalid={
              this.state.error && this.state.error["last_name"] !== undefined
            }
            invalidText={
              this.state.error && this.state.error["last_name"]
                ? this.state.error["last_name"][0]
                : ""
            }
          />
        </div>
        <div style={{ paddingRight: "1vw" }}>
          <TextInput
            value={this.state.data.email || ""}
            id={"email" + this.props.userid}
            name="email"
            labelText="E-mail"
            onChange={this.handleChange}
            invalid={
              this.state.error && this.state.error["email"] !== undefined
            }
            invalidText={
              this.state.error && this.state.error["email"]
                ? this.state.error["email"][0]
                : ""
            }
          />
        </div>
      </div>
    );
  }
}

export default UNDPContactDetails;
