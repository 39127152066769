import axios from "axios";
import { userService } from ".";

import {
  notifyError,
  notifySuccess,
} from "../components/common/notification/NotificationHelper";

export const sdg1672Service = {
  getIndicatorData,
  saveIndicatorData
};

function getIndicatorData(id) {
  return axios.get("/api/v2/sdg1672/" + id + "/").then(response => {
    return response.data;
  });
}

function saveIndicatorData(data) {
  if (data.id !== undefined && data.id !== null) {
    return axios.put("/api/v2/sdg1672/" + data.id + "/", data).then(response => {
      notifySuccess("Data submitted succesfully!");
      return response.data;
    });
  } else {
    return axios.post("/api/v2/sdg1672/", data).then(response => {
      notifySuccess("Data submitted succesfully!");
      return response.data;
    });
  }
}
