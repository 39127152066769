import React from "react";
import { Loading } from "carbon-components-react";

export default () => {
  return (
    <div>
      {/* <img
        src="/resources/img/spinner.gif"
        style={{ width: "120px", margin: "auto", display: "block" }}
        alt="Loading..."
      /> */}
      <Loading />
    </div>
  );
};
