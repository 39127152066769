export const sdg1662Constants = {
  GET_DATA_SUCCESS: "GET_DATA_SUCCESS",
  GET_DATA_FAILURE: "GET_DATA_FAILURE",
  UPDATE_DATA_SUCCESS: "UPDATE_DATA_SUCCESS",
  UPDATE_DATA_FAILURE: "UPDATE_DATA_FAILURE",
  UPDATE_DATA_FIELD: "UPDATE_DATA_FIELD",
  UPDATE_DATA_SATISFIED_FIELD: "UPDATE_DATA_SATISFIED_FIELD",
  UPDATE_METADATA_FIELD: "UPDATE_METADATA_FIELD",
  CLEAR_DATA: "CLEAR_DATA",
  SAVE_DATA_SUCCESS: "SAVE_DATA_SUCCESS",
  SAVE_DATA_FAILURE: "SAVE_DATA_FAILURE",
  UPDATE_1662_QUESTIONAIRE_FILE: "UPDATE_1662_QUESTIONAIRE_FILE",
  UPDATE_1662_IMPL_MANUAL_FILE: "UPDATE_1662_IMPL_MANUAL_FILE",
  UPDATE_1662_INT_MANUAL_FILE: "UPDATE_1662_INT_MANUAL_FILE"  
};
