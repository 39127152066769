import { userService } from "../_services";
import { history } from "../_helpers";
import { userConstants } from "../_constants/user.constants";
import { notifyError } from "../components/common/notification/NotificationHelper";

import axios from 'axios'

export const userActions = {
  login,
  undpLogin,
  undpAuth,
  logout,
  fetchUserProfile
};

function login(username, password) {
  return dispatch => {
    dispatch(request({ username }));

    userService.login(username, password).then(
      data => {
        dispatch(success(data));
        history.push("/");
      },
      error => {
        if (error.response.status === 500) {
          notifyError(
            "Server not available at the moment. Please try again later."
          );
        } else {
          console.log("Error:", error.response);
          dispatch(failure(error));
        }
      }
    );
  };
}

function undpLogin() {
  return dispatch => {
    userService.undpLogin();
  };
}

function undpAuth(code) {
  return dispatch => {
    dispatch(undp_auth({ code }));
    userService.undpAuthenticate(code).then(
      user => {
        dispatch(success(user));
        history.push("/");
      },
      error => {
        if (error.response.status === 500) {
          notifyError(
            "Server not available at the moment. Please try again later."
          );
        } else {
          localStorage.setItem("unauthorizedUndp", true);
          dispatch(undp_not_auth(error));
          history.push("/");
        }
      }
    );
  };
}

function logout() {

  userService.logout();

  localStorage.removeItem("unauthorizedUndp");
  localStorage.removeItem("token");
  axios.defaults.headers.common["Authorization"] = "";
  delete axios.defaults.headers.common["Authorization"];
  history.push("/");

  return { type: userConstants.LOGOUT };
}

function fetchUserProfile(token) {
  return dispatch => {
    userService.fetchUserProfile(token).then(
      user => {
        dispatch(success(user));
      },
      error => {
        if (error.response.status === 500) {
          notifyError(
            "Server not available at the moment. Please try again later."
          );
        } else {
          // dispatch(failure(error));
          userService.logout();
          history.push("/login");
        }
      }
    );
  };
}

function undp_auth(user) {
  return { type: userConstants.UNDP_AUTH_REQUEST, user };
}

function undp_not_auth(error) {
  return { type: userConstants.UNDP_NOT_AUTH, error };
}

function request(user) {
  return { type: userConstants.LOGIN_REQUEST, user };
}
function success(user) {
  return { type: userConstants.LOGIN_SUCCESS, user };
}
function failure(error) {
  return { type: userConstants.LOGIN_FAILURE, error };
}
