import React, { Component } from "react";

// import Header from "../common/AppHeader";
import Form from "carbon-components-react/lib/components/Form";
import Button from "carbon-components-react/lib/components/Button";
import TextInput from "carbon-components-react/lib/components/TextInput";

import "../../styles/components/login/login.css";

import { connect } from "react-redux";

import { userActions } from "../../_actions";

class NSOLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      submitted: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { username, password } = this.state;
    const { dispatch } = this.props;
    if (username && password) {
      dispatch(userActions.login(username, password));
    }
  }

  render() {
    let loginAlert = this.props.loginFailure ? (
      <div className="alert alert-danger">Wrong username or password!</div>
    ) : null;

    return (
      <div>
        {/* <Header /> */}
        <div className="content-wrapper login-content">
          <section className="content">
            <div className="login-container">
              {/* <img src="/resources/img/sdg-en-16.png" className="sdg-icon" /> */}
              <img
                src="/resources/img/cover-2.jpg"
                className="sdg-image"
                alt="cover"
              />
              <div className="login-details">
                {loginAlert}
                <label className="login-label">Login Details</label>
                <Form onSubmit={this.handleSubmit}>
                  <TextInput
                    onChange={this.handleChange}
                    name="username"
                    className="login-input"
                    labelText="Username"
                    required
                  ></TextInput>
                  <TextInput
                    onChange={this.handleChange}
                    labelText="Password"
                    name="password"
                    type="password"
                    required
                  ></TextInput>
                  <Button className="login-button" type="submit">
                    Login
                  </Button>
                </Form>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn, loginFailure } = state.authentication;
  return {
    loggingIn,
    loginFailure
  };
}

const connectedLoginPage = connect(mapStateToProps)(NSOLogin);

export { connectedLoginPage as NSOLogin };
