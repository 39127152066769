import React, { Component } from "react";

import { history } from "../../../_helpers/history";
import { Button } from "carbon-components-react";
class NavigateButton extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    history.push(this.props.to);
  }
  render() {
    return <Button onClick={this.handleClick}>{this.props.title}</Button>;
  }
}

export default NavigateButton;
