import React, { Component } from "react";

class SideNavLinkFocals extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  state = {};

  handleClick(e) {
    this.props.onClick(e);
  }
  render() {
    let elem = this.props.isActive ? (
      <li className="bx--side-nav__item">
        <a
          className="bx--side-nav__link  bx--side-nav__link--current"
          onClick={this.handleClick}
        >
          {/* ::before */}
          <div className="bx--side-nav__icon bx--side-nav__icon--small">
            <svg
              //   xmlns=
              //   width="32"
              //   height="32"
              viewBox="0 0 32 32"
              aria-hidden="true"
              style={{
                focusable: "false",
                perspectiveOrigin: "xMidYMid meet",
                willChange: "transform",
                xmlns: "http://www.w3.org/2000/svg",
                width: "26",
                height: "26"
                // viewBox: "0 0 32 32"
              }}
            >
              <path d="M16 2a14 14 0 1 0 14 14A14 14 0 0 0 16 2zm0 5a4.5 4.5 0 1 1-4.5 4.5A4.49 4.49 0 0 1 16 7zm8 17.92a11.93 11.93 0 0 1-16 0v-.58A5.2 5.2 0 0 1 13 19h6a5.21 5.21 0 0 1 5 5.31v.61z"></path>
            </svg>
          </div>
          <div className="bx--side-nav__link-text">
            <div className="bx--side-nav__link-text">{this.props.name}</div>
            <div
              className="bx--side-nav__link-text"
              style={{ fontSize: "9px" }}
            >
              {this.props.description}
            </div>
          </div>
        </a>
      </li>
    ) : (
      <li className="bx--side-nav__item">
        <a className="bx--side-nav__link" onClick={this.handleClick}>
          <div className="bx--side-nav__icon bx--side-nav__icon--small">
            <svg
              //   xmlns=
              //   width="32"
              //   height="32"
              viewBox="0 0 32 32"
              aria-hidden="true"
              style={{
                focusable: "false",
                perspectiveOrigin: "xMidYMid meet",
                willChange: "transform",
                xmlns: "http://www.w3.org/2000/svg",
                width: "26",
                height: "26"
                // viewBox: "0 0 32 32"
              }}
            >
              <path d="M16 2a14 14 0 1 0 14 14A14 14 0 0 0 16 2zm0 5a4.5 4.5 0 1 1-4.5 4.5A4.49 4.49 0 0 1 16 7zm8 17.92a11.93 11.93 0 0 1-16 0v-.58A5.2 5.2 0 0 1 13 19h6a5.21 5.21 0 0 1 5 5.31v.61z"></path>
            </svg>
          </div>
          <div className="bx--side-nav__link-text">
            <div className="bx--side-nav__link-text">{this.props.name}</div>
            <div
              className="bx--side-nav__link-text"
              style={{ fontSize: "9px" }}
            >
              {this.props.description}
            </div>
          </div>
        </a>
      </li>
    );

    return <React.Fragment>{elem}</React.Fragment>;
  }
}

export default SideNavLinkFocals;
