import React, { Component } from "react";

import { connect } from "react-redux";

import { sdg1633Actions } from "../../../../../_actions";
import { Link } from "react-router-dom";
import { RawDataTable } from "./data/RawDataTable";
import { RespondentsDisputeTable } from "./data/ResponedentsDisputeTable";
import { DisputeResolutionTable } from "./data/DisputeResolutionTable";
import { DisputeMechanismAccessTable } from "./data/DisputeMechanismAccessTable";
import { DisputeResolutionActionTable } from "./data/DisputeResolutionActionTable";

class IndicatorData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      error: props.error
    };

    this.handleTableChange = this.handleTableChange.bind(this);
    this.goToMetadata = this.goToMetadata.bind(this);
  }

  handleTableChange(name, value) {
    let { dispatch } = this.props;

    let target = name.split(":");

    // console.log("Target", target);

    dispatch(
      sdg1633Actions.updateDataField(parseInt(target[0]), target[1], value)
    );
  }

  goToMetadata() {
    this.props.onPrevious();
  }

  render() {
    let info = this.props.data && (!this.props.data.id || this.props.data.id === null) ? (
        <div className="row">
          <div className="col-sm-12" style={{ marginBottom: "15px" }}>
            * Please save <Link onClick={this.goToMetadata}>metadata</Link>
            before entering indicator data.
          </div>
        </div>
      ) : null;

    return (
      <React.Fragment>
        <div className="box-header with-border">
          <div className="col-sm-8">
            <h3>Data fields</h3>
          </div>
        </div>
        <div className="box-body">
          {info}
          <div className="row" style={{background:"lightblue"}}>
            <div className="col-sm-12">
              <h3>To calculate numerator:</h3>
            </div>
          </div>
          <div className="row" style={{background:"lightblue"}}>
            <div className="col-sm-12">
              <div style={{display:"flex"}}>a)	Number of respondents who experienced a dispute (at least once) during the past two years (Question AJ1: Count respondents who answered ‘yes’ at least once)</div>
            </div>
          </div>
          <div className="row" style={{background:"lightblue"}}>
            <div className="col-sm-12">
              <div style={{display:"flex"}}>b)	Number of respondents who experienced a dispute during the past two years who accessed a formal or informal dispute resolution mechanism (Question AJ4: Count respondents who answered ‘yes’ at least once)</div>
            </div>
          </div>
          <div className="row" style={{background:"lightblue"}}>
            <div className="col-sm-12">
              <h3>To calculate denominator:</h3>
            </div>
          </div>
          <div className="row" style={{background:"lightblue"}}>
            <div className="col-sm-12">
              <div style={{display:"flex"}}>c)	Number of respondents who experienced a dispute in the past two years but did not use a dispute resolution mechanism (Question AJ4: Count respondents who answered ‘no’ to all)</div>
            </div>
          </div>
          <div className="row" style={{background:"lightblue"}}>
            <div className="col-sm-12">
              <div style={{display:"flex"}}>d)	Number of respondents who experienced a dispute in the past two years and did not use a dispute resolution mechanism only because they chose so (i.e. voluntarily self-excluded) (Question AJ4: ‘no’ to all AND Question AJ5: 11 or 12 or 13 AND none of AJ5 21-27 = yes)</div>
            </div>
          </div>
          <div className="row" style={{background:"lightblue"}}>
            <div className="col-sm-12">
              <div style={{display:"flex"}}>e)	Number of those who experienced a dispute in the past two years (a) minus those who are voluntarily self-excluded (d) = (e)</div>
            </div>
          </div>
          <RawDataTable />
          <div className="row">
            <div className="col-sm-12">
              <h3>Additional distribution tables:</h3>
            </div>
          </div>
          <RespondentsDisputeTable />
          <DisputeResolutionTable />
          <DisputeMechanismAccessTable />
          <DisputeResolutionActionTable />
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  const { data, error } = state.sdg1633State;

  return {
    user,
    data,
    error
  };
}

const connectedIndicatorData = connect(mapStateToProps)(IndicatorData);

export { connectedIndicatorData as IndicatorData };
