import React, { Component } from "react";
import { Checkbox, TextInput } from "carbon-components-react";
import { connect } from "react-redux";

import { sdg1671cActions } from "../../../../../../_actions";
import YesNoRadio from "../../../../../common/elements/YesNoRadio";

class Disaggregation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sexDisaggregation: false,

    };

    this.handleChange = this.handleChange.bind(this);
    this.handlePopulationChange = this.handlePopulationChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleAnothersourceRadioChange = this.handleAnothersourceRadioChange.bind(
      this
    );
  }

  handleChange(e) {
    const { dispatch } = this.props;

    dispatch(
      sdg1671cActions.updateMetadataField(e.target.name, e.target.value)
    );
  }

  handlePopulationChange(e) {
    const { dispatch } = this.props;

    let target = e.target.name.split(":");

    dispatch(
      sdg1671cActions.updateDisaggregationPopulationField(parseInt(target[0]), target[1], e.target.value)
    );
  }  

  handleAnothersourceRadioChange(name, value) {
    this.setState({ anotherSource: value });
  }

  handleSexDisaggregationChange(name, value) {
    this.setState({ sexDisaggregation: value });
  }

  handleRadioChange(name, value) {
    const { dispatch } = this.props;

    dispatch(sdg1671cActions.updateMetadataField(name, value));
  }

  handleCheckBoxChange(e, name) {
    // console.log("Event", e, name);
    const { dispatch } = this.props;

    let value = false;
    if (e && e !== undefined) {
      value = e;
    }

    dispatch(
      sdg1671cActions.updateMetadataField(name, !value)
    );
  }

  render() {
    let anotherTable = true ? (
      <React.Fragment>
        <br />

        <div className="col-sm-12">
          <table
            className="table table-bordered dataTable"
            name="question-table"
            style={{ tableLayout: "fixed" }}
          >
            <thead>
              <tr>
                <th></th>
                <th>Data available for the youth age group “below 45 years”?</th>
                <th>Other youth age group(s) used by your country:</th>
              </tr>
            </thead>
            <tbody>
              <tr role="row">
                <td>Judges</td>
                <td>
                  <YesNoRadio
                    name="jud_under_45"
                    defaultValue={
                      this.props.data &&
                        this.props.data["jud_under_45"] !==
                        undefined
                        ? this.props.data["jud_under_45"]
                        : null
                    }
                    _handleSelectedValue={this.handleRadioChange}
                  />
                </td>
                <td>
                <TextInput
                    id="jud_other_youth_groups"
                    name="jud_other_youth_groups"
                    // className="input-1671b"
                    labelText=""
                    onChange={this.handleChange}
                    disabled={this.props.data && this.props.data["jud_under_45"] !== null ? this.props.data["jud_under_45"]: true}
                    value={
                      this.props.data && this.props.data["jud_other_youth_groups"]
                        ? this.props.data["jud_other_youth_groups"]
                        : ""
                    }
                    invalid={
                      this.props.error && 
                      this.props.error["jud_other_youth_groups"] !== undefined
                    }
                    invalidText={
                      this.props.error &&
                      this.props.error["jud_other_youth_groups"]
                        ? this.props.error["jud_other_youth_groups"][0]
                        : ""
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Registrars</td>
                <td>
                  <YesNoRadio
                    name="reg_under_45"
                    defaultValue={
                      this.props.data &&
                        this.props.data["reg_under_45"] !==
                        undefined
                        ? this.props.data["reg_under_45"]
                        : null
                    }
                    _handleSelectedValue={this.handleRadioChange}
                  />
                </td>
                <td>
                <TextInput
                    id="reg_other_youth_groups"
                    name="reg_other_youth_groups"
                    // className="input-1671b"
                    labelText=""
                    onChange={this.handleChange}
                    disabled={this.props.data && this.props.data["reg_under_45"] !== null ? this.props.data["reg_under_45"]: true}
                    value={
                      this.props.data && this.props.data["reg_other_youth_groups"]
                        ? this.props.data["reg_other_youth_groups"]
                        : ""
                    }
                    invalid={
                      this.props.error && 
                      this.props.error["reg_other_youth_groups"] !== undefined
                    }
                    invalidText={
                      this.props.error &&
                      this.props.error["reg_other_youth_groups"]
                        ? this.props.error["reg_other_youth_groups"][0]
                        : ""
                    }
                  />

                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </React.Fragment>
    ) : (
        <React.Fragment />
      );

    // let sourceTable = true ? (
    //   <React.Fragment>
    //     <br />

    //     <div className="col-sm-12">
    //       <table
    //         className="table table-bordered dataTable"
    //         name="question-table"
    //         style={{ tableLayout: "fixed" }}
    //       >

    //         <tbody>
    //           <tr role="row">
    //             <td>
    //               <div style={{ display: "flex" }}>
    //                 {/* <div className="col-sm-2">2.</div> */}
    //                 <div className="col-sm-12" style={{ display: "flex" }}>
    //                   <div>1.</div>
    //                   <TextInput
    //                     id="0:group_name"
    //                     name="0:group_name"
    //                     // className="input-1671b"
    //                     labelText=""
    //                     onChange={this.handlePopulationChange}
    //                     value={
    //                       // this.props.data && this.props.data.disaggregation.population_groups[0].group_name
    //                       //   ? this.props.data.disaggregation.population_groups[0].group_name
    //                       //   : 
    //                       ""
    //                     }
    //                   />
    //                 </div>
    //               </div>
    //             </td>
    //           </tr>
    //           <tr>
    //             <td>
    //               <div style={{ display: "flex" }}>
    //                 {/* <div className="col-sm-2">2.</div> */}
    //                 <div className="col-sm-12" style={{ display: "flex" }}>
    //                   <div>2.</div>
    //                   <TextInput
    //                     id="1:group_name"
    //                     name="1:group_name"
    //                     // className="input-1671b"
    //                     labelText=""
    //                     onChange={this.handlePopulationChange}
    //                     value={
    //                       // this.props.data && this.props.data.disaggregation.population_groups[1].group_name
    //                         // ? this.props.data.disaggregation.population_groups[1].group_name
    //                         // : 
    //                         ""
    //                     }
    //                   />
    //                 </div>
    //               </div>
    //             </td>
    //           </tr>
    //           <tr>
    //             <td>
    //               <div style={{ display: "flex" }}>
    //                 {/* <div className="col-sm-2">2.</div> */}
    //                 <div className="col-sm-12" style={{ display: "flex" }}>
    //                   <div>3.</div>
    //                   <TextInput
    //                     id="2:group_name"
    //                     name="2:group_name"
    //                     // className="input-1671b"
    //                     labelText=""
    //                     onChange={this.handlePopulationChange}
    //                     value={
    //                       // this.props.data && this.props.data.disaggregation.population_groups[2].group_name
    //                       //   ? this.props.data.disaggregation.population_groups[2].group_name
    //                       //   : 
    //                         ""
    //                     }
    //                   />
    //                 </div>
    //               </div>
    //             </td>
    //           </tr>
    //           <tr>
    //             <td>
    //               <div style={{ display: "flex" }}>
    //                 {/* <div className="col-sm-2">2.</div> */}
    //                 <div className="col-sm-12" style={{ display: "flex" }}>
    //                   <div>4.</div>
    //                   <TextInput
    //                     id="3:group_name"
    //                     name="3:group_name"
    //                     // className="input-1671b"
    //                     labelText=""
    //                     onChange={this.handlePopulationChange}
    //                     value={
    //                       // this.props.data && this.props.data.disaggregation.population_groups[3].group_name
    //                       //   ? this.props.data.disaggregation.population_groups[3].group_name
    //                       //   : 
    //                         ""
    //                     }
    //                   />
    //                 </div>
    //               </div>
    //             </td>
    //           </tr>
    //           <tr>
    //             <td>
    //               <div style={{ display: "flex" }}>
    //                 {/* <div className="col-sm-2">2.</div> */}
    //                 <div className="col-sm-12" style={{ display: "flex" }}>
    //                   <div>5.</div>
    //                   <TextInput
    //                     id="4:group_name"
    //                     name="4:group_name"
    //                     // className="input-1671b"
    //                     labelText=""
    //                     onChange={this.handlePopulationChange}
    //                     value={
    //                       // this.props.data && this.props.data.disaggregation.population_groups[4].group_name
    //                       //   ? this.props.data.disaggregation.population_groups[4].group_name
    //                       //   : 
    //                         ""
    //                     }
    //                   />
    //                 </div>
    //               </div>
    //             </td>
    //           </tr>
    //         </tbody>
    //       </table>
    //     </div>
    //   </React.Fragment>
    // ) : (
    //     <React.Fragment />
    //   );

    return (
      <React.Fragment>
        <div className="box-header with-border">
          <h3 className="box-title metadata-title">Disaggregation</h3>
        </div>
        <div className="box-body with-border">
          <div className="row" style={{ display: "block", marginBottom: "15px" }}>
            <div>
              1.	Is public servant data available for the two youth age groups set by this indicator, or which other youth age groups do you use?
              {anotherTable}
            </div>
          </div>
          <div
            className="row"
            style={{ display: "block", marginBottom: "15px" }}
          >
            <div>
              2. Is data on judges disaggregated by disability status available?
            </div>
            <div className="col-sm-4">
              <YesNoRadio
                name="jud_dis_status_available"
                id="jud_dis_status_available"
                defaultValue={
                  this.props.data &&
                    this.props.data["jud_dis_status_available"] !==
                    undefined
                    ? this.props.data["jud_dis_status_available"]
                    : null
                }
                _handleSelectedValue={this.handleRadioChange}
              />
            </div>
            {
              this.props.data &&
              this.props.data["jud_dis_status_available"] === true && <React.Fragment>
                <div className="col-sm-12">
                  How was disability status assessed?
                </div>
                <div className="col-sm-6">
                  <Checkbox
                    id="jud_self_report"
                    name="jud_self_report"
                    labelText={`Self-report by employee`} 
                    onChange={() => this.handleCheckBoxChange(this.props.data["jud_self_report"], "jud_self_report")}
                    checked={this.props.data["jud_self_report"]? this.props.data["jud_self_report"]: false}
                  />
                </div>
                <div className="col-sm-6">
                  <Checkbox
                    id="jud_medical_certification"
                    name="jud_medical_certification"
                    labelText={`Medical certification provided by employee`}
                    onChange={() => this.handleCheckBoxChange(this.props.data["jud_medical_certification"], "jud_medical_certification")}
                    checked={this.props.data["jud_medical_certification"]? this.props.data["jud_medical_certification"]: false}
                    /> 
                    
                </div>
                <div className="col-sm-6">
                  <Checkbox 
                    id="jud_other_assessments"
                    name="jud_other_assessments"                  
                    labelText={`Other`} 
                    onChange={() => this.handleCheckBoxChange(this.props.data["jud_other_assessments"], "jud_other_assessments")}
                    checked={this.props.data["jud_other_assessments"]? this.props.data["jud_other_assessments"]: false}
                  />
                </div>
              </React.Fragment>
            }
          </div>
          <div
            className="row"
            style={{ display: "block", marginBottom: "15px" }}
          >
            <div>
              3.	Is data on registrars disaggregated by disability status available?
            </div>
            <div className="col-sm-4">
              <YesNoRadio
                name="reg_dis_status_available"
                defaultValue={
                  this.props.data &&
                    this.props.data["reg_dis_status_available"] !==
                    undefined
                    ? this.props.data["reg_dis_status_available"]
                    : null
                }
                _handleSelectedValue={this.handleRadioChange}
              />
            </div>
            {
              this.props.data &&
              this.props.data["reg_dis_status_available"] && <React.Fragment>
                <div className="col-sm-12">
                  How was disability status assessed?
                </div>
                <div className="col-sm-6">
                  <Checkbox
                    id="reg_self_report"
                    name="reg_self_report"
                    labelText={`Self-report by employee`} 
                    onChange={() => this.handleCheckBoxChange(this.props.data["reg_self_report"], "reg_self_report")}
                    checked={this.props.data["reg_self_report"]? this.props.data["reg_self_report"]: false}
                  />
                </div>
                <div className="col-sm-6">
                  <Checkbox
                    id="reg_medical_certification"
                    name="reg_medical_certification"
                    labelText={`Medical certification provided by employee`}
                    onChange={() => this.handleCheckBoxChange(this.props.data["reg_medical_certification"], "reg_medical_certification")}
                    checked={this.props.data["reg_medical_certification"]? this.props.data["reg_medical_certification"]: false}
                    /> 
                    
                </div>
                <div className="col-sm-6">
                  <Checkbox 
                    id="reg_other_assessments"
                    name="reg_other_assessments"                  
                    labelText={`Other`} 
                    onChange={() => this.handleCheckBoxChange(this.props.data["reg_other_assessments"], "reg_other_assessments")}
                    checked={this.props.data["reg_other_assessments"]? this.props.data["reg_other_assessments"]: false}
                  />
                </div>
              </React.Fragment>
            }


          </div>
          {/* <div
            className="row"
            style={{ display: "block", marginBottom: "15px" }}
          >
            <div>
              4.	 Is data on judges disaggregated by nationally relevant population groups available?
            </div>
            <div className="col-sm-4">
            <YesNoRadio
                name="population_group_disaggregated"
                defaultValue={
                  this.props.data &&
                    this.props.data["population_group_disaggregated"] !==
                    undefined
                    ? this.props.data["population_group_disaggregated"]
                    : null
                }
                _handleSelectedValue={this.handleRadioChange}
              />
            </div>
            {
              this.props.data &&
              this.props.data["population_group_disaggregated"] && <React.Fragment>
                <div className="col-sm-6">
                  <TextInput
                    id="population_group_disaggregated_source"
                    name="population_group_disaggregated_source"
                    // className="input-1671b"
                    labelText="Source:"
                    onChange={this.handleChange}
                    value={
                      this.props.data && this.props.data["population_group_disaggregated_source"]
                        ? this.props.data["population_group_disaggregated_source"]
                        : ""
                    }
                  />
                </div>
                <div className="col-sm-6">
                  <TextInput
                    id="population_group_disaggregated_url"
                    name="population_group_disaggregated_url"
                    // className="input-1671b"
                    labelText="URL (if available):"
                    onChange={this.handleChange}
                    value={
                      this.props.data && this.props.data["population_group_disaggregated_url"]
                        ? this.props.data["population_group_disaggregated_url"]
                        : ""
                    }
                  />
                </div>
                <div>
                  <div>
                    For which population groups is public servant data available?
              </div>
                  {sourceTable}
                </div>
              </React.Fragment>
            }
          </div> */}

        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { data, error } = state.sdg1671cState;

  return {
    data,
    error,
  };
}

const connectedSource = connect(mapStateToProps)(Disaggregation);

export { connectedSource as Disaggregation };
