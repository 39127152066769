import React, { Component } from "react";
import {
  Button,
  RadioButtonGroup,
  RadioButton,
  TextInput
} from "carbon-components-react";
import axios from "axios";
import CountrySelection from "../common/CountrySelection";
import { notifyError, notifySuccess } from "../common/notification/NotificationHelper";
import Spinner from "../common/spinner/Spinner";

class NotifyUsers extends Component {
  constructor(props) {
    super(props);

    this.sendMailCredentials = this.sendMailCredentials.bind(this);
    this.handleRadioClick = this.handleRadioClick.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.onHandleEmailChange = this.onHandleEmailChange.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
  }
  state = { send: "all", resetting: false, email: null, country: null };

  sendMailCredentials() {
    let data;

    let sendOption = this.state.send;

    if (sendOption === "all") {
      data = {
        send_to_all: "true"
      };
    } else if (sendOption === "single") {
      let email = this.state.email;
      data = { email_list: [email] };
    } else {
      let country = this.state.country;
      data = { send_to_country: country };
    }


    axios.post("/api/mailcredentials/", data).then(response => {
      //console.log("PWD generated");
      if(response.status === 200)
        notifySuccess("Email notification sent!");
      else
        notifyError("Error sending email. Please try again.")  
    });
  }

  handleResetPassword() {
    this.setState({resetting: true});
    axios.get("/api/users/resetpass/").then(response => {
      //console.log("PWD generated");

      this.setState({resetting: false});

      if(response.status === 200)
        notifySuccess("Password reset finished!");
      else
        notifyError("Error resetting paswords.")  
    });    
  }

  handleRadioClick(e) {
    this.setState({ send: e.target.value });
  }

  onSelectionChanged(selectedValue) {
    this.setState({ country: selectedValue });
  }

  onHandleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  render() {
    let countrySelection =
      this.state.send === "country" ? (
        <React.Fragment>
          <div className="row">
            <div style={{ padding: "15px" }}>
              <CountrySelection onSelectionChanged={this.onSelectionChanged} />
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment />
      );

    let emailSelection =
      this.state.send === "single" ? (
        <React.Fragment>
          <div className="row">
            <div style={{ padding: "15px" }}>
              <TextInput
                id="email"
                name="email"
                // className="input-1671b"
                style={{ width: "420px" }}
                labelText="Email:"
                onChange={this.onHandleEmailChange}
              />
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment />
      );

    return (
      <div style={{ height: "calc(100vh - 163px" }}>
        <div id="content-wrapper" className="content-wrapper">
          <div style={{ marginBottom: "15px" }}>
            <div className="page-header">
              <div className="page-header-left">
                <h1 className="page-header-title">
                  Select option for sending notification to NSO focal points
                </h1>
              </div>
            </div>
            <div className="row">
              <div
                className="col-sm-12"
                style={{ textAlign: "justify", fontSize: "16px" }}
              >
                This action will send an email with username and password to
                selected NSO focal points
              </div>
            </div>
          </div>
          <div className="box">
            <div className="box-body">
              <div className="row">
                <RadioButtonGroup name="send-nso" valueSelected="all">
                  <RadioButton
                    name="all"
                    value="all"
                    labelText="Send to all NSO focal points"
                    onClick={this.handleRadioClick}
                  />
                  <RadioButton
                    name="country"
                    labelText="Send to NSO focal points in selected country"
                    value="country"
                    onClick={this.handleRadioClick}
                  />
                  <RadioButton
                    name="single"
                    value="single"
                    labelText="Send to single NSO focal point"
                    onClick={this.handleRadioClick}
                  />
                </RadioButtonGroup>
              </div>
              {countrySelection}
              {emailSelection}

              <div className="row" style={{ marginTop: "15px" }}>
                <Button
                  onClick={this.sendMailCredentials}
                  style={{ padding: "15px" }}
                >
                  Send email notification
                </Button>
              </div>
              <div className="row" style={{ marginTop: "15px" }}>
                <Button
                  onClick={this.handleResetPassword}
                  style={{ padding: "15px" }}
                  disabled={this.state.resetting}
                >
                  Reset password for NSO users (testing phase only) {this.state.resetting? <Spinner />: null}
                </Button>
              </div>              
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotifyUsers;
