import axios from "axios";
import { userService } from ".";

import {
  notifyError,
  notifySuccess,
} from "../components/common/notification/NotificationHelper";

export const sdg1662Service = {
  getIndicatorData,
  saveIndicatorData,
  validateIndicatorData,
  exportDataToExcel
};

function getIndicatorData(id) {
  return axios.get("/api/v2/sdg1662/" + id + "/").then(response => {
    return response.data;
  });
}

function saveIndicatorData(data) {
  if (data.id !== undefined && data.id !== null) {
    return axios.put("/api/v2/sdg1662/" + data.id + "/", data).then(response => {
      notifySuccess("Data submitted succesfully!");
      return response.data;
    });
  } else {
    return axios.post("/api/v2/sdg1662/", data).then(response => {
      notifySuccess("Data submitted succesfully!");
      return response.data;
    });
  }
}

function validateIndicatorData(validateStr, data) {
  return axios.post("/api/v2/" + validateStr, data).then(response => {
    return response.data;
  });
}

function exportDataToExcel() {
  return axios
    .get("/api/V2/sdg1662/export/", { responseType: "blob" })
    .then(response => {
      let blob = new Blob([response.data], {
          type: "application/xlsx; charset=utf-8"
        }),
        downloadUrl = window.URL.createObjectURL(blob),
        filename = "sdg1662_details.xlsx";

      let a = document.createElement("a");
      if (typeof a.download === "undefined") {
        window.location.href = downloadUrl;
      } else {
        a.href = downloadUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
      }
      return response.data;
    });
}
