import React, { Component } from "react";

import { connect } from "react-redux";

import "../styles/App.css";
import "react-notifications-component/dist/theme.css";

import { AppHeader } from "./common/AppHeader";
import MainRouter from "./router/MainRouter";

import { Router } from "react-router-dom";
import ReactNotifications from "react-notifications-component";

import { history } from "../_helpers";
import { userActions } from "../_actions";
import AppFooter from "./common/AppFooter";

class App extends Component {
  componentDidMount() {
    let token = localStorage.getItem("token");

    if (token) {
      const { dispatch } = this.props;
      dispatch(userActions.fetchUserProfile(token));
    }
  }
  render() {
    return (
      <Router history={history}>
        <div id="App">
          <ReactNotifications />
          <div className="app-content">
            <AppHeader />
            <MainRouter />
            <AppFooter />
          </div>
        </div>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;

  return {
    user
  };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };
