export const sdg1671bConstants = {
  GET_1671b_DATA_SUCCESS: "GET_1671b_DATA_SUCCESS",
  GET_1671b_DATA_FAILURE: "GET_1671b_DATA_FAILURE",
  UPDATE_1671b_DATA_SUCCESS: "UPDATE_1671b_DATA_SUCCESS",
  UPDATE_1671b_DATA_FAILURE: "UPDATE_1671b_DATA_FAILURE",
  UPDATE_1671b_DATA_FIELD: "UPDATE_1671b_DATA_FIELD",
  UPDATE_1671b_SOURCE_DATA_FIELD: "UPDATE_1671b_SOURCE_DATA_FIELD",
  UPDATE_1671b_UNIT_DATA_FIELD: "UPDATE_1671b_UNIT_DATA_FIELD",
  UPDATE_1671b_METADATA_FIELD: "UPDATE_1671b_METADATA_FIELD",
  UPDATE_1671b_SOURCE_FIELD: "UPDATE_1671b_SOURCE_FIELD",
  UPDATE_1671b_OCCUPATIONAL_FIELD: "UPDATE_1671b_OCCUPATIONAL_FIELD",
  UPDATE_1671b_OCCUPATIONAL_FILE: "UPDATE_1671b_OCCUPATIONAL_FILE",
  UPDATE_1671b_CAREER_DATA_FIELD: "UPDATE_1671b_CAREER_DATA_FIELD",
  UPDATE_1671b_DISAGGREGATION_FIELD: "UPDATE_1671b_DISAGGREGATION_FIELD",
  UPDATE_1671b_DISAGGREGATION_POPULATION_FIELD: "UPDATE_1671b_DISAGGREGATION_POPULATION_FIELD",
  UPDATE_1671b_DENOMINATOR_FIELD: "UPDATE_1671b_DENOMINATOR_FIELD",
  UPDATE_1671b_PROPORTIONS_FIELD: "UPDATE_1671b_PROPORTIONS_FIELD",
  UPDATE_1671b_RATIOS_FIELD: "UPDATE_1671b_RATIOS_FIELD",
  CLEAR_1671b_DATA: "CLEAR_1671b_DATA",
  SAVE_1671b_DATA_SUCCESS: "SAVE_1671b_DATA_SUCCESS",
  SAVE_1671b_DATA_FAILURE: "SAVE_1671b_DATA_FAILURE"
};
