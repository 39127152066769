import React, { Component } from "react";

import "../../styles/components/footer.css";

class AppFooter extends Component {
  state = {};
  render() {
    return (
      <footer className="app-footer">
        <nav>
          <img
            className="footer-img"
            alt="UNDP Logo"
            src="/resources/img/undp-white-logo-tagline-en.png"
          />
          <img
            className="footer-img"
            alt="UNDP Logo"
            style={{ float: "right" }}
            src="/resources/img/sdg-footer-en.png"
          />
        </nav>
        <nav>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: "25px",
              paddingRight: "25px",
              fontSize: "12px"
            }}
          >
            <div style={{ paddingBottom: "5px" }}>
              © 2020 United Nations Development Programme
            </div>
            <div
              style={{
                position: "relative",

                paddingBottom: "5px"
              }}
            >
              <strong>Contact Us:&nbsp;&nbsp;</strong>
              <a href="mailto:sdg16indicators@undp.org">
                sdg16indicators@undp.org
              </a>
            </div>
          </div>
        </nav>
      </footer>
    );
  }
}

export default AppFooter;
