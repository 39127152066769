import React, { Component } from "react";

import { connect } from "react-redux";
import { userActions } from "../../_actions";

class UNDPAuth extends Component {
  state = {
    sent: false
  };

  componentDidMount() {
    const search = window.location.search;
    const params = new URLSearchParams(search);

    this.setState({
      code: params.get("code")
    });
  }

  render() {
    const { dispatch } = this.props;

    if (this.state.code && !this.state.sent) {
      this.state.sent = true;

      dispatch(userActions.undpAuth({ code: this.state.code }));
    }

    return <div></div>;
  }
}

function mapStateToProps(state) {
  const { authenticating } = state.authentication;
  return {
    authenticating
  };
}

const connectedUndpAuth = connect(mapStateToProps)(UNDPAuth);
export { connectedUndpAuth as UNDPAuth };
