import React, { Component } from "react";

import { connect } from "react-redux";

import { sdg1671bActions } from "../../../../../_actions";
import { RawDataTable } from "./data/RawDataTable";
import { ProportionsTable } from "./data/ProportionsTable";
import { RatiosTable } from "./data/RatiosTable";

class IndicatorData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      error: props.error
    };

    this.handleTableChange = this.handleTableChange.bind(this);
    this.goToMetadata = this.goToMetadata.bind(this);
  }

  handleTableChange(e) {
    let { dispatch } = this.props;

    let target = e.target.name.split(":");

    // console.log("Target", target);

    dispatch(
      sdg1671bActions.updateDataField(parseInt(target[0]), target[1], e.target.value)
    );
  }

  goToMetadata() {
    this.props.onPrevious();
  }

  getTotalRowNumber(row) {
    let val = 0;

    if (this.props.data && this.props.data.data[0][row]) {
      val += parseInt(this.props.data.data[0][row])
    }

    if (this.props.data && this.props.data.data[1][row]) {
      val += parseInt(this.props.data.data[1][row])
    }

    return val;
  }

  getTotalNumber(index, prefix) {
    let val = 0;

    if (this.props.data && this.props.data.data[index][prefix+"_officials"]) {
      val += parseInt(this.props.data.data[index][prefix+"_officials"])
    }

    if (this.props.data && this.props.data.data[index][prefix+"_executives"]) {
      val += parseInt(this.props.data.data[index][prefix+"_executives"])
    }

    if (this.props.data && this.props.data.data[index][prefix+"_professionals"]) {
      val += parseInt(this.props.data.data[index][prefix+"_professionals"])
    }

    if (this.props.data && this.props.data.data[index][prefix+"_associate"]) {
      val += parseInt(this.props.data.data[index][prefix+"_associate"])
    }

    if (this.props.data && this.props.data.data[index][prefix+"_general"]) {
      val += parseInt(this.props.data.data[index][prefix+"_general"])
    }

    return val;
  }

  getProportionsColumns(index, column1, column2) {

    if (this.props.data && this.props.data.data[index][column1]) {
      return (parseInt(this.props.data.data[index][column1])/parseInt(this.getTotalRowNumber(column2)));
    }
    else return 0;
  }


  render() {

    return (
      <React.Fragment>
        <div className="box-header with-border">
          <div className="col-sm-8">
            <h3>Data fields</h3>
          </div>
        </div>
        <RawDataTable />
        <ProportionsTable />
        <RatiosTable />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { data } = state.sdg1671bState;
  const { user } = state.authentication;


  return {
    user,
    data
  };
}

const connectedIndicatorData = connect(mapStateToProps)(IndicatorData);

export { connectedIndicatorData as IndicatorData };
