import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css";

export const notifySuccess = message => {
  return store.addNotification({
    title: "Success!",
    message: message,
    type: "success",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["anumated", "fadeOut"],
    dismiss: {
      duration: 5000
    },
    width: 350
  });
};

export const notifyError = message => {
  return store.addNotification({
    title: "Error",
    message: message,
    type: "danger",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["anumated", "fadeOut"],
    dismiss: {
      duration: 5000
    },
    width: 350
  });
};
