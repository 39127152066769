import React, { Component } from "react";

import { connect } from "react-redux";

import { sdg1671bActions } from "../../../../../../_actions";
import { TextInput, NumberInput } from "carbon-components-react";

class RatiosTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      error: props.error
    };

    this.goToMetadata = this.goToMetadata.bind(this);
    this.updateDenominator = this.updateDenominator.bind(this);
    this.calculateYouthRatio = this.calculateYouthRatio.bind(this);
    this.calculateRatioWithDenominator = this.calculateRatioWithDenominator.bind(this);
  }

  updateDenominator(e) {
    let { dispatch } = this.props;

    dispatch(
      sdg1671bActions.updateDenominator(e.target.name, parseFloat(e.target.value))
    );
  }

  goToMetadata() {
    this.props.onPrevious();
  }

  getTotalRowNumber(row) {
    let val = 0;

    if (this.props.data && this.props.data.data[0][row]) {
      val += parseInt(this.props.data.data[0][row])
    }

    if (this.props.data && this.props.data.data[1][row]) {
      val += parseInt(this.props.data.data[1][row])
    }

    return val;
  }

  getTotalNumber(index, prefix) {
    let val = 0;

    if (this.props.data && this.props.data.data[index][prefix+"_officials"]) {
      val += parseInt(this.props.data.data[index][prefix+"_officials"])
    }

    if (this.props.data && this.props.data.data[index][prefix+"_executives"]) {
      val += parseInt(this.props.data.data[index][prefix+"_executives"])
    }

    if (this.props.data && this.props.data.data[index][prefix+"_professionals"]) {
      val += parseInt(this.props.data.data[index][prefix+"_professionals"])
    }

    if (this.props.data && this.props.data.data[index][prefix+"_associate"]) {
      val += parseInt(this.props.data.data[index][prefix+"_associate"])
    }

    if (this.props.data && this.props.data.data[index][prefix+"_general"]) {
      val += parseInt(this.props.data.data[index][prefix+"_general"])
    }

    return val;
  }

  getProportionsColumns(index, column1, column2) {

    if (this.props.data && this.props.data.data[index][column1] !== null && this.props.data.data[2][column2] && (this.props.data.data[2][column2]  !== 0)) {
      return ((parseInt(this.props.data.data[index][column1])/parseInt(this.props.data.data[2][column2]))*100.00).toFixed(2);
    }
    else return 0;
  }

  getProportionsTotalsRow(column1, column2) {

    if (this.props.data && this.props.data.data[2][column1] && parseInt(this.props.data.data[2][column1]) !== 0) {
      return ((parseInt(this.props.data.data[2][column1])/parseInt(this.props.data.data[2][column2]))*100.00).toFixed(2);
    }
    else return 0;
  }
  
  getProportionsRowTotals(column1) {

    if (this.props.data && this.props.data.data[2][column1] && parseInt(this.props.data.data[2][column1]) !== 0) {
      // console.log(column1, ((parseInt(this.props.data.data[2][column1])/(parseInt(this.getTotalNumber(2,"total"))))*100.00).toFixed(2))
      return ((parseInt(this.props.data.data[2][column1])/(parseInt(this.props.data.data[2]["total_national"])))*100.00).toFixed(2);
    }
    else return 0;
  }

  getProportionsColumnTotals(index, column1) {

    if (this.props.data && parseInt(this.getTotalNumber(index, column1)) !== 0) {
      return ((parseInt(this.getTotalNumber(index, column1))/(parseInt(this.props.data.data[2]["total_national"])))*100.00).toFixed(2);
    }
    else return 0;
  }

  updateRatioField(column, name) {

    const val = this.getProportionsColumns(1, column, column);

    if (this.props.data &&  this.props.data.ratios &&  this.props.data.ratios[0] && !isNaN(val) && (this.props.data.ratios[0][name] === null || parseFloat(this.props.data.ratios[0][name]).toFixed(2) !== (val/50).toFixed(2))) {
      let { dispatch } = this.props;
      dispatch (
        sdg1671bActions.updateRatios(name, (val/50).toFixed(2))
      )
    }
    return (val/50).toFixed(2)
  }

  calculateYouthRatio() {
    
    const val = ((parseFloat(this.getProportionsRowTotals("below_25")) + parseFloat(this.getProportionsRowTotals("age_25_34")))/this.props.data.denominator["youth_ratio"]).toFixed(2)


    if (this.props.data &&  this.props.data.ratios &&  this.props.data.ratios[0] && !isNaN(val) && (this.props.data.ratios[0]["bellow34_total_national"] === null || parseFloat(this.props.data.ratios[0]["bellow34_total_national"]).toFixed(2) !== val)) {
      let { dispatch } = this.props;
      dispatch (
        sdg1671bActions.updateRatios("bellow34_total_national", val)
      )      
    }

    return val;
  }

  calculateRatioWithDenominator(proportion, denominator, name) {

    const val = (this.getProportionsRowTotals(proportion)/this.props.data.denominator[denominator]).toFixed(2)

    if (this.props.data &&  this.props.data.ratios &&  this.props.data.ratios[0] && !isNaN(val) && ( this.props.data.ratios[0][name] === null || parseFloat(this.props.data.ratios[0][name]).toFixed(2) !== val)) {
      let { dispatch } = this.props;
      dispatch (
        sdg1671bActions.updateRatios(name, val)
      )      
    }

    return val;
  }



  render() {
    //console.log("Render");
    return (
      <React.Fragment>
        <div className="box-body">
          <div className="col-sm-8">
            <h3>TABLE 3: Ratios</h3>
          </div>
          <div className="row">
            <div className="col-sm-12 adaptive-table">
              <fieldset
                disabled={this.props.data && (!this.props.data.id || this.props.data.id === null)}
              >
                <table className="table table-bordered table-1671b">
                  <thead>
                    <tr>
                      <th width="25%"></th>
                      <th style={{background:"#A9D08E"}} colSpan="2">
                        Female representation ratios
                      </th>
                      <th style={{background:"#A9D08E"}} colSpan="2">
                        ‘Youth’ representation ratios
                      </th>
                      <th style={{background:"#A9D08E"}} colSpan="2">
                        Disabled persons representation ratios
                      </th>
                      <th style={{background:"#A9D08E"}} colSpan="2">
                        Population group A representation ratios
                      </th>
                      <th style={{background:"#A9D08E"}} colSpan="2">
                        Population group B representation ratios
                      </th>
                      <th style={{background:"#A9D08E"}} colSpan="2">
                        Population group C representation ratios
                      </th>
                    </tr>
                    <tr>
                      <th width="30%"></th>
                      <th colSpan="2">
                        Proportion of female public servants in [occupational category x] / Proportion of women in the working-age population
                      </th>
                      <th colSpan="2">
                        Proportion of ‘young’ public servants aged 34 and below in [occupational category x] / Proportion of the working-age population aged above the eligibility age for a public service job and below 35
                      </th>
                      <th colSpan="2">
                        Proportion of disabled public servants in [occupational category x] / Proportion of disabled persons in the working-age population
                      </th>
                      <th colSpan="2">
                        Proportion of public servants belonging to population group A in [occupational category x] / Proportion of population group A in the working-age population
                      </th>
                      <th colSpan="2">
                        Proportion of public servants belonging to population group B in [occupational category x] / Proportion of population group B in the working-age population
                      </th>
                      <th colSpan="2">
                        Proportion of public servants belonging to population group C in [occupational category x] / Proportion of population group C in the working-age population
                      </th>
                    </tr>
                    <tr>
                      <th width="30%"></th>
                      <th>
                        Denominator:
                      </th>
                      <th>
                        50%
                      </th>
                      <th>
                        Denominator:
                      </th>
                      <th>
                        Already provided to us by UNDESA for each country (Proportion of the population (both sexes) aged above 18 (the 'default' eligibility age for a public service job) and below 35)
                        <NumberInput
                          id="youth_ratio"
                          name="youth_ratio"
                          // className="input-1671b"
                          label=""
                          onChange={this.updateDenominator}
                          value={
                            this.props.data && this.props.data.denominator && this.props.data.denominator["youth_ratio"] != null
                              ? this.props.data.denominator["youth_ratio"]
                              : 0
                          }
                        />
                      </th>
                      <th>
                        Denominator:
                      </th>
                      <th>
                        Needs to be determined by each country (Proportion of disabled persons in the working-age population, for both sexes, above eligibility age and below retirement age for a public service job)
                        <NumberInput
                          id="disabled_ratio"
                          name="disabled_ratio"
                          // className="input-1671b"
                          label=""
                          onChange={this.updateDenominator}
                          value={
                            this.props.data && this.props.data.denominator && this.props.data.denominator["disabled_ratio"]
                              ? this.props.data.denominator["disabled_ratio"]
                              : 0
                          }
                        />

                      </th>
                      <th>
                        Denominator:
                      </th>
                      <th>
                        Needs to be determined by each country (Proportion of population group A in the working-age population, for both sexes, above eligibility age and below retirement age for a public service job)
                        <NumberInput
                          id="group_a_ratio"
                          name="group_a_ratio"
                          // className="input-1671b"
                          label=""
                          onChange={this.updateDenominator}
                          value={
                            this.props.data && this.props.data.denominator && this.props.data.denominator["group_a_ratio"]
                              ? this.props.data.denominator["group_a_ratio"]
                              : 0
                          }
                        />
                      </th>
                      <th>
                        Denominator:
                      </th>
                      <th>
                        Needs to be determined by each country (Proportion of population group B in the working-age population, for both sexes, above eligibility age and below retirement age for a public service job)
                        <NumberInput
                          id="group_b_ratio"
                          name="group_b_ratio"
                          // className="input-1671b"
                          label=""
                          onChange={this.updateDenominator}
                          value={
                            this.props.data && this.props.data.denominator && this.props.data.denominator["group_b_ratio"]
                              ? this.props.data.denominator["group_b_ratio"]
                              : 0
                          }
                        />
                      </th>
                      <th>
                        Denominator:
                      </th>
                      <th>
                        Needs to be determined by each country (Proportion of population group C in the working-age population, for both sexes, above eligibility age and below retirement age for a public service job)
                        <NumberInput
                          id="group_c_ratio"
                          name="group_c_ratio"
                          // className="input-1671b"
                          label=""
                          onChange={this.updateDenominator}
                          value={
                            this.props.data && this.props.data.denominator && this.props.data.denominator["group_c_ratio"]
                              ? this.props.data.denominator["group_c_ratio"]
                              : 0
                          }
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{background:"#A9D08E"}} colSpan="13">
                        Total public service personnel
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Senior Managers: Senior Government Officials
                      </td>
                      <td colSpan="2">
                        <TextInput
                          id="female_senior_managers"
                          name="female_senior_managers"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleChange}
                          value={this.updateRatioField("total_officials", "female_senior_managers")}
                        />

                      </td>
                    </tr>
                    <tr>
                      <td>
                        Other Managers: Managing Directors and Chief Executives and Business Services and Administration Managers
                      </td>
                      <td colSpan="2">
                        <TextInput
                          id="female_other_managers"
                          name="female_other_managers"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleChange}
                          value={this.updateRatioField("total_executives", "female_other_managers")}
                        />

                      </td>
                    </tr>
                    <tr>
                      <td>
                        Administration Professionals
                      </td>
                      <td colSpan="2">
                        <TextInput
                          id="female_administration_professionals"
                          name="female_administration_professionals"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleChange}
                          value={this.updateRatioField("total_professionals", "female_administration_professionals")}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Business and Administration Associate Professionals
                      </td>
                      <td colSpan="2">
                        <TextInput
                          id="female_ba_associate_professionals"
                          name="female_ba_associate_professionals"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleChange}
                          value={this.updateRatioField("total_associate", "female_ba_associate_professionals")}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        General and Keyboard Clerks
                      </td>
                      <td colSpan="2">
                        <TextInput
                          id="female_gk_clerks"
                          name="female_gk_clerks"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleChange}
                          value={this.updateRatioField("total_general", "female_gk_clerks")}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Total national-level public service personnel (including police, education, health, front-desk administrative and all other public service personnel)
                      </td>
                      <td colSpan="2">
                        <TextInput
                          id="female_total_national"
                          name="female_total_national"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleChange}
                          value={this.updateRatioField("total_national", "female_total_national")}
                        />
                      </td>
                      <td colSpan="2">
                        <TextInput
                          id="bellow34_total_national"
                          name="bellow34_total_national"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleChange}
                          value={
                            this.props.data && this.props.data.denominator && this.props.data.denominator["youth_ratio"]
                              ? this.calculateYouthRatio()
                              : 0
                          }
                        />
                      </td>
                      <td colSpan="2">
                        <TextInput
                          id="disabled_total_national"
                          name="disabled_total_national"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleChange}
                          value={
                            this.props.data  && this.props.data.denominator && this.props.data.denominator["disabled_ratio"] 
                              ? this.calculateRatioWithDenominator("disability", "disabled_ratio", "disabled_total_national")
                              : 0
                          }
                        />
                      </td>
                      <td colSpan="2">
                        <TextInput
                          id="population_a_total_national"
                          name="population_a_total_national"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleChange}
                          value={
                            this.props.data  && this.props.data.denominator && this.props.data.denominator["group_a_ratio"]
                              ? this.calculateRatioWithDenominator("group_a_value", "group_a_ratio", "population_a_total_national")
                              : 0
                          }
                        />
                      </td>
                      <td colSpan="2">
                      <TextInput
                          id="population_b_total_national"
                          name="population_b_total_national"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleChange}
                          value={
                            this.props.data  && this.props.data.denominator && this.props.data.denominator["group_b_ratio"]
                              ? this.calculateRatioWithDenominator("group_b_value", "group_b_ratio", "population_b_total_national")
                              : 0
                          }
                        />
                      </td>
                      <td colSpan="2">
                      <TextInput
                          id="population_c_national"
                          name="population_c_national"
                          // className="input-1671b"
                          labelText=""
                          onChange={this.handleChange}
                          value={
                            this.props.data  && this.props.data.denominator && this.props.data.denominator["group_c_ratio"]
                              ? this.calculateRatioWithDenominator("group_c_value", "group_c_ratio", "population_c_national")
                              : 0
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </fieldset>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { data } = state.sdg1671bState;

  return {
    data
  };
}

const connectedIndicatorData = connect(mapStateToProps)(RatiosTable);

export { connectedIndicatorData as RatiosTable };
