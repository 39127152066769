export const sdg1671cConstants = {
  GET_1671c_DATA_SUCCESS: "GET_1671c_DATA_SUCCESS",
  GET_1671c_DATA_FAILURE: "GET_1671c_DATA_FAILURE",
  UPDATE_1671c_DATA_SUCCESS: "UPDATE_1671c_DATA_SUCCESS",
  UPDATE_1671c_DATA_FAILURE: "UPDATE_1671c_DATA_FAILURE",
  UPDATE_1671c_DATA_FIELD: "UPDATE_1671c_DATA_FIELD",
  UPDATE_1671c_PROPORTIONS_FIELD: "UPDATE_1671c_PROPORTIONS_FIELD",
  UPDATE_1671c_RATIOS_FIELD: "UPDATE_1671c_RATIOS_FIELD",
  UPDATE_1671c_METADATA_FIELD: "UPDATE_1671c_METADATA_FIELD",
  CLEAR_1671c_DATA: "CLEAR_1671c_DATA",
  SAVE_1671c_DATA_SUCCESS: "SAVE_1671c_DATA_SUCCESS",
  SAVE_1671c_DATA_FAILURE: "SAVE_1671c_DATA_FAILURE",
  UPDATE_1671c_DENOMINATOR_FIELD: "UPDATE_1671c_DENOMINATOR_FIELD"
};
