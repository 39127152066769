import axios from "axios";
import { userService } from ".";

import {
  notifyError,
  notifySuccess,
} from "../components/common/notification/NotificationHelper";

export const sdg1671bService = {
  getIndicatorData,
  saveIndicatorData,
  validateIndicatorData
};

function getIndicatorData(id) {
  return axios.get("/api/v2/sdg1671b/" + id + "/").then(response => {
    return response.data;
  });
}

function saveIndicatorData(data) {
  if (data.id !== undefined && data.id !== null) {
    return axios.put("/api/v2/sdg1671b/" + data.id + "/", data).then(response => {
      notifySuccess("Data saved succesfully!");
      return response.data;
    });
  } else {
    return axios.post("/api/v2/sdg1671b/", data).then(response => {
      notifySuccess("Data saved succesfully!");
      return response.data;
    });
  }
}

function validateIndicatorData(validateStr, data) {
  return axios.post("/api/v2/" + validateStr, data).then(response => {
    if (validateStr.indexOf("/validate") !== -1)
    notifySuccess("Data succesfully validated!");
    else
    notifySuccess("Data unlocked for edit!");
    return response.data;
  });
}
